import {
  DataType,
  ExamLanguageDTO,
  FunctionParameterAndReturnTypeDTO,
  LanguageDTO,
  TestCaseDTO,
} from '@phs/interfaces';
import { CodeGenerator } from '@phs/code';

export interface FormProps {
  title: string; // 제목
  level: number; // 난이도
  detailLevel: number; // 세부 난이도
  overallPredictionLevel: number | null;
  overallPredictionAverageScore: number | null;
  algorithmTypeList: string[]; // 알고리즘 분류
  content?: string; // 설명
  parameters?: FunctionParameterAndReturnTypeDTO; // 매개변수 & Return Type
  languages: ExamLanguageDTO[]; // 응시 언어
  exampleTestCases: TestCaseDTO[]; // 예시 테스트 케이스
  accuracyTestCases?: TestCaseDTO[]; // 정확도 테스트 케이스
  accuracyFileTestCases?: TestCaseDTO[]; // 대용량 정확도 테스트 케이스
  efficiencyTestCases?: TestCaseDTO[]; // 효율성 테스트 케이스
  efficiencyFileTestCases?: TestCaseDTO[]; // 대용량 효율성 테스트 케이스
  scoreRateYn?: boolean;
  accuracyScoreRate?: number;
  efficiencyScoreRate?: number;
}

const fallback: FormProps = {
  title: '',
  level: 0,
  detailLevel: 0,
  algorithmTypeList: [],
  overallPredictionAverageScore: null,
  overallPredictionLevel: null,
  content: '',
  parameters: {
    paramInfo: [
      {
        paramType: DataType.STRING,
        paramName: 'Parameter',
      },
    ],
    returnType: DataType.STRING,
  },
  languages: [],
  exampleTestCases: [],
  accuracyTestCases: [],
  accuracyFileTestCases: [],
  efficiencyTestCases: [],
  efficiencyFileTestCases: [],
  scoreRateYn: false,
  accuracyScoreRate: 50,
  efficiencyScoreRate: 50,
};

export const initWithFallback = (data: LanguageDTO[]): FormProps => {
  return {
    ...fallback,
    languages: CodeGenerator.getLanguageTemplate(data) || [],
  };
};
