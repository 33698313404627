import { CompanySortType } from '@interfaces/company/enum.ts';
import { companySortType } from '@stores/atoms/manage/company.sort.ts';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { HandleTableClickProps } from '@widget/table';
import { CompanyDTO } from '@interfaces/company/company.dto';
import { SortType } from '@widget/table/src/Sort/enum';
import { useRef } from 'react';

export function useSort() {
  const setSortType = useSetRecoilState(companySortType);
  const resetSortType = useResetRecoilState(companySortType);
  const map = useRef<Map<string, SortType>>(new Map<string, SortType>());

  const getSortValue = (key: string, sortType?: SortType) => {
    return `${key.toUpperCase()},${sortType}` as CompanySortType;
  };

  const convertMapToSortType = () => {
    const sortType = [...map.current.keys()]
      .filter((key) => map.current.get(key) !== SortType.NONE)
      .map((key) => getSortValue(key, map.current.get(key)));
    setSortType(sortType);
  };

  const onClick = (props: HandleTableClickProps<CompanyDTO>) => {
    if (!props.sortType) return;
    map.current.set(props.key, props.sortType);
    convertMapToSortType();
  };

  return {
    onClick,
    resetSortType,
  };
}
