import { selector } from 'recoil';
import { stateKeys } from '@constants/key.ts';
import { functionParametersAndReturnTypes } from '@stores/atoms/exam/exam.function.ts';
import {
  FunctionParameterAndReturnTypeDTO,
  TestCaseArgsDTO,
} from '@phs/interfaces';
import { CodeTransformer } from '@phs/code';

export const functionTemplateParameters =
  selector<FunctionParameterAndReturnTypeDTO>({
    key: stateKeys.exam.functionTemplateParameters,
    get: ({ get }) => {
      return get(functionParametersAndReturnTypes);
    },
  });

export const functionInputAndOutput = selector({
  key: stateKeys.exam.functionInputAndOutput,
  get: ({ get }) => {
    const params = get(functionParametersAndReturnTypes);

    return {
      params: params.paramInfo.map(({ paramName, paramType }) => ({
        parameterType: paramType,
        parameterName: paramName,
        value: '',
      })),
      returnType: params.returnType,
    };
  },
});

// 예시/정확도/효율성 테이블 UI
export const functionTestCaseArgs = selector<TestCaseArgsDTO[]>({
  key: stateKeys.exam.functionParams,
  get: ({ get }) => {
    const params = get(functionParametersAndReturnTypes);

    return params.paramInfo.map(({ paramName, paramType }) => ({
      parameterType: paramType,
      parameterName: paramName,
      value: '',
    }));
  },
});

export const functionReturnType = selector<string>({
  key: stateKeys.exam.functionReturns,
  get: ({ get }) => {
    return CodeTransformer.dataType2Text[
      get(functionParametersAndReturnTypes).returnType
    ];
  },
});
