import { useMemo, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { TabExpandableItemProps } from '@widget/tab-expandable';
import { ExamLanguageDTO, ModifyCommandType } from '@phs/interfaces';
import { CodeGenerator, CodeTestCase } from '@phs/code';
import { FormProps } from '@pages/Exam/shared/config.ts';

interface UseLanguageProps {
  languages?: ExamLanguageDTO[];
  setValue: UseFormSetValue<FormProps>;
  updateLanguageCallback: (languages: ExamLanguageDTO[]) => void;
}

export function useLanguage({
  languages = [],
  setValue,
  updateLanguageCallback,
}: UseLanguageProps) {
  const [isModelOpened, setIsModalOpened] = useState<boolean>(false);
  const [cursor, setCursor] = useState<number>(0);

  const currentCursor = useMemo(() => cursor, [cursor]);

  const currentLanguages = useMemo(
    () => languages.filter(({ usage }) => usage) ?? [],
    [languages],
  );

  const currentLanguage = useMemo(() => {
    if (currentLanguages.length === 0) return undefined;

    return currentLanguages[currentCursor];
  }, [currentCursor, currentLanguages, languages]);

  const currentTabItems = useMemo(() => {
    return (
      currentLanguages.map(({ language }) => ({
        name: language,
        key: language,
      })) ?? ([] as TabExpandableItemProps[])
    );
  }, [currentLanguages]);

  const onChangeCode = (type: string, code?: string) => {
    const newLanguages = languages.slice();
    const targetIndex = newLanguages.findIndex(
      (language) => language.language === currentLanguage?.language,
    );
    if (targetIndex === -1) return;
    const targetLanguage = newLanguages[targetIndex];

    targetLanguage[
      type as keyof Pick<ExamLanguageDTO, 'defaultSource' | 'expectedSource'>
    ] = code ?? '';
    if (targetLanguage.commandType === ModifyCommandType.READ) {
      targetLanguage.commandType = ModifyCommandType.UPDATE;
    }

    newLanguages[targetIndex] = targetLanguage;
    setValue('languages', newLanguages);
  };

  const onChangeLanguages = (data: ExamLanguageDTO[]) => {
    const languages = data.map(
      ({
        defaultSource,
        expectedSource,
        usage,
        language,
        commandType,
        ...rest
      }) => {
        if (usage && !commandType) {
          commandType = ModifyCommandType.CREATE;
        } else if (usage && commandType === ModifyCommandType.DELETE) {
          commandType = ModifyCommandType.UPDATE;
        } else if (
          (!usage && commandType === ModifyCommandType.READ) ||
          commandType === ModifyCommandType.UPDATE
        ) {
          commandType = ModifyCommandType.DELETE;
        } else if (!usage && commandType === ModifyCommandType.CREATE) {
          commandType = undefined;
        }

        return {
          ...rest,
          usage,
          language,
          commandType,
          defaultSource:
            defaultSource === '' && usage
              ? CodeGenerator.getStdioTemplate(language)
              : defaultSource,
          expectedSource:
            commandType === CodeTestCase.commandType.CREATE
              ? ''
              : expectedSource,
        };
      },
    );
    updateLanguageCallback(languages);
    setValue('languages', languages);
    setIsModalOpened(false);
  };

  const onChangeCursor = (idx: number) => setCursor(idx);

  const onRemove = (idx: number) => {
    if (!currentTabItems[idx] || Number.isNaN(idx)) return;

    const { key: targetKey } = currentTabItems[idx];

    const newLanguages = languages.map((props) => {
      if (props.language === targetKey) {
        return {
          ...props,
          usage: false,
        };
      }

      return props;
    });

    onChangeLanguages(newLanguages);
    onChangeCursor(0);
  };

  return {
    isModelOpened,
    setIsModalOpened,
    currentTabItems,
    currentLanguages,
    currentLanguage,
    currentCursor,
    onChangeCode,
    onChangeLanguages,
    onChangeCursor,
    onRemove,
  };
}
