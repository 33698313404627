import { UseFormSetValue } from 'react-hook-form';
import ExpandableTab from '@widget/tab-expandable';
import ModalWidget from '@widget/modal';
import { ExamLanguageDTO } from '@phs/interfaces';
import { FormProps } from '@pages/Exam/shared/config.ts';
import { RequireTitle } from './style.ts';
import { useLanguage } from './hooks.ts';
import Code from './Code';
import Empty from './Empty';
import Modal from './Modal';
import { useMemo } from 'react';

interface LanguageProps {
  languages: ExamLanguageDTO[];
  setValue: UseFormSetValue<FormProps>;
  updateLanguageCallback: (languages: ExamLanguageDTO[]) => void;
}

function Language({
  languages,
  setValue,
  updateLanguageCallback,
}: LanguageProps) {
  const {
    isModelOpened,
    currentLanguages,
    currentTabItems,
    currentLanguage,
    setIsModalOpened,
    onChangeCode,
    onChangeCursor,
    onChangeLanguages,
    onRemove,
  } = useLanguage({
    languages,
    setValue,
    updateLanguageCallback,
  });

  const noLanguage = useMemo(() => {
    return languages.every(({ usage }) => !usage);
  }, [languages]);

  return (
    <>
      <RequireTitle className={noLanguage ? 'invalid' : ''}>
        언어 선택 & 설정
      </RequireTitle>
      <ExpandableTab
        item={currentTabItems}
        activeKey={currentLanguage?.language}
        onClick={onChangeCursor}
        onAdd={() => setIsModalOpened(true)}
        onRemove={onRemove}
      />
      {currentLanguage && <Code {...currentLanguage} onChange={onChangeCode} />}
      {currentLanguages.length === 0 && (
        <Empty onClick={() => setIsModalOpened(true)}>
          +를 눌러 언어를 선택해주세요
        </Empty>
      )}
      {isModelOpened && (
        <ModalWidget>
          <Modal
            currentLanguages={languages}
            onConfirm={onChangeLanguages}
            onClose={() => setIsModalOpened(false)}
          />
        </ModalWidget>
      )}
    </>
  );
}

export default Language;
