import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import Tab from '@widget/tab';
import { PMS_PAGES } from '@phs/constants';
import { useTab } from '@hooks/useTab.ts';
import Header from './Header';
import { Menu } from './config';
import { Container } from './style';

const DashboardPage: FC = () => {
  const { currentTab, onClick } = useTab({
    items: Menu,
    navigateList: [PMS_PAGES.DASHBOARD.HOME, PMS_PAGES.DASHBOARD.PACKAGES],
  });

  return (
    <Container>
      <Header />
      <Tab item={Menu} activeKey={currentTab} onClick={onClick} />
      <Outlet />
    </Container>
  );
};

export default DashboardPage;
