/* eslint-disable indent */
import { localStorage } from '@helpers/storage';
import { Nullable } from '@phs/interfaces';

export const syncAccessTokenEffects =
	(key: string) =>
	({ setSelf, onSet }: any) => {
		const accessToken: Nullable<string> = localStorage.get(key);

		if (accessToken !== null) {
			setSelf(JSON.parse(accessToken));
		}

		if (accessToken === null) {
			setSelf(null);
		}

		onSet((newValue: Nullable<string>, _: any, isReset: boolean) => {
			isReset ? localStorage.remove(key) : localStorage.set(key, newValue);
		});
	};

export const syncRefreshTokenEffects =
	(key: string) =>
	({ setSelf, onSet }: any) => {
		const refreshToken: Nullable<string> = localStorage.get(key);

		if (refreshToken !== null) {
			setSelf(JSON.parse(refreshToken));
		}

		if (refreshToken === null) {
			setSelf(null);
		}

		onSet((newValue: Nullable<string>, _: any, isReset: boolean) => {
			isReset ? localStorage.remove(key) : localStorage.set(key, newValue);
		});
	};
