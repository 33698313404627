import { PhaseProblemDTO } from '@phs/interfaces';
import { TabExpandableItemProps } from '@widget/tab-expandable';

export const DefaultTabItem: TabExpandableItemProps = {
  name: '기본 설정',
  key: 'default',
  immutable: true,
};

export interface PackageExamFormProps {
  sn?: number;
  title: string;
  manualDifficultyYn: boolean;
  level: number;
  detailLevel: number;
  overallPredictionLevel: number | null;
  overallPredictionAverageScore: number | null;
  stepProblemList: PhaseProblemDTO[];
  onlyProblemUpdateYn: boolean;
}

export const fallback: PackageExamFormProps = {
  sn: undefined,
  title: '',
  manualDifficultyYn: false,
  level: 0,
  detailLevel: 0,
  overallPredictionAverageScore: null,
  overallPredictionLevel: null,
  stepProblemList: [],
  onlyProblemUpdateYn: false,
};
