import { Input } from './style';
import { forwardRef } from 'react';
import type {
  DetailedHTMLProps,
  InputHTMLAttributes,
  ForwardedRef,
} from 'react';

export interface NumberInputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  error?: string;
}

const NumberInput = (
  { error, ...props }: NumberInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  return <Input {...props} error={error} ref={ref} />;
};

export default forwardRef<HTMLInputElement, NumberInputProps>(NumberInput);
