import type { RouteObject } from 'react-router-dom';
import { PMS_PAGES } from '@phs/constants';
import AuthenticatedLayout from '@layout/Authenticated';
import DashboardLayout from '@layout/Dashboard';
import DashboardPage from '@pages/Dashboard';
import SingularExam from '@pages/Dashboard/Singular';
import PackageExam from '@pages/Dashboard/Package';

const DashboardRoute: RouteObject = {
  path: PMS_PAGES.DASHBOARD.HOME,
  element: (
    <AuthenticatedLayout>
      <DashboardLayout />
    </AuthenticatedLayout>
  ),
  children: [
    {
      path: PMS_PAGES.DASHBOARD.HOME,
      element: <DashboardPage />,
      children: [
        {
          index: true,
          element: <SingularExam />,
        },
        {
          path: PMS_PAGES.DASHBOARD.PACKAGES,
          element: <PackageExam />,
        },
      ],
    },
  ],
};

export default DashboardRoute;
