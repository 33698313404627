import {
  Container,
  OptionGroup,
  Option,
  DisplayArea,
  SelectedValue,
  ArrowSvg,
} from './style';
import {
  DropDownMultiplePropsOnClickPropsItemProps,
  useMultipleDropDown,
  useOutsideClick,
} from './hooks';
import { FC, ReactNode } from 'react';
import CheckBox from '@widget/checkbox';

export type { DropDownMultiplePropsOnClickPropsItemProps };
export type DropDownMultiplePropsOnClickProps = Map<
  number,
  DropDownMultiplePropsOnClickPropsItemProps
>;

export interface DropDownMultipleProps {
  prefixIcon?: ReactNode;
  title: string;
  items: DropDownMultiplePropsOnClickPropsItemProps[];
  value: string[];
  onChange: (props: string[]) => void;
}

const DropDownMultiple: FC<DropDownMultipleProps> = ({
  prefixIcon,
  title,
  items = [],
  onChange,
  value,
}) => {
  const { isOpen, setOpen, handleClick, placeholder } = useMultipleDropDown({
    title,
    isOpen: false,
    onChange: onChange,
    items,
    value,
  });

  const ref = useOutsideClick(() => {
    setOpen(false);
  });

  return (
    <Container ref={ref}>
      <OptionGroup open={isOpen}>
        {items.map(({ name, key }) => (
          <Option
            key={`widget-select-option-${key}`}
            onClick={(e) => {
              handleClick(key);
              e.preventDefault();
            }}
          >
            <a>
              <CheckBox id={key} isActive={value.includes(key)} />
              <span>{name}</span>
            </a>
          </Option>
        ))}
      </OptionGroup>
      <DisplayArea onClick={() => setOpen(!isOpen)} open={isOpen}>
        <SelectedValue>
          {
            <>
              {prefixIcon && prefixIcon}
              {placeholder}
            </>
          }
        </SelectedValue>
        <ArrowSvg
          open={isOpen}
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M11.9998 14.8247C11.8029 14.8246 11.6082 14.7842 11.4276 14.706C11.247 14.6277 11.0844 14.5133 10.9498 14.3697L7.38477 10.6097L8.61477 9.43969L11.9998 12.9997L15.3848 9.42969L16.6148 10.5997L13.0498 14.3697C12.9151 14.5133 12.7525 14.6277 12.5719 14.706C12.3913 14.7842 12.1966 14.8246 11.9998 14.8247Z'
            fill='#868E96'
          />
        </ArrowSvg>
      </DisplayArea>
    </Container>
  );
};

export default DropDownMultiple;
