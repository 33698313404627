import { FC } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { PackageExamFormProps } from '@pages/Exam/package/config.ts';
import {
  FunctionParameterAndReturnTypeDTO,
  PhaseProblemSourceDTO,
} from '@phs/interfaces';
import ExpandableTab from '@widget/tab-expandable';
import Code from './Code';
import { RequireTitle } from './style.ts';
import { useLanguage } from './hooks.ts';

interface LanguageProps {
  params: FunctionParameterAndReturnTypeDTO;
  tabIdx: number;
  languages?: PhaseProblemSourceDTO[];
  setValue: UseFormSetValue<PackageExamFormProps>;
}

const Language: FC<LanguageProps> = ({
  tabIdx,
  languages = [],
  setValue,
  params,
}) => {
  const {
    currentTabItems,
    currentLanguage,
    onChangeCode,
    onChangeCursor,
    onChangeToggle,
  } = useLanguage({
    params,
    tabIdx,
    languages,
    setValue,
  });

  return (
    <>
      <RequireTitle>언어별&nbsp;설정</RequireTitle>
      <ExpandableTab
        item={currentTabItems}
        activeKey={currentLanguage?.language}
        onClick={onChangeCursor}
        mode={'read'}
      />
      {currentLanguage && (
        <Code
          {...currentLanguage}
          hasInitCode={tabIdx === 0}
          onChange={onChangeCode}
          onToggle={onChangeToggle}
        />
      )}
    </>
  );
};

export default Language;
