import styled from '@emotion/styled';

export const CustomTable = styled.table`
  width: 100%;
  margin-top: 12px;

  th {
    min-width: 200px;
    height: 20px;
    vertical-align: middle;
    background: ${({ theme }) => theme.colors.gray_500};
    border: 1px solid ${({ theme }) => theme.colors.gray_300};
  }

  td {
    height: 30px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    vertical-align: middle;
    background: ${({ theme }) => theme.colors.gray_100};
    border: 1px solid ${({ theme }) => theme.colors.gray_300};
  }
`;

export const NumberList = styled.div`
  display: flex;
  padding: 0 12px;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

export const NumberText = styled.div`
  width: 60px;
  height: 24px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border: 1px solid ${({ theme }) => theme.colors.gray_400};
  color: ${({ theme }) => theme.colors.gray_700};
`;
