import { SERVICE_REGISTRY } from '../constants/service';

export const ServiceTypes = {
  GENERATOR: Symbol.for(SERVICE_REGISTRY.GENERATOR),
  VALIDATOR: Symbol.for(SERVICE_REGISTRY.VALIDATOR),
  TRANSFORMER: Symbol.for(SERVICE_REGISTRY.TRANSFORMER),
  TESTCASE: Symbol.for(SERVICE_REGISTRY.TESTCASE),
  FILE_HANDLER: Symbol.for(SERVICE_REGISTRY.FILE_HANDLER),
  PREVIEW: Symbol.for(SERVICE_REGISTRY.PREVIEW),
};
