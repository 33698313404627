import { FC } from 'react';
import { useSetRecoilState } from 'recoil';
import DropDown from '@widget/dropdown';
import { pmsAccessToken } from '@stores/atoms/shared/auth.ts';
import { UserWrap } from './style';
import AuthRepository from '@repositories/auth.repository';

const User: FC = () => {
  const resetAcessToken = useSetRecoilState(pmsAccessToken);
  const onClick = async () => {
    await AuthRepository.logout();
    resetAcessToken(null);
  };

  return (
    <UserWrap>
      <DropDown
        title={'관리자'}
        items={[
          {
            name: '로그아웃',
            key: 'logout',
          },
        ]}
        onClick={onClick}
      />
    </UserWrap>
  );
};

export default User;
