import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  width: max-content;
  min-width: 582px;
  margin: auto;
`;

export const Page = styled.a<{ isActive?: boolean }>`
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 6px;
  box-sizing: border-box;
  font-family: inherit;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${({ isActive }) => (isActive ? '#343a40' : '#868e96')};
  background: ${({ isActive }) => (isActive ? '#dee2e6' : 'transparent')};
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    color: #868e96;
    background: #dee2e6;
  }

  &:active {
    color: #343a40;
    background: #dee2e6;
  }
`;

export const Anchor = styled.a<{ isDisabled?: boolean }>`
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};

  svg {
    path {
      fill: ${({ isDisabled }) =>
        isDisabled ? 'rgba(134, 142, 150, 0.4)' : '#868E96'};
    }
  }

  &:hover {
    background: ${({ isDisabled }) => (isDisabled ? 'transparent' : '#dee2e6')};
  }
`;
