import { Container } from './style';
import { useAlertModal } from './hook.ts';
import { CSSProperties, FC, PropsWithChildren } from 'react';
import ModalWidget from '@widget/modal';
import TextButton from '@widget/button-text';

interface AlertModalProps extends PropsWithChildren {
  confirmText?: string;
  style?: CSSProperties;
}

const AlertModal: FC<AlertModalProps> = ({
  children,
  confirmText = '확인',
  style,
}) => {
  const { info, onClose } = useAlertModal();
  if (!info.status) return null;

  return (
    <ModalWidget>
      <Container style={style}>
        {children || info.text}
        <TextButton
          theme={'primary'}
          style={{
            width: 128,
            marginTop: 'auto',
          }}
          onClick={onClose}
        >
          {confirmText}
        </TextButton>
      </Container>
    </ModalWidget>
  );
};

export default AlertModal;
