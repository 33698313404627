import { injectable } from 'inversify';
import 'reflect-metadata';
import { Language } from './base';
import { ExamParameterDTO } from '../interfaces/language.dto';

@injectable()
export class Java extends Language {
	public get defaultTimeLimit(): number {
		return 3000;
	}

	public loadFunctionCode(parameters: ExamParameterDTO): string {
		return parameters.paramInfo
			.map(({ paramType, paramName }) => {
				const returnType = this.returnType[paramType];
				return `${returnType.paramType} ${paramName}`.trim();
			})
			.join(', ');
	}

	public getFunctionTemplate(parameters: ExamParameterDTO): string {
		const { type, value } = this.returnType[parameters.returnType];

		return `class Solution {\n    public ${type} solution(${this.loadFunctionCode(
			parameters,
		)}) {\n        ${type} answer${value};\n        return answer;\n    }\n}`;
	}

	public getStdioTemplate() {
		return 'import java.util.Scanner;\n\npublic class Main {\n    public static void main(String[] args) {\n        Scanner sc = new Scanner(System.in);\n        int a = sc.nextInt();\n        int b = sc.nextInt();\n\n        System.out.println(a + b);\n    }\n}';
	}

	public get returnType() {
		return {
			// Primitive Types
			INT: this.setReturnType('int', 'int', ' = 0'),
			LONG: this.setReturnType('long', 'long', ' = 0'),
			DOUBLE: this.setReturnType('double', 'double', ' = 0'),
			STRING: this.setReturnType('String', 'String', ' = ""'),
			BOOL: this.setReturnType('boolean', 'boolean', ' = true'),
			// Reference Types
			INT_ARRAY: this.setReturnType('int[]', 'int[]', ' = {}'),
			LONG_ARRAY: this.setReturnType('long[]', 'long[]', ' = {}'),
			DOUBLE_ARRAY: this.setReturnType('double[]', 'double[]', ' = {}'),
			STRING_ARRAY: this.setReturnType('String[]', 'String[]', ' = {}'),
			BOOL_ARRAY: this.setReturnType('boolean[]', 'boolean[]', ' = {}'),
			// 2 Dimensional Reference Types
			INT_2_ARRAY: this.setReturnType('int[][]', 'int[][]', ' = {{}}'),
			LONG_2_ARRAY: this.setReturnType('long[][]', 'long[][]', ' = {{}}'),
			DOUBLE_2_ARRAY: this.setReturnType('double[][]', 'double[][]', ' = {{}}'),
			STRING_2_ARRAY: this.setReturnType('String[][]', 'String[][]', ' = {{}}'),
			BOOL_2_ARRAY: this.setReturnType('boolean[][]', 'boolean[][]', ' = {{}}'),
		};
	}
}
