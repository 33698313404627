import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { useMutation } from '@tanstack/react-query';
import { isSameString } from '@phs/helpers';
import AuthRepository from '@repositories/auth.repository.ts';
import { useAlertModal } from '@components/Modals/Alert/hook.ts';
import {
  passwordModalPhrase,
  passwordModalStatus,
} from '@stores/atoms/shared/modal.ts';
import { ForcePasswordChangeReqDTO } from '@interfaces/shared/auth.dto.ts';
import { zodResolver } from '@hookform/resolvers/zod';
import { schema } from './scheme.ts';
import { AxiosError, isAxiosError } from 'axios';
import { RuntimeExceptionHandler } from '@phs/exceptions';
import { PMS_EXCEPTION_MESSAGES } from '@constants/exceptions.ts';
import { ERROR_CODE, ERROR_MESSAGE } from '@constants/error.tsx';

interface UseFormProps {
  newPassword: string;
  checkPassword: string;
  currentPassword: string;
}

interface UsePasswordModalProps {
  email: string;
  currentPassword: string;
}

export function usePasswordModal({
  email,
  currentPassword,
}: UsePasswordModalProps) {
  const [isOpen, setIsOpen] = useRecoilState(passwordModalStatus);
  const [phrase, setPharse] = useRecoilState(passwordModalPhrase);
  const { onOpen } = useAlertModal();
  const { mutate } = useMutation({
    mutationFn: AuthRepository.forceChangePassword,
  });
  const { register, setValue, handleSubmit, getValues, watch } =
    useForm<UseFormProps>({
      defaultValues: {
        newPassword: '',
        checkPassword: '',
        currentPassword: currentPassword,
      },
      resolver: zodResolver(schema),
    });

  const isDisabledSubmit = useMemo(
    () => getValues('newPassword') === '' || getValues('checkPassword') === '',
    [watch('newPassword'), watch('checkPassword')],
  );

  useEffect(() => {
    if (isOpen) {
      setValue('currentPassword', currentPassword);
    }
  }, [isOpen]);

  const forcePasswordChange = (
    data: ForcePasswordChangeReqDTO,
    onSuccess?: (res: boolean) => void,
  ) => {
    mutate(data, {
      onError: (error: unknown) => {
        if (isAxiosError(error)) {
          const { response } = error as AxiosError;

          switch ((response?.data as any)?.code) {
            case ERROR_CODE.INVALID_PASSWORD:
            default:
              onOpen(
                (response?.data as any)?.message ||
                  ERROR_MESSAGE[ERROR_CODE.INVALID_PASSWORD],
              );
              break;
          }
        } else {
          onOpen('알 수 없는 에러가 발생하였습니다.');

          new RuntimeExceptionHandler({
            error,
            message: PMS_EXCEPTION_MESSAGES.PMS_API_ERROR,
          });
        }
      },
      onSuccess,
    });
  };

  const open = (isFirst: boolean) => {
    setPharse(
      isFirst
        ? '첫 로그인입니다.'
        : '동일한 비밀번호를 90일 이상 사용하고 계십니다.',
    );
    setIsOpen(true);
  };

  const close = () => setIsOpen(false);

  const onSubmit = (data: UseFormProps) => {
    const { newPassword, checkPassword, currentPassword } = data;

    if (isSameString(currentPassword, newPassword)) {
      return onOpen('이전과 동일한 비밀번호는 설정할 수 없습니다.');
    }

    if (!isSameString(newPassword, checkPassword)) {
      return onOpen('비밀번호 확인이 일치하지 않습니다.');
    }

    forcePasswordChange(
      {
        id: email,
        currentPassword,
        newPassword,
      },
      () => close(),
    );
  };

  return {
    isDisabledSubmit,
    isOpen,
    open,
    close,
    register,
    handleSubmit,
    onSubmit,
    phrase,
  };
}
