import { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { packageExamLanguages } from '@stores/atoms/exam/exam.package.ts';
import { functionTemplateParameters } from '@stores/selectors/exam/function.ts';
import { useDebouncedCallback } from '@react-hookz/web';
import { ExamLanguageDTO } from '@phs/interfaces';
import { CodeGenerator, CodeTestCase } from '@phs/code';

interface UseLanguageProps {
  languages: ExamLanguageDTO[];
  updateLanguageCallback: (languages: ExamLanguageDTO[]) => void;
}

export function useLanguage({
  languages,
  updateLanguageCallback,
}: UseLanguageProps) {
  const params = useRecoilValue(functionTemplateParameters);
  const setLanguages = useSetRecoilState(packageExamLanguages);
  const [isOpenedModal, setIsOpenedModal] = useState<boolean>(false);

  const onChange = useDebouncedCallback<(idx: number, usage: boolean) => void>(
    (idx, usage) => {
      const { defaultSource, commandType, expectedSource, language } =
        languages[idx];

      const newLanguages = [
        ...languages.slice(0, idx),
        {
          ...languages[idx],
          usage,
          referenceSourceYn: false,
          referenceSource: '',
          commandType:
            usage && commandType === CodeTestCase.commandType.READ
              ? CodeTestCase.commandType.CREATE
              : usage && !commandType
              ? CodeTestCase.commandType.CREATE
              : commandType,
          defaultSource:
            defaultSource === '' && usage
              ? CodeGenerator.getFunctionTemplate(language, params)
              : defaultSource,
          expectedSource:
            commandType === CodeTestCase.commandType.CREATE
              ? ''
              : expectedSource,
        },
        ...languages.slice(idx + 1),
      ];

      setLanguages(newLanguages);
      updateLanguageCallback(newLanguages);
    },
    [languages],
    30,
  );

  return {
    isOpenedModal,
    setIsOpenedModal,
    languages,
    onChange,
  };
}
