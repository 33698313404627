import { useCallback, useRef } from 'react';
import { type ResizeCallback, type ResizeStartCallback } from 're-resizable';

export function useResize({ minHeight = 1000 }: { minHeight?: number }) {
  const changedHeightRef = useRef(1000);
  const originalHeightRef = useRef(1000);

  const onResizeStart: ResizeStartCallback = () => {
    changedHeightRef.current = originalHeightRef.current;
  };

  const onResize: ResizeCallback = useCallback(
    (_, __, ref, data) => {
      const height =
        (changedHeightRef?.current ?? (minHeight as number)) + data.height;
      originalHeightRef.current = height;
      ref.style.height = `${height}px`;
    },
    [minHeight],
  );

  return {
    onResize,
    onResizeStart,
  };
}
