import { FC } from 'react';
import type {
  FieldErrors,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';
import { DropdownItemType } from '@widget/dropdown';
import Title from '@pages/Exam/shared/Title';
import Level from '@pages/Exam/shared/Level';
import LanguageAndSettings from '../../shared/LanguageAndSettings';
import { PackageExamFormProps } from '../../config.ts';
import { ExamLanguageDTO } from '@phs/interfaces';
import DetailLevel from '@pages/Exam/shared/DetailLevel/index.tsx';
import Algorithm from '@pages/Exam/shared/Algorithm/index.tsx';
import ExpectationLevel from '@pages/Exam/shared/ExpectationLevel/index.tsx';
import CheckBox from '@widget/checkbox';

interface PackageDefaultProps {
  level: number;
  detailLevel: number;
  algorithmTypeList: string[];
  register: UseFormRegister<PackageExamFormProps>;
  errors: FieldErrors<PackageExamFormProps>;
  watch: UseFormWatch<PackageExamFormProps>;
  onChangeLevel?: (props: DropdownItemType) => void;
  onChangeDetailLevel?: (props: DropdownItemType) => void;
  onChangeAlgorithm?: (props: string[]) => void;
  updateLanguageCallback: (languages: ExamLanguageDTO[]) => void;
  expectStepPassRateList?: number[];
  manualDifficultyYn: boolean;
  onChangeManualDifficultyYn: () => void;
}

const PackageDefault: FC<PackageDefaultProps> = ({
  register,
  errors,
  level,
  onChangeLevel,
  watch,
  updateLanguageCallback,
  algorithmTypeList,
  detailLevel,
  onChangeAlgorithm,
  onChangeDetailLevel,
  expectStepPassRateList,
  manualDifficultyYn,
  onChangeManualDifficultyYn,
}) => {
  return (
    <>
      <Title<PackageExamFormProps>
        placeholder={'문제명을 입력해주세요.'}
        register={register}
        errors={errors}
        watch={watch}
      />
      <CheckBox
        isActive={manualDifficultyYn}
        onClick={onChangeManualDifficultyYn}
      >
        입력 난이도로 공개
      </CheckBox>
      <Level<PackageExamFormProps>
        {...(level > 0 && {
          initialState: level,
        })}
        title={'종합 난이도'}
        onChange={onChangeLevel}
        errors={errors}
      />
      <DetailLevel<PackageExamFormProps>
        {...(level > 0 && {
          initialState: detailLevel,
        })}
        title={'세부 난이도'}
        onChange={onChangeDetailLevel}
        errorMessage={errors?.detailLevel?.message}
      />
      <ExpectationLevel
        passRates={expectStepPassRateList}
        averageScore={watch('overallPredictionAverageScore')}
        level={watch('overallPredictionLevel')}
      />
      <Algorithm
        onChange={onChangeAlgorithm}
        selectedValues={algorithmTypeList}
        readOnly
      />
      <LanguageAndSettings updateLanguageCallback={updateLanguageCallback} />
    </>
  );
};

export default PackageDefault;
