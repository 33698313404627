import { FC, useEffect } from 'react';
import { useResetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import EventUtil from '@helpers/eventutil.ts';
import { functionParametersAndReturnTypes } from '@stores/atoms/exam/exam.function.ts';
import EditorHeader, { EditorEventType } from '@components/EditorHeader';
import { useLanguageList } from '@hooks/exam/useLanguage.ts';
import { FormProps } from '@pages/Exam/shared/config.ts';
import Title from '@pages/Exam/shared/Title';
import Level from '@pages/Exam/shared/Level';
import Editor from '@pages/Exam/shared/Editor';
import Calculation from '@pages/Exam/shared/Calculation';
import ParametersAndReturnTypes from '@pages/Exam/function/shared/Parameters';
import Language from '@pages/Exam/function/shared/Language';
import ExampleCase from '@pages/Exam/function/shared/ExampleCase';
import AccuracyCase from '@pages/Exam/function/shared/AccuracyCase';
import EfficiencyCase from '@pages/Exam/function/shared/EfficiencyCase';
import { useUpdateLanguageSet } from '@pages/Exam/shared/useUpdateLanguageSet.ts';
import { usePreview } from '../shared/usePreview.ts';
import { useFuncExam } from './hooks.ts';
import { Container, EditorContent, ErrorText } from './style.ts';
import { CodePreview } from '@phs/code';
import DetailLevel from '@pages/Exam/shared/DetailLevel/index.tsx';
import Algorithm from '@pages/Exam/shared/Algorithm/index.tsx';

const CreateFuncExamPage: FC = () => {
  const reset = useResetRecoilState(functionParametersAndReturnTypes);
  const navigate = useNavigate();
  const { languageList } = useLanguageList();
  const {
    trigger,
    watch,
    errors,
    register,
    setValue,
    getValues,
    handleSubmit,
    params,
    isEnableCalculation,
    isAbleToAddTestCase,
    onSubmit,
    onChangeLevel,
    onChangeDetailLevel,
    onChangeAlgorithm,
    onBlurInputParam,
    onChangeSelectParam,
    onClickParam,
    onFocusInputParam,
    onClickSelectParam,
    accuracyTestCasesCount,
    efficiencyTestCasesCount,
    isCreateLoading,
  } = useFuncExam({
    data: languageList,
  });
  const { onPreview } = usePreview({
    trigger,
    getValues,
  });

  const { updateLanguageCallback } = useUpdateLanguageSet({
    testCases: watch('efficiencyTestCases') ?? [],
    fileTestCases: watch('efficiencyFileTestCases') ?? [],
    setValue,
  });

  const onClick = async (type: EditorEventType) => {
    switch (type) {
      case EditorEventType.CANCEL:
        navigate(-1);
        break;

      case EditorEventType.PREVIEW:
        await onPreview();
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    EventUtil.initBeforeunloadEvent();
    return () => {
      EventUtil.destroyBeforeunloadEvent();
      CodePreview.cancelPreview();
      reset();
    };
  }, []);

  return (
    <Container
      method={'POST'}
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e) => {
        //@ts-ignore
        if (e.key === 'Enter' && e?.target?.type !== 'textarea') {
          e.preventDefault();
        }
      }}
    >
      <EditorHeader
        title={<>문제&nbsp;생성</>}
        cancel={'취소'}
        onClick={onClick}
        preview={'미리 보기'}
        submit={'문제 만들기'}
        isSubmitLoading={isCreateLoading}
      />
      <EditorContent>
        <Title<FormProps>
          placeholder={'제목을 입력해주세요.'}
          register={register}
          watch={watch}
          errors={errors}
        />
        <Level<FormProps>
          {...(watch('level') > 0 && {
            initialState: watch('level'),
          })}
          onChange={onChangeLevel}
          errors={errors}
        />
        <DetailLevel<FormProps>
          {...(watch('detailLevel') > 0 && {
            initialState: watch('detailLevel'),
          })}
          onChange={onChangeDetailLevel}
          errorMessage={errors?.detailLevel?.message}
        />
        <Algorithm
          onChange={onChangeAlgorithm}
          selectedValues={watch('algorithmTypeList')}
        />
        <ErrorText>{errors?.algorithmTypeList?.message}</ErrorText>
        <Editor setValue={setValue} errors={errors} />
        <ParametersAndReturnTypes
          params={params}
          onBlurInput={onBlurInputParam}
          onChangeSelect={onChangeSelectParam}
          onClick={onClickParam}
          onFocusInput={onFocusInputParam}
          onClickSelectParam={onClickSelectParam}
        />
        <Language
          languages={watch('languages')}
          setValue={setValue}
          updateLanguageCallback={updateLanguageCallback}
        />
        <ExampleCase
          disabled={!isAbleToAddTestCase}
          languages={watch('languages')}
          testCases={watch('exampleTestCases')}
          setValue={setValue}
        />
        <AccuracyCase
          disabled={!isAbleToAddTestCase}
          languages={watch('languages')}
          testCases={watch('accuracyTestCases')}
          fileTestCases={watch('accuracyFileTestCases')}
          setValue={setValue}
        />
        <EfficiencyCase
          disabled={!isAbleToAddTestCase}
          languages={watch('languages')}
          testCases={watch('efficiencyTestCases')}
          fileTestCases={watch('efficiencyFileTestCases')}
          setValue={setValue}
        />
        <Calculation
          isActive={watch('scoreRateYn')}
          disabled={!isEnableCalculation}
          accuracyScoreRate={watch('accuracyScoreRate')}
          efficiencyScoreRate={watch('efficiencyScoreRate')}
          register={register}
          accuracyCount={accuracyTestCasesCount}
          efficiencyCount={efficiencyTestCasesCount}
        />
      </EditorContent>
    </Container>
  );
};

export default CreateFuncExamPage;
