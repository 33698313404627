import { useResetRecoilState, useRecoilState } from 'recoil';
import {
  currentPage,
  pageSize,
  totalPage,
} from '@stores/atoms/shared/pagination.ts';
import { useDebouncedCallback } from '@react-hookz/web';

export function usePagination() {
  const [currentPageState, setCurrentPage] = useRecoilState(currentPage);
  const [pageSizeState, setPageSize] = useRecoilState(pageSize);
  const [totalPageState, setTotalPage] = useRecoilState(totalPage);

  const resetPageSize = useResetRecoilState(pageSize);
  const resetCurrentPage = useResetRecoilState(currentPage);

  const resetPage = () => {
    resetPageSize();
    resetCurrentPage();
  };

  const handleChangePageSize = (size: number) => {
    setPageSize(size);
    setCurrentPage(0);
  };

  const updateCurrentPage = useDebouncedCallback(
    (page: number) => setCurrentPage(page),
    [],
    400,
  );

  const updatePageSize = useDebouncedCallback(
    (page: number) => {
      setCurrentPage(0);
      setPageSize(page);
    },
    [],
    400,
  );

  return {
    updateCurrentPage,
    updatePageSize,
    resetPage,
    setTotalPage,
    currentPage: currentPageState,
    pageSize: pageSizeState,
    totalPage: totalPageState,
    handleChangePageSize,
    setCurrentPage,
  };
}
