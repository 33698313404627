import {
  IconC,
  IconCPP,
  IconCSHARP,
  IconJava,
  IconJavascript,
  IconJavascript48,
  IconKotlin,
  IconPython,
  IconR,
  IconRuby,
  IconScala,
  IconSwift,
  IconPypy,
  IconGo,
} from '@widget/icons-language';
import { LanguageType } from '@phs/interfaces';
import { ReactNode } from 'react';

interface LanguageProps {
  editorLanguage: string;
  reportLanguage: string;
  svg: (id: string, size: number, key?: string) => ReactNode;
}

const Language: Record<keyof typeof LanguageType, LanguageProps> = {
  C: {
    editorLanguage: 'c',
    reportLanguage: 'C',
    svg: (id, size: number, key?: string) => (
      <IconC key={key} id={id} width={size} height={size} />
    ),
  },
  CSHARP: {
    editorLanguage: 'csharp',
    reportLanguage: 'C#',
    svg: (id, size: number, key?: string) => (
      <IconCSHARP key={key} id={id} width={size} height={size} />
    ),
  },
  CPP: {
    editorLanguage: 'cpp',
    reportLanguage: 'C++',
    svg: (id, size: number, key?: string) => (
      <IconCPP key={key} id={id} width={size} height={size} />
    ),
  },
  JAVA: {
    editorLanguage: 'java',
    reportLanguage: 'Java',
    svg: (id, size: number, key?: string) => (
      <IconJava key={key} id={id} width={size} height={size} />
    ),
  },
  JAVASCRIPT: {
    editorLanguage: 'javascript',
    reportLanguage: 'Javascript',
    svg: (id, size: number, key?: string) => {
      if (size === 48) {
        return (
          <IconJavascript48 key={key} id={id} width={size} height={size} />
        );
      }
      return <IconJavascript key={key} id={id} width={size} height={size} />;
    },
  },
  PYTHON: {
    editorLanguage: 'python',
    reportLanguage: 'Python',
    svg: (id, size: number, key?: string) => (
      <IconPython key={key} id={id} width={size} height={size} />
    ),
  },
  PYPY: {
    editorLanguage: 'python',
    reportLanguage: 'PyPy',
    svg: (id, size: number, key?: string) => (
      <IconPypy key={key} id={id} width={size} height={size} />
    ),
  },
  RUBY: {
    editorLanguage: 'ruby',
    reportLanguage: 'Ruby',
    svg: (id, size: number, key?: string) => (
      <IconRuby key={key} id={id} width={size} height={size} />
    ),
  },
  SCALA: {
    editorLanguage: 'scala',
    reportLanguage: 'Scala',
    svg: (id, size: number, key?: string) => (
      <IconScala key={key} id={id} width={size} height={size} />
    ),
  },
  SWIFT: {
    editorLanguage: 'swift',
    reportLanguage: 'Swift',
    svg: (id, size: number, key?: string) => (
      <IconSwift key={key} id={id} width={size} height={size} />
    ),
  },
  KOTLIN: {
    editorLanguage: 'kotlin',
    reportLanguage: 'Kotlin',
    svg: (id, size: number, key?: string) => (
      <IconKotlin key={key} id={id} width={size} height={size} />
    ),
  },
  R: {
    editorLanguage: 'r',
    reportLanguage: 'R',
    svg: (id, size: number, key?: string) => (
      <IconR key={key} id={id} width={size} height={size} />
    ),
  },
  GO: {
    editorLanguage: 'go',
    reportLanguage: 'Go',
    svg: (id, size: number, key?: string) => (
      <IconGo key={key} id={id} width={size} height={size} />
    ),
  },
  DEFAULT: {
    editorLanguage: '',
    reportLanguage: '',
    svg: (_, __: number) => <></>,
  },
};

export default Language;
