import styled from '@emotion/styled';

export const Container = styled.nav`
  width: 100%;
  border-bottom: 1px solid #ced4da;
  background: transparent;
`;

export const Wrapper = styled.ul`
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;

export const Item = styled.li<{ isActive?: boolean }>`
  display: inline-block;
  height: 48px;
  margin-bottom: -1px;
  padding: 12px 24px;
  box-sizing: border-box;
  border-bottom: 3px solid
    ${({ isActive }) => (isActive ? '#12b886' : 'transparent')};
  color: ${({ isActive }) => (isActive ? '#343a40' : '#adb5bd')};
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    color: #343a40;
  }
`;
