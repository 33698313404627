import { atom } from 'recoil';
import { DataType, FunctionParameterAndReturnTypeDTO } from '@phs/interfaces';
import { atomKeys } from '@constants/key.ts';

// 매개변수 & Return Type
export const functionParametersAndReturnTypes =
  atom<FunctionParameterAndReturnTypeDTO>({
    key: atomKeys.exam.functionParameters,
    default: {
      paramInfo: [
        {
          paramType: DataType.STRING,
          paramName: 'Parameter',
        },
      ],
      returnType: DataType.STRING,
    },
  });
