import { DetailedHTMLProps, FC, HTMLAttributes, useEffect } from 'react';
import { Resizable } from 're-resizable';
import { Editor as TuiEditor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import type { UseFormSetValue, FieldErrors } from 'react-hook-form';
import { Container, RequireTitle, Hint } from './style.ts';
import { FormProps } from '../config.ts';
import { resizeOptions } from './config.ts';
import { useEditor } from './useEditor.ts';
import { useResize } from './useResize.ts';

interface EditorProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  initialValue?: string;
  setValue?: UseFormSetValue<FormProps>;
  errors?: FieldErrors<FormProps>;
}

const Editor: FC<EditorProps> = ({
  initialValue = '',
  setValue,
  errors,
  ...rest
}) => {
  const { editorRef, addImageBlobHook, onChange } = useEditor(setValue);
  const { onResizeStart, onResize } = useResize({ minHeight: 1000 });

  useEffect(() => {
    if (!editorRef.current) return;
    editorRef.current.getInstance().setMarkdown(initialValue);
  }, [editorRef]);

  return (
    <Container {...rest}>
      <RequireTitle>문제 설명</RequireTitle>
      <Resizable
        {...resizeOptions}
        onResizeStart={onResizeStart}
        onResize={onResize}
      >
        <TuiEditor
          usageStatistics={false}
          previewStyle={'vertical'}
          initialEditType='markdown' // markdown, wysiwyg
          ref={editorRef}
          hooks={{
            addImageBlobHook,
          }}
          onChange={onChange}
          height='100%'
        />
      </Resizable>
      <Hint>{errors?.content?.message}</Hint>
    </Container>
  );
};

export default Editor;
