import { FC } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import TextButton from '@widget/button-text';
import { ExamLanguageDTO, TestCaseDTO, TestCaseType } from '@phs/interfaces';
import Table, { RenderTableBody } from '@components/Table';
import { FormProps } from '@pages/Exam/shared/config.ts';
import EfficiencyTestCaseModal from '../Modals/EfficiencyTestCase';
import HighCapacityTestCaseModal from '../Modals/HighCapacityTestCase';
import { ButtonWrapper, Container, Title, TitleWrap } from './style';
import { useEfficiencyCase } from './hooks';
import { HEADERS } from './config';

interface EfficiencyCaseProps {
  disabled?: boolean;
  languages?: ExamLanguageDTO[];
  testCases?: TestCaseDTO[];
  fileTestCases?: TestCaseDTO[];
  setValue: UseFormSetValue<FormProps>;
}

const EfficiencyCase: FC<EfficiencyCaseProps> = ({
  disabled = false,
  languages = [],
  testCases = [],
  fileTestCases = [],
  setValue,
}) => {
  const {
    isModalOpened,
    isFileModalOpened,
    handleOpen,
    renderData,
    renderFileData,
    onSubmit,
    onFileSubmit,
    downloadAllFiles,
    onClose,
  } = useEfficiencyCase({
    disabled,
    setValue,
  });

  return (
    <Container>
      {isModalOpened && (
        <EfficiencyTestCaseModal
          languages={languages}
          testCaseType={TestCaseType.EFFICIENCY}
          testCases={testCases}
          onSubmit={onSubmit}
          onClose={onClose}
        />
      )}
      {isFileModalOpened && (
        <HighCapacityTestCaseModal
          languages={languages}
          testCaseType={TestCaseType.EFFICIENCY}
          testCases={fileTestCases}
          onSubmit={onFileSubmit}
          onClose={onClose}
        />
      )}
      <TitleWrap>
        <Title>효율성 테스트 케이스</Title>
        <ButtonWrapper>
          <TextButton
            onClick={() => downloadAllFiles(fileTestCases)}
            style={{
              minWidth: 120,
              height: 52,
            }}
          >
            테스트 케이스 파일 다운로드
          </TextButton>
          <TextButton
            onClick={() => handleOpen('file', true)}
            style={{
              minWidth: 120,
              height: 52,
            }}
          >
            대용량 테스트 케이스 추가
          </TextButton>
          <TextButton
            data-testid={'efficiency-testcase'}
            onClick={() => handleOpen('testcase', true)}
            style={{
              minWidth: 120,
              height: 52,
            }}
          >
            테스트 케이스 추가 및 수정
          </TextButton>
        </ButtonWrapper>
      </TitleWrap>
      <Table<TestCaseDTO>
        headers={HEADERS}
        data={testCases}
        renderTableBody={renderData as RenderTableBody<TestCaseDTO>}
        fixLayout
      />
      {fileTestCases.length > 0 && (
        <Table<TestCaseDTO>
          headers={HEADERS}
          data={fileTestCases}
          renderTableBody={renderFileData as RenderTableBody<TestCaseDTO>}
          fixLayout
        />
      )}
    </Container>
  );
};

export default EfficiencyCase;
