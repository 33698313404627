import styled from '@emotion/styled';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 168px;

	h4 {
		margin-top: 12px;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		color: rgb(52 58 64);
	}
`;
