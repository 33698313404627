import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { singularToggleKeys as getSingularToggleKeys } from '@stores/selectors/exam/singular.toggle.ts';
import { DefaultProblemDTO } from '@interfaces/dashboard/problem.dto.ts';
import { PMS_PAGES } from '@phs/constants';
import { useRecoilValue } from 'recoil';

import {
  useCopyProblem,
  useDefaultProblemList,
} from '@hooks/dashboard/useProblem.ts';
import { useAlertModal } from '@components/Modals/Alert/hook.ts';
import { useConfirmModal } from '@components/Modals/Confirm/hook.ts';
import PstRepository from '@repositories/pst.repository.ts';
import { packageToggleKeys as getPackageToggleKeys } from '@stores/selectors/exam/package.toggle';
import { usePackageProblemList } from '@hooks/dashboard/usePackageProblem';

export enum ProblemBaseType {
  DEFAULT = 'default',
  PACKAGE = 'package',
}

const getToggleKeys = (data: DefaultProblemDTO[]) => data.map(({ sn }) => sn);

export function useHeader() {
  const { data: singularData, refetch: singularRefetch } =
    useDefaultProblemList({
      enabled: false,
    });
  const { data: packageData, refetch: packageRefetch } = usePackageProblemList({
    enabled: false,
  });
  const singularToggleKeys = useRecoilValue(
    getSingularToggleKeys(singularData.problemList.content),
  );

  const packageToggleKeys = useRecoilValue(
    getPackageToggleKeys(packageData.problemList.content),
  );
  const location = useLocation();

  const currentExamType = useMemo(() => {
    if (location.pathname.includes(PMS_PAGES.DASHBOARD.PACKAGES)) {
      return {
        name: '추가 조건 제시형',
        type: 'package',
      };
    }

    return {
      name: '단일 풀이형',
      type: 'default',
    };
  }, [location]);

  const toggleKeys = useMemo(() => {
    return currentExamType.type === 'package'
      ? packageToggleKeys
      : singularToggleKeys;
  }, [currentExamType, singularToggleKeys, packageToggleKeys]);

  const { onOpen } = useAlertModal();
  const { onConfirmOpen, onOk } = useConfirmModal();

  const navigation = useNavigate();

  const { mutate, isLoading: isCopyLoading } = useCopyProblem(
    getToggleKeys(toggleKeys as DefaultProblemDTO[]),
    '',
  );

  const [isModalVisible, setIsModalVisible] = useState(false);

  const checkSnListEmpty = (list: number[]) => {
    if (list.length !== 0) return false;
    onOpen('선택된 문제가 없습니다.');
    return true;
  };

  const onDeleteExam = () => {
    const baseProblemSnList = getToggleKeys(toggleKeys as DefaultProblemDTO[]);
    if (checkSnListEmpty(baseProblemSnList)) return;

    onConfirmOpen(async () => {
      try {
        await PstRepository.deleteProblem(baseProblemSnList);
        onOk();
        if (currentExamType.type === 'package') packageRefetch?.();
        else singularRefetch?.();
      } catch (e: any) {
        onOpen('문제 삭제에 실패했습니다.');
        console.error(e);
      }
    }, '정말로 문제를 삭제하시겠습니까?');
  };

  const onCopyExam = useCallback(() => {
    const baseProblemSnList = getToggleKeys(toggleKeys as DefaultProblemDTO[]);
    if (checkSnListEmpty(baseProblemSnList)) return;
    mutate(
      {
        baseProblemSnList,
        type: currentExamType.type,
      },
      {
        onError: () => {
          onOpen('문제 복사시 문제가 발생했습니다.');
        },
        onSuccess: () => {
          if (currentExamType.type === 'package') packageRefetch?.();
          else singularRefetch?.();
        },
      },
    );
  }, [currentExamType.type, mutate, toggleKeys]);

  const onCreateExam = (type: 'stdio' | 'function' | 'package') =>
    navigation(`${PMS_PAGES.EXAM.CREATE(type)}`);

  const onClickModal = () => setIsModalVisible(!isModalVisible);

  return {
    isModalVisible,
    onDeleteExam,
    onCopyExam,
    onCreateExam,
    onClickModal,
    currentExamType,
    isCopyLoading,
  };
}
