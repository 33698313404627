import { useState } from 'react';
import { ExamLanguageDTO } from '@phs/interfaces';

interface UseLanguageModalProps {
  currentLanguages: ExamLanguageDTO[];
  onConfirm?: (data: ExamLanguageDTO[]) => void;
  onClose?: () => void;
}

export function useLanguageModal({
  currentLanguages,
  onConfirm,
  onClose,
}: UseLanguageModalProps) {
  const [cache, setCache] = useState<ExamLanguageDTO[]>(currentLanguages);

  const onCancel = () => {
    onClose?.();
  };

  const onSubmit = () => {
    onConfirm?.(cache);
  };

  const onChangeCache = (idx: number, usage: boolean) => {
    setCache((prev) => {
      const newCache = prev.slice();
      newCache[idx].usage = usage;

      return newCache;
    });
  };

  return {
    cache,
    onChangeCache,
    onCancel,
    onSubmit,
  };
}
