import { Container, FilterWrapper, Header } from './style.ts';
import { FC, useEffect } from 'react';
import { useCompany } from '@hooks/manage/useCompany.ts';
import { CompanyDTO } from '@interfaces/company/company.dto';
import Table, { RenderTableBody } from 'src/components/Table';
import Pagination from 'src/components/Pagination';
import { usePagination } from '@components/Pagination/hooks';
import Search from 'src/components/Search';
import { useSearch } from '@components/Search/hooks';
import Menu from './Menu';
import { useSort } from './Sort/hooks';
import { useCompanyManagement } from './hooks';
import { HEADERS } from './config';

const CompanyManagement: FC = () => {
  const { data } = useCompany();
  const { renderData } = useCompanyManagement();
  const { resetSearchKeyword } = useSearch();
  const { resetPage, setTotalPage } = usePagination();
  const { resetSortType, onClick } = useSort();

  useEffect(() => {
    return () => {
      resetSortType();
      resetSearchKeyword();
      resetPage();
    };
  }, []);

  useEffect(() => {
    const { totalPages } = data.companyList;
    setTotalPage(totalPages);
  }, [data.companyList, data.companyList.totalPages, setTotalPage]);

  return (
    <Container>
      <Header>
        <Menu />
      </Header>
      <FilterWrapper>
        <Search placeholder={'기업명을 입력해주세요.'} />
      </FilterWrapper>
      <Table<CompanyDTO>
        headers={HEADERS}
        data={data.companyList.content}
        renderTableBody={renderData as RenderTableBody<CompanyDTO>}
        onClick={onClick}
      />
      <Pagination />
    </Container>
  );
};

export default CompanyManagement;
