import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import EditorHeader from '@components/EditorHeader';
import { usePopUpDetail } from '@hooks/notice/usePopUp.ts';
import { Container, EditorContent } from './style';
import DefaultSetting from './DefaultSetting';
import ContentSetting from './ContentSetting';
import SizeSetting from './SizeSetting';
import Fieldset from './Fieldset';
import { usePopUp } from './hooks.ts';

const PopUpEditPage: FC = () => {
  const { sn } = useParams<{ sn: string }>();
  const {
    handleSubmit,
    onSubmit,
    onCancelClick,
    onDownloadClick,
    onChangeDates,
    onChangeInput,
    onBack,
    watch,
    register,
    errors,
    setValue,
  } = usePopUp(sn);
  const data = usePopUpDetail(sn!);

  useEffect(() => {
    setValue('title', data.title);
    setValue('startDateTime', dayjs(data.startDateTime));
    setValue('endDateTime', dayjs(data.endDateTime));
    setValue('todayOption', data.viewTodayPermitYn);
    setValue('pause', data.pauseYn);
    setValue('fileUid', data.fileUid);
    setValue('imageLink', data.imageLink);
    setValue('imageLinkOption', data.imageLinkYn);
    setValue('width', data.size.width);
    setValue('height', data.size.height);
    setValue('left', data.position.x);
    setValue('up', data.position.y);

    if (data.fileName) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setValue('image', [{ name: data.fileName }]);
    } else {
      setValue('image', null);
    }
  }, [data]);

  return (
    <Container method={'POST'} onSubmit={handleSubmit(onSubmit)}>
      <EditorHeader
        title={<>팝업&nbsp;추가&nbsp;/&nbsp;수정</>}
        cancel={'취소'}
        submit={'팝업 등록/수정'}
        onClick={onBack}
      />
      <EditorContent>
        <Fieldset title={'팝업 관리'}>
          <DefaultSetting
            register={register}
            todayOption={watch('todayOption')}
            isPause={watch('pause')}
            dates={[watch('startDateTime'), watch('endDateTime')]}
            onChangeDates={onChangeDates}
            errors={errors}
          />
        </Fieldset>
        <Fieldset title={'컨텐츠 설정'}>
          <ContentSetting
            onChangeFileInput={onChangeInput}
            register={register}
            defaultName={watch('image')?.[0]?.name ?? ''}
            imageLinkOption={watch('imageLinkOption')}
            defaultImageLink={watch('imageLink')}
            errors={errors}
            onCancelClick={onCancelClick}
            onDownloadClick={onDownloadClick}
          />
        </Fieldset>
        <Fieldset title={'팝업 크기 및 위치 설정'}>
          <SizeSetting register={register} errors={errors} />
        </Fieldset>
      </EditorContent>
    </Container>
  );
};

export default PopUpEditPage;
