import { useRecoilValue } from 'recoil';
import { useQuery } from '@tanstack/react-query';
import { withPaginationFallback } from '@helpers/fallback';
import {
  PopUpDTO,
  PopUpListRsDTO,
  PopUpRsDTO,
} from '@interfaces/notice/popup.dto';
import { PopUpStatus } from '@interfaces/notice/popup.enum.ts';
import { popUpPaginationQuery } from '@stores/selectors/notice/query';
import PopupRepository from '@repositories/popup.repository.ts';
import { queryKeys } from '@constants/key.ts';

const popUpListFallback = withPaginationFallback<PopUpDTO>({
  key: 0,
  sn: 0,
  title: '',
  status: PopUpStatus.WAITING,
  pauseYn: false,
  createdDateTime: '',
  startDateTime: '',
  endDateTime: '',
  lastModifiedDateTime: '',
});

export function usePopUpList(): { data: PopUpListRsDTO; refetch?: () => void } {
  const { query, queryString } = useRecoilValue(popUpPaginationQuery);
  const { data, isError, isLoading, refetch } = useQuery<
    string,
    unknown,
    PopUpListRsDTO,
    readonly string[]
  >(
    [queryKeys.popup, queryString],
    () => PopupRepository.readPopUpList(query),
    {
      refetchOnMount: true,
      cacheTime: 0,
      staleTime: 0,
    },
  );

  if (isError || isLoading) return { data: popUpListFallback };

  return {
    data,
    refetch,
  };
}

const popupFallback: PopUpRsDTO = {
  title: '',
  startDateTime: '',
  endDateTime: '',
  pauseYn: false,
  viewTodayPermitYn: false,
  fileUid: '',
  fileName: '',
  imageLinkYn: false,
  size: {
    width: 0,
    height: 0,
  },
  position: {
    x: 0,
    y: 0,
  },
};

export function usePopUpDetail(sn: string): PopUpRsDTO {
  const { data, isError, isLoading } = useQuery([queryKeys.popup, sn], () =>
    PopupRepository.readPopUp(sn),
  );

  if (isError || isLoading) return popupFallback;

  return data;
}
