import styled from '@emotion/styled';
import { css } from '@emotion/react';

const CommonStyle = css`
  box-sizing: border-box;
  font-family: inherit;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  min-height: 40px;
  min-width: 88px;
  padding: 0 16px;
  display: inline-block;
`;

export const NoneContainer = styled.button`
  ${CommonStyle};
`;

export const PrimaryContainer = styled.button`
  ${CommonStyle};

  background: #12b886;
  border: 1px solid #12b886;
  color: #fff;

  &:hover {
    background: #0ca678;
  }

  &:not(:disabled):active {
    opacity: 0.8;
  }

  &:disabled {
    color: #212529;
    cursor: not-allowed;
    background: #adb5bd;
    border: 1px solid #adb5bd;
  }
`;

export const SecondaryContainer = styled.button`
  ${CommonStyle};

  background: #dee2e6;
  border-radius: 4px;
  color: #495057;

  &:hover {
    background: #ced4da;
  }

  &:not(:disabled):active {
    opacity: 0.8;
  }

  &:disabled {
    color: #212529;
    cursor: not-allowed;
    background: #adb5bd;
    border: 1px solid #adb5bd;
  }
`;

export const TertiaryContainer = styled.button`
  ${CommonStyle};

  background: #fff;
  border: 1px solid #12b886;
  border-radius: 4px;
  color: #12b886;

  &:hover {
    background: #d3f9d8;
  }

  &:active {
    opacity: 0.8;
  }
`;
