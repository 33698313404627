import { PaginationRsDTO } from '@interfaces/shared/pagination.dto.ts';

export const withPaginationFallback = <T = any>(data: Record<string, any>) => {
  return {
    empty: true,
    first: true,
    last: true,
    number: 0,
    numberOfElements: 0,
    pageable: {
      offset: 0,
      pageNumber: 0,
      pageSize: 0,
      paged: false,
      sort: {
        empty: true,
        sorted: false,
        unsorted: true,
      },
      unpaged: true,
    },
    size: 0,
    sort: {
      empty: true,
      sorted: false,
      unsorted: true,
    },
    totalElements: 0,
    totalPages: 0,
    content: [data],
  } as PaginationRsDTO<T[]>;
};
