import { selector } from 'recoil';
import { stateKeys } from '@constants/key.ts';
import { toggles } from '@stores/atoms/shared/table.ts';

export const tableToggles = selector({
	key: stateKeys.table.currentToggles,
	get: ({ get }) => {
		const _toggles = get(toggles);

		return { currentToggles: _toggles };
	},
});
