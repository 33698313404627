import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from './style.ts';
import { Menu } from './config.ts';
import Tab from '@widget/tab';
import { useTab } from '@hooks/useTab.ts';
import { PMS_PAGES } from '@phs/constants';

const NoticePage: FC = () => {
  const { currentTab, onClick } = useTab({
    items: Menu,
    navigateList: [PMS_PAGES.NOTICE.HOME],
  });

  return (
    <Container>
      <Tab item={Menu} activeKey={currentTab} onClick={onClick} />
      <Outlet />
    </Container>
  );
};

export default NoticePage;
