import { PMS_URL } from '@phs/constants';
import { BaseRepository } from '@phs/repositories';
import {
  MakeProblemReqDTO,
  MakeProblemResDTO,
  PackageProblemTestCasesRsDTO,
  ProblemListRsDTO,
  ProblemTestCasesRsDTO,
} from '@interfaces/dashboard/problem.dto.ts';
import {
  PackageProblemMakeReqDTO,
  PackageProblemMakeResDTO,
  ProblemBaseType,
  ProblemType,
} from '@phs/interfaces';

class PSTRepository extends BaseRepository {
  constructor(baseURL: string) {
    super(baseURL);
  }

  public async getDefaultProblemList(
    queryString: string,
  ): Promise<ProblemListRsDTO> {
    const { data: response } = await super.get<ProblemListRsDTO>(
      `${PMS_URL.PROBLEM_LIST('default') + queryString}`,
    );

    return response;
  }

  public async getPackageProblemList(
    queryString: string,
  ): Promise<ProblemListRsDTO> {
    const { data: response } = await super.get<ProblemListRsDTO>(
      `${PMS_URL.PROBLEM_LIST('package') + queryString}`,
    );

    return response;
  }

  public async readTestCases(sn: string): Promise<ProblemTestCasesRsDTO> {
    const { data: response } = await super.get<ProblemTestCasesRsDTO>(
      `${PMS_URL.READ_TESTCASES(sn)}`,
    );

    return response;
  }

  public async readPackageTestCases(
    sn: string,
  ): Promise<PackageProblemTestCasesRsDTO> {
    const { data: response } = await super.get<PackageProblemTestCasesRsDTO>(
      `${PMS_URL.READ_PACKAGE_TESTCASES(sn)}`,
    );

    return response;
  }

  public async createProblem(data: MakeProblemReqDTO) {
    const { data: response } = await super.post(
      `${PMS_URL.CREATE_PROBLEM}`,
      data,
    );

    return response;
  }

  public async readProblem({
    sn,
    problemPossessionType,
  }: {
    sn: string;
    problemPossessionType?: ProblemType;
  }): Promise<MakeProblemResDTO> {
    const { data: response } = await super.get<MakeProblemResDTO>(
      PMS_URL.READ_PROBLEM(sn),
      {
        params: { problemPossessionType },
      },
    );

    return response;
  }

  public async updateProblem({
    sn,
    data,
  }: {
    sn: string;
    data: MakeProblemReqDTO;
  }) {
    const { data: response } = await super.put(
      PMS_URL.UPDATE_PROBLEM(sn),
      data,
    );

    return response;
  }

  public async copyProblem({
    baseProblemSnList,
    type,
  }: {
    baseProblemSnList: number[];
    type: string;
  }) {
    const { data: response } = await super.post(PMS_URL.COPY_PROBLEM(type), {
      baseProblemSnList,
    });

    return response;
  }

  public async syncProblem(problemSn: string, problemType: ProblemBaseType) {
    const { data: response } = await super.post(
      PMS_URL.SYNC_PROBLEM(problemSn, problemType.toLowerCase()),
    );

    return response;
  }

  public async convertProblemPublic(
    problemSn: string,
    companyYn: boolean,
    jobdaYn: boolean,
  ) {
    const { data: response } = await super.put(
      PMS_URL.CONVERT_PUBLIC_PROBLEM(problemSn),
      {},
      {
        params: {
          companyYn,
          jobdaYn,
        },
      },
    );

    return response;
  }

  public async bringProblem(
    type: string,
    baseProblemSnList: number[],
    companySn: number,
  ) {
    const { data: response } = await super.post(
      PMS_URL.BRING_PROBLEM_LIST(type),
      {
        baseProblemSnList,
        companySn,
      },
    );

    return response;
  }

  public async createPackageProblem(data: PackageProblemMakeReqDTO) {
    const { data: response } = await super.post(
      PMS_URL.CREATE_PACKAGE_PROBLEM,
      data,
    );
    return response;
  }

  public async readPackageProblem(
    sn: string,
  ): Promise<PackageProblemMakeResDTO> {
    const { data: response } = await super.get(
      PMS_URL.READ_PACKAGE_PROBLEM(sn),
    );
    return response;
  }

  public async updatePackageProblem({
    sn,
    data,
  }: {
    sn: string;
    data: PackageProblemMakeReqDTO;
  }) {
    const { data: response } = await super.put(
      PMS_URL.UPDATE_PACKAGE_PROBLEM(sn),
      data,
    );
    return response;
  }

  public async deleteProblem(baseProblemSnList: number[]) {
    const { data: response } = await super.delete(PMS_URL.DELETE_PROBLEM, {
      params: { baseProblemSnList },
    });
    return response;
  }

  public async checkProblemHistory(sn: string) {
    const { data: response } = await super.get(
      PMS_URL.CHECK_PROBLEM_HISTORY(sn),
    );
    return response;
  }
}

const pstRepository = new PSTRepository(import.meta.env.VITE_API_URL);
export default pstRepository;
