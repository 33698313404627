import { FC } from 'react';
import { Frame } from './style.ts';
import AutoScaling from '@pages/Manage/Operation/AutoScaling';
import Candidate from '@pages/Manage/Operation/Candidate';

const OperationManagement: FC = () => {
  return (
    <Frame>
      <AutoScaling />
      <Candidate />
    </Frame>
  );
};

export default OperationManagement;
