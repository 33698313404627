import { FC, ReactNode, memo } from 'react';
import Empty from './Empty';
import Removable from './Removable';
import { Container, Tbody, EmptyArea } from './style';
import { useUploadTable } from './hooks';

export { Removable };

export type BorderTableHeaderType = string | ReactNode;

export interface BorderTableProps {
  headers: BorderTableHeaderType[];
  data?: ReactNode[][];
}

const BorderTable: FC<BorderTableProps> = ({ headers, data, ...rest }) => {
  const { colSpan } = useUploadTable({ headerLength: headers.length });

  return (
    <Container {...rest}>
      <thead>
        <tr>
          {headers.map((header, idx) => (
            <th key={idx}>{header}</th>
          ))}
        </tr>
      </thead>
      <Tbody>
        {data?.map((items, idx) => {
          return (
            <tr key={`widget-upload-table-tbody-tr-${idx}`}>
              {items.map((element, idx) => (
                <td key={`widget-upload-table-td-${idx}`}>{element}</td>
              ))}
            </tr>
          );
        })}
      </Tbody>
      {data?.length === 0 && (
        <EmptyArea>
          <tr>
            <td colSpan={colSpan}>
              <Empty />
            </td>
          </tr>
        </EmptyArea>
      )}
    </Container>
  );
};

export default memo(BorderTable);
