import { isAxiosError } from 'axios';
import {
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormGetValues,
  UseFormTrigger,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { FormEventHandler, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { packageCurrentLanguages } from '@stores/selectors/exam/package.ts';
import { packageExamLanguages } from '@stores/atoms/exam/exam.package.ts';
import { CodeGenerator } from '@phs/code';
import { PMS_PAGES } from '@phs/constants';
import {
  ModifyCommandType,
  PackageProblemMakeReqDTO,
  PhaseProblemDTO,
} from '@phs/interfaces';
import { useLanguageList } from '@hooks/exam/useLanguage.ts';
import { useCreatePackageExam } from '@hooks/exam/usePackageExam.ts';
import { useAlertModal } from '@components/Modals/Alert/hook.ts';
import { usePhaseValidation } from '../shared/usePhaseValidation.ts';
import { DefaultTabItem, PackageExamFormProps } from '../config.ts';

interface UseCreateExamProps {
  hasTitle?: boolean;
  hasLevel?: boolean;
  append?: UseFieldArrayAppend<PackageExamFormProps, 'stepProblemList'>;
  remove?: UseFieldArrayRemove;
  getValues: UseFormGetValues<PackageExamFormProps>;
  trigger: UseFormTrigger<PackageExamFormProps>;
  stepProblemList: PhaseProblemDTO[];
}

export function useCreateExam({
  hasTitle = false,
  hasLevel = false,
  append,
  remove,
  getValues,
  trigger,
  stepProblemList,
}: UseCreateExamProps) {
  const { validate } = usePhaseValidation(getValues('stepProblemList'));
  const { onOpen } = useAlertModal();
  const navigate = useNavigate();
  const data = useLanguageList();
  const { create, isLoading: isCreateLoading } = useCreatePackageExam();
  const currentLanguages = useRecoilValue(packageCurrentLanguages);
  const setLanguages = useSetRecoilState(packageExamLanguages);

  const [cursor, setCursor] = useState<number>(0);

  const items = useMemo(() => {
    const stepProblemItems = stepProblemList.map((_, i) => ({
      name: `단계 ${i + 1}`,
      key: `package-tab-item-${i + 1}`,
    }));

    return [DefaultTabItem, ...stepProblemItems];
  }, [stepProblemList]);

  const getTotalAlgorithmList = () =>
    [
      ...new Set(
        stepProblemList
          .map((stepProblem) => stepProblem.algorithmTypeList)
          .flat()
          .filter((v) => v !== undefined),
      ),
    ] as string[];

  const currentActiveKey = useMemo(() => items[cursor].key, [cursor, items]);

  const stepCursor = useMemo(() => (cursor > 1 ? cursor - 1 : 0), [cursor]);

  const removePropertiesFromStep = (step: PhaseProblemDTO) => {
    const data = {
      ...step,
      judgeLanguageList: step.judgeLanguageList
        ?.filter((item) => item.commandType !== ModifyCommandType.READ)
        .map((item) => ({
          ...item,
          referenceSource: item.referenceSourceYn ? item.referenceSource : '',
        })),
    };

    delete data.parameters;
    if (!data.testCaseScoreRateYn) {
      delete data.accuracyScoreRate;
      delete data.efficiencyScoreRate;
    }
    if (!data.summaryYn) {
      data.summary = '';
    }
    return data;
  };

  const onAdd = () => {
    if (!hasTitle) return onOpen('제목을 입력해주세요.');
    if (!hasLevel) return onOpen('종합 난이도를 선택하지 않았습니다.');
    if (currentLanguages.length < 1) {
      return onOpen('언어를 선택하지 않았습니다.');
    }

    append?.(
      CodeGenerator.getPackageTemplate({
        judgeLanguageList: currentLanguages,
        number: items.length,
      }),
    );

    setTimeout(() => {
      onChangeCursor(items.length);
    }, 100);
  };

  const onRemove = (idx: number) => {
    const itemLength = items.length;
    if (cursor === itemLength - 1) {
      onChangeCursor(cursor - 1);
    }
    remove?.(idx - 1);
  };

  const onChangeCursor = (idx: number) => setCursor(idx);

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    trigger();
    const { title, level, stepProblemList, detailLevel, manualDifficultyYn } =
      getValues();

    if (title === '') {
      onOpen('제목을 입력해주세요.');
      return;
    }

    if (level < 1) {
      onOpen('종합 난이도를 선택하지 않았습니다.');
      return;
    }

    const payload: PackageProblemMakeReqDTO = {
      title,
      manualDifficultyYn,
      packageLevel: level.toString(),
      packageDetailLevel: detailLevel,
      stepProblemList: stepProblemList
        .map((data, i) => ({ ...data, number: i + 1 }))
        .filter(({ commandType }) => commandType !== ModifyCommandType.READ)
        .map((step) => removePropertiesFromStep(step)),
      onlyProblemUpdateYn: false,
    };

    validate(() => {
      create(payload, {
        onSuccess: () => {
          navigate(PMS_PAGES.DASHBOARD.HOME);
        },
        onError: (error) => {
          if (!isAxiosError(error)) {
            return onOpen('문제 정보 생성 시 에러가 발생했습니다.');
          }

          onOpen(
            error.response!.data?.message ||
              '문제 정보 생성 시 에러가 발생했습니다.',
          );
        },
      });
    });
  };

  useEffect(() => {
    if (!data?.languageList.length || data?.languageList.length < 1) return;
    setLanguages(CodeGenerator.getLanguageTemplate(data.languageList));
  }, [data.languageList, setLanguages]);

  return {
    cursor,
    stepCursor,
    items,
    currentActiveKey,
    onAdd,
    onRemove,
    onChangeCursor,
    onSubmit,
    isCreateLoading,
    getTotalAlgorithmList,
  };
}
