import { UseFormGetValues, UseFormTrigger } from 'react-hook-form';
import { PackageProblemMakeReqDTO } from '@phs/interfaces';
import { CodePreview } from '@phs/code';
import { PackageExamFormProps } from '@pages/Exam/package/config.ts';
import { usePhaseValidation } from '@pages/Exam/package/shared/usePhaseValidation.ts';
import { useAccessToken } from '@hooks/auth/useAuth.ts';
import { useAlertModal } from '@components/Modals/Alert/hook.ts';

interface UsePreviewProps {
  trigger: UseFormTrigger<PackageExamFormProps>;
  getValues: UseFormGetValues<PackageExamFormProps>;
}

export function usePreview({ trigger, getValues }: UsePreviewProps) {
  const { onOpen } = useAlertModal();
  const { refresh } = useAccessToken();
  const { validate } = usePhaseValidation(getValues('stepProblemList'));

  const onPreview = async () => {
    trigger();
    const { title, level, stepProblemList, detailLevel, manualDifficultyYn } =
      getValues();

    if (title === '') {
      onOpen('제목을 입력해주세요.');
      return;
    }

    if (level < 1) {
      onOpen('종합 난이도를 선택하지 않았습니다.');
      return;
    }

    const payload: PackageProblemMakeReqDTO = {
      title,
      packageLevel: level.toString(),
      packageDetailLevel: detailLevel,
      manualDifficultyYn,
      stepProblemList,
      onlyProblemUpdateYn: false,
    };

    validate(async () => {
      await refresh({
        onSuccess: (accessToken) => {
          CodePreview.setTargetOrigin(
            `https://${import.meta.env.VITE_DEFAULT_TENANT}.${
              import.meta.env.VITE_EXAM_URL
            }`,
          );
          CodePreview.openPackageWindow(payload, accessToken);
        },
        onError: () => {
          onOpen('알 수 없는 문제가 발생하였습니다.');
        },
      });
    });
  };

  return {
    onPreview,
  };
}
