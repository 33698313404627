// 언어 변경 시에 효율성 테스트 케이스 testCaseLanguageSet 변경하는 로직

import { CodeGenerator, CodeTestCase } from '@phs/code';
import {
  ExamLanguageDTO,
  FunctionParameterAndReturnTypeDTO,
  LanguageType,
  ModifyCommandType,
  PhaseProblemDTO,
  PhaseProblemSourceDTO,
  TestCaseDTO,
  TestCaseLanguageDTO,
  TestCaseType,
} from '@phs/interfaces';
import { UseFormSetValue } from 'react-hook-form';
import { PackageExamFormProps } from '../config';

interface UseUpdateLanguageSetProps {
  stepProblemList: PhaseProblemDTO[];
  setValue: UseFormSetValue<PackageExamFormProps>;
  updateStep?: (index: number) => void;
}

export function useUpdateLanguageSet({
  stepProblemList,
  setValue,
  updateStep,
}: UseUpdateLanguageSetProps) {
  const getLanguagePriorityMap = (languages: ExamLanguageDTO[]) => {
    return languages.reduce((priorityMap, languageData) => {
      //@ts-ignore
      priorityMap[languageData.language] = languageData.priority;
      return priorityMap;
    }, {});
  };

  const hasLanguage = (
    targetLanguage: LanguageType,
    languageSet:
      | TestCaseLanguageDTO[]
      | ExamLanguageDTO[]
      | PhaseProblemSourceDTO[],
  ) => {
    return languageSet.some(
      ({ language, commandType }) =>
        language === targetLanguage && commandType !== ModifyCommandType.DELETE,
    );
  };

  const updateJudgeLanguageList = (
    languages: ExamLanguageDTO[],
    step: PhaseProblemDTO,
    index: number,
  ) => {
    const languagePriorityMap = getLanguagePriorityMap(languages);
    const usingLanguageList = languages.filter(({ usage }) => usage);
    const judgeLanguageList = step.judgeLanguageList ?? [];
    const params = step.parameters;

    const deletedLanguages = judgeLanguageList
      .filter(({ language }) => !hasLanguage(language, usingLanguageList))
      .map(({ language }) => language);

    const addedLanguages = usingLanguageList
      .filter(({ language }) => !hasLanguage(language, judgeLanguageList))
      .map(({ language }) => language);

    let newJudgeLanguageList = [...judgeLanguageList];

    // 삭제한 랭귀지 삭제 처리 (CREATE인거는 그냥 뺌)
    newJudgeLanguageList = newJudgeLanguageList.filter(
      (languageSet) =>
        !(
          deletedLanguages.includes(languageSet.language) &&
          languageSet.commandType === ModifyCommandType.CREATE
        ),
    );

    // 삭제한 랭귀지 삭제 처리 (DELETE 처리)
    newJudgeLanguageList = newJudgeLanguageList.map((languageSet) => ({
      ...languageSet,
      commandType: deletedLanguages.includes(languageSet.language)
        ? ModifyCommandType.DELETE
        : languageSet.commandType,
      usage: !deletedLanguages.includes(languageSet.language),
    }));

    // 추가한 랭귀지 추가 처리 (DELETED 처리 고려)
    addedLanguages.forEach((language) => {
      const targetLanguageSet = newJudgeLanguageList.find(
        (v) => v.language === language,
      );

      if (targetLanguageSet) {
        targetLanguageSet.commandType = ModifyCommandType.UPDATE;
      } else {
        const defaultLanguage = languages.find((v) => v.language === language);
        if (!defaultLanguage) return;
        newJudgeLanguageList.push({
          ...defaultLanguage,
          usage: true,
          commandType: ModifyCommandType.CREATE,
          defaultSource: CodeGenerator.getFunctionTemplate(
            language,
            params as FunctionParameterAndReturnTypeDTO,
          ),
          expectedSource: '',
        });
      }
    });

    // 정렬
    newJudgeLanguageList.sort(
      (v1, v2) =>
        // @ts-ignore
        languagePriorityMap[v1.language] - languagePriorityMap[v2.language],
    );

    setValue(
      `stepProblemList.${index}.judgeLanguageList`,
      newJudgeLanguageList,
    );
  };

  const updateTestCases = (
    languages: ExamLanguageDTO[],
    targetTestCases: TestCaseDTO[],
    index: number,
  ) => {
    // 삭제한 경우 : testCaseLanguageSet 있지만 languages에 없음
    // 추가한 경우 : languages 있지만 testCaseLanguageSet 없음
    const languagePriorityMap = getLanguagePriorityMap(languages);
    const usingLanguageList = languages.filter(({ usage }) => usage);
    const efficiencyTestCase = targetTestCases.find(
      ({ testCaseType }) => testCaseType === TestCaseType.EFFICIENCY,
    );

    if (!efficiencyTestCase) return;

    const languageSet = efficiencyTestCase.testCaseLanguageSet;

    const deletedLanguages = languageSet
      .filter(({ language }) => !hasLanguage(language, usingLanguageList))
      .map(({ language }) => language);

    const addedLanguages = usingLanguageList
      .filter(({ language }) => !hasLanguage(language, languageSet))
      .map(({ language }) => language);

    // 테스트 케이스
    const newTestCases = targetTestCases.map((testCase) => {
      if (testCase.testCaseType !== TestCaseType.EFFICIENCY) return testCase;
      let newLanguageSet = [...testCase.testCaseLanguageSet];

      // 삭제한 랭귀지 삭제 처리 (CREATE인거는 그냥 뺌)
      newLanguageSet = newLanguageSet.filter(
        (languageSet) =>
          !(
            deletedLanguages.includes(languageSet.language) &&
            languageSet.commandType === ModifyCommandType.CREATE
          ),
      );

      // 삭제한 랭귀지 삭제 처리 (DELETE 처리)
      newLanguageSet = newLanguageSet.map((languageSet) => ({
        ...languageSet,
        commandType: deletedLanguages.includes(languageSet.language)
          ? ModifyCommandType.DELETE
          : languageSet.commandType,
      }));

      // 추가한 랭귀지 추가 처리 (DELETED 처리 고려)
      addedLanguages.forEach((language) => {
        const targetLanguageSet = newLanguageSet.find(
          (v) => v.language === language,
        );
        if (targetLanguageSet) {
          targetLanguageSet.commandType = ModifyCommandType.UPDATE;
        } else {
          newLanguageSet.push(CodeTestCase.createLanguageSetItem(language));
        }
      });

      // 정렬
      newLanguageSet.sort(
        (v1, v2) =>
          // @ts-ignore
          languagePriorityMap[v1.language] - languagePriorityMap[v2.language],
      );

      return {
        ...testCase,
        testCaseLanguageSet: newLanguageSet,
        commandType:
          testCase.commandType === ModifyCommandType.READ
            ? ModifyCommandType.UPDATE
            : testCase.commandType,
      };
    });
    setValue(`stepProblemList.${index}.testCaseList`, newTestCases);
  };

  // language 바뀔 때 효율성 테스트케이스 testCaseLanguageSet 바꿔주기
  const updateLanguageCallback = (languages: ExamLanguageDTO[]) => {
    stepProblemList.forEach((step, index) => {
      updateTestCases(languages, step.testCaseList, index);
      updateStep?.(index);
      updateJudgeLanguageList(languages, step, index);
    });
  };

  return {
    updateLanguageCallback,
  };
}
