import { FC } from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import Checkbox from '@widget/checkbox';
import TextInput from '@widget/input-text';
import RangeCalendar from '@components/RangeCalendar';
import { RangeValue } from 'rc-picker/es/interface';
import { Dayjs } from 'dayjs';
import { Nullable } from '@phs/interfaces';
import { FormProps } from '../config';
import {
  RequireTitle,
  InputWrap,
  Title,
  CheckboxTitle,
  DateRangeWrapper,
} from './style';

interface DefaultSettingProps {
  dates: [Nullable<Dayjs>, Nullable<Dayjs>];
  register: UseFormRegister<FormProps>;
  isPause?: boolean;
  todayOption?: boolean;
  onChangeDates?: (
    values: RangeValue<Dayjs>,
    formatString: [string, string],
  ) => void;
  errors?: FieldErrors<FormProps>;
}

const DefaultSetting: FC<DefaultSettingProps> = ({
  isPause = false,
  todayOption = false,
  errors,
  register,
  dates,
  onChangeDates,
}) => {
  return (
    <>
      <RequireTitle>제목 (관리용)</RequireTitle>
      <InputWrap>
        <TextInput
          placeholder={'제목 (관리용)을 입력해주세요.'}
          error={errors?.title?.message}
          {...register('title')}
        />
        <span>{errors?.title?.message}</span>
      </InputWrap>
      <RequireTitle>게시 기간</RequireTitle>
      <DateRangeWrapper>
        <RangeCalendar
          value={dates}
          onChange={onChangeDates}
          placeholder={['시작시간을 선택해주세요.', '종료시간을 선택해주세요.']}
        />
      </DateRangeWrapper>
      <Checkbox {...register('pause')} id={'pause'} isActive={isPause}>
        <CheckboxTitle>
          게시 일시중지 (게시중 상태의 게시물이더라도 팝업이 노출되지 않습니다.)
        </CheckboxTitle>
      </Checkbox>
      <Title>게시 설정</Title>
      <Checkbox
        {...register('todayOption')}
        id={'todayOption'}
        isActive={todayOption}
      >
        <CheckboxTitle>오늘 하루 그만 보기 옵션 허용</CheckboxTitle>
      </Checkbox>
    </>
  );
};

export default DefaultSetting;
