export const PMS_PAGES = {
  AUTH: {
    HOME: '/auth',
    LOGIN: '/auth/login',
  },
  DASHBOARD: {
    HOME: '/',
    PACKAGES: '/packages',
  },
  MANAGE: {
    HOME: '/manage',
    DOCUMENT: '/manage/document',
    OPERATION: '/manage/operation',
  },
  NOTICE: {
    HOME: '/notice',
  },
  POPUP: {
    HOME: `/popup`,
    EDIT: (sn: string) => `/popup/${sn}/edit`,
  },
  DATA: {
    HOME: '/data',
    LEVEL: '/data/level',
  },
  EXAM: {
    HOME: '/exam',
    CREATE: (type: string) => `/exam/${type}/create`,
    EDIT: (type: string, sn: string) => `/exam/${type}/${sn}/edit`,
  },
  ERROR: {
    HOME: '/error',
    SESSION: '/error/session',
  },
};
