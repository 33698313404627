import { useForm } from 'react-hook-form';
import { useAuth } from '@hooks/auth/useAuth.ts';
import { LoginReqDTO } from '@interfaces/shared/auth.dto.ts';
import { useConfirmModal } from '@components/Modals/Confirm/hook.ts';

const defaultValues = {
  email: '',
  password: '',
};

export function useLogin() {
  const { onConfirmOpen } = useConfirmModal();
  const { login, isLoginExists } = useAuth();

  const { register, handleSubmit, getValues } = useForm<LoginReqDTO>({
    defaultValues,
    reValidateMode: 'onBlur',
  });

  const handleLogin = async (data: LoginReqDTO) => {
    await isLoginExists(data, async (result) => {
      if (result) {
        onConfirmOpen(
          () => login(data),
          <>
            동일한 계정으로 접속되어 있습니다.
            <br />
            기존 접속을 해제하시겠습니까?
          </>,
        );
      } else {
        await login(data);
      }
    });
  };

  return {
    handleLogin,
    register,
    handleSubmit,
    getValues,
  };
}
