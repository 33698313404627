import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { AxiosError, isAxiosError } from 'axios';
import { CodeFileHandler, CodeTestCase } from '@phs/code';
import {
  AxiosExceptionHandler,
  RuntimeExceptionHandler,
} from '@phs/exceptions';
import {
  BulkFileMap,
  ExamLanguageDTO,
  ModifyCommandType,
  TestCaseDTO,
  TestCaseLanguageDTO,
  TestCaseType,
} from '@phs/interfaces';
import { functionTemplateParameters } from '@stores/selectors/exam/function.ts';
import { useAlertModal } from '@components/Modals/Alert/hook';
import { PMS_EXCEPTION_MESSAGES } from '@constants/exceptions';
import { useFileUpload } from '@hooks/file/useFileUpload';
import FileRepository from '@repositories/file.repository.ts';
import { InputContainer, LanguageContainer, LanguageName } from './style';
import { LanguageConfig } from '@widget/icons-language';
import Input from '@widget/input-number';
import { BorderTableHeaderType, Removable } from '@widget/table-border';
import { IconModify } from '@widget/icons';
import FileInput from '@widget/input-file';
import { useFileValidation } from '@pages/Exam/shared/useFileValidation.ts';
import _ from 'lodash-es';

interface UseTestCaseProps {
  testCaseType?: TestCaseType;
  languages?: ExamLanguageDTO[];
  testCases?: TestCaseDTO[];
  onSubmit?: (data: TestCaseDTO[]) => void;
  onClose?: () => void;
}

export function useTestCase({
  testCaseType = TestCaseType.ACCURACY,
  languages = [],
  testCases = [],
  onSubmit,
  onClose,
}: UseTestCaseProps) {
  const params = useRecoilValue(functionTemplateParameters);
  const { onOpen } = useAlertModal();
  const { uploadFile } = useFileUpload();
  const [cache, setCache] = useState<TestCaseDTO[]>(testCases);
  const [timeLimitCache, setTimeLimitCache] = useState<TestCaseLanguageDTO[]>(
    [],
  );
  const { checkValidation } = useFileValidation();

  const [isTimeLimitModalOpen, setIsTimeLimitModalOpen] =
    useState<boolean>(false);

  // -1은 전체 time limit 변경
  const [testCaseIndex, setTestCaseIndex] = useState<number>(-1);

  useEffect(() => {
    if (testCases.length === 0) {
      const defaultCache = [
        CodeTestCase.createFileTestCase(
          {
            testCaseType,
            testCaseLanguageSet: isEfficiency
              ? CodeTestCase.createLanguageSet(languages)
              : [],
          },
          params,
          1,
        ),
      ];
      setCache(defaultCache);
      return;
    }
    setCache(_.cloneDeep(testCases));
  }, [testCases]);

  const selectedLanguages = useMemo(
    () => languages.filter(({ usage }) => usage),
    [languages],
  );

  const isEfficiency = useMemo(
    () => testCaseType === TestCaseType.EFFICIENCY,
    [testCaseType],
  );

  const onCloseTimeLimitModal = () => {
    setIsTimeLimitModalOpen(false);
  };

  const onSubmitTimeLimit = () => {
    const newCache = [...cache];
    if (testCaseIndex === -1) {
      newCache.forEach((testCase) => {
        testCase.testCaseLanguageSet.forEach((languageSet) => {
          languageSet.timeLimit =
            timeLimitCache.find(
              (timeLimit) => timeLimit.language === languageSet.language,
            )?.timeLimit ?? 0;
          if (languageSet.commandType === ModifyCommandType.READ) {
            languageSet.commandType = ModifyCommandType.UPDATE;
          }
        });
        if (testCase.commandType === ModifyCommandType.READ) {
          testCase.commandType = ModifyCommandType.UPDATE;
        }
      });
    } else {
      const targetTestCase = newCache[testCaseIndex];
      targetTestCase.testCaseLanguageSet.forEach((testCase) => {
        testCase.timeLimit =
          timeLimitCache.find(
            (timeLimit) => timeLimit.language === testCase.language,
          )?.timeLimit ?? 0;
        if (testCase.commandType === ModifyCommandType.READ) {
          testCase.commandType = ModifyCommandType.UPDATE;
        }
      });
      if (targetTestCase.commandType === ModifyCommandType.READ) {
        targetTestCase.commandType = ModifyCommandType.UPDATE;
      }
    }
    setCache(newCache);
    onCloseTimeLimitModal();
  };

  const renderTimeLimitData = (data: ExamLanguageDTO[]) => {
    return data.map((language) => {
      return [
        <LanguageContainer>
          {LanguageConfig[language.language].svg('svg', 48)}
          <LanguageName>{language.language}</LanguageName>
        </LanguageContainer>,
        <InputContainer>
          <Input
            type='number'
            min='0'
            value={
              timeLimitCache.find(
                (value) => value.language === language.language,
              )?.timeLimit
            }
            onChange={({ target: { value } }) => {
              setTimeLimitCache((prev) => {
                const newCache = [...prev];
                const findValue = newCache.find(
                  (value) => value.language === language.language,
                );
                if (findValue) findValue.timeLimit = Number(value);
                return newCache;
              });
            }}
          />
          ms
        </InputContainer>,
      ];
    });
  };

  const getDefaultTimeLimit = (languages: ExamLanguageDTO[]) => {
    return languages
      .filter(({ usage }) => usage)
      .map(({ language }) => ({
        language,
        timeLimit: CodeTestCase.getTimeLimit(language),
        commandType: ModifyCommandType.CREATE,
      }));
  };

  const openTimeLimitModal = (testCaseIndex: number) => {
    setTestCaseIndex(testCaseIndex);
    if (testCaseIndex === -1) {
      setTimeLimitCache(getDefaultTimeLimit(languages));
    } else {
      const newTimeLimitCache = cache[testCaseIndex].testCaseLanguageSet.map(
        (v) => ({ ...v }),
      );
      setTimeLimitCache(newTimeLimitCache);
    }
    setIsTimeLimitModalOpen(true);
  };

  const renderHeaders = () => {
    const headers: BorderTableHeaderType[] = ['Parameters', `Return`];
    if (isEfficiency) {
      const TimeLimitHeader = () => {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px',
            }}
          >
            Time Limit{' '}
            <IconModify
              width='26'
              height='26'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                openTimeLimitModal(-1);
              }}
            />
          </div>
        );
      };
      headers.push(<TimeLimitHeader />);
    }
    headers.push('');
    return headers;
  };

  const renderData = (data: TestCaseDTO[]) => {
    const getTimeLimit = (testCaseLanguageSet: TestCaseLanguageDTO[]) => {
      const usingLanguageSet = testCaseLanguageSet.filter(
        ({ commandType }) => commandType !== ModifyCommandType.DELETE,
      );
      const minTimeLimit = Math.min(
        ...usingLanguageSet.map(({ timeLimit }) => timeLimit),
      );
      const maxTimeLimit = Math.max(
        ...usingLanguageSet.map(({ timeLimit }) => timeLimit),
      );

      return {
        editable: minTimeLimit === maxTimeLimit,
        value:
          minTimeLimit === maxTimeLimit
            ? minTimeLimit
            : `${minTimeLimit}~${maxTimeLimit}`,
      };
    };

    return data
      .map((props, idx) => {
        const nodes = [];
        nodes.push(
          <FileInput
            defaultFileName={props?.inputFile?.fileName}
            key={`${props.number}-${props.sn}-input-${idx}`}
            elementId={`${props.number}-${props.sn}-input-${idx}`}
            accept='.txt'
            placeholder='Input.txt'
            onClick={(e) => (e.currentTarget.value = '')}
            onChange={async (e) => {
              await onChangeUpload?.(e, props, idx, 'input');
            }}
          />,
          <FileInput
            defaultFileName={props?.outputFile?.fileName}
            elementId={`${props.number}-${props.sn}-output-${idx}`}
            accept='.txt'
            placeholder='Output.txt'
            onClick={(e) => (e.currentTarget.value = '')}
            onChange={async (e) => {
              await onChangeUpload?.(e, props, idx, 'output');
            }}
          />,
        );

        if (isEfficiency) {
          const timeLimit = getTimeLimit(props.testCaseLanguageSet);

          nodes.push(
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '16px',
                justifyContent: 'center',
              }}
            >
              <Input
                disabled={!timeLimit.editable}
                value={timeLimit.value}
                onChange={({ target: { value } }) => {
                  const newCache = [...cache];
                  const languageSet = newCache[idx].testCaseLanguageSet;
                  const data = Number(value);
                  if (data < 0 || isNaN(data)) return;

                  languageSet.forEach((language) => {
                    language.timeLimit = data;
                  });
                  setCache(newCache);
                }}
              />
              <span style={{ color: '#343a40' }}>ms</span>
              <IconModify
                width='26'
                height='26'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  openTimeLimitModal(idx);
                }}
              />
            </div>,
          );
        }

        nodes.push(
          <Removable
            key={`${props.number}-${props.sn}-output-${idx}`}
            onRemove={() => {
              deleteTestCase(idx);
            }}
          ></Removable>,
        );

        return {
          nodes,
          commandType: props.commandType,
        };
      })
      .filter((v) => v.commandType !== ModifyCommandType.DELETE)
      .map((v) => v.nodes);
  };

  const createTestCase = () => {
    setCache((prev) => {
      return [
        ...prev,
        CodeTestCase.createFileTestCase(
          {
            testCaseType,
            testCaseLanguageSet: isEfficiency
              ? CodeTestCase.createLanguageSet(languages)
              : [],
          },
          params,
          cache.length + 1,
        ),
      ];
    });
  };

  const updateBulkTestCases = (testCases: TestCaseDTO[]) => {
    setCache([...CodeTestCase.deleteAllTestCases(cache), ...testCases]);
  };

  const updateTestCase = (idx: number, value: Partial<TestCaseDTO>) => {
    const newCache = cache.slice();
    newCache[idx] = CodeTestCase.updateTestCase(cache[idx], value);
    setCache(newCache);
  };

  const deleteTestCase = (idx: number) => {
    const targetTestCase = cache[idx];
    if (targetTestCase.sn) {
      setCache((prev) => {
        return prev.map((testCase, i) => ({
          ...testCase,
          number: i + 1,
          commandType:
            idx === i ? ModifyCommandType.DELETE : testCase.commandType,
        }));
      });
    } else {
      setCache((prev) => {
        return prev
          .filter((_, i) => idx !== i)
          .map((testCase, i) => ({
            ...testCase,
            number: i + 1,
          }));
      });
    }
  };

  const validateFile = (file: File) => {
    if (!CodeFileHandler.validateExt(file.name, '.txt$')) {
      onOpen('txt 파일만 업로드 할 수 있습니다.');
      return false;
    }

    if (!CodeFileHandler.validateSize(file.size)) {
      onOpen('20MB까지만 업로드 할 수 있습니다.');
      return false;
    }

    return true;
  };

  const validateBulkFile = (file: File) => {
    if (!CodeFileHandler.validateSize(file.size)) {
      onOpen('20MB까지만 업로드 할 수 있습니다.');
      return false;
    }

    return true;
  };

  const onChangeBulkUpload = async (
    e: ChangeEvent<HTMLInputElement>,
  ): Promise<boolean> => {
    const files = e.currentTarget.files;
    if (files === null || files.length <= 0) return false;

    try {
      const uploadList: Array<Promise<unknown>> = [];

      for (const file of [...files]) {
        if (!validateBulkFile(file)) return false;

        const fileInfo = file.name.split('.');
        await checkValidation(
          fileInfo[1] === 'in'
            ? params.paramInfo.map(({ paramType }) => paramType)
            : [params.returnType],
          file,
        );
        const promise = CodeFileHandler.validateFile(
          fileInfo[1] === 'in'
            ? params.paramInfo.map(({ paramType }) => paramType)
            : [params.returnType],
          file,
        );

        uploadList.push(promise);
      }

      await Promise.all(uploadList);
      const { status, result } = CodeFileHandler.validateFileMap(files);
      if (!status) throw new Error(result as string);

      const fileTestCases: TestCaseDTO[] = [];

      for await (const [_fileName, file] of result as BulkFileMap) {
        try {
          if (file.input && file.output) {
            const { fileUid: inputFileUid } = await FileRepository.fileUpload(
              file.input,
            );

            const { fileUid: outputFileUid } = await FileRepository.fileUpload(
              file.output,
            );

            const testCase = CodeTestCase.createFileTestCase(
              {
                testCaseType,
                inputFile: {
                  fileUid: inputFileUid,
                  fileName: `${_fileName}.in.txt`,
                },
                outputFile: {
                  fileUid: outputFileUid,
                  fileName: `${_fileName}.out.txt`,
                },
              },
              params,
              cache.length,
            );

            if (isEfficiency) {
              testCase.testCaseLanguageSet =
                CodeTestCase.createLanguageSet(languages);
            }

            fileTestCases.push(testCase);
          }
        } catch (error) {
          new RuntimeExceptionHandler({
            error,
            message: PMS_EXCEPTION_MESSAGES.PMS_FILE_UPLOAD_ERROR,
          });

          onOpen('파일 일괄 업로드 중 에러가 발생하였습니다.');
          return false;
        }
      }
      updateBulkTestCases(fileTestCases);
      return true;
    } catch (error: unknown) {
      onOpen(
        (error as any)?.message ?? '파일 일괄 업로드 중 에러가 발생하였습니다.',
      );

      if (isAxiosError(error)) {
        new AxiosExceptionHandler({
          error: error as AxiosError,
          message: PMS_EXCEPTION_MESSAGES.PMS_API_ERROR,
        });
      } else {
        new RuntimeExceptionHandler({
          error,
          message: PMS_EXCEPTION_MESSAGES.PMS_FILE_UPLOAD_ERROR,
        });
      }

      return false;
    }
  };

  const onChangeUpload = async (
    e: ChangeEvent<HTMLInputElement>,
    props: TestCaseDTO,
    idx: number,
    type: 'input' | 'output',
  ) => {
    const files = e.currentTarget.files;
    if (files === null || files.length <= 0) return;

    try {
      const file = files[0];
      if (!validateFile(file)) return;
      const paramTypeList =
        type === 'input'
          ? props.input.map((value) => value.parameterType)
          : [props.output.parameterType];
      await checkValidation(paramTypeList, file);
      await uploadFile(file, (fileUid) => {
        if (type === 'input') {
          props.inputFile = {
            fileName: file.name,
            fileUid,
          };
        } else {
          props.outputFile = {
            fileName: file.name,
            fileUid,
          };
        }

        updateTestCase(idx, props);
      });
    } catch (error: any) {
      onOpen(error.message ?? '파일 등록시 문제가 발생했습니다.');
      if (isAxiosError(error)) {
        new AxiosExceptionHandler({
          error: error as AxiosError,
          message: PMS_EXCEPTION_MESSAGES.PMS_API_ERROR,
        });
      } else {
        new RuntimeExceptionHandler({
          error,
          message: PMS_EXCEPTION_MESSAGES.PMS_FILE_UPLOAD_ERROR,
        });
      }
    }
  };

  const validateArgs = () => {
    let message = '';
    const isValid = cache
      .filter(({ commandType }) => commandType !== ModifyCommandType.DELETE)
      .every(({ inputFile, outputFile }, idx) => {
        if (!inputFile?.fileUid || !outputFile?.fileUid) {
          message = `${idx + 1}번째 테스트 케이스에 파일이 없습니다.`;
        }

        return inputFile?.fileUid && outputFile?.fileUid;
      });
    if (message) onOpen(message);

    return isValid;
  };

  const handleSubmit = () => {
    if (!validateArgs()) return;

    onSubmit?.(cache);
  };

  const handleClose = () => {
    setCache([]);
    onClose?.();
  };

  return {
    cache,
    onChangeUpload,
    onChangeBulkUpload,
    handleSubmit,
    handleClose,
    createTestCase,
    updateTestCase,
    deleteTestCase,
    isTimeLimitModalOpen,
    selectedLanguages,
    onCloseTimeLimitModal,
    renderTimeLimitData,
    onSubmitTimeLimit,
    renderHeaders,
    renderData,
  };
}
