import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ExpandableTab from '@widget/tab-expandable';
import EditorHeader, { EditorEventType } from '@components/EditorHeader';
import PackageDefault from '../shared/Default';
import PackagePhase from '../shared/Phase';
import { usePreview } from '../shared/usePreview.ts';
import { usePackageDefault } from './useDefault.ts';
import { usePackagePhase } from './usePhase.ts';
import { useCreateExam } from './useExam.ts';
import { Container, EditorContent } from './style.ts';
import EventUtil from '@helpers/eventutil.ts';
import { useUpdateLanguageSet } from '../shared/useUpdateLanguageSet.ts';
import { CodePreview } from '@phs/code';

const CreatePackageExamPage: FC = () => {
  const navigate = useNavigate();

  const {
    trigger,
    control,
    watch,
    register,
    getValues,
    setValue,
    errors,
    onChangeLevel,
    onChangeDetailLevel,
    onChangeToggle,
    onChangeContent,
    onChangeTestCase,
    onChangePhaseLevel,
    onChangeParams,
    onChangeJudgeLanguageList,
    onChangePhaseAlgorithm,
    onChangePhaseDetailLevel,
    onChangeManualDifficultyYn,
  } = usePackageDefault();

  const { fields, append, remove } = usePackagePhase({ control });

  const {
    cursor,
    stepCursor,
    items,
    currentActiveKey,
    onSubmit,
    onAdd,
    onRemove,
    onChangeCursor,
    isCreateLoading,
    getTotalAlgorithmList,
  } = useCreateExam({
    hasTitle: watch('title') !== '',
    hasLevel: watch('level') > 0,
    stepProblemList: watch('stepProblemList'),
    append,
    remove,
    getValues,
    trigger,
  });

  const { onPreview } = usePreview({
    trigger,
    getValues,
  });

  const onClick = async (type: EditorEventType) => {
    switch (type) {
      case EditorEventType.CANCEL:
        navigate(-1);
        break;

      case EditorEventType.PREVIEW:
        onPreview();
        break;

      default:
        break;
    }
  };

  const { updateLanguageCallback } = useUpdateLanguageSet({
    stepProblemList: watch('stepProblemList'),
    setValue: setValue,
  });

  useEffect(() => {
    EventUtil.initBeforeunloadEvent();
    return () => {
      EventUtil.destroyBeforeunloadEvent();
      CodePreview.cancelPreview();
    };
  }, []);

  return (
    <Container
      method={'POST'}
      onSubmit={onSubmit}
      onKeyDown={(e) => {
        //@ts-ignore
        if (e.key === 'Enter' && e?.target?.type !== 'textarea')
          e.preventDefault();
      }}
    >
      <EditorHeader
        title={<>문제&nbsp;기본&nbsp;설정</>}
        cancel={'취소'}
        onClick={onClick}
        preview={'미리 보기'}
        submit={'문제 만들기'}
        isSubmitLoading={isCreateLoading}
      />
      <EditorContent>
        <ExpandableTab
          item={items}
          activeKey={currentActiveKey}
          maxItemCount={6}
          onAdd={onAdd}
          onRemove={onRemove}
          onClick={onChangeCursor}
        />
        {cursor < 1 && (
          <PackageDefault
            register={register}
            errors={errors}
            level={watch('level')}
            onChangeLevel={onChangeLevel}
            watch={watch}
            updateLanguageCallback={updateLanguageCallback}
            detailLevel={watch('detailLevel')}
            algorithmTypeList={getTotalAlgorithmList()}
            onChangeDetailLevel={onChangeDetailLevel}
            manualDifficultyYn={watch('manualDifficultyYn')}
            onChangeManualDifficultyYn={onChangeManualDifficultyYn}
          />
        )}
        {cursor > 0 && (
          <PackagePhase
            cursor={stepCursor}
            watch={watch}
            register={register}
            fields={fields}
            errors={errors}
            handleChange={(content) => onChangeContent(content, stepCursor)}
            handleTestCase={(testCases) =>
              onChangeTestCase(testCases, stepCursor)
            }
            handleParams={(params) => {
              onChangeParams(params, stepCursor);
            }}
            handleJudgeLanguageList={(languageList) => {
              onChangeJudgeLanguageList(languageList, stepCursor);
            }}
            handleToggle={(status) => onChangeToggle(status, stepCursor)}
            handleLevel={(props) => onChangePhaseLevel(props, stepCursor)}
            getValues={getValues}
            setValue={setValue}
            handleDetailLevel={(props) =>
              onChangePhaseDetailLevel(props, stepCursor)
            }
            handleAlgorithm={(algorithmTypeList) =>
              onChangePhaseAlgorithm(algorithmTypeList, stepCursor)
            }
          />
        )}
      </EditorContent>
    </Container>
  );
};

export default CreatePackageExamPage;
