import type { RouteObject } from 'react-router-dom';
import { PMS_PAGES } from '@phs/constants';
import AuthenticatedLayout from '@layout/Authenticated';
import ExamLayout from '@layout/Exam';
import CreateExamPage from '@pages/Exam/stdio/Create';
import EditExamPage from '@pages/Exam/stdio/Edit';
import CreateFuncExamPage from '@pages/Exam/function/Create';
import EditFuncExamPage from '@pages/Exam/function/Edit';
import CreatePackageExamPage from '@pages/Exam/package/Create';
import EditPackageExamPage from '@pages/Exam/package/Edit';

const ExamRoute: RouteObject = {
  path: PMS_PAGES.EXAM.HOME,
  element: (
    <AuthenticatedLayout>
      <ExamLayout />
    </AuthenticatedLayout>
  ),
  children: [
    {
      path: PMS_PAGES.EXAM.CREATE('stdio'),
      element: <CreateExamPage />,
    },
    {
      path: PMS_PAGES.EXAM.EDIT('stdio', ':sn'),
      element: <EditExamPage />,
    },
    {
      path: PMS_PAGES.EXAM.CREATE('function'),
      element: <CreateFuncExamPage />,
    },
    {
      path: PMS_PAGES.EXAM.EDIT('function', ':sn'),
      element: <EditFuncExamPage />,
    },
    {
      path: PMS_PAGES.EXAM.CREATE('package'),
      element: <CreatePackageExamPage />,
    },
    {
      path: PMS_PAGES.EXAM.EDIT('package', ':sn'),
      element: <EditPackageExamPage />,
    },
  ],
};

export default ExamRoute;
