interface UseLanguageModalProps {
  onClose?: () => void;
}

export function useLanguageModal({ onClose }: UseLanguageModalProps) {
  const onCancel = () => {
    onClose?.();
  };

  return {
    onCancel,
  };
}
