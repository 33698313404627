import { FC, useEffect } from 'react';
import Table from '@components/ToggleTable';
import Pagination from '@components/Pagination';
import Search from '@components/Search';
import { useTable } from '@components/ToggleTable/hooks.ts';
import { usePagination } from '@components/Pagination/hooks';
import { useSearch } from '@components/Search/hooks';
import { RenderTableBody } from '@components/Table';
import { usePopUpList } from '@hooks/notice/usePopUp';
import { PopUpDTO } from '@interfaces/notice/popup.dto';
import { Container, Header, FilterWrapper } from './style';
import PopUpMenu from './Menu';
import PopUpFilter from './Filter';
import PopUpSort from './Sort';
import { usePopUpSort } from './Sort/hooks';
import { useNotice } from './hooks';
import { HEADERS } from './config';

const PopUpNotice: FC = () => {
  const { data } = usePopUpList();
  const { resetPage, setTotalPage } = usePagination();
  const { renderData } = useNotice();
  const { resetTableToggle } = useTable({});
  const { resetSearchKeyword } = useSearch();
  const { resetPopUpSortType } = usePopUpSort();

  useEffect(() => {
    setTotalPage(data.totalPages);
  }, [data, setTotalPage]);

  useEffect(() => {
    return () => {
      resetTableToggle();
      resetPopUpSortType();
      resetSearchKeyword();
      resetPage();
    };
  }, []);

  return (
    <Container>
      <Header>
        <PopUpMenu />
      </Header>
      <FilterWrapper>
        <PopUpFilter />
        <PopUpSort />
        <Search
          placeholder={'제목을 입력해주세요.'}
          style={{ maxWidth: '33%' }}
        />
      </FilterWrapper>
      <Table<PopUpDTO>
        headers={HEADERS}
        data={data.content}
        renderTableBody={renderData as RenderTableBody<PopUpDTO>}
      />
      <Pagination />
    </Container>
  );
};

export default PopUpNotice;
