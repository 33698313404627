import { Container } from 'inversify';
import {
  C,
  Cpp,
  CSharp,
  Go,
  Java,
  JavaScript,
  Kotlin,
  Language,
  Pypy,
  Python,
  R,
  Ruby,
  Scala,
  Swift,
} from './langauges';
import { TYPES } from './types';
import {
  CodeGenerator as Generator,
  CodeGeneratorProps,
  CodePreview as Preview,
  CodePreviewProps,
  CodeTestCase as TestCase,
  CodeTestCaseProps,
  CodeTransformer as Transformer,
  CodeTransformerProps,
  CodeValidator as Validator,
  CodeValidatorProps,
  FileHandler,
  FileHandlerProps,
} from './services';

const container = new Container();

// Dependencies
container.bind<Language>(TYPES.C).to(C);
container.bind<Language>(TYPES.CPP).to(Cpp);
container.bind<Language>(TYPES.CSHARP).to(CSharp);
container.bind<Language>(TYPES.JAVA).to(Java);
container.bind<Language>(TYPES.JAVASCRIPT).to(JavaScript);
container.bind<Language>(TYPES.KOTLIN).to(Kotlin);
container.bind<Language>(TYPES.PYTHON).to(Python);
container.bind<Language>(TYPES.R).to(R);
container.bind<Language>(TYPES.RUBY).to(Ruby);
container.bind<Language>(TYPES.SCALA).to(Scala);
container.bind<Language>(TYPES.SWIFT).to(Swift);
container.bind<Language>(TYPES.PYPY).to(Pypy);
container.bind<Language>(TYPES.GO).to(Go);

// Services
container.bind<CodeGeneratorProps>(TYPES.GENERATOR).to(Generator);
container.bind<CodeValidatorProps>(TYPES.VALIDATOR).to(Validator);
container.bind<CodeTransformerProps>(TYPES.TRANSFORMER).to(Transformer);
container.bind<CodeTestCaseProps>(TYPES.TESTCASE).to(TestCase);
container.bind<FileHandlerProps>(TYPES.FILE_HANDLER).to(FileHandler);
container.bind<CodePreviewProps>(TYPES.PREVIEW).to(Preview).inSingletonScope();

const CodeGenerator = container.get<CodeGeneratorProps>(TYPES.GENERATOR);
const CodeValidator = container.get<CodeValidatorProps>(TYPES.VALIDATOR);
const CodeTransformer = container.get<CodeTransformerProps>(TYPES.TRANSFORMER);
const CodeTestCase = container.get<CodeTestCaseProps>(TYPES.TESTCASE);
const CodeFileHandler = container.get<FileHandlerProps>(TYPES.FILE_HANDLER);
const CodePreview = container.get<CodePreviewProps>(TYPES.PREVIEW);

export {
  CodeGenerator,
  CodeValidator,
  CodeTransformer,
  CodeTestCase,
  CodeFileHandler,
  CodePreview,
};
