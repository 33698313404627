import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { confirmModalInfo } from '@stores/selectors/shared/modal.ts';
import {
  confirmModalAction,
  confirmModalStatus,
  confirmModalText,
} from '@stores/atoms/shared/modal.ts';
import { ReactNode } from 'react';

export function useConfirmModal() {
  const resetStatus = useResetRecoilState(confirmModalStatus);
  const resetText = useResetRecoilState(confirmModalText);
  const resetFunction = useResetRecoilState(confirmModalAction);
  const setStatus = useSetRecoilState(confirmModalStatus);
  const setText = useSetRecoilState(confirmModalText);
  const setAction = useSetRecoilState(confirmModalAction);
  const info = useRecoilValue(confirmModalInfo);

  const onConfirmOpen = (okFunction: () => void, children?: ReactNode) => {
    setText(children);
    setStatus(true);
    setAction({
      onOk: () => {
        okFunction();
        onOk();
      },
    });
  };

  const onOk = () => {
    resetStatus();
    resetText();
  };

  const onClose = () => {
    resetFunction();
    resetStatus();
    resetText();
  };

  return {
    info,
    onConfirmOpen,
    onOk,
    onClose,
  };
}
