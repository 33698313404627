import FileRepository from '@repositories/file.repository.ts';
import { useMutation } from '@tanstack/react-query';
import { GuideFileType } from '@phs/interfaces';

export function useGuideFileUpload() {
  const { mutate } = useMutation({
    mutationFn: FileRepository.guideFileUpload,
    retry: 3,
    retryDelay: 1000,
  });

  const uploadFile = (
    blob: Blob | File,
    guideType: GuideFileType,
    callback?: (fileUid: string) => void,
  ) =>
    mutate(
      {
        file: blob,
        guideType,
      },
      {
        onSuccess: ({ fileUid }) => {
          callback?.(fileUid);
        },
      },
    );

  return {
    uploadFile,
  };
}
