// 언어 변경 시에 효율성 테스트 케이스 testCaseLanguageSet 변경하는 로직

import { FormProps } from '@pages/Exam/shared/config';
import { CodeTestCase } from '@phs/code';
import {
  ExamLanguageDTO,
  LanguageType,
  ModifyCommandType,
  TestCaseDTO,
  TestCaseLanguageDTO,
} from '@phs/interfaces';
import { UseFormSetValue } from 'react-hook-form';

interface UseUpdateLanguageSetProps {
  testCases: TestCaseDTO[];
  fileTestCases: TestCaseDTO[];
  setValue: UseFormSetValue<FormProps>;
}

export function useUpdateLanguageSet({
  fileTestCases,
  testCases,
  setValue,
}: UseUpdateLanguageSetProps) {
  const getLanguagePriorityMap = (languages: ExamLanguageDTO[]) => {
    return languages.reduce((priorityMap, languageData) => {
      //@ts-ignore
      priorityMap[languageData.language] = languageData.priority;
      return priorityMap;
    }, {});
  };

  const hasLanguage = (
    targetLanguage: LanguageType,
    languageSet: TestCaseLanguageDTO[] | ExamLanguageDTO[],
  ) => {
    return languageSet.some(
      ({ language, commandType }) =>
        language === targetLanguage && commandType !== ModifyCommandType.DELETE,
    );
  };

  const updateTestCases = (
    languages: ExamLanguageDTO[],
    targetTestCases: TestCaseDTO[],
    isFile: boolean,
  ) => {
    // 삭제한 경우 : testCaseLanguageSet 있지만 languages에 없음
    // 추가한 경우 : languages 있지만 testCaseLanguageSet 없음
    const languagePriorityMap = getLanguagePriorityMap(languages);
    const usingLanguageList = languages.filter(({ usage }) => usage);
    const languageSet = targetTestCases[0].testCaseLanguageSet;

    const deletedLanguages = languageSet
      .filter(({ language }) => !hasLanguage(language, usingLanguageList))
      .map(({ language }) => language);

    const addedLanguages = usingLanguageList
      .filter(({ language }) => !hasLanguage(language, languageSet))
      .map(({ language }) => language);

    // 테스트 케이스
    const newTestCases = targetTestCases.map((testCase) => {
      let newLanguageSet = [...testCase.testCaseLanguageSet];

      // 삭제한 랭귀지 삭제 처리 (CREATE인거는 그냥 뺌)
      newLanguageSet = newLanguageSet.filter(
        (languageSet) =>
          !(
            deletedLanguages.includes(languageSet.language) &&
            languageSet.commandType === ModifyCommandType.CREATE
          ),
      );

      // 삭제한 랭귀지 삭제 처리 (DELETE 처리)
      newLanguageSet = newLanguageSet.map((languageSet) => ({
        ...languageSet,
        commandType: deletedLanguages.includes(languageSet.language)
          ? ModifyCommandType.DELETE
          : languageSet.commandType,
      }));

      // 추가한 랭귀지 추가 처리 (DELETED 처리 고려)
      addedLanguages.forEach((language) => {
        const targetLanguageSet = newLanguageSet.find(
          (v) => v.language === language,
        );
        if (targetLanguageSet) {
          targetLanguageSet.commandType = ModifyCommandType.UPDATE;
        } else {
          newLanguageSet.push(CodeTestCase.createLanguageSetItem(language));
        }
      });

      // 정렬
      newLanguageSet.sort(
        (v1, v2) =>
          // @ts-ignore
          languagePriorityMap[v1.language] - languagePriorityMap[v2.language],
      );

      return {
        ...testCase,
        testCaseLanguageSet: newLanguageSet,
        commandType:
          testCase.commandType === ModifyCommandType.READ
            ? ModifyCommandType.UPDATE
            : testCase.commandType,
      };
    });
    if (isFile) setValue('efficiencyFileTestCases', newTestCases);
    else setValue('efficiencyTestCases', newTestCases);
  };

  // language 바뀔 때 효율성 테스트케이스 testCaseLanguageSet 바꿔주기
  const updateLanguageCallback = (languages: ExamLanguageDTO[]) => {
    if (testCases.length > 0) updateTestCases(languages, testCases, false);
    if (fileTestCases.length > 0)
      updateTestCases(languages, fileTestCases, true);
  };

  return {
    updateLanguageCallback,
  };
}
