import styled from '@emotion/styled';

export const Container = styled.div`
	box-sizing: border-box;
	padding: 20px 16px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.gray_400};
	text-align: center;
`;

export const TitleWrapper = styled.div`
	position: relative;
	width: 100%;
	max-width: 1398px;
	margin: auto;
`;

export const Title = styled.h1`
	margin-left: auto;
	font-size: 18px;
	font-weight: 700;
`;

export const ButtonWrapper = styled.div`
	position: absolute;
	top: -8px;
	right: 16px;
	display: flex;
	gap: 8px;
	min-width: 280px;
	width: max-content;
	margin-left: auto;
`;
