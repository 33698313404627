import styled from '@emotion/styled';

export const Container = styled.form`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  width: 400px;
  max-height: 800px;
  box-sizing: border-box;
  padding: 16px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.white};
`;

export const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
`;

export const Title = styled.div`
  position: sticky;
  top: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  background: ${({ theme }) => theme.colors.white};

  &.border {
    border-bottom: solid 1px ${({ theme }) => theme.colors.gray_300};
  }
`;

export const CloseBtn = styled.a`
  cursor: pointer;
`;

export const ContentWrapper = styled.div`
  min-height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray_700};
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  text-align: center;
  white-space: break-spaces;
`;

export const BottomWrapper = styled.div`
  display: flex;
  gap: 8px;
  height: 32px;
  width: 100%;
  max-width: 33%;
  margin: 0 auto;
`;
