import styled from '@emotion/styled';

export const Container = styled.input<{
  fontColor?: string;
  placeholderColor?: string;
  backgroundColor?: string;
}>`
  width: 100%;
  padding: 16px 12px;
  box-sizing: border-box;
  font-family: inherit;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #868e96;
  border-radius: 4px;
  background: ${({ backgroundColor }) => backgroundColor ?? '#495057'};
  color: ${({ fontColor }) => fontColor ?? '#F1F3F5'};
  outline: none;

  &::placeholder {
    color: ${({ placeholderColor }) => placeholderColor ?? '#868E96'};
  }

  &:hover {
    border: 1px solid #e9ecef;
  }

  &:focus {
    border: 1px solid #12b886;
  }

  :-webkit-autofill {
    box-shadow: 0 0 0 1000px #495057 inset;
    transition: none;
    -webkit-text-fill-color: #f1f3f5;
  }
`;
