import { injectable } from 'inversify';
import 'reflect-metadata';
import { Language } from './base';
import { ExamParameterDTO } from '../interfaces/language.dto';

@injectable()
export class Ruby extends Language {
  public get defaultTimeLimit(): number {
    return 5000;
  }

  public loadFunctionCode(parameters: ExamParameterDTO): string {
    return parameters.paramInfo
      .map(({ paramType, paramName }) => {
        const returnType = this.returnType[paramType];
        return `${returnType.paramType} ${paramName}`.trim();
      })
      .join(', ');
  }

  public getFunctionTemplate(parameters: ExamParameterDTO): string {
    const { value } = this.returnType[parameters.returnType];

    return `def solution(${this.loadFunctionCode(
      parameters,
    )})\n    answer${value}\n    return answer\nend`;
  }

  public getStdioTemplate() {
    return "a, b = gets.chomp.split(' ').map(&:to_i)\nputs a + b";
  }

  public get returnType() {
    return {
      // Primitive Types
      INT: this.setReturnType('', '', ' = 0'),
      LONG: this.setReturnType('', '', ' = 0'),
      DOUBLE: this.setReturnType('', '', ' = 0'),
      STRING: this.setReturnType('', '', " = ''"),
      BOOL: this.setReturnType('', '', ' = true'),
      // Reference Types
      INT_ARRAY: this.setReturnType('', '', ' = []'),
      LONG_ARRAY: this.setReturnType('', '', ' = []'),
      DOUBLE_ARRAY: this.setReturnType('', '', ' = []'),
      STRING_ARRAY: this.setReturnType('', '', ' = []'),
      BOOL_ARRAY: this.setReturnType('', '', ' = []'),
      // 2 Dimensional Reference Types
      INT_2_ARRAY: this.setReturnType('', '', ' = [[]]'),
      LONG_2_ARRAY: this.setReturnType('', '', ' = [[]]'),
      DOUBLE_2_ARRAY: this.setReturnType('', '', ' = [[]]'),
      STRING_2_ARRAY: this.setReturnType('', '', ' = [[]]'),
      BOOL_2_ARRAY: this.setReturnType('', '', ' = [[]]'),
    };
  }
}
