import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { RuntimeExceptionHandler } from '@phs/exceptions';
import { functionInputAndOutput } from '@stores/selectors/exam/function.ts';
import { Input, InputTableHeaderType, Removable } from '@widget/table-input';
import { CodeTestCase, CodeTransformer } from '@phs/code';
import {
  ExamLanguageDTO,
  ModifyCommandType,
  TestCaseDTO,
  TestCaseType,
} from '@phs/interfaces';
import { useValidation } from '../../useValidation.ts';
import { PMS_EXCEPTION_MESSAGES } from '@constants/exceptions.ts';

interface UseTestCaseProps {
  languages?: ExamLanguageDTO[];
  testCaseType?: TestCaseType;
  testCases?: TestCaseDTO[];
  onSubmit?: (data: TestCaseDTO[]) => void;
  onClose?: () => void;
}

export function useTestCase({
  testCaseType = TestCaseType.EXAMPLE,
  languages = [],
  testCases = [],
  onSubmit,
  onClose,
}: UseTestCaseProps) {
  const { params, returnType } = useRecoilValue(functionInputAndOutput);
  const [cache, setCache] = useState<TestCaseDTO[]>(testCases);
  const { validate } = useValidation(cache);

  useEffect(() => {
    if (testCases.length === 0) {
      const defaultCache = [
        CodeTestCase.createTestCase({
          input: params,
          output: {
            parameterName: 'return',
            parameterType: returnType,
            value: '',
          },
          number: 1,
          testCaseType,
          testCaseLanguageSet: [],
        }),
      ];
      setCache(defaultCache);
      return;
    }
    setCache(testCases);
  }, [testCases]);

  const selectedLanguages = useMemo(
    () => languages.filter(({ usage }) => usage),
    [languages],
  );

  const renderHeaders = (): InputTableHeaderType[] => {
    return [
      'Parameters',
      `Return(${CodeTransformer.dataType2Text[returnType]})`,
      '',
    ];
  };

  const addTestCase = () => {
    setCache((prev) => {
      return [
        ...prev,
        CodeTestCase.createTestCase({
          input: params,
          output: {
            parameterName: 'return',
            parameterType: returnType,
            value: '',
          },
          number: cache.length + 1,
          testCaseType,
          testCaseLanguageSet: [],
        }),
      ];
    });
  };

  const updateTestCase = (idx: number, value: Partial<TestCaseDTO>) => {
    const newCache = cache.slice();
    newCache[idx] = CodeTestCase.updateTestCase(cache[idx], value);
    return newCache;
  };

  const deleteTestCase = (idx: number) => {
    const targetTestCase = cache[idx];

    if (targetTestCase.sn) {
      setCache((prev) => {
        return prev.map((testCase, i) => {
          if (idx === i)
            return CodeTestCase.deleteTestCase(testCase, {
              number: i + 1,
            });

          return { ...testCase, number: i + 1 };
        });
      });
    } else {
      setCache((prev) => {
        return prev
          .filter((_, i) => idx !== i)
          .map((testCase, i) => ({
            ...testCase,
            number: i + 1,
          }));
      });
    }
  };

  const onChangeInput = (
    event: ChangeEvent<HTMLInputElement>,
    rowIdx: number,
    columnIdx: number,
  ) => {
    const { input: inputValue } = cache[rowIdx];

    setCache(
      updateTestCase(rowIdx, {
        input: [
          ...inputValue.slice(0, columnIdx),
          {
            ...inputValue[columnIdx],
            value: event.currentTarget.value,
          },
          ...inputValue.slice(columnIdx + 1),
        ],
      }),
    );
  };

  const onChangeOutput = (
    event: ChangeEvent<HTMLInputElement>,
    idx: number,
  ) => {
    const { output: outputValue } = cache[idx];

    setCache(
      updateTestCase(idx, {
        output: {
          ...outputValue,
          value: event.currentTarget.value,
        },
      }),
    );
  };

  const renderData = (data: TestCaseDTO[]) => {
    if (!data) return undefined;

    return data
      .map(
        (
          { sn, input: inputValue, output: outputValue, commandType },
          rowIdx,
        ) => {
          const nodes = inputValue
            .map(({ value }, columnIdx) => (
              <Input
                data-testid={`testcase-textarea-input-${rowIdx}-${columnIdx}`}
                key={`testcase-textarea-function-input-${sn}-${rowIdx}-${columnIdx}`}
                placeholder={'Input'}
                value={value}
                onChange={(e) => onChangeInput(e, rowIdx, columnIdx)}
              />
            ))
            .concat([
              <Input
                data-testid={`testcase-textarea-output-${rowIdx}`}
                placeholder={'Output'}
                value={outputValue.value}
                onChange={(e) => onChangeOutput(e, rowIdx)}
              />,
              <Removable
                key={`testcase-textarea-function-output-${sn}-${rowIdx}`}
                onRemove={() => deleteTestCase(rowIdx)}
              ></Removable>,
            ]);

          return {
            nodes,
            commandType,
          };
        },
      )
      .filter((v) => v.commandType !== ModifyCommandType.DELETE)
      .map((v) => v.nodes);
  };

  const handleSubmit = () =>
    validate(
      () => {
        onSubmit?.(cache);
      },
      (error) => {
        new RuntimeExceptionHandler({
          error,
          message: PMS_EXCEPTION_MESSAGES.PMS_VALIDATION_ERROR,
        });
      },
    );

  const handleClose = () => {
    setCache([]);
    onClose?.();
  };

  return {
    cache,
    addTestCase,
    renderData,
    handleSubmit,
    handleClose,
    selectedLanguages,
    renderHeaders,
  };
}
