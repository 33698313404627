import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  gap: 8px;

  button:first-of-type {
    margin-left: auto;
  }
`;
