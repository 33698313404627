import { useEffect, useState } from 'react';
import CompanyRepository from '@repositories/company.repository.ts';
import { COMPANY_MENU } from './config.ts';
import { useAlertModal } from '@components/Modals/Alert/hook.ts';

export function useCompanyMenu() {
  const { onOpen } = useAlertModal();
  const [commonSchemeSync, setCommonSchemeSync] = useState<boolean>(false);
  const [schedulerSync, setSchedulerSync] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const commonSchedulerSync =
        await CompanyRepository.getCommonSchedulerSync();
      const schemeSync = await CompanyRepository.getCommonSchemeSync();

      setSchedulerSync(commonSchedulerSync);
      setCommonSchemeSync(schemeSync);
    })();
  }, []);

  const getSync = (menu: COMPANY_MENU) => {
    switch (menu) {
      case COMPANY_MENU.COMMON:
        return commonSchemeSync;
      case COMPANY_MENU.SCHEDULER:
        return schedulerSync;
      //항상 열어준다.
      case COMPANY_MENU.DB:
      default:
        return false;
    }
  };

  const onClickButton = async (menu: COMPANY_MENU) => {
    switch (menu) {
      case COMPANY_MENU.COMMON:
        try {
          await CompanyRepository.postCommonSchemeSync();
        } catch (e: any) {
          onOpen(e.response.data.message ?? '동기화시 문제가 발생했습니다.');
        }
        return;
      case COMPANY_MENU.SCHEDULER:
        try {
          await CompanyRepository.postCommonSchedulerSync();
        } catch (e: any) {
          onOpen(e.response.data.message ?? '동기화시 문제가 발생했습니다.');
        }
        return;
      case COMPANY_MENU.DB:
        try {
          await CompanyRepository.postCommonDataBaseSync();
        } catch (e: any) {
          onOpen(e.response.data.message ?? '동기화시 문제가 발생했습니다.');
        }
        return;
      default:
        return;
    }
  };

  return {
    getSync,
    onClickButton,
  };
}
