import styled from '@emotion/styled';

export const Container = styled.fieldset`
	font-family: Pretendard;
	box-sizing: border-box;
	padding: 32px 0;
	border-bottom: 1px solid ${({ theme }) => theme.colors.gray_300};

	legend {
		margin-bottom: 32px;
		font-size: 24px;
		font-weight: 700;
		line-height: 24px;
		color: #212529;
	}
`;
