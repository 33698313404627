import { FC } from 'react';
import { useRecoilValue } from 'recoil';
import { packageExamList } from '@stores/selectors/exam/package.ts';
import CheckBox from '@widget/checkbox';
import Modal from './Modal';
import {
  Container,
  Item,
  ItemWrapper,
  RequireTitle,
  TitleWrapper,
} from './style.ts';
import { useLanguage } from './hooks.ts';
import { ExamLanguageDTO } from '@phs/interfaces';
import { useLanguageList } from '@hooks/exam/useLanguage.ts';
import { CodeGenerator } from '@phs/code';

interface LanguageAndSettingsProps {
  updateLanguageCallback: (languages: ExamLanguageDTO[]) => void;
}

const LanguageAndSettings: FC<LanguageAndSettingsProps> = ({
  updateLanguageCallback,
}) => {
  const languages = useRecoilValue(packageExamList);
  const { languageList } = useLanguageList();

  const { isOpenedModal, onChange, setIsOpenedModal } = useLanguage({
    languages,
    updateLanguageCallback,
  });

  return (
    <Container>
      <TitleWrapper>
        <RequireTitle>언어 선택 & 설정</RequireTitle>
        <svg
          onClick={() => setIsOpenedModal(true)}
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M12.0004 20.3504C10.3489 20.3504 8.73453 19.8607 7.36138 18.9432C5.98823 18.0257 4.91799 16.7216 4.286 15.1958C3.65401 13.67 3.48865 11.9911 3.81084 10.3714C4.13302 8.75165 4.92828 7.26382 6.09605 6.09605C7.26382 4.92828 8.75165 4.13302 10.3714 3.81084C11.9911 3.48865 13.67 3.65401 15.1958 4.286C16.7216 4.91799 18.0257 5.98823 18.9432 7.36138C19.8607 8.73453 20.3504 10.3489 20.3504 12.0004C20.3477 14.2141 19.4672 16.3365 17.9018 17.9018C16.3365 19.4672 14.2141 20.3477 12.0004 20.3504ZM12.0004 5.35039C10.6851 5.35039 9.39944 5.74041 8.30585 6.47112C7.21227 7.20183 6.35992 8.24042 5.8566 9.45555C5.35327 10.6707 5.22158 12.0078 5.47817 13.2977C5.73476 14.5877 6.36812 15.7726 7.29813 16.7027C8.22815 17.6327 9.41307 18.266 10.703 18.5226C11.993 18.7792 13.3301 18.6475 14.5452 18.1442C15.7604 17.6409 16.799 16.7885 17.5297 15.6949C18.2604 14.6013 18.6504 13.3156 18.6504 12.0004C18.6477 10.2375 17.9463 8.5476 16.6997 7.30106C15.4532 6.05451 13.7633 5.35304 12.0004 5.35039Z'
            fill='#868E96'
          />
          <path
            d='M12.8504 10.8754H11.1504V16.1254H12.8504V10.8754Z'
            fill='#868E96'
          />
          <path
            d='M12.8504 7.87539H11.1504V9.37539H12.8504V7.87539Z'
            fill='#868E96'
          />
        </svg>
      </TitleWrapper>
      <ItemWrapper>
        {languages.map(({ language, usage }, idx) => (
          <CheckBox
            id={language}
            isActive={usage}
            key={language}
            onClick={() => onChange(idx, !usage)}
          >
            <Item>{language}</Item>
          </CheckBox>
        ))}
      </ItemWrapper>
      {isOpenedModal && (
        <Modal
          currentLanguages={CodeGenerator.getLanguageTemplate(languageList)}
          onClose={() => setIsOpenedModal(false)}
        />
      )}
    </Container>
  );
};

export default LanguageAndSettings;
