import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { DropdownItemType } from '@widget/dropdown';
import { fallback, PackageExamFormProps } from '../config.ts';
import { schema } from '../scheme.ts';
import { useDebouncedCallback } from '@react-hookz/web';
import {
  ExamLanguageDTO,
  FunctionParameterAndReturnTypeDTO,
  TestCaseDTO,
} from '@phs/interfaces';

export function usePackageDefault() {
  const {
    trigger,
    control,
    watch,
    register,
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
  } = useForm<PackageExamFormProps>({
    defaultValues: fallback,
    resolver: zodResolver(schema),
    mode: 'onChange',
  });

  const onChangeLevel = useDebouncedCallback(
    (props: DropdownItemType) => {
      setValue('level', Number(props.key));
      trigger('level');
    },
    [],
    100,
  );

  const onChangeManualDifficultyYn = useDebouncedCallback(
    () => {
      setValue('manualDifficultyYn', !getValues('manualDifficultyYn'));
    },
    [],
    100,
  );

  const onChangeDetailLevel = useDebouncedCallback(
    (props: DropdownItemType) => {
      setValue('detailLevel', Number(props.key));
      trigger('detailLevel');
    },
    [],
    100,
  );

  const onChangeToggle = useDebouncedCallback(
    (status: boolean, cursor: number) => {
      setValue(`stepProblemList.${cursor}.summaryYn`, status);
    },
    [],
    100,
  );

  const onChangeContent = useDebouncedCallback(
    (content: string, cursor: number) => {
      setValue(`stepProblemList.${cursor}.content`, content);
    },
    [],
    100,
  );

  const onChangeTestCase = useDebouncedCallback(
    (testCases: TestCaseDTO[], cursor: number) => {
      setValue(`stepProblemList.${cursor}.testCaseList`, testCases);
    },
    [],
    100,
  );

  const onChangePhaseLevel = useDebouncedCallback(
    (props: DropdownItemType, cursor: number) => {
      setValue(`stepProblemList.${cursor}.level`, Number(props.key));
      trigger(`stepProblemList.${cursor}.level`);
    },
    [],
    100,
  );

  const onChangePhaseDetailLevel = useDebouncedCallback(
    (props: DropdownItemType, cursor: number) => {
      setValue(`stepProblemList.${cursor}.detailLevel`, Number(props.key));
      trigger(`stepProblemList.${cursor}.detailLevel`);
    },
    [],
    100,
  );

  const onChangePhaseAlgorithm = useDebouncedCallback(
    (props: string[], cursor: number) => {
      setValue(`stepProblemList.${cursor}.algorithmTypeList`, props);
      trigger(`stepProblemList.${cursor}.algorithmTypeList`);
    },
    [],
    100,
  );

  const onChangeParams = (
    params: FunctionParameterAndReturnTypeDTO,
    cursor: number,
  ) => {
    setValue(`stepProblemList.${cursor}.parameters`, params);
  };

  const onChangeJudgeLanguageList = (
    languageList: ExamLanguageDTO[],
    cursor: number,
  ) => {
    setValue(`stepProblemList.${cursor}.judgeLanguageList`, languageList);
  };

  return {
    trigger,
    control,
    watch,
    errors,
    clearErrors,
    register,
    setValue,
    getValues,
    handleSubmit,
    onChangeLevel,
    onChangeDetailLevel,
    onChangeToggle,
    onChangeContent,
    onChangeTestCase,
    onChangePhaseLevel,
    onChangeParams,
    onChangeJudgeLanguageList,
    onChangePhaseDetailLevel,
    onChangePhaseAlgorithm,
    onChangeManualDifficultyYn,
  };
}
