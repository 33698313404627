import { useState } from 'react';
import DataRepository from '@repositories/data.repository.ts';

export function useRegister() {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const setError = (message: string) => {
    setErrorMessage(message);
  };
  const isErrorModalOpen = () => {
    return errorMessage !== undefined;
  };

  const onCloseErrorModal = () => {
    setErrorMessage(undefined);
  };

  const setDeleteModalState = (open: boolean) => {
    setDeleteModalOpen(open);
  };

  const downloadFile = (
    fileName: string | undefined,
    fileUid: string | undefined,
  ) => {
    if (fileName === undefined || fileUid === undefined) {
      setErrorMessage('파일이 존재하지 않습니다.');
      return;
    }

    const a = document.createElement('a');

    a.download = fileName;
    a.hidden = true;
    document.body.appendChild(a);

    DataRepository.downloadGuideFile(fileUid)
      .then((value) => {
        if (!value) return;
        const blob = new Blob([value as Blob], { type: 'octet/stream' });
        a.href = URL.createObjectURL(blob);
        a.click();
        a.remove();
      })
      .catch((reason) => setErrorMessage(reason));
  };

  return {
    errorMessage,
    deleteModalOpen,
    setDeleteModalState,
    isErrorModalOpen,
    setError,
    onCloseErrorModal,
    downloadFile,
  };
}
