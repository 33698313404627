import { Control, useFieldArray } from 'react-hook-form';
import { PackageExamFormProps } from '@pages/Exam/package/config.ts';

interface UsePackagePhaseProps {
  control: Control<PackageExamFormProps>;
}

export function usePackagePhase({ control }: UsePackagePhaseProps) {
  const { fields, append, remove } = useFieldArray({
    name: 'stepProblemList',
    control,
  });

  return {
    fields,
    append,
    remove,
  };
}
