import { useEffect, useMemo, useRef, useState } from 'react';

export interface DropDownMultiplePropsOnClickPropsItemProps {
  name: string | number;
  key: string;
}

type UseMultipleDropDownProps = {
  title: string;
  isOpen: boolean;
  onChange: (props: string[]) => void;
  value: string[];
  items: DropDownMultiplePropsOnClickPropsItemProps[];
};

export function useMultipleDropDown(props: UseMultipleDropDownProps) {
  const { value, isOpen, title, items, onChange } = props;

  const [isActive, setIsActive] = useState<boolean>(isOpen as boolean);

  const placeholder = useMemo(() => {
    const size = value.length;

    if (size < 1) return title;

    const displayName = items.find((item) => item.key === value[0])?.name ?? '';

    return size === 1 ? displayName : `(${size}) ${displayName},...`;
  }, [value, items]);

  const handleClick = (key: string) => {
    let newValue = [...value];

    if (newValue.includes(key)) {
      newValue = newValue.filter((v) => v !== key);
    } else {
      newValue.push(key);
    }
    onChange(newValue);
  };

  return {
    placeholder,
    isOpen: isActive,
    setOpen: setIsActive,
    handleClick,
  };
}

export function useOutsideClick(callback: () => void) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [callback]);

  return ref;
}
