import styled from '@emotion/styled';

export const Container = styled.form`
	width: 100vw;
	height: 100vh;
`;

export const EditorContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
	box-sizing: border-box;
	max-width: 1398px;
	padding: 40px;
	margin: auto;
`;
