import styled from '@emotion/styled';
import { css } from '@emotion/react';

const LargeCss = css`
  min-width: 64px;
  height: 28px;
  padding: 5px 16px;
  justify-content: center;
  border: none;
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
`;

const SmallCss = css`
  height: 24px;
  padding: 4px 8px;
  border: 1px solid;
  border-radius: 4px;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
`;

export const Container = styled.div<{
  backgroundColor: string;
  color: string;
  borderColor?: string;
  isLarge?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  border-color: ${({ borderColor }) => borderColor && borderColor};
  color: ${({ color }) => color};
  background: ${({ backgroundColor }) => backgroundColor};

  ${({ isLarge = false }) => (isLarge ? LargeCss : SmallCss)};
`;
