import styled from '@emotion/styled';

export const Container = styled.form`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  width: 720px;
  max-height: 800px;
  box-sizing: border-box;
  padding: 16px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.white};
`;

export const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
`;

export const Title = styled.div`
  position: sticky;
  top: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  background: ${({ theme }) => theme.colors.white};

  &.border {
    border-bottom: solid 1px ${({ theme }) => theme.colors.gray_300};
  }
`;

export const CloseBtn = styled.a`
  cursor: pointer;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-sizing: border-box;
  padding: 32px 0;
`;

export const BottomWrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  max-width: 66%;
  margin: 0 auto;
  justify-content: center;
`;

export const InputButtonWrap = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;

  div {
    width: 100%;
  }

  button {
    width: 120px;
    height: 40px;
  }
`;

export const SnTagWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: center;
`;

export const TagWrap = styled.div`
  position: relative;
  cursor: pointer;

  & > *:nth-of-type(2) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
  }

  &:hover {
    & > *:nth-of-type(2) {
      opacity: 1;
    }
  }
`;
