import { DEPENDENCY_REGISTRY } from '../constants/dependency';

export const LanguageTypes = {
  C: Symbol.for(DEPENDENCY_REGISTRY.C),
  CPP: Symbol.for(DEPENDENCY_REGISTRY.CPP),
  CSHARP: Symbol.for(DEPENDENCY_REGISTRY.CSHARP),
  JAVA: Symbol.for(DEPENDENCY_REGISTRY.JAVA),
  JAVASCRIPT: Symbol.for(DEPENDENCY_REGISTRY.JAVASCRIPT),
  KOTLIN: Symbol.for(DEPENDENCY_REGISTRY.KOTLIN),
  PYTHON: Symbol.for(DEPENDENCY_REGISTRY.PYTHON),
  R: Symbol.for(DEPENDENCY_REGISTRY.R),
  RUBY: Symbol.for(DEPENDENCY_REGISTRY.RUBY),
  SCALA: Symbol.for(DEPENDENCY_REGISTRY.SCALA),
  SWIFT: Symbol.for(DEPENDENCY_REGISTRY.SWIFT),
  PYPY: Symbol.for(DEPENDENCY_REGISTRY.PYPY),
  GO: Symbol.for(DEPENDENCY_REGISTRY.GO),
};
