import { useEffect, useState } from 'react';
import { LevelExcelDataDto } from '@interfaces/company/data.dto.ts';
import DataRepository from '@repositories/data.repository.ts';
import { LEVEL_DATA } from '@pages/Data/config.ts';
import { LevelData } from '@pages/Data/Level/Table';
import { useAlertModal } from '@components/Modals/Alert/hook.ts';

export function useLevel() {
  const [rsData, setRsData] = useState<LevelExcelDataDto | null>(null);
  const [tableData, setTableData] = useState<LevelData[]>([]);
  const { onOpen } = useAlertModal();

  useEffect(() => {
    (async () => {
      try {
        const response = await DataRepository.postLevelData();
        setRsData(response);

        const rs = response.problemWeightInfoMap;

        setTableData([
          {
            level: '1단계',
            value: [
              [
                rs[LEVEL_DATA.LEVEL_1_1].firstStepValue,
                rs[LEVEL_DATA.LEVEL_1_2].firstStepValue,
                rs[LEVEL_DATA.LEVEL_1_3].firstStepValue,
                rs[LEVEL_DATA.LEVEL_1_4].firstStepValue,
                rs[LEVEL_DATA.LEVEL_1_5].firstStepValue,
              ],
              [
                rs[LEVEL_DATA.LEVEL_2_1].firstStepValue,
                rs[LEVEL_DATA.LEVEL_2_2].firstStepValue,
                rs[LEVEL_DATA.LEVEL_2_3].firstStepValue,
                rs[LEVEL_DATA.LEVEL_2_4].firstStepValue,
                rs[LEVEL_DATA.LEVEL_2_5].firstStepValue,
              ],
              [
                rs[LEVEL_DATA.LEVEL_3_1].firstStepValue,
                rs[LEVEL_DATA.LEVEL_3_2].firstStepValue,
                rs[LEVEL_DATA.LEVEL_3_3].firstStepValue,
                rs[LEVEL_DATA.LEVEL_3_4].firstStepValue,
                rs[LEVEL_DATA.LEVEL_3_5].firstStepValue,
              ],
              [
                rs[LEVEL_DATA.LEVEL_4_1].firstStepValue,
                rs[LEVEL_DATA.LEVEL_4_2].firstStepValue,
                rs[LEVEL_DATA.LEVEL_4_3].firstStepValue,
                rs[LEVEL_DATA.LEVEL_4_4].firstStepValue,
                rs[LEVEL_DATA.LEVEL_4_5].firstStepValue,
              ],
              [
                rs[LEVEL_DATA.LEVEL_5_1].firstStepValue,
                rs[LEVEL_DATA.LEVEL_5_2].firstStepValue,
                rs[LEVEL_DATA.LEVEL_5_3].firstStepValue,
                rs[LEVEL_DATA.LEVEL_5_4].firstStepValue,
                rs[LEVEL_DATA.LEVEL_5_5].firstStepValue,
              ],
            ],
          },
          {
            level: '2단계',
            value: [
              [
                rs[LEVEL_DATA.LEVEL_1_1].secondStepValue,
                rs[LEVEL_DATA.LEVEL_1_2].secondStepValue,
                rs[LEVEL_DATA.LEVEL_1_3].secondStepValue,
                rs[LEVEL_DATA.LEVEL_1_4].secondStepValue,
                rs[LEVEL_DATA.LEVEL_1_5].secondStepValue,
              ],
              [
                rs[LEVEL_DATA.LEVEL_2_1].secondStepValue,
                rs[LEVEL_DATA.LEVEL_2_2].secondStepValue,
                rs[LEVEL_DATA.LEVEL_2_3].secondStepValue,
                rs[LEVEL_DATA.LEVEL_2_4].secondStepValue,
                rs[LEVEL_DATA.LEVEL_2_5].secondStepValue,
              ],
              [
                rs[LEVEL_DATA.LEVEL_3_1].secondStepValue,
                rs[LEVEL_DATA.LEVEL_3_2].secondStepValue,
                rs[LEVEL_DATA.LEVEL_3_3].secondStepValue,
                rs[LEVEL_DATA.LEVEL_3_4].secondStepValue,
                rs[LEVEL_DATA.LEVEL_3_5].secondStepValue,
              ],
              [
                rs[LEVEL_DATA.LEVEL_4_1].secondStepValue,
                rs[LEVEL_DATA.LEVEL_4_2].secondStepValue,
                rs[LEVEL_DATA.LEVEL_4_3].secondStepValue,
                rs[LEVEL_DATA.LEVEL_4_4].secondStepValue,
                rs[LEVEL_DATA.LEVEL_4_5].secondStepValue,
              ],
              [
                rs[LEVEL_DATA.LEVEL_5_1].secondStepValue,
                rs[LEVEL_DATA.LEVEL_5_2].secondStepValue,
                rs[LEVEL_DATA.LEVEL_5_3].secondStepValue,
                rs[LEVEL_DATA.LEVEL_5_4].secondStepValue,
                rs[LEVEL_DATA.LEVEL_5_5].secondStepValue,
              ],
            ],
          },
          {
            level: '3단계',
            value: [
              [
                rs[LEVEL_DATA.LEVEL_1_1].thirdStepValue,
                rs[LEVEL_DATA.LEVEL_1_2].thirdStepValue,
                rs[LEVEL_DATA.LEVEL_1_3].thirdStepValue,
                rs[LEVEL_DATA.LEVEL_1_4].thirdStepValue,
                rs[LEVEL_DATA.LEVEL_1_5].thirdStepValue,
              ],
              [
                rs[LEVEL_DATA.LEVEL_2_1].thirdStepValue,
                rs[LEVEL_DATA.LEVEL_2_2].thirdStepValue,
                rs[LEVEL_DATA.LEVEL_2_3].thirdStepValue,
                rs[LEVEL_DATA.LEVEL_2_4].thirdStepValue,
                rs[LEVEL_DATA.LEVEL_2_5].thirdStepValue,
              ],
              [
                rs[LEVEL_DATA.LEVEL_3_1].thirdStepValue,
                rs[LEVEL_DATA.LEVEL_3_2].thirdStepValue,
                rs[LEVEL_DATA.LEVEL_3_3].thirdStepValue,
                rs[LEVEL_DATA.LEVEL_3_4].thirdStepValue,
                rs[LEVEL_DATA.LEVEL_3_5].thirdStepValue,
              ],
              [
                rs[LEVEL_DATA.LEVEL_4_1].thirdStepValue,
                rs[LEVEL_DATA.LEVEL_4_2].thirdStepValue,
                rs[LEVEL_DATA.LEVEL_4_3].thirdStepValue,
                rs[LEVEL_DATA.LEVEL_4_4].thirdStepValue,
                rs[LEVEL_DATA.LEVEL_4_5].thirdStepValue,
              ],
              [
                rs[LEVEL_DATA.LEVEL_5_1].thirdStepValue,
                rs[LEVEL_DATA.LEVEL_5_2].thirdStepValue,
                rs[LEVEL_DATA.LEVEL_5_3].thirdStepValue,
                rs[LEVEL_DATA.LEVEL_5_4].thirdStepValue,
                rs[LEVEL_DATA.LEVEL_5_5].thirdStepValue,
              ],
            ],
          },
          {
            level: '4단계',
            value: [
              [
                rs[LEVEL_DATA.LEVEL_1_1].fourStepValue,
                rs[LEVEL_DATA.LEVEL_1_2].fourStepValue,
                rs[LEVEL_DATA.LEVEL_1_3].fourStepValue,
                rs[LEVEL_DATA.LEVEL_1_4].fourStepValue,
                rs[LEVEL_DATA.LEVEL_1_5].fourStepValue,
              ],
              [
                rs[LEVEL_DATA.LEVEL_2_1].fourStepValue,
                rs[LEVEL_DATA.LEVEL_2_2].fourStepValue,
                rs[LEVEL_DATA.LEVEL_2_3].fourStepValue,
                rs[LEVEL_DATA.LEVEL_2_4].fourStepValue,
                rs[LEVEL_DATA.LEVEL_2_5].fourStepValue,
              ],
              [
                rs[LEVEL_DATA.LEVEL_3_1].fourStepValue,
                rs[LEVEL_DATA.LEVEL_3_2].fourStepValue,
                rs[LEVEL_DATA.LEVEL_3_3].fourStepValue,
                rs[LEVEL_DATA.LEVEL_3_4].fourStepValue,
                rs[LEVEL_DATA.LEVEL_3_5].fourStepValue,
              ],
              [
                rs[LEVEL_DATA.LEVEL_4_1].fourStepValue,
                rs[LEVEL_DATA.LEVEL_4_2].fourStepValue,
                rs[LEVEL_DATA.LEVEL_4_3].fourStepValue,
                rs[LEVEL_DATA.LEVEL_4_4].fourStepValue,
                rs[LEVEL_DATA.LEVEL_4_5].fourStepValue,
              ],
              [
                rs[LEVEL_DATA.LEVEL_5_1].fourStepValue,
                rs[LEVEL_DATA.LEVEL_5_2].fourStepValue,
                rs[LEVEL_DATA.LEVEL_5_3].fourStepValue,
                rs[LEVEL_DATA.LEVEL_5_4].fourStepValue,
                rs[LEVEL_DATA.LEVEL_5_5].fourStepValue,
              ],
            ],
          },
        ]);
      } catch (error) {
        onOpen('데이터 불러오는 중 오류가 발생했습니다.');
      }
    })();
  }, []);

  const setDataByExcel = (rsData: LevelExcelDataDto, data: LevelData[]) => {
    setRsData(rsData);
    setTableData(data);
  };

  return {
    rsData,
    tableData,
    setDataByExcel,
  };
}
