import { ReactNode } from 'react';
import BasicTable, {
  HandleTableClickProps,
  TableHeaderProps,
} from '@widget/table';
import { Container } from './style.ts';
import { useTable } from './hooks.ts';

export type RenderTableBody<T> = (data: T[]) => ReactNode[][];

interface TableProps<T = never> {
  headers: TableHeaderProps[];
  data?: T[];
  renderTableBody?: RenderTableBody<T>;
  onClick?: (props: HandleTableClickProps<T>) => void;
  fixLayout?: boolean;
}

function Table<T = never>({
  headers,
  data,
  renderTableBody,
  onClick,
  fixLayout = false,
  ...rest
}: TableProps<T>) {
  const { render } = useTable<T>({ renderTableBody });

  return (
    <Container fixLayout={fixLayout}>
      <BasicTable<T>
        headers={headers}
        data={render(data)}
        onClick={onClick}
        {...rest}
      />
    </Container>
  );
}

export default Table;
