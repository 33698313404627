import styled from '@emotion/styled';

export const Title = styled.h2`
  margin: 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
`;

export const RequireTitle = styled.span<{ hint?: string }>`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray_900};

  &::after {
    display: inline-block;
    margin-left: 4px;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.red_600};
    vertical-align: text-bottom;
    content: ${({ hint = '*' }) => `'${hint}'`};
  }
`;

export const Divider = styled.div`
  margin: 12px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray_500};
`;

export const TotalScoreRateText = styled.div`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`;
