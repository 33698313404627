import styled from '@emotion/styled';

export const Container = styled.div`
	box-sizing: border-box;
	padding: 16px 0;
	text-align: left;

	div {
		width: 30%;
	}
`;
