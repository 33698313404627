import { useMutation, useQuery } from '@tanstack/react-query';
import {
  MakeProblemReqDTO,
  MakeProblemResDTO,
} from '@interfaces/dashboard/problem.dto.ts';
import { ProblemCategory, ProblemType } from '@phs/interfaces';
import PstRepository from '@repositories/pst.repository.ts';
import { queryKeys } from '@constants/key.ts';

export function useCreateExam() {
  const { mutate, isLoading } = useMutation({
    mutationFn: PstRepository.createProblem,
    retry: 3,
    retryDelay: 1000,
  });

  const create = (
    rq: MakeProblemReqDTO,
    {
      onSuccess,
      onError,
    }: {
      onSuccess?: () => void;
      onError?: () => void;
    },
  ) => {
    mutate(rq, {
      onSuccess,
      onError,
    });
  };

  return { create, isLoading };
}

const readExamFallback: MakeProblemResDTO = {
  title: '',
  content: undefined,
  level: '0',
  detailLevel: 0,
  algorithmTypeList: [],
  overallPredictionAverageScore: 0,
  overallPredictionLevel: 0,
  judgeLanguageList: [],
  judgeType: ProblemCategory.STDIO,
  testCaseList: [],
  testCaseScoreRateYn: false,
  accuracyScoreRate: undefined,
  efficiencyScoreRate: undefined,
  onlyProblemUpdateYn: false,
};

export function useReadExam(props: {
  sn: string;
  problemPossessionType?: ProblemType;
}) {
  const queries = [queryKeys.readPstProblem, props.sn];
  if (props.problemPossessionType) queries.push(props.problemPossessionType);

  const { data, isError, isLoading } = useQuery<
    Promise<MakeProblemReqDTO>,
    unknown,
    MakeProblemResDTO,
    readonly string[]
  >([...queries], () => PstRepository.readProblem(props), {
    enabled: !!props.sn,
    cacheTime: 0,
  });

  if (isError || isLoading) return readExamFallback;

  return data;
}

export function useUpdateExam() {
  const { mutate, isLoading } = useMutation({
    mutationFn: PstRepository.updateProblem,
  });

  const update = (
    data: { sn: string; data: MakeProblemReqDTO },
    {
      onSuccess,
      onError,
    }: {
      onSuccess: () => void;
      onError: () => void;
    },
  ) => {
    mutate(data, {
      onSuccess,
      onError,
    });
  };

  return { update, isLoading };
}
