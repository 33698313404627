import { BaseRepository } from '@phs/repositories';
import {
  AlgorithmExcelDataDto,
  GuideFileInfoRsDto,
  LevelExcelDataDto,
} from '@interfaces/company/data.dto.ts';
import { PMS_URL } from '@phs/constants';
import { GuideFileType } from '@phs/interfaces';

class DataRepository extends BaseRepository {
  constructor(baseURL: string) {
    super(baseURL);
  }

  public async getGuideFile(guideType: GuideFileType) {
    const { data: response } = await super.get<GuideFileInfoRsDto>(
      `${PMS_URL.GUIDE_FILE_INFO}`,
      {
        params: { guideFileType: guideType },
      },
    );
    return response;
  }

  public async postGuideFile(guideType: GuideFileType) {
    const { data: response } = await super.get<GuideFileInfoRsDto>(
      `${PMS_URL.GUIDE_FILE_INFO}`,
      {
        params: { guideFileType: guideType },
      },
    );
    return response;
  }

  public async downloadGuideFile(fileUid: string) {
    const { data: response } = await super.get(
      `${PMS_URL.GUIDE_FILE_DOWNLOAD(fileUid)}`,
      {
        responseType: 'blob',
      },
    );
    return response;
  }

  public async deleteGuideFile(guideType: GuideFileType) {
    const { data: response } = await super.delete(`${PMS_URL.GUIDE_DELETE}`, {
      params: { guideFileType: guideType },
    });
    return response;
  }

  public async postAlgorithmData(excel?: File) {
    if (excel) {
      const formData = new FormData();
      formData.append('file', excel, excel.name);
      const { data: response } = await super.postForm<AlgorithmExcelDataDto>(
        `${PMS_URL.ALGORITHM_EXCEL_DATA}`,
        formData,
      );
      return response;
    } else {
      const { data: response } = await super.post<AlgorithmExcelDataDto>(
        `${PMS_URL.ALGORITHM_EXCEL_DATA}`,
      );
      return response;
    }
  }

  public async postAlgorithmDataSave(
    data: AlgorithmExcelDataDto,
    confirmMessage: string,
  ) {
    const { data: response } = await super.post(
      `${PMS_URL.ALGORITHM_EXCEL_DATA_SAVE}`,
      {
        confirmMessage,
        algorithmTagMap: data.algorithmTagInfoMap,
      },
    );
    return response;
  }

  public async postLevelData(excel?: File) {
    if (excel) {
      const formData = new FormData();
      formData.append('file', excel, excel.name);
      const { data: response } = await super.postForm<LevelExcelDataDto>(
        `${PMS_URL.LEVEL_EXCEL_DATA}`,
        formData,
      );
      return response;
    } else {
      const { data: response } = await super.post<LevelExcelDataDto>(
        `${PMS_URL.LEVEL_EXCEL_DATA}`,
      );
      return response;
    }
  }

  public async postLevelDataSave(
    data: LevelExcelDataDto,
    confirmMessage: string,
  ) {
    const { data: response } = await super.post(
      `${PMS_URL.LEVEL_EXCEL_DATA_SAVE}`,
      {
        confirmMessage,
        problemWeightInfoMap: data.problemWeightInfoMap,
      },
    );
    return response;
  }

  public async downloadAlgorithmFile() {
    return await super.get(`${PMS_URL.ALGORITHM_EXCEL_DATA_DOWNLOAD}`, {
      responseType: 'blob',
    });
  }

  public async downloadLevelFile() {
    return await super.get(`${PMS_URL.LEVEL_EXCEL_DATA_DOWNLOAD}`, {
      responseType: 'blob',
    });
  }
}

const dataRepository = new DataRepository(import.meta.env.VITE_API_URL);

export default dataRepository;
