import { DATE_FORMAT, ProblemBaseType } from '@phs/interfaces';
import Switch from '@widget/switch';
import { IconLoading } from '@widget/icons';
import { PackageProblemDTO } from '@interfaces/dashboard/problem.dto.ts';
import { usePackageProblemList } from '@hooks/dashboard/usePackageProblem.ts';
import { useDate } from '@hooks/shared/useDate.ts';
import Language from '@components/Language';
import PstRepository from '@repositories/pst.repository.ts';
import { PMS_PAGES } from '@phs/constants';
import {
  TableCreateTime,
  TableTitle,
  SyncWrap,
  TitleWrap,
  AlgorithmTags,
} from './style';
import { Convert2Algorithm } from '@pages/Data/config';

export function usePackage() {
  const { refetch } = usePackageProblemList();
  const { safeStr2DateTime } = useDate();

  const renderData = (data: PackageProblemDTO[]) => {
    if (!data) return undefined;

    return data.map((problem, idx) => [
      <span>{problem.sn}</span>,
      <TitleWrap>
        <TableTitle
          data-testid={`exam-item-title-${idx}`}
          to={`${PMS_PAGES.EXAM.EDIT('package', problem.sn.toString())}`}
        >
          {problem.title}
        </TableTitle>
        <AlgorithmTags>
          {problem.algorithmTypeList
            .map((algorithmType) => `#${Convert2Algorithm[algorithmType]}`)
            .join(' ')}
        </AlgorithmTags>
      </TitleWrap>,
      <span>{problem.totalStep}</span>,
      <span>{problem.packageLevel}</span>,
      <span>{problem.levelSet.join(',')}</span>,
      <span>
        {problem.judgeLanguageList.map((language, index) =>
          Language[language].svg(
            `${language} - ${index}`,
            32,
            `singular-exam-table-language-icon-${Math.random() * 100}`,
          ),
        )}
      </span>,
      <TableCreateTime>
        {safeStr2DateTime(
          problem.createdDateTime,
          DATE_FORMAT.YYYY_MM_DD_HH_mm_ss,
        )}
      </TableCreateTime>,
      <span>{problem.companyExposureCount}</span>,
      <span>{problem.jobdaExposureCount}</span>,
      <span>
        <Switch
          checked={problem.publicOption.companyYn}
          onChange={async (check) => {
            try {
              await PstRepository.convertProblemPublic(
                problem.sn.toString(),
                check,
                problem.publicOption.jobdaYn,
              );
              refetch?.();
            } catch (e) {
              console.error(e);
            }
          }}
          color={'#12B886'}
        />
      </span>,
      <span>
        <Switch
          checked={problem.publicOption.jobdaYn}
          onChange={async (check) => {
            try {
              await PstRepository.convertProblemPublic(
                problem.sn.toString(),
                problem.publicOption.companyYn,
                check,
              );
              refetch?.();
            } catch (e) {
              console.error(e);
            }
          }}
          color={'#12B886'}
        />
      </span>,
      <span>
        <SyncWrap syncYn={problem.syncYn}>
          <IconLoading
            onClick={async (event) => {
              if (problem.syncYn) return;
              const target = event.currentTarget;
              try {
                target.classList.add('rotate');
                await PstRepository.syncProblem(
                  problem.sn.toString(),
                  ProblemBaseType.PACKAGE,
                );
                target.classList.remove('rotate');
                refetch?.();
              } catch (e) {
                console.error(e);
                target.classList.remove('rotate');
              }
            }}
          />
        </SyncWrap>
      </span>,
    ]);
  };

  return {
    renderData,
  };
}
