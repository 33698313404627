import { FC } from 'react';
import { IconToolTipInfo } from '@widget/icons';
import { Container, TooltipText, TooltipTextContent } from './style';
import { TooltipProps } from '../shared/intefaces';

export const Tooltip: FC<TooltipProps> = ({
  title = '',
  content = '',
  position = 'left',
  children,
  left,
  top,
  maxContent = true,
  tightPadding = false,
  innerStyle = {},
  contentStyle = {},
  containerStyle = {},
  height = 24,
}) => {
  return (
    <Container height={height} style={containerStyle}>
      {children ? children : <IconToolTipInfo />}
      <TooltipText
        position={position}
        maxContent={maxContent}
        left={left}
        top={top}
        tightPadding={tightPadding}
        style={innerStyle}
      >
        {title !== '' && <h5>{title}</h5>}
        <TooltipTextContent style={contentStyle}>{content}</TooltipTextContent>
      </TooltipText>
    </Container>
  );
};
