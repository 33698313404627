import { injectable } from 'inversify';
import 'reflect-metadata';
import { Language } from './base';
import { ExamParameterDTO } from '../interfaces/language.dto';

@injectable()
export class R extends Language {
	public get defaultTimeLimit(): number {
		return 5000;
	}

	public loadFunctionCode(parameters: ExamParameterDTO): string {
		return parameters.paramInfo
			.map(({ paramType, paramName }) => {
				const returnType = this.returnType[paramType];
				return `${returnType.paramType} ${paramName}`.trim();
			})
			.join(', ');
	}

	public getFunctionTemplate(parameters: ExamParameterDTO): string {
		const { value } = this.returnType[parameters.returnType];

		return `solution <- function(${this.loadFunctionCode(
			parameters,
		)}) {\n    answer <- ${value}\n    answer\n}`;
	}

	public getStdioTemplate() {
		return 'data <- read.table(file("stdin"), header=F);\nprint(data[1, 1] + data[1, 2])';
	}

	public get returnType() {
		return {
			INT: this.setReturnType('', '', '0'),
			LONG: this.setReturnType('', '', '0'),
			DOUBLE: this.setReturnType('', '', '0.0'),
			STRING: this.setReturnType('', '', '""'),
			BOOL: this.setReturnType('', '', 'TRUE'),

			INT_ARRAY: this.setReturnType('', '', 'vector()'),
			LONG_ARRAY: this.setReturnType('', '', 'vector()'),
			DOUBLE_ARRAY: this.setReturnType('', '', 'vector()'),
			STRING_ARRAY: this.setReturnType('', '', 'vector()'),
			BOOL_ARRAY: this.setReturnType('', '', 'vector()'),

			INT_2_ARRAY: this.setReturnType('', '', 'matrix(, nrow = 1, ncol = 1)'),
			LONG_2_ARRAY: this.setReturnType('', '', 'matrix(, nrow = 1, ncol = 1)'),
			DOUBLE_2_ARRAY: this.setReturnType(
				'',
				'',
				'matrix(, nrow = 1, ncol = 1)',
			),
			STRING_2_ARRAY: this.setReturnType(
				'',
				'',
				'matrix(, nrow = 1, ncol = 1)',
			),
			BOOL_2_ARRAY: this.setReturnType('', '', 'matrix(, nrow = 1, ncol = 1)'),
		};
	}
}
