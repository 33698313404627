import { TableHeaderProps } from '@widget/table';

export const HEADERS: TableHeaderProps[] = [
  {
    name: 'NO.',
    key: 'sequence',
    width: '10%',
  },
  {
    name: '입력',
    key: 'input',
    width: '45%',
  },
  {
    name: '출력',
    key: 'output',
    width: '45%',
  },
];
