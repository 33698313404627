import { FC } from 'react';
import EditorHeader from '@components/EditorHeader';
import { Container, EditorContent } from './style';
import DefaultSetting from './DefaultSetting';
import ContentSetting from './ContentSetting';
import SizeSetting from './SizeSetting';
import Fieldset from './Fieldset';
import { usePopUp } from './hooks.ts';

const PopUpPage: FC = () => {
  const {
    handleSubmit,
    onSubmit,
    onCancelClick,
    onDownloadClick,
    onChangeDates,
    onBack,
    onChangeInput,
    watch,
    register,
    errors,
  } = usePopUp();

  return (
    <Container method={'POST'} onSubmit={handleSubmit(onSubmit)}>
      <EditorHeader
        title={<>팝업&nbsp;추가&nbsp;/&nbsp;수정</>}
        cancel={'취소'}
        submit={'팝업 등록/수정'}
        onClick={onBack}
      />
      <EditorContent>
        <Fieldset title={'팝업 관리'}>
          <DefaultSetting
            register={register}
            todayOption={watch('todayOption')}
            isPause={watch('pause')}
            dates={[watch('startDateTime'), watch('endDateTime')]}
            onChangeDates={onChangeDates}
            errors={errors}
          />
        </Fieldset>
        <Fieldset title={'컨텐츠 설정'}>
          <ContentSetting
            onChangeFileInput={onChangeInput}
            register={register}
            defaultName={watch('image')?.[0]?.name ?? ''}
            imageLinkOption={watch('imageLinkOption')}
            errors={errors}
            onCancelClick={onCancelClick}
            onDownloadClick={onDownloadClick}
          />
        </Fieldset>
        <Fieldset title={'팝업 크기 및 위치 설정'}>
          <SizeSetting register={register} errors={errors} />
        </Fieldset>
      </EditorContent>
    </Container>
  );
};

export default PopUpPage;
