import { useRecoilValue } from 'recoil';
import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { pstDefaultPaginationQuery } from '@stores/selectors/exam/singular.query.ts';
import { mutationKeys, queryKeys } from '@constants/key.ts';
import PSTRepository from '@repositories/pst.repository.ts';
import { ExposureFrequencyType, ProblemCategory } from '@phs/interfaces';
import {
  DefaultProblemDTO,
  ProblemListRsDTO,
} from '@interfaces/dashboard/problem.dto.ts';
import { withPaginationFallback } from '@helpers/fallback.ts';

const defaultProblemFallback: {
  data: ProblemListRsDTO;
  refetch?: () => void;
} = {
  data: {
    problemList: withPaginationFallback<DefaultProblemDTO>({
      companyExposureCount: 0,
      content: '',
      algorithmTypeList: [],
      createdDateTime: '',
      exposureFrequency: ExposureFrequencyType.MEDIUM,
      jobdaExposureCount: 0,
      judgeLanguageList: [],
      judgeType: ProblemCategory.STDIO,
      level: '',
      publicOption: {
        companyYn: false,
        jobdaYn: false,
      },
      sn: 0,
      syncYn: false,
      title: '',
    }),
  },
};

export function useDefaultProblemList(
  options?: Omit<
    UseQueryOptions<
      Promise<ProblemListRsDTO>,
      unknown,
      ProblemListRsDTO,
      readonly string[]
    >,
    'initialData' | 'queryFn' | 'queryKey'
  >,
): {
  data: ProblemListRsDTO;
  refetch?: () => void;
} {
  const queryString = useRecoilValue(pstDefaultPaginationQuery);
  const { data, isError, isLoading, refetch } = useQuery<
    Promise<ProblemListRsDTO>,
    unknown,
    ProblemListRsDTO,
    readonly string[]
  >(
    [queryKeys.pst, queryString],
    () => PSTRepository.getDefaultProblemList(queryString),
    options,
  );

  if (isError || isLoading) return defaultProblemFallback;

  return {
    data,
    refetch,
  };
}

export function useCopyProblem(snList: number[], type: string) {
  const { mutate, isLoading } = useMutation({
    mutationKey: [mutationKeys.pst.copy, snList, type],
    mutationFn: PSTRepository.copyProblem,
  });

  return { mutate, isLoading };
}
