import styled from '@emotion/styled';
import { labelColor } from './Icon';
import { css } from '@emotion/react';

export const Container = styled.div<{ color: labelColor }>`
	display: inline-flex;
	align-items: center;
	height: 32px;
	border-radius: 4px;

	${({ color }) => (color === 'white' ? WhiteCss : GrayCss)}
	svg {
		&.close {
			&:hover {
				cursor: pointer;
				fill: #495057;
			}
		}
	}
`;

const WhiteCss = css`
	padding: 6px 12px;
	border: 1px solid #ced4da;
	color: #495057;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
`;

const GrayCss = css`
	padding: 6px 16px;
	border: 1px solid #dee2e6;
	color: #343a40;
	background: #f1f3f5;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
`;

export const Title = styled.div`
	height: 18px;
`;

export const SuffixIcon = styled(Title)`
	overflow: hidden;
	margin-left: 8px;
`;

export const SuffixElement = styled(Title)`
	margin-left: 8px;
`;
