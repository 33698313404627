import { PMS_PAGES } from '@phs/constants';
import { pmsAccessToken } from '@stores/atoms/shared/auth.ts';
import { PropsWithChildren, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useTokenValidation } from '@hooks/shared/useTokenValidation.ts';

const AuthenticatedLayout = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const token = useRecoilValue(pmsAccessToken);
  const validator = useTokenValidation();

  useEffect(() => {
    if (!token) return;
  }, [navigate, token, validator]);

  if (!token) return <Navigate to={PMS_PAGES.AUTH.LOGIN} replace />;
  return <>{children}</>;
};

export default AuthenticatedLayout;
