import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const RequireTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray_900};

  &::after {
    display: inline-block;
    margin-left: 4px;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.red_600};
    vertical-align: middle;
    content: '* 난이도 예측 로직을 기반으로 예측된 수치입니다.';
  }
`;

export const Item = styled.div`
  display: flex;
  .key {
    width: 150px;
    font-size: 14px;
  }

  .value {
    font-weight: 700;
  }
`;
