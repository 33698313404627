import { Nullable } from '@phs/interfaces';

class KeyRepository {
  private static _instance: KeyRepository;
  private _store = new Map();

  private constructor() {
    if (KeyRepository._instance) {
      return KeyRepository._instance;
    }
  }

  public static getInstance(): typeof KeyRepository._instance {
    if (!KeyRepository._instance) {
      KeyRepository._instance = new KeyRepository();
    }

    return KeyRepository._instance;
  }

  public setData(key: string, value: any): KeyRepository {
    this._store.set(key, JSON.stringify(value));
    return this;
  }

  public getData(key: string): Nullable<string> {
    const data = this._store.get(key);
    if (!data) return null;
    return JSON.parse(this._store.get(key));
  }

  public get accessToken(): string {
    const accessToken = this.getData('accessToken');
    if (!accessToken) return '';
    return `Bearer ${accessToken}`;
  }

  public updateAccessToken(token: string) {
    this.setData('accessToken', token);
    return this;
  }

  public get refreshToken(): string {
    const refreshToken = this.getData('refreshToken');
    if (!refreshToken) return '';
    return refreshToken;
  }

  public updateRefreshToken(token: string) {
    this.setData('refreshToken', token);
    return this;
  }

  public clear() {
    this._store.clear();
  }
}

export default KeyRepository;
