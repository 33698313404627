import { DATE_FORMAT } from '@phs/interfaces';
import dayjs from 'dayjs';

class DateHelper {
  public str2DateTime(dateString: string, format: DATE_FORMAT) {
    return dayjs(dateString).format(format);
  }

  public milliseconds2Format(
    milliseconds: number,
    format: DATE_FORMAT = DATE_FORMAT.YYYY_MM_DD_HH_mm_ss,
  ) {
    return dayjs(milliseconds).format(format);
  }

  public safeStr2DateTime(
    dateString: string,
    format: DATE_FORMAT = DATE_FORMAT.YYYY_MM_DD_HH_mm_ss,
  ) {
    const date = dayjs(dateString);
    return date.isValid() ? date.format(format) : '-';
  }

  public now(format: DATE_FORMAT = DATE_FORMAT.YYYY_MM_DD_HH_mm_ss) {
    const date = dayjs();
    return date.format(format);
  }
}

export default DateHelper;
