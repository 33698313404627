import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => theme.colors.gray_900};
`;

export const FormWrapper = styled.form`
  position: fixed;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 400px;
  padding: 40px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.gray_800};
  box-shadow: 16px 16px 32px rgba(0, 0, 0, '16%');
  border-radius: 8px;
`;

export const Title = styled.h1`
  margin: 80px 0 8px 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray_100};
`;

export const ItemWrap = styled.div`
  width: 100%;
  margin-top: 16px;
`;
