export const IPRegex =
	/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]).){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;

export const LocalHostRegex = 'localhost';

export const IPPortRegex =
	/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]):[0-9]+$/g;

export const getRegex = (target: string | string[]) => {
	if (!Array.isArray(target)) return new RegExp(target, 'gi');

	return new RegExp(target.join('|'), 'gi');
};
