import { inject, injectable } from 'inversify';
import 'reflect-metadata';
import { DataType } from '@phs/interfaces';
import {
  CheckDataTypeReturnDTO,
  ValidateCodePrecisionReturnDTO,
} from '../interfaces/validator.dto';
import { TYPES } from '../types';
import { CodeTransformer } from './transformer';

export interface CodeValidatorProps {
  readonly dataType: typeof DataType;
  readonly maxInt: number;
  readonly minInt: number;

  isEmpty: (data: string) => boolean;
  isDecimal: (data: string) => boolean;
  isDouble: (data: string) => boolean;
  isBool: (data: string) => boolean;

  checkDataTypes(
    dataTypes: DataType[],
    input: string,
  ): { status: boolean; message: string };

  readonly checkDataType: CheckDataTypeReturnDTO; // 데이터 타입 체크
  readonly validateCodePrecision: ValidateCodePrecisionReturnDTO; // 파라미터 15자리 제한 체크
}

@injectable()
export class CodeValidator implements CodeValidatorProps {
  private readonly _DATA_TYPE = DataType;
  private readonly _MAX_INT: number = 2147483647;
  private readonly _MIN_INT: number = -2147483648;

  constructor(
    @inject(TYPES.TRANSFORMER) private readonly transformer: CodeTransformer,
  ) {}

  public get dataType() {
    return this._DATA_TYPE;
  }

  public get maxInt() {
    return this._MAX_INT;
  }

  public get minInt() {
    return this._MIN_INT;
  }

  public isEmpty(data: string) {
    return data === ''.replaceAll(' ', '');
  }

  public isDecimal(data: string) {
    return `${data}`.split('.').length === 1;
  }

  public isDouble(data: string) {
    return `${data}`.split('.').length <= 2 && `${data}`.split('.').length > 0;
  }

  public isBool(data: string) {
    return `${data}` === 'true' || `${data}` === 'false';
  }

  public checkDataTypes(dataTypes: DataType[], input: string) {
    try {
      const inputArray = JSON.parse(`[${input}]`) as Array<string>;

      if (dataTypes.length !== inputArray.length) {
        throw {
          status: false,
          message: '등록하려는 데이터의 매개변수의 개수가 다릅니다.',
        };
      }

      // @ts-ignore
      dataTypes.forEach((dataType: DataType, idx: number) => {
        const value = inputArray[idx];
        const isValidate = this.checkDataType[dataType](JSON.stringify(value));

        if (!isValidate) {
          throw {
            status: false,
            message: `${idx + 1}번째 매개변수 타입이 설정값 ${
              this.transformer.dataType2Text[dataType]
            }와(과) 다릅니다.`,
          };
        }

        const isValid = this.validateCodePrecision[dataType](value.toString());

        if (!isValid) {
          throw {
            status: false,
            message: `${idx + 1}번째 테스트 케이스의 ${
              this.transformer.dataType2Text[dataType]
            }형 결과값의\n 자릿수는 15자리 이하로 입력해야 합니다.`,
          };
        }
      });

      return {
        status: true,
        message: '',
      };
    } catch (error: any) {
      const isCustomError = error.status === false;
      return {
        status: false,
        message: isCustomError
          ? error.message
          : '등록하려는 데이터의 형식이 잘 못 되었습니다.',
      };
    }
  }

  public get validateCodePrecision(): ValidateCodePrecisionReturnDTO {
    return {
      INT: (_: string) => true,
      LONG: (data: string) => {
        try {
          return this.transformer.string2Digit(data) <= 15;
        } catch (error) {
          return false;
        }
      },
      STRING: (_: string) => true,
      BOOL: (_: string) => true,
      INT_ARRAY: (_: string) => true,
      LONG_ARRAY: (data: string) => {
        try {
          const parsedData = this.transformer.string2Array(data);
          if (!Array.isArray(parsedData)) return false;

          const unexpectedInt = (parsedData as []).find(
            (d) => !this.validateCodePrecision[DataType.LONG](String(d)),
          );

          return unexpectedInt === undefined;
        } catch (error) {
          return false;
        }
      },
      STRING_ARRAY: (_: string) => true,
      BOOL_ARRAY: (_: string) => true,
      INT_2_ARRAY: (_: string) => true,
      LONG_2_ARRAY: (data: string) => {
        try {
          const parsedData = this.transformer.string2Array2d(data);
          if (!Array.isArray(parsedData)) return false;

          parsedData.forEach((innerData: string) => {
            const innerArray = this.transformer.string2Array2d(innerData);
            if (!Array.isArray(innerArray)) {
              throw Error;
            }

            innerArray.forEach((value) => {
              if (!this.validateCodePrecision.LONG(`${value}`)) {
                throw Error;
              }
            });
          });

          return true;
        } catch (error) {
          return false;
        }
      },
      STRING_2_ARRAY: (_: string) => true,
      BOOL_2_ARRAY: (_: string) => true,
      DOUBLE: (data: string) => {
        try {
          return this.transformer.string2Digit(data) <= 15;
        } catch (error) {
          return false;
        }
      },
      DOUBLE_ARRAY: (data: string) => {
        try {
          const parsedData = this.transformer.string2Array(data);
          if (!Array.isArray(parsedData)) return false;

          const unexpectedInt = (parsedData as []).find(
            (d) => !this.validateCodePrecision[this.dataType.DOUBLE](String(d)),
          );
          return unexpectedInt === undefined;
        } catch (error) {
          return false;
        }
      },
      DOUBLE_2_ARRAY: (data: string) => {
        try {
          const parsedData = this.transformer.string2Array2d(data);
          if (!Array.isArray(parsedData)) return false;

          parsedData.forEach((innerData: string) => {
            const innerArray = this.transformer.string2Array2d(innerData);

            if (!Array.isArray(innerArray)) {
              throw Error;
            }

            innerArray.forEach((value: string) => {
              if (!this.validateCodePrecision.DOUBLE(`${value}`)) {
                throw Error;
              }
            });
          });

          return true;
        } catch (error) {
          return false;
        }
      },
    };
  }

  public get checkDataType(): CheckDataTypeReturnDTO {
    return {
      STRING: (data) => {
        try {
          return typeof JSON.parse(data) === 'string';
        } catch (e) {
          return false;
        }
      },
      STRING_ARRAY: (data) => {
        try {
          const parsedData = JSON.parse(data);
          if (!Array.isArray(parsedData)) return false;

          const unexpectedString = parsedData.find(
            (d: any) => typeof d !== 'string',
          );

          return unexpectedString === undefined;
        } catch (e) {
          return false;
        }
      },
      STRING_2_ARRAY: (data) => {
        try {
          const parsedData = JSON.parse(data);

          if (!Array.isArray(parsedData)) {
            throw Error;
          }

          //[] 방지
          if (parsedData.length === 0) {
            throw Error;
          }

          parsedData.forEach((innerData: string[]) => {
            if (!Array.isArray(innerData)) {
              throw Error;
            }

            innerData.forEach((value: unknown) => {
              if (typeof value !== 'string') {
                throw Error;
              }
            });
          });

          return true;
        } catch (e) {
          return false;
        }
      },
      INT: (data) => {
        try {
          if (!this.isDecimal(data)) return false;
          const parsedData = JSON.parse(data);
          if (!Number.isInteger(parsedData)) return false;

          const isOverflow =
            this.maxInt < parsedData || parsedData < this.minInt;

          return !isOverflow;
        } catch (e) {
          return false;
        }
      },
      INT_ARRAY: (data) => {
        try {
          if (`${data}`.split('.').length > 1) return false;

          const parsedData = JSON.parse(data);
          const isArray = Array.isArray(parsedData);
          if (!isArray) return false;

          const unexpectedInt = (parsedData as []).find(
            (d) => !this.checkDataType[this.dataType.INT](d),
          );
          return unexpectedInt === undefined;
        } catch (e) {
          return false;
        }
      },
      INT_2_ARRAY: (data) => {
        try {
          if (!this.isDecimal(data)) return false;
          const parsedData = JSON.parse(data);

          const isArray = Array.isArray(parsedData);
          if (!isArray) {
            throw Error;
          }

          //[] 방지
          if (parsedData.length === 0) {
            throw Error;
          }

          parsedData.forEach((d: number[]) => {
            const isInnerArray = Array.isArray(d);
            if (!isInnerArray) {
              throw Error;
            }
            d.forEach((d2: number) => {
              if (!this.checkDataType.INT(`${d2}`)) {
                throw Error;
              }
            });
          });
          return true;
        } catch (e) {
          return false;
        }
      },
      LONG: (data) => {
        try {
          if (!this.isDecimal(data)) return false;
          const parsedData = JSON.parse(data);

          const isInteger = Number.isInteger(parsedData);
          if (!isInteger) return false;

          // javascript safe 범위보다 큰 값을 가질 수 있기에 비교하는게 의미가 없다.
          // const isOverflow = MAX_LONG < parsedData || parsedData < MIN_LONG;
          // if (isOverflow) return false;
          return true;
        } catch (e) {
          return false;
        }
      },
      LONG_ARRAY: (data) => {
        try {
          if (`${data}`.split('.').length > 1) return false;

          const parsedData = JSON.parse(data);
          if (!Array.isArray(parsedData)) return false;

          const unexpectedInt = (parsedData as []).find(
            (d) => !this.checkDataType[this.dataType.LONG](d),
          );
          return unexpectedInt === undefined;
        } catch (e) {
          return false;
        }
      },
      LONG_2_ARRAY: (data) => {
        try {
          if (!this.isDecimal(data)) return false;
          const parsedData = JSON.parse(data);

          if (!Array.isArray(parsedData)) {
            throw Error;
          }

          //[] 방지
          if (parsedData.length === 0) {
            throw Error;
          }

          parsedData.forEach((innerData: number[]) => {
            if (!Array.isArray(innerData)) {
              throw Error;
            }

            innerData.forEach((value: number) => {
              if (!this.checkDataType.LONG(`${value}`)) {
                throw Error;
              }
            });
          });

          return true;
        } catch (e) {
          return false;
        }
      },
      DOUBLE: (data) => {
        try {
          if (!this.isDouble(data)) return false;
          const parsedData = JSON.parse(data);
          return Number.isFinite(parsedData);
        } catch (e) {
          return false;
        }
      },
      DOUBLE_ARRAY: (data) => {
        try {
          const parsedData = JSON.parse(data);
          if (!Array.isArray(parsedData)) return false;

          const unexpectedInt = (parsedData as []).find(
            (d) => !this.checkDataType[DataType.DOUBLE](d),
          );
          return unexpectedInt === undefined;
        } catch (e) {
          return false;
        }
      },
      DOUBLE_2_ARRAY: (data) => {
        try {
          const parsedData = JSON.parse(data);

          if (!Array.isArray(parsedData)) {
            throw Error;
          }

          //[] 방지
          if (parsedData.length === 0) {
            throw Error;
          }

          parsedData.forEach((innerData: number[]) => {
            const isInnerArray = Array.isArray(innerData);
            if (!isInnerArray) {
              throw Error;
            }

            innerData.forEach((value: number) => {
              if (!this.checkDataType.DOUBLE(`${value}`)) {
                throw Error;
              }
            });
          });

          return true;
        } catch (e) {
          return false;
        }
      },
      BOOL: (data) => {
        try {
          if (!this.isBool(data)) return false;
          return typeof JSON.parse(data) === 'boolean';
        } catch (e) {
          return false;
        }
      },
      BOOL_ARRAY: (data) => {
        try {
          const parsedData = JSON.parse(data);

          const isArray = Array.isArray(parsedData);
          if (!isArray) return false;

          const unexpectedInt = (parsedData as []).find(
            (d) => !this.checkDataType[DataType.BOOL](d),
          );
          return unexpectedInt === undefined;
        } catch (e) {
          return false;
        }
      },
      BOOL_2_ARRAY: (data) => {
        try {
          const parsedData = JSON.parse(data);
          const isArray = Array.isArray(parsedData);
          if (!isArray) {
            throw Error;
          }

          //[] 방지
          if (parsedData.length === 0) {
            throw Error;
          }

          parsedData.forEach((innerData: number[]) => {
            if (!Array.isArray(innerData)) {
              throw Error;
            }

            innerData.forEach((value: number) => {
              if (!this.checkDataType.BOOL(`${value}`)) {
                throw Error;
              }
            });
          });

          return true;
        } catch (e) {
          return false;
        }
      },
    };
  }
}
