import styled from '@emotion/styled';

export const Container = styled.div`
  height: max-content;
`;

export const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 12px;
`;

export const RequireTitle = styled.span`
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray_900};

  &.invalid {
    &::after {
      display: inline-block;
      margin-left: 4px;
      font-size: 12px;
      color: ${({ theme }) => theme.colors.red_600};
      vertical-align: text-bottom;
      content: '* 테스트 케이스 추가하세요';
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 50%;
  justify-content: flex-end;
`;

export const FileName = styled.span`
  font-weight: 700;
  cursor: pointer;
`;

export const TableCell = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
`;
