import { FC } from 'react';
import { useForm } from 'react-hook-form';
import ModalWidget from '@widget/modal';
import { IconClose } from '@widget/icons';
import TextButton from '@widget/button-text';
import Input from '@widget/input';
import {
  Container,
  CloseBtn,
  Title,
  TopWrapper,
  ContentWrapper,
  BottomWrapper,
  SnTagWrap,
  InputButtonWrap,
} from './style.ts';
import { useBringProblem } from '@pages/Dashboard/Modal/hooks.tsx';
import LoadingButton from '@widget/button-loading';

interface ModalProps {
  title: string;
  type: string;
  onClose?: () => void;
}

interface FormProps {
  problemSn: string;
  companySn: number;
}

const Modal: FC<ModalProps> = ({ title, type, onClose }) => {
  const {
    getValues,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useForm<FormProps>({});

  const { addSn, onBringProblem, tagRender, isBringLoading } =
    useBringProblem();

  const onSubmit = async (data: FormProps) => {
    await onBringProblem(data.companySn.toString(), type);
    onClose && onClose();
  };

  return (
    <ModalWidget>
      <Container
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') e.preventDefault();
        }}
      >
        <TopWrapper>
          <Title>{title}</Title>
          <CloseBtn onClick={onClose}>
            <IconClose />
          </CloseBtn>
        </TopWrapper>
        <ContentWrapper>
          <SnTagWrap>{tagRender()}</SnTagWrap>
          <Input
            placeholder={'companySn'}
            type={'number'}
            error={errors.companySn?.message}
            {...register('companySn', {
              valueAsNumber: true,
              required: 'company sn을 입력하고 엔터를 누르세요.',
            })}
          />
          <InputButtonWrap>
            <Input
              placeholder={'sn을 입력하고 추가버튼을 눌러주세요'}
              type={'number'}
              {...register('problemSn')}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  addSn(getValues('problemSn'));
                  setValue('problemSn', '');
                }
              }}
            />
            <TextButton
              theme={'primary'}
              onClick={() => {
                addSn(getValues('problemSn'));
                setValue('problemSn', '');
                setFocus('problemSn');
              }}
            >
              추가+
            </TextButton>
          </InputButtonWrap>
        </ContentWrapper>
        <BottomWrapper>
          <TextButton onClick={onClose}>취소</TextButton>
          <LoadingButton type={'submit'} isLoading={isBringLoading}>
            확인
          </LoadingButton>
        </BottomWrapper>
      </Container>
    </ModalWidget>
  );
};

export default Modal;
