import { Input as StyledInput, InputWrap } from './style';
import type {
  DetailedHTMLProps,
  ForwardedRef,
  InputHTMLAttributes,
} from 'react';
import { forwardRef } from 'react';

interface InputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  error?: string;
}

const Input = (props: InputProps, ref: ForwardedRef<HTMLInputElement>) => {
  return (
    <InputWrap>
      <StyledInput {...props} ref={ref} />
      <p>{props.error}</p>
    </InputWrap>
  );
};

export default forwardRef<HTMLInputElement, InputProps>(Input);
