import { ReactNode } from 'react';

export const ERROR_CODE = {
  UNAUTHORIZED: 401,
  WRONG_ACCOUNT: 'U002',
  FIRST_LOGIN: 'U003',
  SHOULD_CHANGE_PASSWORD: 'U004',
  BLOCK_LOGIN: 'L001',
  TOKEN_INVALID: 'J001',
  DUPLICATE_LOGIN: 'J004',
  TOKEN_EXPIRE: 'J003',
  INVALID_PASSWORD: 'B001',
};

export const ERROR_MESSAGE: Record<string, ReactNode> = {
  B001: <>비밀번호 형식이 유효하지 않습니다.</>,
  U002: <>이메일과 비밀번호를 확인하세요.</>,
  U003: (
    <>
      최초 로그인 시 비밀번호를 설정해야 합니다.
      <br />
      새로운 비밀번호를 설정해 주세요.
    </>
  ),
  U004: (
    <>
      동일한 비밀번호를 90일 이상 사용하고 계십니다.
      <br />
      새로운 비밀번호를 설정해 주세요.
    </>
  ),
  U005: <></>,
  L001: (
    <>
      비밀번호를 5회 잘못 입력하셨습니다.
      <br />
      5분간 접속이 제한됩니다.
    </>
  ),
  J004: <>중복 로그인 발생하여 로그인 화면으로 이동합니다.</>,
};
