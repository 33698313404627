import { injectable } from 'inversify';
import 'reflect-metadata';
import { Language } from './base';
import { ExamParameterDTO } from '../interfaces/language.dto';

@injectable()
export class Cpp extends Language {
  public get defaultTimeLimit(): number {
    return 1000;
  }

  public loadFunctionCode(parameters: ExamParameterDTO): string {
    return parameters.paramInfo
      .map(({ paramType, paramName }) => {
        const returnType = this.returnType[paramType];
        return `${returnType.paramType} ${paramName}`.trim();
      })
      .join(', ');
  }

  public getFunctionTemplate(parameters: ExamParameterDTO): string {
    const { type, value } = this.returnType[parameters.returnType];

    return `#include <string>\n#include <vector>\n\nusing namespace std;\n\n${type} solution(${this.loadFunctionCode(
      parameters,
    )}) {\n    ${type} answer${value};\n    return answer;\n}`;
  }

  public getStdioTemplate() {
    return '#include <iostream>\n\nusing namespace std;\n\nint main(void) {\n    int a;\n    int b;\n    cin >> a >> b;\n    cout << a + b << endl;\n    return 0;\n}';
  }

  public get returnType() {
    return {
      // Primitive Types
      INT: this.setReturnType('int', 'int', ' = 0'),
      LONG: this.setReturnType('long long', 'long long', ' = 0'),
      DOUBLE: this.setReturnType('double', 'double', ' = 0'),
      STRING: this.setReturnType('string', 'string', ' = ""'),
      BOOL: this.setReturnType('bool', 'bool', ' = true'),
      // Reference Types
      INT_ARRAY: this.setReturnType('vector<int>', 'vector<int>'),
      LONG_ARRAY: this.setReturnType('vector<long long>', 'vector<long long>'),
      DOUBLE_ARRAY: this.setReturnType('vector<double>', 'vector<double>'),
      STRING_ARRAY: this.setReturnType('vector<string>', 'vector<string>'),
      BOOL_ARRAY: this.setReturnType('vector<bool>', 'vector<bool>'),
      // 2 Dimensional Reference Types
      INT_2_ARRAY: this.setReturnType(
        'vector<vector<int>>',
        'vector<vector<int>>',
        ' = {{}}',
      ),
      LONG_2_ARRAY: this.setReturnType(
        'vector<vector<long long>>',
        'vector<vector<long long>>',
        ' = {{}}',
      ),
      DOUBLE_2_ARRAY: this.setReturnType(
        'vector<vector<double>>',
        'vector<vector<double>>',
        ' = {{}}',
      ),
      STRING_2_ARRAY: this.setReturnType(
        'vector<vector<string>>',
        'vector<vector<string>>',
        ' = {{}}',
      ),
      BOOL_2_ARRAY: this.setReturnType(
        'vector<vector<bool>>',
        'vector<vector<bool>>',
        ' = {{}}',
      ),
    };
  }
}
