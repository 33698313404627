import FileRepository from '@repositories/file.repository.ts';
import { useMutation } from '@tanstack/react-query';

export function useFileUpload() {
  const { mutate } = useMutation({
    mutationFn: FileRepository.fileUpload,
    retry: 3,
    retryDelay: 1000,
  });

  const uploadFile = (
    blob: Blob | File,
    callback?: (fileUid: string) => void,
  ) =>
    mutate(blob, {
      onSuccess: ({ fileUid }) => {
        callback?.(fileUid);
      },
    });

  return {
    uploadFile,
  };
}
