import styled from '@emotion/styled';

export const RequireTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray_900};

  &::after {
    display: inline-block;
    margin-left: 4px;
    color: ${({ theme }) => theme.colors.red_600};
    vertical-align: middle;
    content: '*';
  }
`;

export const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  span {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    color: #fa5252;
  }
`;
