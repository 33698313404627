import {
  NoneContainer,
  PrimaryContainer,
  SecondaryContainer,
  TertiaryContainer,
} from './style';
import { ButtonHTMLAttributes, DetailedHTMLProps, FC, ReactNode } from 'react';

export interface TextButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children?: ReactNode;
  theme?: 'primary' | 'secondary' | 'tertiary' | 'none';
}

const TextButton: FC<TextButtonProps> = ({
  children,
  theme,
  type = 'button',
  ...rest
}) => {
  switch (theme) {
    case 'primary':
      return (
        <PrimaryContainer type={type} {...rest}>
          {children}
        </PrimaryContainer>
      );

    case 'secondary':
      return (
        <SecondaryContainer type={type} {...rest}>
          {children}
        </SecondaryContainer>
      );

    case 'tertiary':
      return (
        <TertiaryContainer type={type} {...rest}>
          {children}
        </TertiaryContainer>
      );

    default:
      return (
        <NoneContainer type={type} {...rest}>
          {children}
        </NoneContainer>
      );
  }
};

export default TextButton;
