import styled from '@emotion/styled';

export const Container = styled.div`
  position: relative;
`;

export const DisplayArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  box-sizing: border-box;
  padding: 12px 8px 12px 12px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background: #f1f3f5;
  transition: all 0.5s ease;
  cursor: pointer;
`;

export const IconArea = styled.div<{
  isOpen?: boolean;
  direction: 'up' | 'down';
}>`
  transform: ${({ isOpen, direction }) =>
    isOpen
      ? direction === 'up'
        ? 'rotate(0) translate(0, 10%)'
        : 'rotate(0.5turn) translate(0, 10%)'
      : direction === 'up'
      ? 'rotate(0.5turn)'
      : 'rotate(0)'};
  transition: all 0.5s ease;
`;

export const SelectedValue = styled.span`
  margin-right: 6px;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #495057;
`;

export const OptionGroup = styled.ul<{
  isOpen?: boolean;
  direction: 'up' | 'down';
}>`
  position: absolute;
  ${({ direction }) => (direction === 'up' ? `bottom: 0;` : ``)};
  transform: ${({ isOpen, direction }) =>
    isOpen
      ? direction === 'up'
        ? 'translateY(-42px) scale(1, 1)'
        : 'translateY(42px) scale(1, 1)'
      : direction === 'up'
      ? 'translateY(42px) scale(1, 0)'
      : 'translateY(100px) scale(1, 0)'};
  border: 1px solid #adb5bd;
  border-radius: 4px;
  background: white;
  transition: all 0.2s ease-in-out;
  z-index: 10;
`;

export const Option = styled.li<{ isActive?: boolean }>`
  display: inline-block;
  width: 100%;
  min-width: 40px;
  padding: 6px 12px;
  box-sizing: border-box;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #495057;
  cursor: pointer;

  &:first-of-type {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-of-type {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:hover {
    background: #e9ecef;
  }
`;
