import styled from '@emotion/styled';

export const Input = styled.input<{ error?: string }>`
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding: 12px;
  font-family: inherit;
  background: none;
  outline: none;
  border: 1px solid;
  border-radius: 4px;
  border-color: ${({ error }) => (error ? '#fa5252' : '#CED4DA')};
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: #343a40;

  &::placeholder {
    font-size: 12px;
    font-weight: 400;
    color: #adb5bd;
    vertical-align: middle;
  }

  &:focus-visible,
  &:target,
  &:hover,
  &:focus,
  &:focus-within,
  &:active,
  &:visited {
    border-color: ${({ error }) => (error ? '#fa5252' : '#495057')};
    outline: none;
    box-shadow: none;
  }

  &:disabled {
    color: #adb5bd;
    cursor: not-allowed;
    opacity: 0.4;
  }
`;
