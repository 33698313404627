import styled from '@emotion/styled';

export const Container = styled.div<{ isActive?: boolean }>`
	display: inline-flex;
	align-items: center;
	min-width: 280px;
	width: 100%;
	height: 40px;
	padding: 8px;
	box-sizing: border-box;
	border: 1px solid ${({ isActive }) => (isActive ? '#343a40' : '#ced4da')};
	border-radius: 4px;
	background: #fff;

	&:active {
		border: 1px solid #343a40;
	}
`;

export const Input = styled.input`
	width: 100%;
	height: 24px;
	margin-left: 8px;
	border: none;
	outline: none;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	&::placeholder {
		font-family: inherit;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		color: #adb5bd;
	}

	&::-webkit-search-cancel-button {
		-webkit-appearance: none;
		width: 18px;
		height: 18px;
		cursor: pointer;
		background: url("data:image/svg+xml,%3Csvg width='18' height='19' viewBox='0 0 18 19' fill='%23ADB5BD' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.9508 5.07579L13.0508 4.17578L9.00078 8.22578L4.95078 4.17578L4.05078 5.07579L8.09703 9.12579L4.05078 13.1758L4.95078 14.0758L9.00078 10.0258L13.0508 14.0758L13.9508 13.1758L9.90078 9.12579L13.9508 5.07579Z'%3E%3C/path%3E%3C/svg%3E")
			center center no-repeat;
	}

	&::-webkit-search-cancel-button:hover {
		-webkit-appearance: none;
		width: 18px;
		height: 18px;
		cursor: pointer;
		background: url("data:image/svg+xml,%3Csvg width='18' height='19' viewBox='0 0 18 19' fill='%343a40' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.9508 5.07579L13.0508 4.17578L9.00078 8.22578L4.95078 4.17578L4.05078 5.07579L8.09703 9.12579L4.05078 13.1758L4.95078 14.0758L9.00078 10.0258L13.0508 14.0758L13.9508 13.1758L9.90078 9.12579L13.9508 5.07579Z'%3E%3C/path%3E%3C/svg%3E")
			center center no-repeat;
	}
`;
