import { usePopUpList } from '@hooks/notice/usePopUp.ts';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { tableToggleKeys } from '@stores/selectors/notice/toggle.ts';
import { PMS_PAGES } from '@phs/constants';
import popupRepository from '@repositories/popup.repository.ts';
import { useAlertModal } from '@components/Modals/Alert/hook.ts';
import { useConfirmModal } from '@components/Modals/Confirm/hook.ts';

export function useMenu() {
  const { data, refetch } = usePopUpList();
  const navigation = useNavigate();
  const toggleKeys = useRecoilValue(tableToggleKeys(data.content));
  const { onOpen } = useAlertModal();
  const { onConfirmOpen, onOk } = useConfirmModal();

  const onAddClick = () => navigation(PMS_PAGES.POPUP.HOME);

  const onDeleteClick = async () => {
    const sn = toggleKeys.map(({ sn }) => sn);
    if (sn.length < 1) {
      onOpen('선택된 팝업이 없습니다.');
      return;
    }

    onConfirmOpen(async () => {
      try {
        await popupRepository.deletePopUpBulk(sn);
        onOk();
        refetch?.();
      } catch (e: any) {
        console.error(e);
      }
    }, '정말로 팝업을 삭제하시겠습니까?');
  };

  return {
    onAddClick,
    onDeleteClick,
  };
}
