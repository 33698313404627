import { useRoutes } from 'react-router-dom';
import NotFoundRoute from './404';
import AuthRoute from './auth';
import DashboardRoute from './dashboard';
import ErrorRoute from './error';
import ExamRoute from './exam';
import ManageRoute from './manage';
import NoticeRoute from './notice';
import PopUpRoute from './popup';
import DataRoute from './data.tsx';

export default function Routes() {
  return useRoutes([
    AuthRoute,
    DashboardRoute,
    ExamRoute,
    ...ManageRoute,
    NoticeRoute,
    PopUpRoute,
    DataRoute,
    ErrorRoute,
    NotFoundRoute,
  ]);
}
