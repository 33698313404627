import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@constants/key.ts';
import PstRepository from '@repositories/pst.repository.ts';
import {
  PackageProblemTestCasesRsDTO,
  ProblemTestCasesRsDTO,
} from '@interfaces/dashboard/problem.dto.ts';

const fallback: ProblemTestCasesRsDTO = {
  problemTestCaseRsList: [],
};

export function useTestCase(sn: string, disabled?: boolean) {
  const { data, isError, isLoading } = useQuery<
    ProblemTestCasesRsDTO,
    unknown,
    ProblemTestCasesRsDTO,
    readonly string[]
  >([queryKeys.testCases, sn], () => PstRepository.readTestCases(sn), {
    enabled: disabled ? false : !!sn,
  });
  if (isError || isLoading) return fallback;

  return data;
}

const packageFallback: PackageProblemTestCasesRsDTO = {
  packageProblemTestCaseRsList: [],
};

export function usePackageTestCase(sn: string) {
  const { data, isError, isLoading } = useQuery<
    Promise<PackageProblemTestCasesRsDTO>,
    unknown,
    PackageProblemTestCasesRsDTO,
    readonly string[]
  >([queryKeys.testCases, sn], () => PstRepository.readPackageTestCases(sn), {
    enabled: !!sn,
  });
  if (isError || isLoading) return packageFallback;

  return data;
}
