import { BaseRepository } from '@phs/repositories';
import { PMS_URL } from '@phs/constants';
import { LanguageListResDTO } from '@interfaces/dashboard/language.dto';

class LanguageRepository extends BaseRepository {
  constructor(baseURL: string) {
    super(baseURL);
  }

  public async readLanguageList() {
    const { data: response } = await super.get<LanguageListResDTO>(
      PMS_URL.LANGUAGE_LIST,
    );

    return response;
  }
}

const languageRepository = new LanguageRepository(import.meta.env.VITE_API_URL);
export default languageRepository;
