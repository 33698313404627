import * as z from 'zod';
import {
  DataType,
  LanguageType,
  ModifyCommandType,
  TestCaseFileType,
  TestCaseType,
} from '@phs/interfaces';
import { Validator } from '@helpers/validator.ts';

const testCaseValidation = z
  .object({
    input: z
      .object({
        parameterType: z.nativeEnum(DataType),
        parameterName: z.string(),
        value: z.string(),
      })
      .array(),
    output: z.object({
      parameterType: z.nativeEnum(DataType),
      parameterName: z.string(),
      value: z.string(),
    }),
    commandType: z.nativeEnum(ModifyCommandType),
    number: z.number(),
    testCaseLanguageSet: z
      .object({
        commandType: z.nativeEnum(ModifyCommandType),
        language: z.nativeEnum(LanguageType),
        timeLimit: z.number(),
        sn: z.number().optional(),
      })
      .array(),
    testCaseType: z.nativeEnum(TestCaseType),
    sn: z.number().optional(),
    type: z.nativeEnum(TestCaseFileType).optional(),
    inputFile: z
      .object({
        fileName: z.string(),
        fileUid: z.string(),
      })
      .optional()
      .nullable(),
    outputFile: z
      .object({
        fileName: z.string(),
        fileUid: z.string(),
      })
      .optional()
      .nullable(),
  })
  .array();

export const schema = z.object({
  title: z.string().min(1, '제목을 입력해주세요.'),
  level: z.number().min(1, '난이도를 선택해주세요.'),
  detailLevel: z.number().min(1, '세부 난이도를 선택해주세요.'),
  content: z.string().min(1, '설명을 입력해주세요.'),
  algorithmTypeList: z.string().array().min(1, '알고리즘 분류를 선택해주세요.'),
  parameters: z
    .object({
      paramInfo: z
        .object({
          paramType: z.nativeEnum(DataType),
          paramName: z
            .string()
            .nonempty('빈 값은 입력이 제한됩니다.')
            .max(30, '30자 이하로 입력 가능합니다.')
            .refine(
              (value) => !Validator.hasKorean(value),
              '한글 입력은 제한됩니다.',
            )
            .refine(
              (value) => !Validator.hasNumeric(value),
              '숫자는 맨 앞에 올 수 없습니다.',
            )
            .refine(
              (value) => !Validator.hasSpecialCharacters(value),
              '특수문자 및 공백은 입력은 제한됩니다.',
            ),
        })
        .array(),
      returnType: z.nativeEnum(DataType),
    })
    .optional(),
  languages: z
    .object({
      sn: z.number().optional(),
      language: z.nativeEnum(LanguageType),
      commandType: z.nativeEnum(ModifyCommandType).optional(),
      defaultSource: z.string(),
      expectedSource: z.string().optional(),
      compiler: z.string().optional(),
      usage: z.boolean(),
      priority: z.number(),
    })
    .array()
    .min(1, '언어가 선택되지 않았습니다.'),
  exampleTestCases: testCaseValidation,
  accuracyTestCases: testCaseValidation,
  efficiencyTestCases: testCaseValidation.optional(),
  scoreRateYn: z.boolean().optional(),
  accuracyScoreRate: z.number().min(0).optional(),
  efficiencyScoreRate: z.number().min(0).optional(),
});
