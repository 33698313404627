import { PMS_URL } from '@phs/constants';
import { CompanyListRsDTO } from '@interfaces/company/company.dto';
import { BaseRepository } from '@phs/repositories';

class CompanyRepository extends BaseRepository {
  constructor(baseURL: string) {
    super(baseURL);
  }

  public async getCompanyList(query: Record<string, any>) {
    const { data: response } = await super.get<CompanyListRsDTO>(
      `${PMS_URL.COMPANY_LIST}`,
      {
        params: query,
      },
    );

    return response;
  }

  public async postDatabaseSync(companySn: number) {
    const { data: response } = await super.post(
      PMS_URL.DATABASE_SYNC,
      undefined,
      { params: { companySn } },
    );

    return response;
  }

  public async getCommonSchemeSync() {
    const { data: response } = await super.get<boolean>(
      `${PMS_URL.COMMON_SCHEME_SYNC}`,
    );

    return response;
  }

  public async postCommonSchemeSync() {
    const { data: response } = await super.post(
      `${PMS_URL.COMMON_SCHEME_SYNC}`,
    );

    return response;
  }

  public async getCommonSchedulerSync() {
    const { data: response } = await super.get<boolean>(
      `${PMS_URL.COMMON_SCHEDULER_SYNC}`,
    );
    return response;
  }

  public async postCommonSchedulerSync() {
    const { data: response } = await super.post(
      `${PMS_URL.COMMON_SCHEDULER_SYNC}`,
    );
    return response;
  }

  public async postCommonDataBaseSync() {
    const { data: response } = await super.post(
      `${PMS_URL.COMMON_DATABASE_SYNC}`,
    );
    return response;
  }
}

const companyRepository = new CompanyRepository(import.meta.env.VITE_API_URL);

export default companyRepository;
