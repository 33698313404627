import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { CodeTestCase, CodeValidator } from '@phs/code';
import {
  ExamLanguageDTO,
  ModifyCommandType,
  TestCaseDTO,
  TestCaseLanguageDTO,
  TestCaseType,
} from '@phs/interfaces';
import { InputTableHeaderType, Removable } from '@widget/table-input';
import { LanguageConfig } from '@widget/icons-language';
import { Textarea } from '@widget/textarea';
import { IconModify } from '@widget/icons';
import Input from '@widget/input-number';
import { useAlertModal } from '@components/Modals/Alert/hook.ts';
import { InputContainer, LanguageContainer, LanguageName } from './style';
import _ from 'lodash-es';

interface UseTestCaseProps {
  languages?: ExamLanguageDTO[];
  testCaseType?: TestCaseType;
  testCases?: TestCaseDTO[];
  onSubmit?: (data: TestCaseDTO[]) => void;
  onClose?: () => void;
}

export function useTestCase({
  testCaseType = TestCaseType.EXAMPLE,
  languages = [],
  testCases = [],
  onSubmit,
  onClose,
}: UseTestCaseProps) {
  const { onOpen } = useAlertModal();
  const [cache, setCache] = useState<TestCaseDTO[]>(testCases);
  const [timeLimitCache, setTimeLimitCache] = useState<TestCaseLanguageDTO[]>(
    [],
  );

  const [isTimeLimitModalOpen, setIsTimeLimitModalOpen] =
    useState<boolean>(false);

  // -1은 전체 time limit 변경
  const [testCaseIndex, setTestCaseIndex] = useState<number>(-1);

  useEffect(() => {
    if (testCases.length === 0) {
      const defaultCache = [
        CodeTestCase.createTestCase({
          number: 1,
          testCaseType,
          testCaseLanguageSet: CodeTestCase.createLanguageSet(languages),
        }),
      ];
      setCache(defaultCache);
      return;
    }
    setCache(_.cloneDeep(testCases));
  }, [testCases]);

  const selectedLanguages = useMemo(
    () => languages.filter(({ usage }) => usage),
    [languages],
  );

  const onSubmitTimeLimit = () => {
    const newCache = [...cache];
    if (testCaseIndex === -1) {
      newCache.forEach((testCase) => {
        testCase.testCaseLanguageSet.forEach((languageSet) => {
          languageSet.timeLimit =
            timeLimitCache.find(
              (timeLimit) => timeLimit.language === languageSet.language,
            )?.timeLimit ?? 0;

          if (languageSet.commandType === ModifyCommandType.READ) {
            languageSet.commandType = ModifyCommandType.UPDATE;
          }
        });

        if (testCase.commandType === ModifyCommandType.READ) {
          testCase.commandType = ModifyCommandType.UPDATE;
        }
      });
    } else {
      const targetTestCase = newCache[testCaseIndex];
      targetTestCase.testCaseLanguageSet.forEach((testCase) => {
        testCase.timeLimit =
          timeLimitCache.find(
            (timeLimit) => timeLimit.language === testCase.language,
          )?.timeLimit ?? 0;

        if (testCase.commandType === ModifyCommandType.READ) {
          testCase.commandType = ModifyCommandType.UPDATE;
        }
      });

      if (targetTestCase.commandType === ModifyCommandType.READ) {
        targetTestCase.commandType = ModifyCommandType.UPDATE;
      }
    }

    setCache(newCache);
    onCloseTimeLimitModal();
  };

  const renderTimeLimitData = (data: ExamLanguageDTO[]) => {
    return data.map((language, idx) => {
      return [
        <LanguageContainer>
          {LanguageConfig[language.language].svg('svg', 48)}
          <LanguageName>{language.language}</LanguageName>
        </LanguageContainer>,
        <InputContainer>
          <Input
            data-testid={`time-limit-input-${idx}`}
            type='number'
            min='0'
            value={
              timeLimitCache.find(
                (value) => value.language === language.language,
              )?.timeLimit
            }
            onChange={({ target: { value } }) => {
              setTimeLimitCache((prev) => {
                const newCache = [...prev];
                const findValue = newCache.find(
                  (value) => value.language === language.language,
                );
                if (findValue) findValue.timeLimit = parseInt(value);
                return newCache;
              });
            }}
          />
          ms
        </InputContainer>,
      ];
    });
  };

  const renderHeaders = (): InputTableHeaderType[] => {
    return [
      'Parameters',
      `Return(string)`,
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '10px',
        }}
      >
        Time Limit{' '}
        <IconModify
          width='26'
          height='26'
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openTimeLimitModal(-1);
          }}
        />
      </div>,
      '',
    ];
  };

  const getDefaultTimeLimit = (languages: ExamLanguageDTO[]) => {
    return languages
      .filter(({ usage }) => usage)
      .map(({ language }) => ({
        language,
        timeLimit: CodeTestCase.getTimeLimit(language),
        commandType: ModifyCommandType.CREATE,
      }));
  };

  const openTimeLimitModal = (testCaseIndex: number) => {
    setTestCaseIndex(testCaseIndex);

    if (testCaseIndex === -1) {
      setTimeLimitCache(getDefaultTimeLimit(languages));
    } else {
      const newTimeLimitCache = cache[testCaseIndex].testCaseLanguageSet.map(
        (v) => ({ ...v }),
      );

      setTimeLimitCache(newTimeLimitCache);
    }

    setIsTimeLimitModalOpen(true);
  };

  const onCloseTimeLimitModal = () => {
    setIsTimeLimitModalOpen(false);
  };

  const addTestCase = () => {
    setCache((prev) => {
      return [
        ...prev,
        CodeTestCase.createTestCase({
          number: cache.length + 1,
          testCaseType,
          testCaseLanguageSet: CodeTestCase.createLanguageSet(languages),
        }),
      ];
    });
  };

  const updateTestCase = (idx: number, value: Partial<TestCaseDTO>) => {
    const newCache = cache.slice();
    newCache[idx] = CodeTestCase.updateTestCase(cache[idx], value);
    return newCache;
  };

  const deleteTestCase = (idx: number) => {
    const targetTestCase = cache[idx];

    if (targetTestCase.sn) {
      setCache((prev) => {
        return prev.map((testCase, i) => {
          if (idx === i)
            return CodeTestCase.deleteTestCase(testCase, {
              number: i + 1,
            });

          return { ...testCase, number: i + 1 };
        });
      });
    } else {
      setCache((prev) => {
        return prev
          .filter((_, i) => idx !== i)
          .map((testCase, i) => ({ ...testCase, number: i + 1 }));
      });
    }
  };

  const onChangeInput = (
    event: ChangeEvent<HTMLTextAreaElement>,
    idx: number,
  ) => {
    const { input: inputValue } = cache[idx];

    setCache(
      updateTestCase(idx, {
        input: [
          {
            ...inputValue[0],
            value: event.currentTarget.value,
          },
        ],
      }),
    );
  };

  const onChangeOutput = (
    event: ChangeEvent<HTMLTextAreaElement>,
    idx: number,
  ) => {
    const { output: outputValue } = cache[idx];

    setCache(
      updateTestCase(idx, {
        output: {
          ...outputValue,
          value: event.currentTarget.value,
        },
      }),
    );
  };

  const getTimeLimit = (testCaseLanguageSet: TestCaseLanguageDTO[]) => {
    const usingLanguageSet = testCaseLanguageSet.filter(
      ({ commandType }) => commandType !== ModifyCommandType.DELETE,
    );
    const minTimeLimit = Math.min(
      ...usingLanguageSet.map(({ timeLimit }) => timeLimit),
    );

    const maxTimeLimit = Math.max(
      ...usingLanguageSet.map(({ timeLimit }) => timeLimit),
    );

    return {
      editable: minTimeLimit === maxTimeLimit,
      value:
        minTimeLimit === maxTimeLimit
          ? minTimeLimit
          : `${minTimeLimit}~${maxTimeLimit}`,
    };
  };

  const renderData = (data: TestCaseDTO[]) => {
    if (!data) return undefined;

    return data
      .map(
        (
          {
            sn,
            input: inputValue,
            output: outputValue,
            testCaseLanguageSet,
            commandType,
          },
          idx,
        ) => {
          const timeLimit = getTimeLimit(testCaseLanguageSet);

          const elements = [
            <Textarea
              data-testid={`testcase-textarea-input-${idx}`}
              key={`testcase-textarea-input-${sn}-${idx}`}
              placeholder={'Input'}
              value={inputValue[0].value}
              onChange={(e) => onChangeInput(e, idx)}
            />,
            <Textarea
              data-testid={`testcase-textarea-output-${idx}`}
              placeholder={'Output'}
              value={outputValue.value}
              onChange={(e) => onChangeOutput(e, idx)}
            />,
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '16px',
                justifyContent: 'center',
              }}
            >
              <Textarea
                data-testid={`testcase-textarea-time-${idx}`}
                disabled={!timeLimit.editable}
                value={timeLimit.value}
                onChange={({ target: { value } }) => {
                  const newCache = [...cache];
                  const languageSet = newCache[idx].testCaseLanguageSet;
                  const data = Number(value);
                  if (data < 0 || isNaN(data)) return;

                  languageSet.forEach((language) => {
                    language.timeLimit = data;
                  });

                  setCache(newCache);
                }}
              />
              <span style={{ color: '#343a40' }}>ms</span>
              <IconModify
                data-testid={`add-time-testcase-${idx}`}
                width='26'
                height='26'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  openTimeLimitModal(idx);
                }}
              />
            </div>,
            <Removable
              key={`testcase-textarea-output-${sn}-${idx}`}
              onRemove={() => deleteTestCase(idx)}
            ></Removable>,
          ];

          return { data: elements, commandType };
        },
      )
      .filter((v) => v.commandType !== ModifyCommandType.DELETE)
      .map((v) => v.data);
  };

  const validateArgs = () => {
    let result = true;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    cache
      .filter(({ commandType }) => commandType !== ModifyCommandType.DELETE)
      .forEach(({ input: inputValue, output: outputValue }, idx) => {
        if (CodeValidator.isEmpty(inputValue[0].value)) {
          onOpen(
            <>{`${
              idx + 1
            }번째 테스트 케이스의 1번째 매개변수가 비었습니다.`}</>,
          );
          result = false;
          return false;
        }

        if (CodeValidator.isEmpty(outputValue.value)) {
          onOpen(
            <>{`${
              idx + 1
            }번째 테스트 케이스의 2번째 매개변수가 비었습니다.`}</>,
          );
          result = false;
          return false;
        }
        return false;
      });

    return result;
  };

  const handleSubmit = () => {
    if (!validateArgs()) return;

    onSubmit?.(cache);
  };

  const handleClose = () => {
    setCache([]);
    onClose?.();
  };

  return {
    cache,
    addTestCase,
    renderData,
    handleSubmit,
    handleClose,
    isTimeLimitModalOpen,
    onCloseTimeLimitModal,
    selectedLanguages,
    openTimeLimitModal,
    renderHeaders,
    renderTimeLimitData,
    onSubmitTimeLimit,
  };
}
