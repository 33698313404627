export enum DATE_FORMAT {
  YYYY_MM_DD_HH_mm_ss = 'YYYY-MM-DD HH:mm:ss',
  yyyy_MM_DD_HH_mm_ss = 'yyyy-MM-DD HH:mm:ss',
  yyyy_MM_dd_HH_mm_ss = 'yyyy-MM-dd HH:mm:ss',
  yyyy_MM_dd_HH_mm_ss_dot = 'yyyy.MM.dd HH:mm:ss',
  YYYY_MM_DD = 'YYYY-MM-DD',
  YYYY_MM_DD_HH_KR = 'YYYY년 MM월 DD일',
  yyyy_MM_DD_HH_mm = 'yyyy.MM.dd HH:mm',
  YYYY_MM_DD_HH_mm_KR = 'YYYY년 MM월 DD일 HH:mm',
  YYYY_MM_DD_HH_KR_mm_ss_Dot = 'YYYY년 MM월 DD일 HH:mm:ss',
  HH_mm_ss = 'HH:mm:ss',
  HH_mm = 'HH:mm',
  h_mm_KR = 'h시간 mm분',
  yyyy_MM_dd = 'yyyy-MM-dd',
  yyyy_MM_dd_HH_mm = 'yyyy-MM-dd HH:mm',
  YYYY_MM_DD_T_HH_mm_ss_SSS = 'YYYY-MM-DDTHH:mm:ss.SSS',
  YYYY_MM_DD_T_HH_mm_ss = 'YYYY-MM-DDTHH:mm:ss',
}
