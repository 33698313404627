//@ts-nocheck
import { useImageUpload } from '@hooks/file/useImageUpload';
import { Editor } from '@toast-ui/react-editor';

import { useRef } from 'react';
import type { Nullable } from '@phs/interfaces';
// @ts-ignore
import type { PluginContext, PluginInfo, HTMLMdNode } from '@toast-ui/editor';
import { Schema } from 'prosemirror-model';
import {
  Transaction,
  Selection,
  TextSelection,
  EditorState,
} from 'prosemirror-state';

type HookCallback = (url: string, text?: string) => void;

const HIGHLIGHT = 'highlight';

export function useEditor(handleChange?: (content: string) => void) {
  const { uploadImage } = useImageUpload();
  const containerRef = useRef<Nullable<HTMLDivElement>>(null);
  const editorRef = useRef<Nullable<Editor>>(null);

  const onChange = () => {
    if (!editorRef.current) return;
    handleChange?.(editorRef.current?.getInstance()?.getMarkdown());
  };

  const addImageBlobHook = async (
    blob: Blob | File,
    callback: HookCallback,
  ) => {
    await uploadImage(blob, callback);
    // callback(imageUrl, blob?.name || 'image');
    return false;
  };

  const createSelection = (
    tr: Transaction,
    selection: Selection,
    SelectionClass: typeof TextSelection,
    openTag: string,
    closeTag: string,
  ) => {
    const { mapping, doc } = tr;
    const { from, to, empty } = selection;
    const mappedFrom = mapping.map(from) + openTag.length;
    const mappedTo = mapping.map(to) - closeTag.length;

    return empty
      ? SelectionClass.create(doc, mappedTo, mappedTo)
      : SelectionClass.create(doc, mappedFrom, mappedTo);
  };

  const highLightSyntaxPlugin = (context: PluginContext): PluginInfo => {
    const { pmState } = context;
    return {
      markdownCommands: {
        // @ts-ignore
        highlight: (_, editorState: EditorState<Schema>, dispatch) => {
          const { tr, selection, schema } = editorState;
          const slice = selection.content();
          const textContent = slice.content.textBetween(
            0,
            slice.content.size,
            '\n',
          );
          const openTag = `<span class='${HIGHLIGHT}'>`;
          const closeTag = `</span>`;
          const highlighted = `${openTag}${textContent}${closeTag}`;
          // doc.content의 child 길이가 달라서 error가 난다...
          const beforeContentSize = tr.doc.content.childCount;
          tr.replaceSelectionWith(schema.text(highlighted)).setSelection(
            createSelection(
              tr,
              selection,
              pmState.TextSelection,
              openTag,
              closeTag,
            ),
          );
          const afterContentSize = tr.doc.content.childCount;
          if (beforeContentSize !== afterContentSize) return false;
          try {
            dispatch!(tr);
          } catch (e: any) {
            console.error(e);
          }
          return true;
        },
      },
      wysiwygCommands: {
        highlight: (_, { tr, selection, schema }, dispatch) => {
          const { from, to } = selection;
          const attrs = { htmlAttrs: { class: `${HIGHLIGHT}` } };
          const mark = schema.marks.span.create(attrs);
          tr.addMark(from, to, mark);
          try {
            dispatch!(tr);
          } catch (e: any) {
            console.log('wysiwygCommands :', e);
          }

          return true;
        },
      },
      toHTMLRenderers: {
        htmlInline: {
          span: (node: HTMLMdNode, { entering }: { entering: boolean }) => {
            return entering
              ? { type: 'openTag', tagName: 'span', attributes: node.attrs! }
              : { type: 'closeTag', tagName: 'span' };
          },
        },
      },
    };
  };

  const execHighlight = () => {
    if (!editorRef.current) return;
    editorRef.current.getInstance().exec(HIGHLIGHT);
  };

  const removeHighlight = () => {
    const markdown = editorRef.current?.getInstance()?.getMarkdown();
    if (!markdown) return;
    const convertedMarkdown = markdown
      .replaceAll(/<span class=[\', \"]highlight[\', \"]>/g, '')
      .replaceAll('</span>', '');
    editorRef.current?.getInstance()?.setMarkdown(convertedMarkdown);
  };

  return {
    containerRef,
    editorRef,
    addImageBlobHook,
    onChange,
    execHighlight,
    highLightSyntaxPlugin,
    removeHighlight,
  };
}
