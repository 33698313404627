class EventUtil {
  static handleBeforeUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = '';
  };

  static handlePopState = () => {
    if (confirm('변경사항이 저장되지 않습니다. 페이지를 나가시겠습니까?')) {
      history.go(-1);
    } else {
      history.pushState(null, '', location.href);
    }
  };

  static initBeforeunloadEvent = () => {
    history.pushState(null, '', location.href);
    window.addEventListener('popstate', this.handlePopState);
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  };

  static destroyBeforeunloadEvent = () => {
    window.removeEventListener('popstate', this.handlePopState);
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  };
}

export default EventUtil;
