import { Container } from './style';
import {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  PropsWithChildren,
  useEffect,
} from 'react';

interface ModalProps
  extends PropsWithChildren,
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const Modal: FC<ModalProps> = ({ children }) => {
  useEffect(() => {
    const modalCount = document.body.dataset.modalCount
      ? Number(document.body.dataset.modalCount)
      : 0;
    document.body.dataset.modalCount = `${modalCount + 1}`;
    document.body.style.overflow = 'hidden';
    return () => {
      const modalCount = document.body.dataset.modalCount
        ? Number(document.body.dataset.modalCount)
        : 0;
      document.body.dataset.modalCount = `${modalCount - 1}`;
      if (modalCount === 1) document.body.style.overflow = 'auto';
    };
  }, []);

  return <Container>{children}</Container>;
};

export default Modal;
