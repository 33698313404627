import { FC, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PaginationWrap } from '@pages/Dashboard/Singular/style.ts';
import Pagination from '@components/Pagination';
import { usePagination } from '@components/Pagination/hooks.ts';
import Filters from '@pages/Dashboard/Package/Filters';
import { usePackageProblemList } from '@hooks/dashboard/usePackageProblem.ts';
import { Headers } from '@pages/Dashboard/Package/config.ts';
import { RenderTableBody } from '@components/Table';
import Table from '@components/ToggleTable';
import { PackageProblemDTO } from '@interfaces/dashboard/problem.dto.ts';
import { usePackage } from '@pages/Dashboard/Package/hooks.tsx';
import { useTable } from '@components/ToggleTable/hooks.ts';
import { useSearch } from '@components/Search/hooks.ts';
import { useFilters } from '@pages/Dashboard/Package/Filters/hooks.ts';
import { Container } from './style.ts';

const PackageExam: FC = () => {
  const { data } = usePackageProblemList();
  const {
    setTotalPage,
    resetPage,
    pageSize,
    currentPage,
    handleChangePageSize,
    setCurrentPage,
  } = usePagination();
  const { renderData } = usePackage();
  const { resetTableToggle } = useTable({});
  const { resetSearchKeyword, searchKeyword, setSearchKeyword } = useSearch();
  const {
    resetFilter,
    handleLanguageFilter,
    handleLevelFilter,
    handlePackageLevelFilter,
    handlePublicType,
    handleStepFilter,
    handleChangeAlgorithmType,
    language,
    level,
    packageLevel,
    publicType,
    step,
    algorithmType,
  } = useFilters();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setSearchParams(
      {
        level: level,
        language: language,
        publicType: publicType,
        step: step,
        packageLevel: packageLevel,
        pageSize: pageSize.toString(),
        currentPage: currentPage.toString(),
        searchKeyword: searchKeyword,
        algorithmType: algorithmType,
      },
      { preventScrollReset: true, replace: true },
    );
  }, [
    packageLevel,
    step,
    level,
    language,
    publicType,
    pageSize,
    currentPage,
    searchKeyword,
    algorithmType,
  ]);

  useEffect(() => {
    const packageLevel = searchParams.getAll('packageLevel');
    handlePackageLevelFilter(packageLevel);

    const level = searchParams.getAll('level');
    handleLevelFilter(level);

    const language = searchParams.getAll('language');
    handleLanguageFilter(language);

    const publicType = searchParams.getAll('publicType');
    handlePublicType(publicType);

    const algorithmType = searchParams.getAll('algorithmType');
    handleChangeAlgorithmType(algorithmType);

    const step = searchParams.getAll('step');
    handleStepFilter(step);

    const pageSize = searchParams.get('pageSize') ?? 10;
    handleChangePageSize(+pageSize);

    const currentPage = searchParams.get('currentPage') ?? 0;
    setCurrentPage(+currentPage);

    const searchKeyword = searchParams.get('searchKeyword') ?? '';
    setSearchKeyword(searchKeyword);
  }, [searchParams]);

  useEffect(() => {
    return () => {
      resetTableToggle();
      resetFilter();
      resetSearchKeyword();
      resetPage();
    };
  }, []);

  useEffect(() => {
    setTotalPage(data.problemList.totalPages);
  }, [data, setTotalPage]);

  return (
    <Container>
      <Filters />
      <Table<PackageProblemDTO>
        headers={Headers}
        data={data.problemList.content}
        renderTableBody={renderData as RenderTableBody<PackageProblemDTO>}
      />
      <PaginationWrap>
        <Pagination />
      </PaginationWrap>
    </Container>
  );
};

export default PackageExam;
