import { Container } from './style';
import { ChangeEventHandler, CSSProperties, FC, forwardRef } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

interface LoginInputProps {
  register?: UseFormRegisterReturn;
  placeholder?: string;
  onChange?: ChangeEventHandler;
  fontColor?: string;
  placeholderColor?: string;
  backgroundColor?: string;
  type?: string;
  style?: CSSProperties;
}

const LoginInput: FC<LoginInputProps> = forwardRef(
  (
    {
      register,
      placeholder,
      onChange,
      placeholderColor,
      backgroundColor,
      type,
      style,
    },
    ref,
  ) => {
    return (
      <Container
        placeholder={placeholder}
        onChange={onChange}
        placeholderColor={placeholderColor}
        backgroundColor={backgroundColor}
        type={type}
        style={style}
        {...ref}
        {...register}
      />
    );
  },
);

export default LoginInput;
