import styled from '@emotion/styled';

export const Frame = styled.div`
  margin-top: 64px;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
`;

export const HorizontalDivider = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray_600};
`;

export const ButtonWrap = styled.div`
  display: flex;
  gap: 8px;
  max-width: 30%;
  height: 32px;
  margin-bottom: 16px;
  margin-right: auto;
`;

export const FileDownload = styled.a`
  display: inline-block;
  width: 630px;
  height: 40px;
  background: ${({ theme }) => theme.colors.green_100};
  border-radius: 4px;
  padding: 12px 11px;
  cursor: pointer;
`;
