export enum TestCaseType {
  EXAMPLE = 'EXAMPLE',
  ACCURACY = 'ACCURACY',
  EFFICIENCY = 'EFFICIENCY',
}

export enum TestCaseFileType {
  COMMON_FILE = 'COMMON_FILE', // PMS
  PRIVATE_FILE = 'PRIVATE_FILE', // CMS
  TEXT = 'TEXT',
}

export enum BulkTestCaseFileType {
  PARAM = 'PARAM',
  RETURN = 'RETURN,',
}
