import { Container } from './style.ts';
import { usePagination } from './hooks.ts';
import { default as Page } from '@widget/pagination';
import { paginationArgs } from '@stores/selectors/shared/pagination';
import { useRecoilValue } from 'recoil';

const Pagination = () => {
  const {
    currentPage: cursor,
    totalPage,
    pageIdxArray,
    pageSize,
  } = useRecoilValue(paginationArgs);
  const { updateCurrentPage, updatePageSize } = usePagination();

  return (
    <Container>
      <Page
        pages={pageIdxArray}
        defaultPage={cursor}
        pageSize={pageSize}
        totalPage={totalPage - 1}
        onChangePage={(currentPage) => updateCurrentPage(currentPage)}
        onChangePageLimit={(props) => updatePageSize(Number(props.key))}
      />
    </Container>
  );
};

export default Pagination;
