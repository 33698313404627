export const Levels = [
	{
		name: '1',
		key: '1',
	},
	{
		name: '2',
		key: '2',
	},
	{
		name: '3',
		key: '3',
	},
	{
		name: '4',
		key: '4',
	},
	{
		name: '5',
		key: '5',
	},
];

export const Public = [
	{
		name: '기업 서비스',
		key: 'COMPANY',
	},
	{
		name: '잡다 CMS',
		key: 'JOBDA',
	},
	{
		name: '비공개',
		key: 'PRIVATE',
	},
];

export const Steps = [
	{
		name: '1',
		key: '1',
	},
	{
		name: '2',
		key: '2',
	},
	{
		name: '3',
		key: '3',
	},
	{
		name: '4',
		key: '4',
	},
	{
		name: '5',
		key: '5',
	},
];

export const TotalLevel = [
	{
		name: '1',
		key: '1',
	},
	{
		name: '2',
		key: '2',
	},
	{
		name: '3',
		key: '3',
	},
	{
		name: '4',
		key: '4',
	},
	{
		name: '5',
		key: '5',
	},
];
