import {
  PackageProblemDTO,
  PackageProblemListRsDto,
  ProblemListRsDTO,
} from '@interfaces/dashboard/problem.dto.ts';
import { useRecoilValue } from 'recoil';
import { pstPackagePaginationQuery } from '@stores/selectors/exam/package.query.ts';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { queryKeys } from '@constants/key.ts';
import { ExposureFrequencyType, ProblemCategory } from '@phs/interfaces';
import PSTRepository from '@repositories/pst.repository.ts';
import { withPaginationFallback } from '@helpers/fallback.ts';

const PackageProblemFallback: {
  data: PackageProblemListRsDto;
  refetch?: () => void;
} = {
  data: {
    problemList: withPaginationFallback<PackageProblemDTO>({
      companyExposureCount: 0,
      content: '',
      createdDateTime: '',
      exposureFrequency: ExposureFrequencyType.MEDIUM,
      jobdaExposureCount: 0,
      judgeLanguageList: [],
      judgeType: ProblemCategory.STDIO,
      levelSet: [],
      algorithmTypeList: [],
      packageLevel: 0,
      publicOption: {
        companyYn: false,
        jobdaYn: false,
      },
      sn: 0,
      syncYn: false,
      title: '',
      totalStep: 0,
    }),
  },
};

export function usePackageProblemList(
  options?: Omit<
    UseQueryOptions<
      Promise<ProblemListRsDTO>,
      unknown,
      PackageProblemListRsDto,
      readonly string[]
    >,
    'initialData' | 'queryFn' | 'queryKey'
  >,
): {
  data: PackageProblemListRsDto;
  refetch?: () => void;
} {
  const queryString = useRecoilValue(pstPackagePaginationQuery);
  const { data, isError, isLoading, refetch } = useQuery<
    Promise<ProblemListRsDTO>,
    unknown,
    PackageProblemListRsDto,
    readonly string[]
  >(
    [queryKeys.pst, queryString],
    () => PSTRepository.getPackageProblemList(queryString),
    options,
  );

  if (isError || isLoading) return PackageProblemFallback;

  return {
    data,
    refetch,
  };
}
