import { Container, Title } from './style.ts';
import Editor from '@monaco-editor/react';
import { FunctionExamDTO } from '@phs/interfaces';
import { LanguageConfig } from '@widget/icons-language';
import { useCode } from './hooks.ts';

interface CodeProps extends FunctionExamDTO {
  onChange?: (type: string, code?: string) => void;
}

function Code({
  language,
  defaultSource,
  expectedSource,
  onChange: handleChange,
}: CodeProps) {
  const { onChange } = useCode({
    handleChange,
  });

  return (
    <Container>
      <Title>지원자 초기 코드</Title>
      <Editor
        value={defaultSource}
        language={LanguageConfig[language].editorLanguage}
        onChange={(code) => onChange('defaultSource', code)}
        height={'300px'}
        theme={'vs-dark'}
        options={{
          minimap: {
            enabled: false,
          },
          scrollbar: {
            alwaysConsumeMouseWheel: false,
          },
          scrollBeyondLastLine: false,
        }}
      />
      <Title>예상 답안 (선택 사항)</Title>
      <Editor
        value={expectedSource}
        language={LanguageConfig[language].editorLanguage}
        onChange={(code) => onChange('expectedSource', code)}
        height={'300px'}
        theme={'vs-dark'}
        options={{
          minimap: {
            enabled: false,
          },
          scrollbar: {
            alwaysConsumeMouseWheel: false,
          },
          scrollBeyondLastLine: false,
        }}
      />
    </Container>
  );
}

export default Code;
