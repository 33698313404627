import { FC } from 'react';
import { Container } from './style';

type tagColor = 'blue' | 'cyan' | 'green' | 'yellow' | 'red' | 'gray';

interface TagProps {
	colorType: tagColor;
	text: string;
}

interface ColorGroup {
	color: string;
	backgroundColor: string;
}

interface ColorGroupWithBorder extends ColorGroup {
	border: string;
}

const SmallTag: FC<TagProps> = (props) => {
	const { colorType, text } = props;
	const colorGroup = (): ColorGroupWithBorder => {
		switch (colorType) {
			case 'blue':
				return {
					color: '#1C7ED6',
					backgroundColor: '#E7F5FF',
					border: '#74C0FC',
				};
			case 'cyan':
				return {
					color: '#1098AD',
					backgroundColor: '#E3FAFC',
					border: '#66D9E8',
				};
			case 'green':
				return {
					color: '#37B24D',
					backgroundColor: '#EBFBEE',
					border: '#8CE99A',
				};
			case 'yellow':
				return {
					color: '#F59F00',
					backgroundColor: '#FFF9DB',
					border: '#FFE066',
				};
			case 'red':
				return {
					color: '#F03E3E',
					backgroundColor: '#FFF5F5',
					border: '#FFA8A8',
				};
			case 'gray':
			default:
				return {
					color: '#74C0FC',
					backgroundColor: '#1C7ED6',
					border: '#E7F5FF',
				};
		}
	};
	return (
		<Container
			backgroundColor={colorGroup().backgroundColor}
			color={colorGroup().color}
			borderColor={colorGroup().border}
		>
			{text}
		</Container>
	);
};

const LargeTag: FC<TagProps> = (props) => {
	const { colorType, text } = props;
	const colorGroup = (): ColorGroup => {
		switch (colorType) {
			case 'blue':
				return {
					color: '#228BE6',
					backgroundColor: '#E7F5FF',
				};
			case 'cyan':
				return {
					color: '#15AABF',
					backgroundColor: '#E3FAFC',
				};
			case 'green':
				return {
					color: '#12B886',
					backgroundColor: '#D3F9D8',
				};
			case 'yellow':
				return {
					color: '#F59F00',
					backgroundColor: '#FFF3BF',
				};
			case 'red':
				return {
					color: '#FA5252',
					backgroundColor: '#FFE3E3',
				};
			case 'gray':
			default:
				return {
					color: '#868E96',
					backgroundColor: '#F1F3F5',
				};
		}
	};
	return (
		<Container
			isLarge={true}
			backgroundColor={colorGroup().backgroundColor}
			color={colorGroup().color}
		>
			{text}
		</Container>
	);
};

export { SmallTag, LargeTag };
