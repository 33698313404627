import { SENTRY_KEYS } from '@phs/constants';
import * as Sentry from '@sentry/react';

interface RuntimeExceptionHandlerConfig {
	error: unknown;
	message: string;
}

class RuntimeExceptionHandler {
	constructor(props: RuntimeExceptionHandlerConfig) {
		this.init(props);
	}

	private init({ error, message }: RuntimeExceptionHandlerConfig) {
		Sentry.withScope(async (scope) => {
			scope.setTag(
				SENTRY_KEYS.TYPES.BROWSER,
				SENTRY_KEYS.MESSAGES.RUNTIME_ERROR,
			);

			await this.writeErrorLog(error);

			Sentry.captureMessage(message);
		});
	}

	private async writeErrorLog(error: unknown) {
		const { message, name } = (error as Error) ?? null;
		Sentry.setContext(SENTRY_KEYS.CONTEXTS.RUNTIME_ERROR_DETAIL, {
			message,
			name,
		});
	}
}

export default RuntimeExceptionHandler;
