import styled from '@emotion/styled';

export const Container = styled.div`
	overflow: hidden;
	border: 1px solid #dee2e6;
`;

export const PlainTable = styled.table`
	width: 100%;

	tr {
		&:not(&:last-of-type) {
			border-bottom: 1px solid #dee2e6;
		}
	}

	thead {
		th {
			padding: 14px 0 14px 12px;
			font-size: 12px;
			font-weight: 400;
			line-height: 18px;
			color: #868e96;
			text-align: left;
			vertical-align: middle;
			background: #f1f3f5;

			&:first-of-type {
				padding-left: 24px;
			}

			&:last-of-type {
				padding-right: 24px;
			}
		}
	}

	tbody {
		background: #fff;

		td {
			box-sizing: border-box;
			height: 72px;
			padding: 14px 0 14px 12px;
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
			vertical-align: middle;

			&:first-of-type {
				padding-left: 24px;
			}

			&:last-of-type {
				padding-right: 24px;
			}
		}
	}
`;
