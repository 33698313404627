import {
  Container,
  OptionGroup,
  Option,
  DisplayArea,
  SelectedValue,
  IconArea,
} from './style';
import { SelectItemProps, useSelect, useOutsideClick } from './hooks';
import { FC } from 'react';

export type { SelectItemProps };

interface SelectProps {
  initialValue?: SelectItemProps;
  items?: SelectItemProps[];
  onClick?: (props: SelectItemProps) => void;
  direction?: 'up' | 'down';
}

const Select: FC<SelectProps> = ({
  initialValue,
  items = [],
  onClick,
  direction = 'up',
  ...rest
}) => {
  const { activeItem, isOpen, setOpen, handleClick } = useSelect({
    item: initialValue ?? items?.[0],
    isOpen: false,
    onClick,
  });

  const ref = useOutsideClick(() => {
    setOpen(false);
  });

  return (
    <Container {...rest} ref={ref}>
      <OptionGroup direction={direction} isOpen={isOpen}>
        {items.map(({ name, key }) => (
          <Option
            key={`widget-select-option-${key}`}
            onClick={() =>
              handleClick({
                name,
                key,
              })
            }
          >
            {name}
          </Option>
        ))}
      </OptionGroup>
      <DisplayArea onClick={() => setOpen(!isOpen)}>
        <>
          <SelectedValue>{activeItem}</SelectedValue>
          <IconArea direction={direction} isOpen={isOpen}>
            <svg
              width='25'
              height='24'
              viewBox='0 0 25 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M12.863 14.8247C12.6662 14.8246 12.4715 14.7842 12.2909 14.706C12.1103 14.6277 11.9477 14.5133 11.813 14.3697L8.24805 10.6097L9.47805 9.43969L12.863 12.9997L16.248 9.42969L17.478 10.5997L13.913 14.3697C13.7784 14.5133 13.6158 14.6277 13.4352 14.706C13.2546 14.7842 13.0599 14.8246 12.863 14.8247Z'
                fill='#868E96'
              />
            </svg>
          </IconArea>
        </>
      </DisplayArea>
    </Container>
  );
};

export default Select;
