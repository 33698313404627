import { FC } from 'react';
import { CardGroup, Explain, TitleWrap } from './style.ts';
import TextButton from '@widget/button-text';
import Card from '@pages/Manage/Operation/Card';
import TextInput from '@widget/input-text';
import { useDate } from '@hooks/shared/useDate.ts';
import { DATE_FORMAT } from '@phs/interfaces';
import { useOperationCandidate } from '@pages/Manage/Operation/Candidate/hooks.ts';
import OperationRepository from '@repositories/operation.repository.ts';

const Candidate: FC = () => {
  const { now } = useDate();
  const { convertCount, candidateCount, convertDateValue, dateInputValue } =
    useOperationCandidate();
  const onClickInitNow = () => {
    const current = now(DATE_FORMAT.YYYY_MM_DD_T_HH_mm_ss);
    OperationRepository.getCandidateCount(current).then((value) => {
      convertCount(value.candidateCount);
      convertDateValue(current);
    });
  };

  return (
    <>
      <TitleWrap>
        모든 기업 시험 응시자 수
        <TextButton theme={'primary'} onClick={onClickInitNow}>
          현재 기준으로 초기화
        </TextButton>
      </TitleWrap>
      <TextInput
        onKeyUp={(event) => {
          if (event.keyCode === 13) {
            event.preventDefault();
            const date = event.currentTarget.value;
            OperationRepository.getCandidateCount(date).then((value) => {
              convertCount(value.candidateCount);
              convertDateValue(date);
            });
          }
        }}
        placeholder={'yyyy-mm-ddT00:00:00 입력 후 Enter를 누르세용'}
        style={{
          width: 600,
        }}
        defaultValue={dateInputValue}
      />
      <Explain>(yyyy-mm-ddT00:00:00 입력 후 Enter를 누르세용)</Explain>
      <CardGroup>
        <Card data={candidateCount} suffix={'명'} />
      </CardGroup>
    </>
  );
};

export default Candidate;
