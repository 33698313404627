import { useMemo, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { useDebouncedCallback } from '@react-hookz/web';
import { PackageExamFormProps } from '@pages/Exam/package/config.ts';
import { TabExpandableItemProps } from '@widget/tab-expandable';
import { CheckBoxOnClickProps } from '@widget/checkbox';
import {
  ExamLanguageDTO,
  FunctionParameterAndReturnTypeDTO,
  ModifyCommandType,
  PhaseProblemSourceDTO,
} from '@phs/interfaces';

interface UseLanguageProps {
  params: FunctionParameterAndReturnTypeDTO;
  setValue: UseFormSetValue<PackageExamFormProps>;
  tabIdx: number;
  languages?: PhaseProblemSourceDTO[];
}

export function useLanguage({
  languages = [],
  setValue,
  tabIdx,
}: UseLanguageProps) {
  const [cursor, setCursor] = useState<number>(0);

  const currentCursor = useMemo(() => cursor, [cursor]);

  const usingLanguages = useMemo(
    () => languages.filter(({ usage }) => usage),
    [languages],
  );

  const currentLanguage = useMemo(() => {
    if (languages.length === 0) return undefined;
    return usingLanguages[currentCursor];
  }, [currentCursor, usingLanguages]);

  const currentTabItems = useMemo(() => {
    return (
      usingLanguages.map(({ language }) => ({
        name: language,
        key: language,
        immutable: true,
      })) ?? ([] as TabExpandableItemProps[])
    );
  }, [usingLanguages]);

  // deleted 포함된 languages의 current index
  const currentLanguageCursor = useMemo(() => {
    const index = languages.findIndex(
      (language) => language.language === currentLanguage?.language,
    );
    if (index === -1) return 0;
    return index;
  }, [currentLanguage]);

  const onChangeToggle: CheckBoxOnClickProps = useDebouncedCallback(
    ({ isActive }) => {
      const newLanguages = languages.slice();
      const targetLanguage = languages[currentLanguageCursor];
      newLanguages[currentLanguageCursor] = {
        ...targetLanguage,
        referenceSourceYn: !isActive,
        commandType:
          targetLanguage.commandType === ModifyCommandType.READ
            ? ModifyCommandType.UPDATE
            : targetLanguage.commandType,
      };
      setValue(`stepProblemList.${tabIdx}.judgeLanguageList`, newLanguages);
    },
    [currentLanguageCursor, languages],
    200,
  );

  const onChangeCode = (type: string, code?: string) => {
    setValue(`stepProblemList.${tabIdx}.judgeLanguageList`, [
      ...languages.slice(0, currentLanguageCursor),
      {
        ...languages[currentLanguageCursor],
        [type]: code ?? '',
        commandType:
          languages[currentLanguageCursor].commandType === 'READ'
            ? ModifyCommandType.UPDATE
            : languages[currentLanguageCursor].commandType,
      },
      ...languages.slice(currentLanguageCursor + 1),
    ] as ExamLanguageDTO[]);
  };

  const onChangeCursor = (idx: number) => setCursor(idx);

  return {
    currentTabItems,
    currentLanguage,
    currentCursor,
    onChangeCode,
    onChangeToggle,
    onChangeCursor,
  };
}
