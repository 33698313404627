import styled from '@emotion/styled';

export const Input = styled.input<{ error?: string }>`
  width: 100%;
  height: 40px;
  background: none;
  outline: none;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid;
  border-left: none;
  border-color: ${({ error }) => (error ? '#fa5252' : '#CED4DA')};
  border-radius: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: #343a40;

  &::placeholder {
    color: #adb5bd;
  }

  &:focus-visible,
  &:target,
  &:hover,
  &:focus,
  &:focus-within,
  &:active,
  &:visited {
    border-color: ${({ error }) => (error ? '#fa5252' : '#495057')};
    border-top: none;
    border-right: none;
    border-bottom: 1px solid;
    border-left: none;
    outline: none;
    box-shadow: none;
  }

  &:disabled {
    color: #adb5bd;
    cursor: not-allowed;
    opacity: 0.4;
  }
`;
