import { css } from '@emotion/react';
import emotionReset from 'emotion-reset';

export const globalStyle = css`
  ${emotionReset};

  sub {
    font-size: smaller;
    vertical-align: sub;
  }
  sup {
    font-size: smaller;
    vertical-align: super;
  }

  *,
  *::after,
  *::before {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  html,
  body {
    padding: 0;
    margin: 0;
    font-family: Pretendard, -apple-system, system-ui, sans-serif;
    font-style: normal;
    font-weight: 400;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
`;
