import { selector } from 'recoil';
import { stateKeys } from '@constants/key.ts';
import {
  alertModalStatus,
  alertModalText,
  confirmModalAction,
  confirmModalStatus,
  confirmModalText,
} from '@stores/atoms/shared/modal.ts';

export const alertModalInfo = selector({
  key: stateKeys.modal.alertModalInfo,
  get: ({ get }) => {
    return {
      status: get(alertModalStatus),
      text: get(alertModalText),
    };
  },
});

export const confirmModalInfo = selector({
  key: stateKeys.modal.confirmModalInfo,
  get: ({ get }) => {
    return {
      status: get(confirmModalStatus),
      text: get(confirmModalText),
      action: get(confirmModalAction),
    };
  },
});
