import { TableHeaderProps } from '@widget/table';

export const HEADERS: TableHeaderProps[] = [
  {
    name: 'NO.',
    key: 'number',
    sortable: true,
  },
  {
    name: '기업명',
    key: 'company_name',
    sortable: true,
  },
  {
    name: '최근 DB 동기화 일자',
    key: 'date',
  },
  {
    name: 'DB 동기화',
    key: 'sync',
  },
];
