import { FC } from 'react';
import { Container } from '@pages/Data/style.ts';
import Tab from '@widget/tab';
import { Menu } from '@pages/Data/config.ts';
import { Outlet } from 'react-router-dom';
import { useTab } from '@hooks/useTab.ts';
import { PMS_PAGES } from '@phs/constants';

const DataPage: FC = () => {
  const { currentTab, onClick } = useTab({
    items: Menu,
    navigateList: [PMS_PAGES.DATA.HOME, PMS_PAGES.DATA.LEVEL],
  });
  return (
    <Container>
      <Tab item={Menu} activeKey={currentTab} onClick={onClick} />
      <Outlet />
    </Container>
  );
};

export default DataPage;
