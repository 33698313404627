import { PMS_URL } from '@phs/constants';
import FileRepository from '@repositories/file.repository.ts';
import { useMutation } from '@tanstack/react-query';

export function useImageUpload() {
  const { mutate } = useMutation(FileRepository.imageUpload, {
    retry: 3,
    retryDelay: 400,
  });

  const getImageUrl = (fileUid: string) =>
    `${import.meta.env.VITE_API_URL}${PMS_URL.FILE_VIEW_BY_ID(fileUid)}`;
  const uploadImage = async (
    blob: Blob | File,
    onSuccess?: (url: string, text?: string) => void,
    onError?: () => void,
  ) => {
    mutate(blob, {
      onSuccess: async ({ fileUid }) => {
        await onSuccess?.(getImageUrl(fileUid), blob?.name || 'image');
      },
      onError: onError,
    });
  };

  return { uploadImage };
}

export function usePopupImageUpload() {
  const { mutate } = useMutation(FileRepository.popupImageUpload, {
    retry: 3,
    retryDelay: 400,
  });

  const uploadImage = async (
    blob: Blob | File,
    onSuccess?: (url: string, text?: string) => void,
    onError?: () => void,
  ) => {
    mutate(blob, {
      onSuccess: async ({ fileUid }) => {
        await onSuccess?.(fileUid, blob?.name || 'image');
      },
      onError: onError,
    });
  };

  return { uploadImage };
}
