import TextButton from '@widget/button-text';
import { FC } from 'react';

interface FilterResetProps {
  onClick: () => void;
  disabled?: boolean;
}

const FilterResetButton: FC<FilterResetProps> = ({
  onClick,
  disabled = false,
}) => {
  return (
    <TextButton
      theme='secondary'
      style={{
        width: '96px',
        height: '40px',
        fontWeight: 700,
        fontSize: '12px',
        lineHeight: '18px',
      }}
      onClick={onClick}
      disabled={disabled}
    >
      필터 초기화
    </TextButton>
  );
};

export default FilterResetButton;
