import { Container } from './style.ts';
import { MENUS } from './config.ts';
import { FC } from 'react';
import LoadingButton from '@widget/button-loading';
import { useCompanyMenu } from './hook.ts';

const CompanyMenu: FC = () => {
  const { getSync, onClickButton } = useCompanyMenu();

  return (
    <Container>
      {MENUS.map(({ key, name }) => {
        return (
          <LoadingButton
            key={key}
            disabled={getSync(key)}
            onClick={() => onClickButton(key)}
          >
            {name}
          </LoadingButton>
        );
      })}
    </Container>
  );
};

export default CompanyMenu;
