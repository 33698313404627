import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { CodeTestCase, CodeValidator } from '@phs/code';
import {
  ExamLanguageDTO,
  ModifyCommandType,
  TestCaseDTO,
  TestCaseType,
} from '@phs/interfaces';
import { InputTableHeaderType, Removable } from '@widget/table-input';
import { Textarea } from '@widget/textarea';
import { useAlertModal } from '@components/Modals/Alert/hook.ts';

interface UseTestCaseProps {
  languages?: ExamLanguageDTO[];
  testCaseType?: TestCaseType;
  testCases?: TestCaseDTO[];
  onSubmit?: (data: TestCaseDTO[]) => void;
  onClose?: () => void;
}

export function useTestCase({
  testCaseType = TestCaseType.EXAMPLE,
  languages = [],
  testCases = [],
  onSubmit,
  onClose,
}: UseTestCaseProps) {
  const { onOpen } = useAlertModal();
  const [cache, setCache] = useState<TestCaseDTO[]>(testCases);

  useEffect(() => {
    if (testCases.length === 0) {
      const defaultCache = [
        CodeTestCase.createTestCase({
          number: 1,
          testCaseType,
        }),
      ];
      setCache(defaultCache);
      return;
    }
    setCache(testCases);
  }, [testCases]);

  const selectedLanguages = useMemo(
    () => languages.filter(({ usage }) => usage),
    [languages],
  );

  const renderHeaders = (): InputTableHeaderType[] => {
    return ['Parameters', `Return(string)`, ''];
  };

  const addTestCase = () => {
    setCache((prev) => {
      return [
        ...prev,
        CodeTestCase.createTestCase({
          number: cache.length + 1,
          testCaseType,
          testCaseLanguageSet: [],
        }),
      ];
    });
  };

  const updateTestCase = (idx: number, value: Partial<TestCaseDTO>) => {
    const newCache = cache.slice();
    newCache[idx] = CodeTestCase.updateTestCase(cache[idx], value);
    return newCache;
  };

  const deleteTestCase = (idx: number) => {
    const targetTestCase = cache[idx];

    if (targetTestCase.sn) {
      setCache((prev) => {
        return prev.map((testCase, i) => {
          if (idx === i)
            return CodeTestCase.deleteTestCase(testCase, {
              number: i + 1,
            });

          return { ...testCase, number: i + 1 };
        });
      });
    } else {
      setCache((prev) => {
        return prev
          .filter((_, i) => idx !== i)
          .map((testCase, i) => ({ ...testCase, number: i + 1 }));
      });
    }
  };

  const onChangeInput = (
    event: ChangeEvent<HTMLTextAreaElement>,
    idx: number,
  ) => {
    const { input: inputValue } = cache[idx];

    setCache(
      updateTestCase(idx, {
        input: [
          {
            ...inputValue[0],
            value: event.currentTarget.value,
          },
        ],
      }),
    );
  };

  const onChangeOutput = (
    event: ChangeEvent<HTMLTextAreaElement>,
    idx: number,
  ) => {
    const { output: outputValue } = cache[idx];

    setCache(
      updateTestCase(idx, {
        output: {
          ...outputValue,
          value: event.currentTarget.value,
        },
      }),
    );
  };

  const renderData = (data: TestCaseDTO[]) => {
    if (!data) return undefined;

    return data
      .map(
        ({ sn, input: inputValue, output: outputValue, commandType }, idx) => {
          const elements = [
            <Textarea
              data-testid={`testcase-textarea-input-${idx}`}
              key={`testcase-textarea-input-${sn}-${idx}`}
              placeholder={'Input'}
              value={inputValue[0].value}
              onChange={(e) => onChangeInput(e, idx)}
            />,
            <Textarea
              data-testid={`testcase-textarea-output-${idx}`}
              placeholder={'Output'}
              value={outputValue.value}
              onChange={(e) => onChangeOutput(e, idx)}
            />,
            <Removable
              key={`testcase-textarea-output-${sn}-${idx}`}
              onRemove={() => deleteTestCase(idx)}
            ></Removable>,
          ];

          return { data: elements, commandType };
        },
      )
      .filter((v) => v.commandType !== ModifyCommandType.DELETE)
      .map((v) => v.data);
  };

  const validateArgs = () => {
    let result = true;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    cache
      .filter(({ commandType }) => commandType !== ModifyCommandType.DELETE)
      .forEach(({ input: inputValue, output: outputValue }, idx) => {
        if (CodeValidator.isEmpty(inputValue[0].value)) {
          onOpen(
            <>{`${
              idx + 1
            }번째 테스트 케이스의 1번째 매개변수가 비었습니다.`}</>,
          );
          result = false;
          return false;
        }

        if (CodeValidator.isEmpty(outputValue.value)) {
          onOpen(
            <>{`${
              idx + 1
            }번째 테스트 케이스의 2번째 매개변수가 비었습니다.`}</>,
          );
          result = false;
          return false;
        }
        return false;
      });

    return result;
  };

  const handleSubmit = () => {
    if (!validateArgs()) return;

    onSubmit?.(cache);
  };

  const handleClose = () => {
    setCache([]);
    onClose?.();
  };

  return {
    cache,
    addTestCase,
    renderData,
    handleSubmit,
    handleClose,
    selectedLanguages,
    renderHeaders,
  };
}
