import { FC, ReactNode } from 'react';
import { Container, SuffixElement, SuffixIcon, Title } from './style';
import { IconClose, labelColor } from './Icon';

type labelSufFixIcon = 'close';

interface JWLabelProps {
	color?: labelColor;
	prefixIcon?: ReactNode;
	suffixElement?: ReactNode;
	suffixIcon?: labelSufFixIcon;
	onClickSuffixIcon?: Function;
	text: string | boolean;
}

const Label: FC<JWLabelProps> = (props) => {
	const {
		color = 'white',
		prefixIcon,
		suffixElement,
		suffixIcon,
		onClickSuffixIcon,
		text,
	} = props;

	const onClickCloseIcon = () => {
		onClickSuffixIcon && onClickSuffixIcon();
	};

	const icon = () => {
		switch (suffixIcon) {
			case 'close':
			default:
				return <IconClose className={'close'} onClick={onClickCloseIcon} />;
		}
	};

	return (
		<Container color={color}>
			{prefixIcon && prefixIcon}
			<Title>{text}</Title>
			{suffixElement && <SuffixElement>{suffixElement}</SuffixElement>}
			{suffixIcon && <SuffixIcon>{icon()}</SuffixIcon>}
		</Container>
	);
};
export default Label;
