import { Outlet } from 'react-router-dom';
import { Container } from './style.ts';

const PopUpLayout = () => {
	return (
		<Container>
			<Outlet />
		</Container>
	);
};

export default PopUpLayout;
