import styled from '@emotion/styled';

export const Container = styled.button`
  width: 100%;
  padding: 16px 0;
  box-sizing: border-box;
  background: #12b886;
  border: 1px solid #12b886;
  font-family: inherit;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  cursor: pointer;

  &:hover {
    background: #0ca678;
  }

  &:active {
    opacity: 0.8;
  }
`;
