export enum PopUpStatus {
	WAITING = 'WAITING',
	POSTING = 'POSTING',
	ENDED = 'ENDED',
}

export enum PopUpSortType {
	SN_DESC = 'SN,DESC',
	SN_ASC = 'SN,ASC',
	START_DATE_TIME_DESC = 'START_DATE_TIME,DESC',
	START_DATE_TIME_ASC = 'START_DATE_TIME,ASC',
}
