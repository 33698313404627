import { CompanyDTO } from '@interfaces/company/company.dto.ts';
import { SyncWrap } from './style.ts';
import { IconLoading } from '@widget/icons';
import CompanyRepository from '@repositories/company.repository.ts';
import { useCompany } from '@hooks/manage/useCompany.ts';
import { DATE_FORMAT } from '@phs/interfaces';
import { useDate } from '@hooks/shared/useDate.ts';

export function useCompanyManagement() {
  const { safeStr2DateTime } = useDate();
  const { refetch } = useCompany();
  const renderData = (data: CompanyDTO[]) => {
    if (!data) return undefined;

    return data.map((company) => [
      <span>{company.sn}</span>,
      <span>{company.companyName}</span>,
      <span>
        {safeStr2DateTime(
          company.databaseSyncTime,
          DATE_FORMAT.YYYY_MM_DD_HH_mm_ss,
        )}
      </span>,
      <span>
        <SyncWrap syncYn={company.databaseSyncYn}>
          <IconLoading
            onClick={async (event) => {
              if (company.databaseSyncYn) return;
              const target = event.currentTarget;
              try {
                target.classList.add('rotate');
                await CompanyRepository.postDatabaseSync(company.sn);
                target.classList.remove('rotate');
                refetch?.();
              } catch (e) {
                console.error(e);
                target.classList.remove('rotate');
              }
            }}
          />
        </SyncWrap>
      </span>,
    ]);
  };

  return {
    renderData,
  };
}
