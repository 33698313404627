import { FC, PropsWithChildren, useMemo } from 'react';
import TextButton from '@widget/button-text';
import {
  FunctionParameterAndReturnTypeDTO,
  ModifyCommandType,
  PhaseProblemSourceDTO,
  TestCaseDTO,
  TestCaseType,
} from '@phs/interfaces';
import Table, { RenderTableBody } from '@components/Table';
import TestCaseModal from '../Modals/TestCase';
import HighCapacityTestCaseModal from '../Modals/HighCapacityTestCase';
import { ButtonWrapper, Container, RequireTitle, TitleWrap } from './style';
import { useAccuracyCase } from './hooks';
import { HEADERS } from './config';

interface AccuracyCaseProps extends PropsWithChildren {
  params: FunctionParameterAndReturnTypeDTO;
  disabled?: boolean;
  languages?: PhaseProblemSourceDTO[];
  testCases?: TestCaseDTO[];
  fileTestCases?: TestCaseDTO[];
  handleTestCase?: (type: TestCaseType, data: TestCaseDTO[]) => void;
}

const AccuracyCase: FC<AccuracyCaseProps> = ({
  params,
  disabled = false,
  languages = [],
  testCases = [],
  fileTestCases = [],
  handleTestCase,
}) => {
  const {
    isModalOpened,
    isFileModalOpened,
    handleOpen,
    renderData,
    renderFileData,
    onSubmit,
    onFileSubmit,
    downloadAllFiles,
    onClose,
  } = useAccuracyCase({
    disabled,
    handleTestCase,
  });

  const noTestCase = useMemo(() => {
    return (
      testCases.every(
        ({ commandType }) => commandType === ModifyCommandType.DELETE,
      ) &&
      fileTestCases.every(
        ({ commandType }) => commandType === ModifyCommandType.DELETE,
      )
    );
  }, [testCases, fileTestCases]);

  return (
    <Container>
      {isModalOpened && (
        <TestCaseModal
          params={params}
          testCaseType={TestCaseType.ACCURACY}
          languages={languages}
          testCases={testCases}
          onSubmit={onSubmit}
          onClose={onClose}
        />
      )}
      {isFileModalOpened && (
        <HighCapacityTestCaseModal
          params={params}
          languages={languages}
          testCaseType={TestCaseType.ACCURACY}
          testCases={fileTestCases}
          onSubmit={onFileSubmit}
          onClose={onClose}
        />
      )}
      <TitleWrap>
        <RequireTitle className={noTestCase ? 'invalid' : ''}>
          정확성 테스트 케이스
        </RequireTitle>
        <ButtonWrapper>
          <TextButton
            onClick={() => downloadAllFiles(fileTestCases)}
            style={{
              minWidth: 120,
              height: 52,
            }}
          >
            테스트 케이스 파일 다운로드
          </TextButton>
          <TextButton
            data-testid={'accuracy-file-testcase'}
            onClick={() => handleOpen('file', true)}
            style={{
              minWidth: 120,
              height: 52,
            }}
          >
            대용량 테스트 케이스 추가
          </TextButton>
          <TextButton
            data-testid={'accuracy-testcase'}
            onClick={() => handleOpen('testcase', true)}
            style={{
              minWidth: 120,
              height: 52,
            }}
          >
            테스트 케이스 추가 및 수정
          </TextButton>
        </ButtonWrapper>
      </TitleWrap>
      <Table<TestCaseDTO>
        headers={HEADERS}
        data={testCases}
        renderTableBody={renderData as RenderTableBody<TestCaseDTO>}
        fixLayout
        data-testid='package-accuracy-testcase'
      />
      {fileTestCases.length > 0 && (
        <Table<TestCaseDTO>
          headers={HEADERS}
          data={fileTestCases}
          renderTableBody={renderFileData as RenderTableBody<TestCaseDTO>}
          fixLayout
          data-testid='package-accuracy-file-testcase'
        />
      )}
    </Container>
  );
};

export default AccuracyCase;
