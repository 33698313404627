import { inject, injectable } from 'inversify';
import 'reflect-metadata';
import {
  DataType,
  ExamLanguageDTO,
  FunctionParameterAndReturnTypeDTO,
  LanguageType,
  ModifyCommandType,
  PhaseProblemSourceDTO,
  ProblemCategory,
  TestCaseDTO,
  TestCaseFileType,
  TestCaseLanguageDTO,
  TestCaseType,
} from '@phs/interfaces';
import {
  C,
  Cpp,
  CSharp,
  Go,
  Java,
  JavaScript,
  Kotlin,
  Pypy,
  Python,
  R,
  Ruby,
  Scala,
  Swift,
} from '../langauges';
import { TYPES } from '../types';
import { CodeTransformer } from './transformer';

export interface CodeTestCaseProps {
  readonly problemType: typeof ProblemCategory;
  readonly testCaseType: typeof TestCaseType;
  readonly testCaseFileType: typeof TestCaseFileType;
  readonly commandType: typeof ModifyCommandType;
  readonly languageType: typeof LanguageType;
  readonly dataType: typeof DataType;

  createJudgeSet(data: PhaseProblemSourceDTO[]): PhaseProblemSourceDTO[];

  createLanguageSet(
    data: ExamLanguageDTO[] | PhaseProblemSourceDTO[],
  ): TestCaseLanguageDTO[];

  createLanguageSetItem(
    language: LanguageType,
    sn?: number,
  ): TestCaseLanguageDTO;

  createFileTestCase(
    testCase: Partial<TestCaseDTO>,
    params: FunctionParameterAndReturnTypeDTO,
    idx: number,
  ): TestCaseDTO;

  createTestCase(data: Partial<TestCaseDTO>): TestCaseDTO;

  readTestCase(data: TestCaseDTO[]): TestCaseDTO[];

  updateTestCase(
    target: TestCaseDTO,
    updatedValue: Partial<TestCaseDTO>,
  ): TestCaseDTO;

  deleteTestCase(
    target: TestCaseDTO,
    updatedValue: Partial<TestCaseDTO>,
  ): TestCaseDTO;

  deleteAllTestCases(target: TestCaseDTO[]): TestCaseDTO[];

  previewTestCase(
    problemType: ProblemCategory,
    data: Array<Array<TestCaseDTO>>,
  ): TestCaseDTO[];

  mergeTestCases(
    type: ProblemCategory,
    data: Array<Array<TestCaseDTO>>,
  ): TestCaseDTO[];

  splitTestCases(
    testCases: TestCaseDTO[],
    commandType?: ModifyCommandType,
  ): Record<
    | 'exampleTestCases'
    | 'accuracyTestCases'
    | 'accuracyFileTestCases'
    | 'efficiencyTestCases'
    | 'efficiencyFileTestCases',
    TestCaseDTO[]
  >;

  removeEscape(testCases: TestCaseDTO[], type: ProblemCategory): TestCaseDTO[];

  stringifyAll(data: string[]): string;

  hasEmptyParameters(data: TestCaseDTO[]): boolean;

  hasSameParameters(
    data: FunctionParameterAndReturnTypeDTO,
    text: string,
    currentIdx: number,
  ): boolean;

  getTimeLimit(language: Omit<LanguageType, 'DEFAULT'>): number;
}

@injectable()
export class CodeTestCase implements CodeTestCaseProps {
  private readonly _PROBLEM_CATEGORY = ProblemCategory;
  private readonly _TESTCASE_TYPE = TestCaseType;
  private readonly _TESTCASE_FILE_TYPE = TestCaseFileType;
  private readonly _COMMAND_TYPE = ModifyCommandType;
  private readonly _LANGUAGE_TYPE = LanguageType;
  private readonly _DATA_TYPE = DataType;

  constructor(
    @inject(TYPES.C) private readonly c: C,
    @inject(TYPES.CPP) private readonly cpp: Cpp,
    @inject(TYPES.CSHARP) private readonly csharp: CSharp,
    @inject(TYPES.JAVA) private readonly java: Java,
    @inject(TYPES.JAVASCRIPT) private readonly javascript: JavaScript,
    @inject(TYPES.KOTLIN) private readonly kotlin: Kotlin,
    @inject(TYPES.PYTHON) private readonly python: Python,
    @inject(TYPES.R) private readonly r: R,
    @inject(TYPES.RUBY) private readonly ruby: Ruby,
    @inject(TYPES.SCALA) private readonly scala: Scala,
    @inject(TYPES.SWIFT) private readonly swift: Swift,
    @inject(TYPES.PYPY) private readonly pypy: Pypy,
    @inject(TYPES.GO) private readonly go: Go,
    @inject(TYPES.TRANSFORMER) private readonly transformer: CodeTransformer,
  ) {}

  public get dataType() {
    return this._DATA_TYPE;
  }

  public get problemType() {
    return this._PROBLEM_CATEGORY;
  }

  public get testCaseType() {
    return this._TESTCASE_TYPE;
  }

  public get testCaseFileType() {
    return this._TESTCASE_FILE_TYPE;
  }

  public get commandType() {
    return this._COMMAND_TYPE;
  }

  public get languageType() {
    return this._LANGUAGE_TYPE;
  }

  public stringifyAll(data: string[]) {
    const result = this.transformer.stringify(
      data.map((value) =>
        this.transformer.sanitize(value, this.problemType.FUNCTION),
      ),
    );

    return result;
  }

  public hasEmptyParameters(data: TestCaseDTO[]) {
    return (
      data.filter(({ commandType }) => commandType !== this.commandType.DELETE)
        .length < 1
    );
  }

  // 매개변수 & Return Type 자료구조에서 동일한 Param Name 있는 경우
  public hasSameParameters(
    data: FunctionParameterAndReturnTypeDTO,
    text: string,
    currentIdx: number,
  ) {
    return (
      data.paramInfo
        .filter((_, idx) => idx !== currentIdx)
        .filter(({ paramName }) => paramName === text).length > 0
    );
  }

  public createJudgeSet(data: PhaseProblemSourceDTO[]) {
    return data.map((props) => ({ ...props }));
  }

  public createLanguageSet(data: ExamLanguageDTO[]): TestCaseLanguageDTO[] {
    return data
      .filter(({ usage }) => usage)
      .map(({ language, sn }) => this.createLanguageSetItem(language, sn));
  }

  public createLanguageSetItem(
    language: LanguageType,
    sn?: number,
  ): TestCaseLanguageDTO {
    return {
      sn,
      language,
      timeLimit: this.getTimeLimit(language),
      commandType: this.commandType.CREATE,
    };
  }

  public createFileTestCase(
    testCase: Partial<TestCaseDTO>,
    params: FunctionParameterAndReturnTypeDTO,
    idx: number,
  ) {
    return {
      type: TestCaseFileType.COMMON_FILE,
      commandType: this.commandType.CREATE,
      input: params.paramInfo.map(({ paramName, paramType }) => ({
        parameterName: paramName,
        parameterType: paramType,
        value: '',
      })),
      output: {
        parameterName: 'return',
        parameterType: params.returnType,
        value: '',
      },
      number: idx,
      testCaseType: TestCaseType.ACCURACY,
      testCaseLanguageSet: [],
      inputFile: {
        fileName: '',
        fileUid: '',
      },
      outputFile: {
        fileName: '',
        fileUid: '',
      },
      ...testCase,
    } as TestCaseDTO;
  }

  public createTestCase(data: Partial<TestCaseDTO>): TestCaseDTO {
    return {
      type: TestCaseFileType.TEXT,
      commandType: this.commandType.CREATE,
      input: [
        {
          parameterType: this.dataType.STRING,
          parameterName: 'Parameter',
          value: '',
        },
      ],
      output: {
        parameterName: 'return',
        parameterType: this.dataType.STRING,
        value: '',
      },
      number: 0,
      testCaseLanguageSet: [],
      testCaseType: this.testCaseType.EXAMPLE,
      ...data,
    };
  }

  public readTestCase(data: TestCaseDTO[]): TestCaseDTO[] {
    return data.map((props) => ({
      ...props,
      commandType: this.commandType.READ,
      testCaseLanguageSet: props.testCaseLanguageSet.map((value) => ({
        ...value,
        commandType: this.commandType.READ,
      })),
    }));
  }

  public updateTestCase(
    target: TestCaseDTO,
    updatedValue: Partial<TestCaseDTO>,
  ) {
    const merge = {
      ...target,
      ...updatedValue,
    };

    if (target.commandType !== this.commandType.CREATE) {
      merge.commandType = this.commandType.UPDATE;
    }

    return merge;
  }

  public deleteTestCase(
    target: TestCaseDTO,
    updatedValue: Partial<TestCaseDTO>,
  ): TestCaseDTO {
    return {
      ...target,
      ...updatedValue,
      commandType: this.commandType.DELETE,
    };
  }

  public deleteAllTestCases(target: TestCaseDTO[]): TestCaseDTO[] {
    return target
      .filter((testCase) => testCase.commandType !== ModifyCommandType.CREATE)
      .map((testCase) => this.deleteTestCase(testCase, {}));
  }

  public previewTestCase(
    problemType: ProblemCategory,
    data: Array<Array<TestCaseDTO>>,
  ) {
    const testCases = data.reduce((acc, cur) => {
      return [...acc, ...cur];
    });

    return testCases
      .filter(({ commandType }) => commandType !== ModifyCommandType.DELETE)
      .map(
        (
          {
            sn,
            commandType,
            testCaseType,
            input,
            output,
            testCaseLanguageSet,
            inputFile,
            outputFile,
            type,
          },
          index: number,
        ) => ({
          sn,
          commandType,
          number: index + 1, // number 겹치면 안된다.
          testCaseType,
          input: input.map((data) => ({
            ...data,
            value: this.transformer.addDoubleQuotes(data.value, problemType),
          })),
          output: {
            ...output,
            value: this.transformer.addDoubleQuotes(output.value, problemType),
          },
          // Delete 아닌 애들은 전부 보내줘야 되며, 이 때 commandType Create 통일
          testCaseLanguageSet: testCaseLanguageSet
            .filter(
              ({ commandType }) =>
                testCaseType === this.testCaseType.EFFICIENCY &&
                commandType !== this.commandType.DELETE,
            )
            .map((value) => ({
              ...value,
              commandType: ModifyCommandType.CREATE,
            })),
          inputFile,
          outputFile,
          type,
        }),
      );
  }

  public mergeTestCases(
    problemType: ProblemCategory,
    data: Array<Array<TestCaseDTO>>,
  ) {
    const testCases = data.reduce((acc, cur) => {
      return [...acc, ...cur];
    });

    return testCases
      .filter(
        ({ commandType }) =>
          problemType === ProblemCategory.PST2 ||
          commandType !== this.commandType.READ,
      )
      .map(
        ({
          sn,
          commandType,
          number,
          testCaseType,
          input,
          output,
          testCaseLanguageSet,
          inputFile,
          outputFile,
          type,
        }) => ({
          sn,
          commandType,
          number,
          testCaseType,
          input: input.map((data) => ({
            ...data,
            value: this.transformer.addDoubleQuotes(data.value, problemType),
          })),
          output: {
            ...output,
            value: this.transformer.addDoubleQuotes(output.value, problemType),
          },
          testCaseLanguageSet:
            problemType === ProblemCategory.PST2
              ? testCaseLanguageSet
              : testCaseLanguageSet.filter(
                  ({ commandType }) =>
                    testCaseType === this.testCaseType.EFFICIENCY &&
                    commandType !== this.commandType.READ,
                ),
          inputFile,
          outputFile,
          type,
        }),
      );
  }

  public splitTestCases(
    testCases: TestCaseDTO[],
    commandType?: ModifyCommandType,
  ) {
    const exampleTestCases: TestCaseDTO[] = [];
    const accuracyTestCases: TestCaseDTO[] = [];
    const accuracyFileTestCases: TestCaseDTO[] = [];
    const efficiencyTestCases: TestCaseDTO[] = [];
    const efficiencyFileTestCases: TestCaseDTO[] = [];

    try {
      testCases.forEach(
        ({ type, testCaseType, testCaseLanguageSet, ...rest }) => {
          const payload: TestCaseDTO = {
            ...rest,
            type,
            testCaseType,
            commandType:
              commandType ?? rest.commandType ?? this.commandType.READ,
            testCaseLanguageSet: [
              ...testCaseLanguageSet.map((value) => ({
                ...value,
                commandType:
                  commandType ?? value.commandType ?? this.commandType.READ,
              })),
            ],
          };

          switch (type) {
            case this.testCaseFileType.TEXT:
              switch (testCaseType) {
                case this.testCaseType.EXAMPLE:
                default:
                  exampleTestCases.push(payload);
                  break;
                case this.testCaseType.ACCURACY:
                  accuracyTestCases.push(payload);
                  break;

                case this.testCaseType.EFFICIENCY:
                  efficiencyTestCases.push(payload);
                  break;
              }
              break;

            default:
              switch (testCaseType) {
                case this.testCaseType.ACCURACY:
                default:
                  accuracyFileTestCases.push(payload);
                  break;

                case this.testCaseType.EFFICIENCY:
                  efficiencyFileTestCases.push(payload);
                  break;
              }
              break;
          }
        },
      );

      return {
        exampleTestCases,
        accuracyTestCases,
        accuracyFileTestCases,
        efficiencyTestCases,
        efficiencyFileTestCases,
      };
    } catch (error) {
      return {
        exampleTestCases: [],
        accuracyTestCases: [],
        accuracyFileTestCases: [],
        efficiencyTestCases: [],
        efficiencyFileTestCases: [],
      };
    }
  }

  public removeEscape(testCases: TestCaseDTO[], problemType: ProblemCategory) {
    return testCases.map(({ input, output, type, ...rest }) => {
      if (type === this.testCaseFileType.TEXT) {
        const filteredInput = input.map(({ value, ...rest }) => ({
          ...rest,
          value: this.transformer.removeDoubleQuotes(value, problemType),
        }));

        const filteredOutput = {
          ...output,
          value: this.transformer.removeDoubleQuotes(output.value, problemType),
        };

        return {
          input: filteredInput,
          output: filteredOutput,
          type,
          ...rest,
        };
      }

      return {
        input,
        output,
        type,
        ...rest,
      };
    }) as TestCaseDTO[];
  }

  public getTimeLimit(language: Omit<LanguageType, 'DEFAULT'>) {
    switch (language) {
      case this.languageType.C:
      default:
        return this.c.defaultTimeLimit;

      case this.languageType.CPP:
        return this.cpp.defaultTimeLimit;

      case this.languageType.CSHARP:
        return this.csharp.defaultTimeLimit;

      case this.languageType.JAVA:
        return this.java.defaultTimeLimit;

      case this.languageType.JAVASCRIPT:
        return this.javascript.defaultTimeLimit;

      case this.languageType.KOTLIN:
        return this.kotlin.defaultTimeLimit;

      case this.languageType.PYTHON:
        return this.python.defaultTimeLimit;

      case this.languageType.PYPY:
        return this.pypy.defaultTimeLimit;

      case this.languageType.R:
        return this.r.defaultTimeLimit;

      case this.languageType.RUBY:
        return this.ruby.defaultTimeLimit;

      case this.languageType.SCALA:
        return this.scala.defaultTimeLimit;

      case this.languageType.SWIFT:
        return this.swift.defaultTimeLimit;

      case this.languageType.GO:
        return this.go.defaultTimeLimit;
    }
  }
}
