import { injectable } from 'inversify';
import 'reflect-metadata';
import { Language } from './base';
import { ExamParameterDTO } from '../interfaces/language.dto';

@injectable()
export class CSharp extends Language {
  public get defaultTimeLimit(): number {
    return 5000;
  }

  public loadFunctionCode(parameters: ExamParameterDTO): string {
    return parameters.paramInfo
      .map(({ paramType, paramName }) => {
        const returnType = this.returnType[paramType];
        return `${returnType.paramType} ${paramName}`.trim();
      })
      .join(', ');
  }

  public getFunctionTemplate(parameters: ExamParameterDTO): string {
    const { type, value } = this.returnType[parameters.returnType];

    return `using System;\n\npublic class Solution {\n    public ${type} solution(${this.loadFunctionCode(
      parameters,
    )}) {\n        ${type} answer${value};\n        return answer;\n    }\n}`;
  }

  public getStdioTemplate() {
    return 'using System;\n\npublic class Example\n{\n    public static void Main()\n    {\n        String[] s;\n\n        Console.Clear();\n        s = Console.ReadLine().Split(\' \');\n\n        int a = Int32.Parse(s[0]);\n        int b = Int32.Parse(s[1]);\n\n        Console.WriteLine("{0}", a + b);\n    }\n}';
  }

  public get returnType() {
    return {
      // Primitive Types
      INT: this.setReturnType('int', 'int', ' = 0'),
      LONG: this.setReturnType('long', 'long', ' = 0'),
      DOUBLE: this.setReturnType('double', 'double', ' = 0'),
      STRING: this.setReturnType('string', 'string', ' = ""'),
      BOOL: this.setReturnType('bool', 'bool', ' = true'),
      // Reference Types
      INT_ARRAY: this.setReturnType('int[]', 'int[]', ' = new int[] {}'),
      LONG_ARRAY: this.setReturnType('long[]', 'long[]', ' = new long[] {}'),
      DOUBLE_ARRAY: this.setReturnType(
        'double[]',
        'double[]',
        ' = new double[] {}',
      ),
      STRING_ARRAY: this.setReturnType(
        'string[]',
        'string[]',
        ' = new string[] {}',
      ),
      BOOL_ARRAY: this.setReturnType('bool[]', 'bool[]', ' = new bool[] {}'),
      // 2 Dimensional Reference Types
      INT_2_ARRAY: this.setReturnType(
        'int[][]',
        'int[][]',
        ' = new int[][] { new int[] {} }',
      ),
      LONG_2_ARRAY: this.setReturnType(
        'long[][]',
        'long[][]',
        ' = new long[][] { new long[] {} }',
      ),
      DOUBLE_2_ARRAY: this.setReturnType(
        'double[][]',
        'double[][]',
        ' = new double[][] { new double[] {} }',
      ),
      STRING_2_ARRAY: this.setReturnType(
        'string[][]',
        'string[][]',
        ' = new string[][] { new string[] {} }',
      ),
      BOOL_2_ARRAY: this.setReturnType(
        'bool[][]',
        'bool[][]',
        ' = new bool[][] { new bool[] {} }',
      ),
    };
  }
}
