import { FC, useRef } from 'react';
import { Container } from '@pages/Data/Algorithms/Function/style.ts';
import { useFunction } from '@pages/Data/Algorithms/Function/hooks.ts';
import LoadingButton from '@widget/button-loading';
import { AlogrithmData } from '@pages/Data/Algorithms/Table';
import { AlgorithmExcelDataDto } from '@interfaces/company/data.dto.ts';
import SyncDataModal from '@pages/Data/Algorithms/Modal';

interface AlgorithmFunctionProps {
  setData: (rsData: AlgorithmExcelDataDto, data: AlogrithmData[]) => void;
  rsData: AlgorithmExcelDataDto | null;
}

const AlgorithmFunction: FC<AlgorithmFunctionProps> = ({ setData, rsData }) => {
  const {
    onUploadData,
    onDownloadData,
    onSyncData,
    openModal,
    convertModalState,
  } = useFunction(setData);
  const uploadInputRef = useRef<HTMLInputElement>(null);

  return (
    <Container>
      <LoadingButton key={'sync-data'} onClick={onSyncData}>
        데이터 동기화 (DB저장)
      </LoadingButton>
      <LoadingButton key={'download-data'} onClick={onDownloadData}>
        엑셀 다운로드
      </LoadingButton>
      <LoadingButton
        key={'upload-data'}
        onClick={() => {
          if (uploadInputRef.current) {
            uploadInputRef.current.click();
          }
        }}
      >
        데이터 업로드
        <input
          style={{ display: 'none' }}
          type='file'
          ref={uploadInputRef}
          onChange={onUploadData}
          onClick={(e) => (e.currentTarget.value = '')}
          multiple
        />
      </LoadingButton>
      {openModal && (
        <SyncDataModal
          rsData={rsData}
          title={'알고리즘 가중치 수정하시겠습니까?'}
          onClose={() => convertModalState(false)}
        />
      )}
    </Container>
  );
};

export default AlgorithmFunction;
