import { BaseRepository } from '@phs/repositories';
import { PMS_URL } from '@phs/constants';
import { GuideFileType } from '@phs/interfaces';

interface GuideFileUpload {
  file: Blob | File;
  guideType: GuideFileType;
}

class FileRepository extends BaseRepository {
  constructor(baseURL: string) {
    super(baseURL);
  }

  public async download(url: string): Promise<Blob> {
    const { data: response } = await super.get<Blob>(url, {
      responseType: 'blob',
    });

    return response;
  }

  public async imageUpload(file: Blob | File): Promise<{ fileUid: string }> {
    const formData = new FormData();
    formData.append('file', file);

    const { data: response } = await super.postForm<{ fileUid: string }>(
      PMS_URL.FILE_UPLOAD_PROBLEM_IMAGE,
      formData,
    );

    return response;
  }

  public async fileUpload(file: Blob | File): Promise<{ fileUid: string }> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    const { data: response } = await super.postForm<{ fileUid: string }>(
      PMS_URL.FILE_UPLOAD,
      formData,
    );

    return response;
  }

  public async guideFileUpload(
    uploadData: GuideFileUpload,
  ): Promise<{ fileUid: string }> {
    const formData = new FormData();
    formData.append('file', uploadData.file, uploadData.file.name);

    const { data: response } = await super.postForm<{ fileUid: string }>(
      PMS_URL.GUIDE_UPLOAD,
      formData,
      { params: { guideFileType: uploadData.guideType } },
    );

    return response;
  }

  public async popupImageUpload(
    file: Blob | File,
  ): Promise<{ fileUid: string }> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    const { data: response } = await super.postForm<{ fileUid: string }>(
      PMS_URL.POPUP_IMAGE_UPLOAD,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );

    return response;
  }

  public async testCaseFileDownload(fileName: string, fileUid: string) {
    const a = document.createElement('a');

    a.download = fileName;
    a.hidden = true;
    document.body.appendChild(a);

    try {
      const url = PMS_URL.TESTCASE_FILE_DOWNLOAD(fileUid);
      const { data: blobPart } = await super.get(url, { responseType: 'blob' });

      const blob = new Blob([blobPart], { type: 'octet/stream' });
      a.href = URL.createObjectURL(blob);
      a.click();
      a.remove();
    } catch (e) {
      alert('다운로드 실패');
      throw e;
    }
  }

  public async testCaseFileBulkDownload(fileName: string, data: object = {}) {
    const a = document.createElement('a');

    a.download = fileName;
    a.hidden = true;
    document.body.appendChild(a);

    try {
      const url = PMS_URL.TESTCASE_FILE_BULK_DOWNLOAD;
      const { data: blobPart } = await super.post(url, data, {
        responseType: 'blob',
      });

      const blob = new Blob([blobPart], { type: 'octet/stream' });
      a.href = URL.createObjectURL(blob);
      a.click();
      a.remove();
    } catch (e) {
      alert('다운로드 실패');
      throw e;
    }
  }
}

const fileRepository = new FileRepository(import.meta.env.VITE_API_URL);
export default fileRepository;
