import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  height: max-content;
`;

export const TitleWrapper = styled.div`
  svg {
    margin-left: 8px;
    vertical-align: bottom;
    cursor: pointer;
  }
`;

export const RequireTitle = styled.span`
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray_900};

  &::after {
    display: inline-block;
    margin-left: 4px;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.red_600};
    vertical-align: text-bottom;
    content: '*';
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
`;

export const Item = styled.span`
  margin-left: 4px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
`;
