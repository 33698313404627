import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { LanguageListResDTO } from '@interfaces/dashboard/language.dto.ts';
import { queryKeys } from '@constants/key.ts';
import LanguageRepository from '@repositories/language.repository.ts';

const fallback: LanguageListResDTO = {
  languageList: [],
};

export function useLanguageList(
  options?: Omit<
    UseQueryOptions<any, any, any, any>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: () => undefined;
  },
) {
  const { data, isError, isLoading } = useQuery<
    Promise<LanguageListResDTO>,
    unknown,
    LanguageListResDTO,
    readonly string[]
  >([queryKeys.language], LanguageRepository.readLanguageList, options);

  if (isError || isLoading) return fallback;

  return data;
}
