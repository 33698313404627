import { atomKeys } from '@constants/key';
import { Nullable } from '@phs/interfaces';
import {
  syncAccessTokenEffects,
  syncRefreshTokenEffects,
} from '@stores/effects/auth';
import { atom } from 'recoil';

export const pmsAccessToken = atom<Nullable<string>>({
  key: atomKeys.auth.pmsAccessToken,
  default: null,
  effects: [syncAccessTokenEffects(atomKeys.auth.pmsAccessToken)],
});

export const pmsRefreshToken = atom<Nullable<string>>({
  key: atomKeys.auth.pmsRefreshToken,
  default: null,
  effects: [syncRefreshTokenEffects(atomKeys.auth.pmsRefreshToken)],
});
