import { useMemo } from 'react';

interface UseUploadTableProps {
  headerLength: number;
}

export function useUploadTable({ headerLength }: UseUploadTableProps) {
  const colSpan = useMemo(() => headerLength, [headerLength]);

  return {
    colSpan,
  };
}
