import OperationRepository from '@repositories/operation.repository.ts';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@constants/key.ts';
import { ScaleOptionRsDTO } from '@interfaces/company/company.dto.ts';

const defaultAutoScalingFallback: ScaleOptionRsDTO = {
  commonCandidateCount: 0,
  companyScaleFactor: 0,
  cheatingScaleFactor: 0,
};

export function useAutoScaling(): ScaleOptionRsDTO {
  const { data, isError, isLoading } = useQuery<
    string,
    unknown,
    ScaleOptionRsDTO,
    readonly string[]
  >([queryKeys.autoScaling], () => OperationRepository.getScaleOption(), {
    refetchOnWindowFocus: false,
  });

  if (isError || isLoading) return defaultAutoScalingFallback;

  return data;
}
