import { ButtonWrap, Container } from './style';
import { useConfirmModal } from './hook.ts';
import { CSSProperties, FC, PropsWithChildren } from 'react';
import ModalWidget from '@widget/modal';
import TextButton from '@widget/button-text';

interface ConfirmModalProps extends PropsWithChildren {
  confirmText?: string;
  cancelText?: string;
  style?: CSSProperties;
}

const ConfirmModal: FC<ConfirmModalProps> = ({
  children,
  confirmText = '확인',
  cancelText = '취소',
  style,
}) => {
  const { info, onClose } = useConfirmModal();
  if (!info.status) return null;

  return (
    <ModalWidget>
      <Container style={style}>
        {children || info.text}
        <ButtonWrap>
          <TextButton
            style={{
              width: 128,
              marginTop: 'auto',
            }}
            onClick={onClose}
          >
            {cancelText}
          </TextButton>
          <TextButton
            theme={'primary'}
            style={{
              width: 128,
              marginTop: 'auto',
            }}
            onClick={info.action.onOk}
          >
            {confirmText}
          </TextButton>
        </ButtonWrap>
      </Container>
    </ModalWidget>
  );
};

export default ConfirmModal;
