import styled from '@emotion/styled';

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  width: 400px;
  max-height: 800px;
  box-sizing: border-box;
  padding: 16px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.white};
`;

export const ContentWrapper = styled.div`
  min-height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray_700};
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  text-align: center;
  white-space: break-spaces;
`;

export const BottomWrapper = styled.div`
  display: flex;
  gap: 8px;
  height: 32px;
  width: 100%;
  max-width: 33%;
  margin: 0 auto;
`;
