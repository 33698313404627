import { injectable } from 'inversify';
import 'reflect-metadata';
import { Language } from './base';
import { ExamParameterDTO } from '../interfaces/language.dto';

@injectable()
export class Swift extends Language {
  public get defaultTimeLimit(): number {
    return 3000;
  }

  public loadFunctionCode(parameters: ExamParameterDTO) {
    return parameters.paramInfo
      .map(({ paramType, paramName }) => {
        const returnType = this.returnType[paramType];
        return `_ ${paramName}:${returnType.paramType}`.trim();
      })
      .join(', ');
  }

  public getFunctionTemplate(parameters: ExamParameterDTO) {
    const { type, value } = this.returnType[parameters.returnType];

    return `import Foundation\n\nfunc solution(${this.loadFunctionCode(
      parameters,
    )}) -> ${type} {\n    return ${value}\n}`;
  }

  public getStdioTemplate() {
    return 'import Foundation\n\nlet n = readLine()!.components(separatedBy: [" "]).map { Int($0)! }\nlet (a, b) = (n[0], n[1])\n\nprint(a + b)';
  }

  public get returnType() {
    return {
      // Primitive Types
      INT: this.setReturnType('Int', 'Int', '0'),
      LONG: this.setReturnType('Int64', 'Int64', '0'),
      DOUBLE: this.setReturnType('Double', 'Double', '0'),
      STRING: this.setReturnType('String', 'String', '""'),
      BOOL: this.setReturnType('Bool', 'Bool', 'true'),
      // Reference Types
      INT_ARRAY: this.setReturnType('[Int]', '[Int]', '[]'),
      LONG_ARRAY: this.setReturnType('[Int64]', '[Int64]', '[]'),
      DOUBLE_ARRAY: this.setReturnType('[Double]', '[Double]', '[]'),
      STRING_ARRAY: this.setReturnType('[String]', '[String]', '[]'),
      BOOL_ARRAY: this.setReturnType('[Bool]', '[Bool]', '[]'),
      // 2 Dimensional Reference Types
      INT_2_ARRAY: this.setReturnType('[[Int]]', '[[Int]]', '[[]]'),
      LONG_2_ARRAY: this.setReturnType('[[Int64]]', '[[Int64]]', '[[]]'),
      DOUBLE_2_ARRAY: this.setReturnType('[[Double]]', '[[Double]]', '[[]]'),
      STRING_2_ARRAY: this.setReturnType('[[String]]', '[[String]]', '[[]]'),
      BOOL_2_ARRAY: this.setReturnType('[[Bool]]', '[[Bool]]', '[[]]'),
    };
  }
}
