import { BaseRepository } from '@phs/repositories';
import { PMS_URL } from '@phs/constants';
import {
  ForcePasswordChangeReqDTO,
  LoginPreviewResDTO,
  LoginReqDTO,
  LoginResDTO,
} from '@interfaces/shared/auth.dto.ts';

class AuthRepository extends BaseRepository {
  constructor(baseURL: string) {
    super(baseURL);
  }

  public async login(data: LoginReqDTO) {
    const { data: response } = await super.post<LoginResDTO>(
      PMS_URL.LOGIN,
      data,
    );
    return response as LoginResDTO;
  }

  public async logout() {
    const { data: response } = await super.post(PMS_URL.LOGOUT);
    return response;
  }

  public async loginExists(data: LoginReqDTO): Promise<boolean> {
    const { data: response } = await super.post<LoginResDTO>(
      PMS_URL.LOGIN_EXISTS,
      data,
    );
    return response;
  }

  public async issueAccessToken() {
    const { data: response } = await super.get<LoginResDTO>(
      PMS_URL.ISSUE_ACCESS_TOKEN,
    );
    return response as LoginResDTO;
  }

  public async loginPreview(sn: number) {
    const { data: response } = await super.post<LoginPreviewResDTO>(
      PMS_URL.LOGIN_PREVIEW(sn),
    );
    return response as LoginPreviewResDTO;
  }

  public async forceChangePassword(data: ForcePasswordChangeReqDTO) {
    const { data: response } = await super.put(
      PMS_URL.FORCE_PASSWORD_CHANGE,
      data,
    );
    return response;
  }
}

const authRepository = new AuthRepository(import.meta.env.VITE_API_URL);
export default authRepository;
