import { useLocation } from 'react-router-dom';
import { PMS_PAGES } from '@phs/constants';
import { ProblemBaseType } from '@phs/interfaces';
import TextButton from '@widget/button-text';
import Modal from '../Modal';
import { useHeader } from './hooks.ts';
import { Container } from './style.ts';
import LoadingButton from '@widget/button-loading';

const Header = () => {
  const location = useLocation();
  const {
    isModalVisible,
    onClickModal,
    currentExamType,
    onCreateExam,
    onCopyExam,
    onDeleteExam,
    isCopyLoading,
  } = useHeader();

  return (
    <Container>
      {isModalVisible && (
        <Modal
          type={
            location.pathname == PMS_PAGES.DASHBOARD.PACKAGES
              ? ProblemBaseType.PACKAGE.toLowerCase()
              : ProblemBaseType.DEFAULT.toLowerCase()
          }
          title={`문제 가져오기 (${currentExamType.name})`}
          onClose={onClickModal}
        />
      )}
      <TextButton theme={'primary'} onClick={onClickModal}>
        문제&nbsp;가져오기
      </TextButton>
      <LoadingButton onClick={onCopyExam} isLoading={isCopyLoading}>
        문제&nbsp;복사
      </LoadingButton>
      <TextButton
        data-testid={'delete-exam'}
        theme={'primary'}
        onClick={onDeleteExam}
      >
        문제&nbsp;삭제
      </TextButton>
      {location.pathname === PMS_PAGES.DASHBOARD.HOME && (
        <>
          <TextButton
            theme={'primary'}
            data-testid={'create-stdio'}
            onClick={() => onCreateExam('stdio')}
          >
            문제&nbsp;생성&nbsp;(STDIO)
          </TextButton>
          <TextButton
            theme={'primary'}
            onClick={() => onCreateExam('function')}
          >
            문제&nbsp;생성&nbsp;(FUNCTION)
          </TextButton>
        </>
      )}
      {location.pathname == PMS_PAGES.DASHBOARD.PACKAGES && (
        <TextButton theme={'primary'} onClick={() => onCreateExam('package')}>
          문제&nbsp;생성&nbsp;
        </TextButton>
      )}
    </Container>
  );
};

export default Header;
