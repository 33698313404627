interface UseCodeProps {
  handleChange?: (type: string, code?: string) => void;
}

export function useCode({ handleChange }: UseCodeProps) {
  const onChange = (type: string, code?: string) => {
    handleChange?.(type, code);
  };

  return { onChange };
}
