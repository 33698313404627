import { Container, IconWrapper, Text } from './style.ts';
import { FC, PropsWithChildren } from 'react';

interface EmptyProps extends PropsWithChildren {
	onClick?: () => void;
}

const Empty: FC<EmptyProps> = ({ children, onClick }) => {
	return (
		<Container onClick={onClick}>
			<IconWrapper>
				<svg
					width='96'
					height='97'
					viewBox='0 0 96 97'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M78 79.5H18C15.79 79.5 14 77.71 14 75.5V21.5C14 19.29 15.79 17.5 18 17.5H37.25C38.365 17.5 39.43 17.965 40.19 18.785L51 30.5H78C80.21 30.5 82 32.29 82 34.5V75.5C82 77.71 80.21 79.5 78 79.5Z'
						fill='#9FA4AB'
					/>
					<path
						d='M85.9998 36.5H26.1398C24.2798 36.5 22.6648 37.78 22.2448 39.595L14.1048 74.595C13.5198 77.105 15.4248 79.5 17.9998 79.5H77.8548C79.7148 79.5 81.3298 78.22 81.7498 76.405L89.8898 41.405C90.4748 38.895 88.5698 36.5 85.9948 36.5H85.9998Z'
						fill='#CFD1D5'
					/>
				</svg>
			</IconWrapper>
			<Text>{children}</Text>
		</Container>
	);
};

export default Empty;
