import styled from '@emotion/styled';

export const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    vertical-align: middle;
  }
`;

export const LanguageContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const LanguageName = styled.div`
  display: block;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.gray_800};
  text-align: left;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${({ theme }) => theme.colors.gray_500};
`;

export const TableWrap = styled.div`
  max-height: 55vh;
  overflow: auto;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
`;
