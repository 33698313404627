import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const RequireTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray_900};

  &::after {
    display: inline-block;
    margin-left: 4px;
    color: ${({ theme }) => theme.colors.red_600};
    vertical-align: middle;
    content: '*';
  }
`;

export const DropDownWrapper = styled.div`
  width: 25%;
`;

export const ErrorText = styled.span`
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  color: #fa5252;
`;
