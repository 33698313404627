import { useState } from 'react';
import { TagWrap } from './style.ts';
import { LargeTag, SmallTag } from '@widget/tags';
import PstRepository from '@repositories/pst.repository.ts';
import { useAlertModal } from '@components/Modals/Alert/hook.ts';

export function useBringProblem() {
  const [snList, setSnList] = useState<string[]>([]);
  const [isBringLoading, setIsBringLoading] = useState<boolean>(false);
  const { onOpen } = useAlertModal();
  const addSn = (sn: string) => {
    if (snList.includes(sn)) return;
    setSnList([...snList, sn]);
  };

  const onBringProblem = async (companySn: string, type: string) => {
    setIsBringLoading(true);
    try {
      if (snList.length === 0) {
        onOpen('sn이 비어있습니다.');
        return;
      }
      await PstRepository.bringProblem(
        type,
        snList.map((value) => Number(value)),
        Number(companySn),
      );
      onOpen('문제 가져오기 완료');
    } catch (e: any) {
      console.error(e);
      onOpen(e?.response?.data?.message);
    } finally {
      setIsBringLoading(false);
    }
  };

  const deleteSn = (target: string) => {
    setSnList(snList.filter((sn) => sn !== target));
  };

  const tagRender = () => {
    return snList.map((sn) => (
      <TagWrap key={sn} onClick={() => deleteSn(sn)}>
        <LargeTag colorType={'blue'} text={sn} />
        <SmallTag colorType={'red'} text={'삭제'} />
      </TagWrap>
    ));
  };

  return {
    addSn,
    onBringProblem,
    tagRender,
    isBringLoading,
  };
}
