import { FC } from 'react';
import { Container, InfoText } from '@pages/Data/Algorithms/Info/style.ts';

interface InfoProps {
  recentSyncDate: string;
}

const Info: FC<InfoProps> = ({ recentSyncDate }) => {
  return (
    <Container>
      <InfoText>최근 업데이트 : {recentSyncDate}</InfoText>
    </Container>
  );
};

export default Info;
