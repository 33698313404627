import jwtDecode, { JwtPayload } from 'jwt-decode';
import { isValidToken } from '@phs/helpers';
import { Nullable } from '@phs/interfaces';

/**
 authorities: ['ADMIN']
 company_name: "dts-company"
 company_sn: 3
 exp: 1692595073
 iat: 1692593273
 id: "company_manager@midasin.com"
 name: "pms"
 token_type: "ACCESS"
 */

export class TokenDecoder {
  public decodeJWT(token: string): JwtPayload {
    return jwtDecode(token);
  }

  public parseValue<T>(token: JwtPayload, key: keyof JwtPayload) {
    if (!Object.prototype.hasOwnProperty.call(token, key)) return null;
    return token[key] as T;
  }

  public isValidExp(token: string) {
    const exp = this.parseValue<Nullable<number>>(this.decodeJWT(token), 'exp');
    return isValidToken(exp ?? Number.MIN_SAFE_INTEGER);
  }
}
