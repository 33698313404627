import { AxiosError, isAxiosError } from 'axios';
import { ERROR_CODE, ERROR_MESSAGE } from '@constants/error.tsx';
import {
  AxiosExceptionHandler,
  RuntimeExceptionHandler,
} from '@phs/exceptions';
import { PMS_EXCEPTION_MESSAGES } from '@constants/exceptions.ts';
import { useAlertModal } from '@components/Modals/Alert/hook.ts';
import { usePasswordModal } from '@components/Modals/Password/hook.ts';

export function useLoginError() {
  const { onOpen } = useAlertModal();
  const { open } = usePasswordModal({
    email: '',
    currentPassword: '',
  });

  const handleError = (error: unknown) => {
    if (isAxiosError(error)) {
      const { response } = error as AxiosError;

      switch ((response?.data as any)?.code) {
        case ERROR_CODE.WRONG_ACCOUNT:
          onOpen(ERROR_MESSAGE[ERROR_CODE.WRONG_ACCOUNT]);
          break;

        case ERROR_CODE.FIRST_LOGIN:
          if ((error as AxiosError)?.config?.data) {
            open(true);
          } else {
            onOpen(ERROR_MESSAGE[ERROR_CODE.FIRST_LOGIN]);
          }
          break;

        case ERROR_CODE.SHOULD_CHANGE_PASSWORD: {
          if ((error as AxiosError)?.config?.data) {
            open(false);
          } else {
            onOpen(ERROR_MESSAGE[ERROR_CODE.SHOULD_CHANGE_PASSWORD]);
          }
          break;
        }

        case ERROR_CODE.BLOCK_LOGIN:
          onOpen(ERROR_MESSAGE[ERROR_CODE.BLOCK_LOGIN]);
          break;

        default:
          onOpen(ERROR_MESSAGE[ERROR_CODE.WRONG_ACCOUNT]);
          break;
      }

      new AxiosExceptionHandler({
        error,
        message: PMS_EXCEPTION_MESSAGES.PMS_LOGIN_ERROR,
      });
    } else {
      onOpen(ERROR_MESSAGE[ERROR_CODE.WRONG_ACCOUNT]);

      new RuntimeExceptionHandler({
        error,
        message: PMS_EXCEPTION_MESSAGES.PMS_LOGIN_ERROR,
      });
    }
  };

  return { handleError };
}
