import styled from '@emotion/styled';

export const Container = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 480px;
	box-sizing: border-box;
	padding: 32px;
	background: ${({ theme }) => theme.colors.white};
	border-radius: 8px;
	overflow: auto;
`;

export const TitleArea = styled.div`
	display: flex;
	justify-content: space-between;
	box-sizing: border-box;
	padding-bottom: 32px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.gray_300};

	h2 {
		margin: 0;
		font-size: 24px;
		font-weight: 700;
		line-height: 32px;
	}

	svg {
		cursor: pointer;
	}
`;

export const LanguageWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
	box-sizing: border-box;
	padding: 16px 0;
	border-bottom: 1px solid ${({ theme }) => theme.colors.gray_300};
`;

export const IconWrapper = styled.div`
	display: flex;
	gap: 16px;

	svg {
		width: 48px;
		height: 48px;
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2px;
`;

export const LanguageName = styled.span`
	display: block;
	font-size: 18px;
	font-weight: 700;
	line-height: 28px;
	color: ${({ theme }) => theme.colors.gray_800};
	text-align: left;
`;

export const LanguageSpec = styled.span`
	display: block;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	color: ${({ theme }) => theme.colors.gray_700};
	text-align: left;
`;
