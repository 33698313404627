import SearchInput from '@widget/input-search';
import { useSearch } from './hooks.ts';
import { Container } from './style.ts';
import { CSSProperties, FC } from 'react';

interface SearchProps {
  placeholder?: string;
  style?: CSSProperties;
}

const Search: FC<SearchProps> = ({ placeholder, style }) => {
  const { handleSearchKeyword, searchKeyword } = useSearch();

  return (
    <Container style={style}>
      <SearchInput
        placeholder={placeholder}
        onChange={(e) => handleSearchKeyword(e.currentTarget.value)}
        value={searchKeyword}
      />
    </Container>
  );
};

export default Search;
