import { CSSProperties, FC, PropsWithChildren, ReactNode } from 'react';
import ModalWidget from '@widget/modal';
import {
  Container,
  TopArea,
  Title,
  CloseBtn,
  BottomArea,
  ContentArea,
} from './style.ts';
import { IconClose } from '@widget/icons';
import TextButton from '@widget/button-text';

interface ClosableModalProps extends PropsWithChildren {
  title?: ReactNode;
  onClose?: () => void;
  onSubmit?: () => void;
  style?: CSSProperties;
}

const ClosableModal: FC<ClosableModalProps> = ({
  children,
  title,
  onClose,
  onSubmit,
  style,
}) => {
  return (
    <ModalWidget>
      <Container style={style}>
        <TopArea>
          <Title>{title}</Title>
          <CloseBtn>
            <IconClose onClick={onClose} />
          </CloseBtn>
        </TopArea>
        <ContentArea>{children}</ContentArea>
        <BottomArea>
          <TextButton
            role={'closable-submit'}
            theme={'primary'}
            style={{
              width: 140,
              height: 44,
            }}
            onClick={onSubmit}
            type={'button'}
          >
            확인
          </TextButton>
        </BottomArea>
      </Container>
    </ModalWidget>
  );
};

export default ClosableModal;
