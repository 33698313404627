import { FC } from 'react';
import Editor from '@monaco-editor/react';
import { PhaseProblemSourceDTO } from '@phs/interfaces';
import { LanguageConfig } from '@widget/icons-language';
import CheckBox, { CheckBoxOnClickProps } from '@widget/checkbox';
import { Container, Hint, SubFunctionWrapper, Title } from './style.ts';
import { useCode } from './hooks.ts';

interface CodeProps extends PhaseProblemSourceDTO {
  hasInitCode?: boolean;
  onChange?: (type: string, code?: string) => void;
  onToggle?: CheckBoxOnClickProps;
}

const Code: FC<CodeProps> = ({
  hasInitCode = true,
  language,
  defaultSource,
  expectedSource,
  referenceSourceYn,
  referenceSource,
  onChange: handleChange,
  onToggle: handleToggle,
}) => {
  const { onChange, onToggle } = useCode({
    handleChange,
    handleToggle,
  });

  return (
    <Container>
      {hasInitCode && (
        <>
          <Title>지원자 초기 코드</Title>
          <Editor
            value={defaultSource}
            language={LanguageConfig[language].editorLanguage}
            onChange={(code) => onChange('defaultSource', code)}
            height={'300px'}
            theme={'vs-dark'}
            options={{
              minimap: {
                enabled: false,
              },
              scrollbar: {
                alwaysConsumeMouseWheel: false,
              },
              scrollBeyondLastLine: false,
            }}
          />
        </>
      )}
      <CheckBox
        data-testid='reference-source-checkbox'
        isActive={referenceSourceYn}
        onClick={onToggle}
        style={{ padding: '32px 0 24px' }}
      >
        <SubFunctionWrapper>
          <span>
            &nbsp;기본&nbsp;제공&nbsp;class&nbsp;/&nbsp;function&nbsp;제공&nbsp;(선택
            사항)&nbsp;
          </span>
          <Hint>
            &nbsp;응시자가&nbsp;활용할&nbsp;수&nbsp;있는&nbsp;class&nbsp;/&nbsp;function&nbsp;제공합니다.&nbsp;응시자는&nbsp;편집이&nbsp;불가능합니다.
          </Hint>
        </SubFunctionWrapper>
      </CheckBox>
      {referenceSourceYn && (
        <Editor
          className='provided-code'
          value={referenceSource}
          language={LanguageConfig[language].editorLanguage}
          onChange={(code) => onChange('referenceSource', code)}
          height={'300px'}
          theme={'vs-dark'}
          options={{
            minimap: {
              enabled: false,
            },
            scrollbar: {
              alwaysConsumeMouseWheel: false,
            },
            scrollBeyondLastLine: false,
          }}
        />
      )}
      <Title>
        예상&nbsp;답안&nbsp;(선택&nbsp;사항)&nbsp;&nbsp;
        <Hint>
          응시자에게는&nbsp;노출되지&nbsp;않으며&nbsp;미리&nbsp;보기를&nbsp;통해&nbsp;예상&nbsp;답안을&nbsp;불러와서&nbsp;확인해&nbsp;보실&nbsp;수&nbsp;있습니다.
        </Hint>
      </Title>
      <Editor
        className='expected-code'
        value={expectedSource ?? ''}
        language={LanguageConfig[language].editorLanguage}
        onChange={(code) => onChange('expectedSource', code)}
        height={'300px'}
        theme={'vs-dark'}
        options={{
          minimap: {
            enabled: false,
          },
          scrollbar: {
            alwaysConsumeMouseWheel: false,
          },
          scrollBeyondLastLine: false,
        }}
      />
    </Container>
  );
};

export default Code;
