import { TagContainer } from './style';

interface TagProps {
  name: string;
  onDelete: () => void;
  readOnly?: boolean;
}

export default function Tag({ name, onDelete, readOnly }: TagProps) {
  return (
    <TagContainer>
      {name}
      {!readOnly && (
        <div className='delete' onClick={onDelete}>
          <svg
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect width='18' height='18' rx='4' fill='white' />
            <path
              d='M13.9508 4.95079L13.0508 4.05078L9.00078 8.10078L4.95078 4.05078L4.05078 4.95079L8.09703 9.00079L4.05078 13.0508L4.95078 13.9508L9.00078 9.90077L13.0508 13.9508L13.9508 13.0508L9.90078 9.00079L13.9508 4.95079Z'
              fill='#ADB5BD'
            />
          </svg>
        </div>
      )}
    </TagContainer>
  );
}
