import { useImageUpload } from '@hooks/file/useImageUpload';
import { Editor } from '@toast-ui/react-editor';
import { useRef } from 'react';
import type { Nullable } from '@phs/interfaces';
import { UseFormSetValue } from 'react-hook-form';
import { FormProps } from '../config.ts';

type HookCallback = (url: string, text?: string) => void;

export function useEditor(setValue?: UseFormSetValue<FormProps>) {
  const { uploadImage } = useImageUpload();
  const containerRef = useRef<Nullable<HTMLDivElement>>(null);
  const editorRef = useRef<Nullable<Editor>>(null);

  const onChange = () => {
    if (!editorRef.current) return;
    setValue?.('content', editorRef.current?.getInstance()?.getMarkdown());
  };

  const addImageBlobHook = async (
    blob: Blob | File,
    callback: HookCallback,
  ) => {
    await uploadImage(blob, callback);
    // callback(imageUrl, blob?.name || 'image');
    return false;
  };

  return {
    containerRef,
    editorRef,
    addImageBlobHook,
    onChange,
  };
}
