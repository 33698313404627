import { useEffect, useState } from 'react';

interface UsePaginationProps {
  defaultPage?: number;
  pages?: number[];
  totalPage: number;
  onFirst?: () => void;
  onBefore?: (prevPage: number) => void;
  onNext?: (nextPage: number) => void;
  onLast?: () => void;
  onChangePage?: (page: number) => void;
}

export function usePagination(props: UsePaginationProps) {
  const [currentPage, setCurrentPage] = useState(props?.defaultPage ?? 0);
  const lastPage = Math.max(...(props?.pages ?? []));
  const firstPage = Math.min(...(props?.pages ?? []));

  useEffect(() => {
    setCurrentPage(props?.defaultPage ?? 0);
  }, [props?.defaultPage]);

  const handleFirst = () => {
    if (firstPage - 1 <= 0) return;
    setCurrentPage(firstPage - 1);
    props.onChangePage && props.onChangePage(firstPage - 1);
  };

  const handleLast = () => {
    if (props.totalPage <= lastPage + 1) return;
    setCurrentPage(lastPage + 1);
    props.onChangePage && props.onChangePage(lastPage + 1);
  };

  const handleNext = () => {
    if (props.totalPage === currentPage) return;
    setCurrentPage(currentPage + 1);
    props.onChangePage && props.onChangePage(currentPage + 1);
  };

  const handlePrev = () => {
    if (currentPage <= 0) return;
    setCurrentPage(currentPage - 1);
    props.onChangePage && props.onChangePage(currentPage - 1);
  };

  const handelClickPage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    props.onChangePage && props.onChangePage(pageNumber);
  };

  const isDisableNext = () => {
    return props.totalPage === currentPage;
  };

  const isDisableNextSkip = () => {
    return props.totalPage <= lastPage + 1;
  };

  const isDisablePrev = () => {
    return currentPage === 0;
  };

  const isDisablePrevSkip = () => {
    return firstPage - 1 <= 0;
  };

  return {
    currentPage,
    handleNext,
    handlePrev,
    handleFirst,
    handleLast,
    handelClickPage,
    isDisableNext,
    isDisableNextSkip,
    isDisablePrev,
    isDisablePrevSkip,
  };
}
