import { RouteObject } from 'react-router-dom';
import { PMS_PAGES } from '@phs/constants';
import AuthenticatedLayout from '@layout/Authenticated';
import DashboardLayout from '@layout/Dashboard';
import NoticePage from '@pages/Notice';
import PopUpNotice from '@pages/Notice/Popup';

const NoticeRoute: RouteObject = {
  path: PMS_PAGES.NOTICE.HOME,
  element: (
    <AuthenticatedLayout>
      <DashboardLayout />
    </AuthenticatedLayout>
  ),
  children: [
    {
      path: PMS_PAGES.NOTICE.HOME,
      element: <NoticePage />,
      children: [
        {
          index: true,
          element: <PopUpNotice />,
        },
      ],
    },
  ],
};

export default NoticeRoute;
