import { selector } from 'recoil';
import { stateKeys } from '@constants/key.ts';
import { searchKeyword } from '@stores/atoms/shared/keyword.ts';
import { currentPage, pageSize } from '@stores/atoms/shared/pagination.ts';
import { popUpStatus } from '@stores/atoms/notice/popup.filter.ts';
import { popUpSortType } from '@stores/atoms/notice/popup.sort';

export const popUpPaginationQuery = selector({
  key: stateKeys.filter.popUpPaginationQuery,
  get: ({ get }) => {
    const _searchKeyword = get(searchKeyword);
    const _currentPage = get(currentPage);
    const _pageSize = get(pageSize);
    const _popUpStatus = get(popUpStatus);
    const _popUpSortType = get(popUpSortType);

    let queryString = `?&keyword=${_searchKeyword}&pageIndex=${_currentPage}&pageSize=${_pageSize}`;

    const payload: Record<string, any> = {
      keyword: _searchKeyword === '' ? undefined : _searchKeyword,
      page: _currentPage,
      size: _pageSize,
    };

    if (_popUpStatus.length > 0) {
      payload['statusSet'] = _popUpStatus;
      queryString += `&statusSet=${_popUpStatus}`;
    }

    if (_popUpSortType.length > 0) {
      payload['sort'] = _popUpSortType;
      queryString += `&sort=${_popUpSortType}`;
    }

    return {
      query: payload,
      queryString,
    };
  },
});
