import { FC, useMemo } from 'react';
import MultipleDropDown from '@widget/dropdown-multiple';
import Search from '@components/Search';
import {
  LEVELS,
  IconLevel,
  Category,
  IconProblemCategory,
  Public,
  IconExposureFrequency,
} from './config';
import { Filter, Container } from './style';
import { useFilters } from './hooks';
import LanguageFilter from './Language';
import FilterResetButton from '@components/FilterResetButton';
import { useSearchParams } from 'react-router-dom';
import { algorithmList } from '@pages/Data/config';

const Filters: FC = () => {
  const {
    handleChangeLevelFilter,
    level,
    handleChangeJudgeTypeFilter,
    handleChangePublicType,
    judgeType,
    publicType,
    algorithmType,
    handleChangeAlgorithmType,
  } = useFilters();
  const [searchParams, setSearchParams] = useSearchParams();
  const filterResetButtonDisabled = useMemo(() => {
    const filteredParams = [...searchParams].filter(([key, value]) => {
      switch (key) {
        case 'pageSize':
          if (value === '10') return false;
          return true;
        case 'currentPage':
          if (value === '0') return false;
          return true;
        case 'searchKeyword':
          if (value === '') return false;
          return true;
        default:
          return true;
      }
    });

    return filteredParams.length === 0;
  }, [searchParams]);

  return (
    <Container>
      <Filter>
        <MultipleDropDown
          title={'알고리즘 분류'}
          items={algorithmList}
          value={algorithmType}
          onChange={handleChangeAlgorithmType}
        />
      </Filter>
      <Filter>
        <MultipleDropDown
          prefixIcon={<IconLevel />}
          title={'난이도'}
          items={LEVELS}
          value={level}
          onChange={handleChangeLevelFilter}
        />
      </Filter>
      <Filter>
        <MultipleDropDown
          prefixIcon={<IconProblemCategory />}
          title={'문제 유형'}
          items={Category}
          value={judgeType}
          onChange={handleChangeJudgeTypeFilter}
        />
      </Filter>
      <Filter>
        <LanguageFilter />
      </Filter>
      <Filter>
        <MultipleDropDown
          // TODO: 추후 변경할 것(임시 아이콘 사용)
          prefixIcon={<IconExposureFrequency />}
          title={'공개 여부'}
          items={Public}
          value={publicType}
          onChange={handleChangePublicType}
        />
      </Filter>
      <Filter>
        <Search />
      </Filter>
      <FilterResetButton
        onClick={() => {
          setSearchParams();
        }}
        disabled={filterResetButtonDisabled}
      />
    </Container>
  );
};

export default Filters;
