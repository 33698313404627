import { LanguageType } from '@phs/interfaces';

export const DEPENDENCY_REGISTRY: Record<
  keyof Omit<typeof LanguageType, 'DEFAULT'>,
  string
> = {
  C: 'C',
  CPP: 'CPP',
  CSHARP: 'CSHARP',
  JAVA: 'JAVA',
  JAVASCRIPT: 'JAVASCRIPT',
  PYTHON: 'PYTHON',
  RUBY: 'RUBY',
  SCALA: 'SCALA',
  SWIFT: 'SWIFT',
  KOTLIN: 'KOTLIN',
  R: 'R',
  GO: 'GO',
  PYPY: 'PYPY',
};
