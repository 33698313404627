import { FC, useEffect } from 'react';
import {
  CardGroup,
  TitleWrap,
} from '@pages/Manage/Operation/AutoScaling/style.ts';
import TextButton from '@widget/button-text';
import Card from '@pages/Manage/Operation/Card';
import { useAutoScaling } from '@hooks/manage/useAutoScaling.ts';
import { useAutoScalingState } from '@pages/Manage/Operation/AutoScaling/hooks.ts';
import OptionSettingModal from '@pages/Manage/Operation/Modal/OptionSetting';

const AutoScaling: FC = () => {
  const data = useAutoScaling();
  const {
    openOptionModal,
    companyScaleFactor,
    commonCandidateCount,
    cheatingScaleFactor,
    convertModalState,
    setOptionValue,
  } = useAutoScalingState();

  useEffect(() => {
    setOptionValue(
      data.commonCandidateCount,
      data.companyScaleFactor,
      data.cheatingScaleFactor,
    );
  }, [data]);

  const onClickOptionConvert = () => {
    convertModalState(true);
  };

  const onCloseModal = () => {
    convertModalState(false);
  };

  return (
    <>
      {openOptionModal && (
        <OptionSettingModal
          title={'AutoScaling 옵션 변경'}
          onClose={onCloseModal}
          companyScaleFactor={companyScaleFactor}
          commonCandidateCount={commonCandidateCount}
          cheatingScaleFactor={cheatingScaleFactor}
          changeOption={(count, factor, cheatScaleFactor) => {
            setOptionValue(count, factor, cheatScaleFactor);
          }}
        />
      )}
      <TitleWrap>
        AutoScaling 옵션 현황
        <TextButton theme={'primary'} onClick={onClickOptionConvert}>
          옵션 변경
        </TextButton>
      </TitleWrap>
      <CardGroup>
        <Card
          title={'잡다 가상 사용자 수'}
          data={commonCandidateCount}
          suffix={'명'}
        />
        <Card
          title={'서버 한 대의 최대 가용자 수 (부정행위 X)'}
          data={companyScaleFactor}
          suffix={'명'}
        />
        <Card
          title={'서버 한 대의 최대 가용자 수 (부정행위 0)'}
          data={cheatingScaleFactor}
          suffix={'명'}
        />
      </CardGroup>
    </>
  );
};

export default AutoScaling;
