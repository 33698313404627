import { PropsWithChildren, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClientProvider } from '@tanstack/react-query';
import { Global, ThemeProvider } from '@emotion/react';
import { pmsAccessToken, pmsRefreshToken } from '@stores/atoms/shared/auth.ts';
import { KeyRepository } from '@phs/repositories';
import GlobalModal from '@components/Portal';
import AlertModal from '@components/Modals/Alert';
import ConfirmModal from '@components/Modals/Confirm';
import { localStorage } from '@helpers/storage';
import { globalStyle } from '@styles/global';
import theme from '@styles/theme';
import { createQueryClient } from '../../client';
import AxiosErrorHandler from '@components/AxiosErrorHandler';

const AppLayout = ({ children }: PropsWithChildren): JSX.Element => {
  const accessToken = useRecoilValue(pmsAccessToken);
  const refreshToken = useRecoilValue(pmsRefreshToken);

  KeyRepository.getInstance().updateAccessToken(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    JSON.parse(localStorage.get('pmsAccessToken')) ?? '',
  );

  useEffect(() => {
    const keyRepository = KeyRepository.getInstance();
    keyRepository.updateRefreshToken(refreshToken ?? '');
    keyRepository.updateAccessToken(accessToken ?? '');
  }, [accessToken, refreshToken]);

  return (
    <QueryClientProvider client={createQueryClient()}>
      <Global styles={globalStyle} />
      <ThemeProvider theme={theme}>
        <GlobalModal>
          <AlertModal />
          <ConfirmModal />
        </GlobalModal>
        <AxiosErrorHandler />
        <BrowserRouter>{children}</BrowserRouter>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default AppLayout;
