import { FC, useMemo } from 'react';
import MultipleDropDown from '@widget/dropdown-multiple';
import Search from '@components/Search';
import { Levels, Steps, Public, TotalLevel } from './config';
import { Filter, Container } from './style';
import { useFilters } from './hooks';
import LanguageFilter from './Language';
import FilterResetButton from '@components/FilterResetButton';
import { useSearchParams } from 'react-router-dom';
import { algorithmList } from '@pages/Data/config';

const Filters: FC = () => {
  const {
    handleLevelFilter,
    handlePackageLevelFilter,
    handleStepFilter,
    handlePublicType,
    handleChangeAlgorithmType,
    level,
    packageLevel,
    publicType,
    step,
    algorithmType,
  } = useFilters();

  const [searchParams, setSearchParams] = useSearchParams();
  const filterResetButtonDisabled = useMemo(() => {
    const filteredParams = [...searchParams].filter(([key, value]) => {
      switch (key) {
        case 'pageSize':
          return value !== '10';

        case 'currentPage':
          return value !== '0';

        case 'searchKeyword':
          return value !== '';

        default:
          return true;
      }
    });

    return filteredParams.length === 0;
  }, [searchParams]);

  return (
    <Container>
      <Filter>
        <MultipleDropDown
          title={'알고리즘 분류'}
          items={algorithmList}
          value={algorithmType}
          onChange={handleChangeAlgorithmType}
        />
      </Filter>
      <Filter>
        <MultipleDropDown
          title={'종합 난이도'}
          items={TotalLevel}
          onChange={handlePackageLevelFilter}
          value={packageLevel}
        />
      </Filter>
      <Filter>
        <MultipleDropDown
          title={'난이도'}
          items={Levels}
          onChange={handleLevelFilter}
          value={level}
        />
      </Filter>
      <Filter>
        <MultipleDropDown
          title={'총 단계수'}
          items={Steps}
          onChange={handleStepFilter}
          value={step}
        />
      </Filter>
      <Filter>
        <LanguageFilter />
      </Filter>
      <Filter>
        <MultipleDropDown
          title={'공개 여부'}
          items={Public}
          onChange={handlePublicType}
          value={publicType}
        />
      </Filter>
      <Filter>
        <Search />
      </Filter>
      <FilterResetButton
        onClick={() => {
          setSearchParams();
        }}
        disabled={filterResetButtonDisabled}
      />
    </Container>
  );
};

export default Filters;
