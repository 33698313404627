import { FC } from 'react';
import { RangePicker } from '@widget/calendar';
import { Nullable } from '@phs/interfaces';
import type { Dayjs } from 'dayjs';
import { RangeValue } from 'rc-picker/es/interface';

interface RangeCalendarProps {
  value?: [Nullable<Dayjs>, Nullable<Dayjs>];
  placeholder?: [string, string];
  onChange?: (
    values: RangeValue<Dayjs>,
    formatString: [string, string],
  ) => void;
}

const RangeCalendar: FC<RangeCalendarProps> = ({
  value,
  placeholder,
  onChange,
}) => {
  return (
    <RangePicker value={value} placeholder={placeholder} onChange={onChange} />
  );
};

export default RangeCalendar;
