import {
  LanguageType,
  Nullable,
  ProblemBaseType,
  ProblemCategory,
  TestCaseDTO,
} from '@phs/interfaces';

export enum MockExamEnterType {
  PREVIEW, // 미리보기
  MOCK_EXAM, // 모의 테스트
  EXPERIENCE, // 체험하기
  EXAM, // 기본 -> 실제 시험
}

export interface ProblemDefaultDTO {
  content?: string;
  title?: string;
  scoreRate?: number;
  passScore?: number;
  summaryYn?: boolean;
  summary?: string;
  judgeLanguageList: Array<{
    language: LanguageType;
    defaultSource?: string;
    expectedSource?: string;
    referenceSource?: string;
  }>;
  judgeType: ProblemCategory;
  testCaseList: TestCaseDTO[];
}

export interface ProblemPackageDTO {
  title: string;
  stepList: ProblemDefaultDTO[];
}

export interface MockProblemDTO {
  problemDefault: Nullable<ProblemDefaultDTO>;
  problemPackage: Nullable<ProblemPackageDTO>;
  problemBaseType: ProblemBaseType;
}

export interface PSTExamOptionDTO {
  titleOpenYn: boolean;
  scoreOpenYn: boolean;
  languageChangePermitYn: boolean;
  copyPastePermitYn: boolean;
  testCaseResultOpenYn: boolean;
  faceRegisterYn: boolean;
  timeLimit: number;
  resolveCommentYn: boolean;
}

export interface MockExamOptionDTO {
  title: string;
  problemCount: number;
  startDateTime: string;
  endDateTime: string;
  multipleAccessPermitYn?: boolean;
  languageList: LanguageType[];
  examOption: PSTExamOptionDTO;
}

export interface MockExamDTO {
  examEnterType: MockExamEnterType;
  token: string;
  mockProblemList: MockProblemDTO[];
  mockExamOption: MockExamOptionDTO;
}
