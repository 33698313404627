import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { GuideFileType } from '@phs/interfaces';
import {
  ButtonWrap,
  FileDownload,
  Frame,
  HorizontalDivider,
  Title,
} from './style.ts';
import { useData } from '@hooks/manage/useData.ts';
import TextButton from '@widget/button-text';
import { useRegister } from './hooks.ts';
import ErrorModal from '../Modal/ErrorModal';
import { useGuideFileUpload } from '@hooks/file/useGuideFileUpload.ts';
import { AxiosError, isAxiosError } from 'axios';
import {
  AxiosExceptionHandler,
  RuntimeExceptionHandler,
} from '@phs/exceptions';
import { PMS_EXCEPTION_MESSAGES } from '@constants/exceptions.ts';
import DeleteConfirmModal from '@pages/Manage/Document/Modal/DeleteConfirmModal';
import DataRepository from '@repositories/data.repository.ts';

interface RegisterProps {
  title: string;
  guideType: GuideFileType;
}

const MAX_LENGTH = 60;

const Register: FC<RegisterProps> = ({ title, guideType }) => {
  const data = useData(guideType);
  const { uploadFile } = useGuideFileUpload();
  const {
    isErrorModalOpen,
    setError,
    onCloseErrorModal,
    errorMessage,
    downloadFile,
    deleteModalOpen,
    setDeleteModalState,
  } = useRegister();
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const [fileUid, setFileUid] = useState<string | undefined>(undefined);
  const pdfInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!data) return;
    setFileName(data.fileName);
    setFileUid(data.fileUid);
  }, [data]);

  const onClickUpload = () => {
    if (pdfInputRef.current) {
      pdfInputRef.current.click();
    }
  };

  const onClickDelete = useCallback(async () => {
    if (fileName === undefined || fileUid === undefined) {
      setError('파일이 존재하지 않습니다.');
      return;
    }
    await DataRepository.deleteGuideFile(guideType);
    setFileUid(undefined);
    setFileName(undefined);
    setDeleteModalState(false);
  }, [fileUid, fileName]);

  const onInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
    //같은 파일도 다시 import 가능하도록
    e.currentTarget.value = '';
  };

  const getPdf = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.currentTarget.files) {
        setError('not found files');
        return;
      }
      const file = e.currentTarget.files[0];

      if (file === undefined) {
        setError('업로드 파일이 없습니다.');
        return;
      }

      const splitFileName = file.name.split('.');
      const fileExtension = splitFileName.pop();
      if (
        fileExtension === undefined ||
        fileExtension.toLowerCase() !== 'pdf'
      ) {
        e.currentTarget.value = '';
        setError('not allow extension');
        return;
      }
      await uploadFile(file, guideType, (fileUid) => {
        setFileName(file.name);
        setFileUid(fileUid);
      });
    } catch (error) {
      if (isAxiosError(error)) {
        new AxiosExceptionHandler({
          error: error as AxiosError,
          message: PMS_EXCEPTION_MESSAGES.PMS_API_ERROR,
        });
      } else {
        new RuntimeExceptionHandler({
          error,
          message: PMS_EXCEPTION_MESSAGES.PMS_FILE_UPLOAD_ERROR,
        });
      }
    }
  };

  return (
    <Frame>
      {isErrorModalOpen() && (
        <ErrorModal onClose={onCloseErrorModal} message={errorMessage || ''} />
      )}
      {deleteModalOpen && (
        <DeleteConfirmModal
          onClose={() => setDeleteModalState(false)}
          onOk={onClickDelete}
        />
      )}
      <Title>{title}</Title>
      <HorizontalDivider />
      <ButtonWrap>
        <TextButton theme={'primary'} onClick={onClickUpload}>
          {`${title} 등록`}
          <input
            style={{ display: 'none' }}
            id={`${guideType}-pdf-upload`}
            type={'file'}
            ref={pdfInputRef}
            onChange={getPdf}
            onClick={(e) => onInputClick(e)}
          />
        </TextButton>
        <TextButton onClick={() => setDeleteModalState(true)}>삭제</TextButton>
      </ButtonWrap>
      <FileDownload onClick={(_) => downloadFile(fileName, fileUid)}>
        {fileName === undefined
          ? `${title} 파일을 업로드 해주세요.`
          : fileName.length < MAX_LENGTH
          ? fileName
          : `${fileName.slice(0, MAX_LENGTH)}...`}
      </FileDownload>
    </Frame>
  );
};

export default Register;
