export const Menu: Array<{
  name: string;
  key: string;
}> = [
  {
    name: '알고리즘 가중치 관리',
    key: '알고리즘 가중치 관리',
  },
  {
    name: '단계별 가중치 관리',
    key: '단계별 가중치 관리',
  },
];

export enum Algorithm {
  MATH = 'MATH',
  IMPLEMENTATION = 'IMPLEMENTATION',
  DYNAMIC_PROGRAMMING = 'DYNAMIC_PROGRAMMING',
  DATA_STRUCTURES = 'DATA_STRUCTURES',
  GRAPHS = 'GRAPHS',
  GREEDY = 'GREEDY',
  STRING = 'STRING',
  BRUTE_FORCING = 'BRUTE_FORCING',
  GEOMETRY = 'GEOMETRY',
  AD_HOC = 'AD_HOC',
  NUMBER_THEORY = 'NUMBER_THEORY',
  TREES = 'TREES',
  SEG_TREE = 'SEG_TREE',
  BINARY_SEARCH = 'BINARY_SEARCH',
  CONSTRUCTIVE = 'CONSTRUCTIVE',
  BFS = 'BFS',
  COMBINATORICS = 'COMBINATORICS',
  CASE_WORK = 'CASE_WORK',
  DFS = 'DFS',
  BIT_MASK = 'BIT_MASK',
  DIJKSTRA = 'DIJKSTRA',
  BACK_TRACKING = 'BACK_TRACKING',
  SWEEPING = 'SWEEPING',
  DISJOINT_SET = 'DISJOINT_SET',
  DIVIDE_AND_CONQUER = 'DIVIDE_AND_CONQUER',
  TWO_POINTER = 'TWO_POINTER',
  GAME_THEORY = 'GAME_THEORY',
  FLOW = 'FLOW',
  LAZY_PROP = 'LAZY_PROP',
  PROBABILITY = 'PROBABILITY',
  EXPONENTIATION_BY_SQUARING = 'EXPONENTIATION_BY_SQUARING',
  OFFLINE_QUERIES = 'OFFLINE_QUERIES',
  COORDINATE_COMPRESSION = 'COORDINATE_COMPRESSION',
  MST = 'MST',
  BIPARTITE_MATCHING = 'BIPARTITE_MATCHING',
  CONVEX_HULL = 'CONVEX_HULL',
  LINEAR_ALGEBRA = 'LINEAR_ALGEBRA',
  TOPOLOGICAL_SORTING = 'TOPOLOGICAL_SORTING',
  FLOYD_WARSHALL = 'FLOYD_WARSHALL',
  LCA = 'LCA',
  SCC = 'SCC',
  SPARSE_TABLE = 'SPARSE_TABLE',
  TRIE = 'TRIE',
  MCMF = 'MCMF',
  LINE_INTERSECTION = 'LINE_INTERSECTION',
  SQUARE_DECOMPOSITION = 'SQUARE_DECOMPOSITION',
  SLIDING_WINDOW = 'SLIDING_WINDOW',
  MITM = 'MITM',
  HLD = 'HLD',
  EULERIAN_PATH = 'EULERIAN_PATH',
  MERGE_SORT_TREE = 'MERGE_SORT_TREE',
}

export const Convert2Algorithm: Record<keyof typeof Algorithm, string> = {
  MATH: '수학',
  IMPLEMENTATION: '구현',
  DYNAMIC_PROGRAMMING: '동적계획법',
  DATA_STRUCTURES: '자료구조',
  GRAPHS: '그래프이론',
  GREEDY: '그리디',
  STRING: '문자열',
  BRUTE_FORCING: '브루트포스',
  GEOMETRY: '기하학',
  AD_HOC: '애드혹',
  NUMBER_THEORY: '정수론',
  TREES: '트리',
  SEG_TREE: '세그먼트트리',
  BINARY_SEARCH: '이분탐색',
  CONSTRUCTIVE: '해구성하기',
  BFS: '너비우선탐색',
  COMBINATORICS: '조합론',
  CASE_WORK: '많은조건분기',
  DFS: '깊이우선탐색',
  BIT_MASK: '비트마스킹',
  DIJKSTRA: '다익스트라',
  BACK_TRACKING: '백트래킹',
  SWEEPING: '스위핑',
  DISJOINT_SET: '분리집합',
  DIVIDE_AND_CONQUER: '분할정복',
  TWO_POINTER: '두포인터',
  GAME_THEORY: '게임이론',
  FLOW: '최대유량',
  LAZY_PROP: '느리게갱신되는세그먼트트리',
  PROBABILITY: '확률론',
  EXPONENTIATION_BY_SQUARING: '분할정복을이용한거듭제곱',
  OFFLINE_QUERIES: '오프라인쿼리',
  COORDINATE_COMPRESSION: '값/좌표압축',
  MST: '최소스패닝트리',
  BIPARTITE_MATCHING: '이분매칭',
  CONVEX_HULL: '볼록껍질',
  LINEAR_ALGEBRA: '선형대수학',
  TOPOLOGICAL_SORTING: '위상정렬',
  FLOYD_WARSHALL: '플로이드워셜',
  LCA: '최소공통조상',
  SCC: '강한연결요소',
  SPARSE_TABLE: '희소배열',
  TRIE: '트라이',
  MCMF: '최소비용최대유량',
  LINE_INTERSECTION: '선분교차판정',
  SQUARE_DECOMPOSITION: '제곱근분할법',
  SLIDING_WINDOW: '슬라이딩윈도우',
  MITM: '중간에서만나기',
  HLD: 'Heavy-light분할',
  EULERIAN_PATH: '오일러경로',
  MERGE_SORT_TREE: '머지소트트리',
};

export const algorithmList: Array<{ key: Algorithm; name: string }> =
  Object.keys(Convert2Algorithm).map((key) => ({
    key: key as Algorithm,
    name: Convert2Algorithm[key as Algorithm],
  }));

export enum LEVEL_DATA {
  LEVEL_1_1 = 'LEVEL_1_1',
  LEVEL_1_2 = 'LEVEL_1_2',
  LEVEL_1_3 = 'LEVEL_1_3',
  LEVEL_1_4 = 'LEVEL_1_4',
  LEVEL_1_5 = 'LEVEL_1_5',
  LEVEL_2_1 = 'LEVEL_2_1',
  LEVEL_2_2 = 'LEVEL_2_2',
  LEVEL_2_3 = 'LEVEL_2_3',
  LEVEL_2_4 = 'LEVEL_2_4',
  LEVEL_2_5 = 'LEVEL_2_5',
  LEVEL_3_1 = 'LEVEL_3_1',
  LEVEL_3_2 = 'LEVEL_3_2',
  LEVEL_3_3 = 'LEVEL_3_3',
  LEVEL_3_4 = 'LEVEL_3_4',
  LEVEL_3_5 = 'LEVEL_3_5',
  LEVEL_4_1 = 'LEVEL_4_1',
  LEVEL_4_2 = 'LEVEL_4_2',
  LEVEL_4_3 = 'LEVEL_4_3',
  LEVEL_4_4 = 'LEVEL_4_4',
  LEVEL_4_5 = 'LEVEL_4_5',
  LEVEL_5_1 = 'LEVEL_5_1',
  LEVEL_5_2 = 'LEVEL_5_2',
  LEVEL_5_3 = 'LEVEL_5_3',
  LEVEL_5_4 = 'LEVEL_5_4',
  LEVEL_5_5 = 'LEVEL_5_5',
}

export const Convert2LevelData: Record<keyof typeof LEVEL_DATA, string> = {
  LEVEL_1_1: '1-1',
  LEVEL_1_2: '1-2',
  LEVEL_1_3: '1-3',
  LEVEL_1_4: '1-4',
  LEVEL_1_5: '1-5',
  LEVEL_2_1: '2-1',
  LEVEL_2_2: '2-2',
  LEVEL_2_3: '2-3',
  LEVEL_2_4: '2-4',
  LEVEL_2_5: '2-5',
  LEVEL_3_1: '3-1',
  LEVEL_3_2: '3-2',
  LEVEL_3_3: '3-3',
  LEVEL_3_4: '3-4',
  LEVEL_3_5: '3-5',
  LEVEL_4_1: '4-1',
  LEVEL_4_2: '4-2',
  LEVEL_4_3: '4-3',
  LEVEL_4_4: '4-4',
  LEVEL_4_5: '4-5',
  LEVEL_5_1: '5-1',
  LEVEL_5_2: '5-2',
  LEVEL_5_3: '5-3',
  LEVEL_5_4: '5-4',
  LEVEL_5_5: '5-5',
};
