import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  height: 32px;
  box-sizing: border-box;
  padding: 0 8px;
  background: rgb(241 243 245);
  border-radius: 4px;

  label {
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  span {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #adb5bd;
  }

  input {
    visibility: hidden;
  }

  :disabled {
    border: none;
    opacity: 0.4;
  }

  :hover {
    background: #adb5bd;

    span {
      color: #fff;
    }
  }
`;
