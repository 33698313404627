import { UseFormGetValues, UseFormTrigger } from 'react-hook-form';
import {
  ModifyCommandType,
  ProblemCategory,
  TestCaseDTO,
} from '@phs/interfaces';
import { CodePreview, CodeTestCase } from '@phs/code';
import { MakeProblemReqDTO } from '@interfaces/dashboard/problem.dto.ts';
import { useAlertModal } from '@components/Modals/Alert/hook.ts';
import { useAccessToken } from '@hooks/auth/useAuth.ts';
import { FormProps } from '@pages/Exam/shared/config.ts';
import { useDebouncedCallback } from '@react-hookz/web';

interface UsePreviewProps {
  trigger: UseFormTrigger<FormProps>;
  getValues: UseFormGetValues<FormProps>;
}

export function usePreview({ trigger, getValues }: UsePreviewProps) {
  const { onOpen } = useAlertModal();
  const { refresh } = useAccessToken();

  const isEmptyTestCase = (testCases: TestCaseDTO[]) => {
    return !testCases.some(
      ({ commandType }) => commandType !== ModifyCommandType.DELETE,
    );
  };

  const onPreview = useDebouncedCallback(
    async (): Promise<void> => {
      const {
        title,
        content,
        level,
        detailLevel,
        algorithmTypeList,
        scoreRateYn,
        accuracyScoreRate,
        efficiencyScoreRate,
        languages,
        exampleTestCases = [],
        accuracyTestCases = [],
        efficiencyTestCases = [],
        accuracyFileTestCases = [],
        efficiencyFileTestCases = [],
      } = getValues();

      if (title === '' || content === '' || level < 1) {
        trigger();
        return;
      }
      const usingLanguageList = languages.filter(({ usage }) => usage);
      if (usingLanguageList.length === 0) {
        onOpen('언어를 선택하지 않았습니다.');
        return;
      }

      if (isEmptyTestCase(exampleTestCases)) {
        onOpen('예시 테스트 케이스가 없습니다.');
        return;
      }

      if (
        isEmptyTestCase(accuracyTestCases) &&
        isEmptyTestCase(accuracyFileTestCases)
      ) {
        onOpen('정확성 테스트 케이스가 없습니다.');
        return;
      }

      await refresh({
        onSuccess: (accessToken) => {
          const payload: MakeProblemReqDTO = {
            title,
            content,
            level: level.toString(),
            detailLevel,
            algorithmTypeList,
            judgeType: ProblemCategory.STDIO,
            judgeLanguageList: languages.filter(({ usage }) => usage),
            testCaseList: CodeTestCase.previewTestCase(ProblemCategory.STDIO, [
              exampleTestCases,
              accuracyTestCases,
              accuracyFileTestCases,
              efficiencyTestCases,
              efficiencyFileTestCases,
            ]),
            testCaseScoreRateYn: scoreRateYn,
            accuracyScoreRate,
            efficiencyScoreRate,
            onlyProblemUpdateYn: false,
          };

          CodePreview.setTargetOrigin(
            // `http://company1.localhost:3000`,
            `https://${import.meta.env.VITE_DEFAULT_TENANT}.${
              import.meta.env.VITE_EXAM_URL
            }`,
          );
          CodePreview.openWindow(payload, accessToken);
        },
        onError: () => {
          onOpen('알 수 없는 문제가 발생하였습니다.');
        },
      });
    },
    [getValues],
    400,
  );

  return { onPreview };
}
