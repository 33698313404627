import styled from '@emotion/styled';

export const Frame = styled.div`
  background: #f8f9fa;
  box-sizing: border-box;
  border: 1px solid #ced4da;
  border-radius: 8px;
  padding: 32px 40px;
  color: #343a40;
  height: 122px;
`;

export const Title = styled.div`
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
`;

export const Data = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  span {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }
`;
