import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  margin-top: 32px;
`;

export const PaginationWrap = styled.section`
  margin-top: 12px;
`;

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const TableTitle = styled(Link)`
  display: inline-block;
  font-weight: 700;
  min-width: 356px;
  max-width: 356px;
  cursor: pointer;
`;

export const AlgorithmTags = styled.div`
  color: rgb(134, 142, 150);
  font-family: Pretendard;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
`;

export const TableCreateTime = styled.div`
  font-weight: 400;
  max-width: 120px;
`;

export const SyncWrap = styled.div<{ syncYn: boolean }>`
  svg {
    cursor: ${({ syncYn }) => (syncYn ? `not-allowed` : `pointer`)};
    opacity: ${({ syncYn }) => (syncYn ? '0.3' : '1')};
    stroke: ${({ syncYn }) => (syncYn ? `gray` : `black`)};

    &.rotate {
      transform-origin: 50% 50%;
      animation: rotate-image 1s linear infinite;
    }

    @keyframes rotate-image {
      100% {
        transform: rotate(1turn);
      }
    }
  }
`;
