import InputTable, {
  InputTableHeaderType,
  InputTableParameterProps,
} from '@widget/table-input';
import { RenderTableBody } from '@components/Table';
import { useTable } from './hooks';

interface TableProps<T = never> {
  parameters?: InputTableParameterProps[];
  headers: InputTableHeaderType[];
  data?: T[];
  renderTableBody?: RenderTableBody<T>;
}

function Table<T = never>({
  parameters = [],
  data,
  renderTableBody,
  headers,
}: TableProps<T>) {
  const { render } = useTable({ renderTableBody });

  return (
    <InputTable headers={headers} parameters={parameters} data={render(data)} />
  );
}

export default Table;
