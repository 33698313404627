import TextInput from '@widget/input-text';
import {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  useEffect,
  useRef,
} from 'react';
import { AiFillPlusSquare, AiFillMinusSquare } from 'react-icons/ai';
import { Container } from './style.ts';

interface InputProps {
  defaultValue?: string;
  eventMode?: 'add' | 'remove';
  onAdd?: () => void;
  onRemove?: () => void;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
}

const Input: FC<InputProps> = ({
  defaultValue = 'Parameter',
  eventMode = 'add',
  onAdd,
  onRemove,
  onChange,
  onBlur,
  onFocus,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = defaultValue;
    }
  }, [onBlur]);

  return (
    <Container>
      <TextInput
        defaultValue={defaultValue}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        ref={inputRef}
        {...rest}
      />
      {eventMode === 'add' ? (
        <AiFillPlusSquare
          size={40}
          style={{ cursor: 'pointer' }}
          onClick={onAdd}
        />
      ) : (
        <AiFillMinusSquare
          size={40}
          style={{ cursor: 'pointer' }}
          onClick={onRemove}
        />
      )}
    </Container>
  );
};

export default Input;
