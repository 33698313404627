import { RouteObject } from 'react-router-dom';
import { PMS_PAGES } from '@phs/constants';
import AuthenticatedLayout from '@layout/Authenticated';
import PopUpLayout from '@layout/PopUp';
import PopUpPage from '@pages/PopUp';
import PopUpEditPage from '@pages/PopUp/Edit.tsx';

const PopUpRoute: RouteObject = {
  path: PMS_PAGES.POPUP.HOME,
  element: (
    <AuthenticatedLayout>
      <PopUpLayout />
    </AuthenticatedLayout>
  ),
  children: [
    {
      index: true,
      element: <PopUpPage />,
    },
    {
      path: `${PMS_PAGES.POPUP.EDIT(':sn')}`,
      element: <PopUpEditPage />,
    },
  ],
};

export default PopUpRoute;
