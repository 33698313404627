import { useState } from 'react';

export function useOperationCandidate() {
  const [candidateCount, setCandidateCount] = useState<number>(0);
  const [dateInputValue, setDateInputValue] = useState<string | undefined>(
    undefined,
  );

  const convertCount = (count: number) => {
    setCandidateCount(count);
  };

  const convertDateValue = (date: string) => {
    setDateInputValue(date);
  };

  return {
    candidateCount,
    dateInputValue,
    convertCount,
    convertDateValue,
  };
}
