import { FC } from 'react';
import ModalWidget from '@widget/modal';
import {
  BottomWrapper,
  Container,
  ContentWrapper,
} from '@pages/Manage/Document/Modal/ErrorModal/style.ts';
import TextButton from '@widget/button-text';

interface ErrorModalProps {
  message: string;
  onClose: () => void;
}

const ErrorModal: FC<ErrorModalProps> = ({ message, onClose }) => {
  return (
    <ModalWidget>
      <Container>
        <ContentWrapper>{message}</ContentWrapper>
        <BottomWrapper>
          <TextButton theme={'primary'} onClick={onClose}>
            확인
          </TextButton>
        </BottomWrapper>
      </Container>
    </ModalWidget>
  );
};

export default ErrorModal;
