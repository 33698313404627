import Header from '@widget/nav-header';
import { Nav } from './config';
import { useEffect, useState } from 'react';
import User from './User';
import { PMS_PAGES } from '@phs/constants';
import { useLocation } from 'react-router-dom';

const GNBCursorMap: Record<string, number> = {
  [PMS_PAGES.DASHBOARD.HOME]: 0,
  [PMS_PAGES.DASHBOARD.PACKAGES]: 0,
  [PMS_PAGES.MANAGE.HOME]: 1,
  [PMS_PAGES.MANAGE.DOCUMENT]: 1,
  [PMS_PAGES.MANAGE.OPERATION]: 1,
  [PMS_PAGES.NOTICE.HOME]: 2,
  [PMS_PAGES.DATA.HOME]: 3,
};

const GNB = () => {
  const { pathname } = useLocation();
  const [cursor, setCursor] = useState<number>(-1);

  useEffect(() => {
    // eslint-disable-next-line no-prototype-builtins
    if (!GNBCursorMap.hasOwnProperty(pathname)) return setCursor(-1);
    setCursor(GNBCursorMap[pathname] as number);
  }, [pathname]);

  return <Header cursor={cursor} navItems={Nav} rightMenu={[<User />]} />;
};

export default GNB;
