import { useState } from 'react';
import { SortType } from './enum';

const nextCursor = {
	[SortType.NONE]: SortType.ASC,
	[SortType.ASC]: SortType.DESC,
	[SortType.DESC]: SortType.NONE,
};

interface UseSortProps {
	onClick?: (sortType: SortType) => void;
}

export function useSort({ onClick }: UseSortProps) {
	const [sortType, setSortType] = useState<SortType>(SortType.NONE);

	const handleClick = () => {
		onClick?.(nextCursor[sortType]);
		setSortType(nextCursor[sortType]);
	};

	return {
		sortType,
		handleClick,
	};
}
