import { useEffect } from 'react';
import { FieldErrors, UseFormClearErrors } from 'react-hook-form';
import { FormProps } from '@pages/Exam/shared/config.ts';
import { useAlertModal } from '@components/Modals/Alert/hook.ts';

interface UseErrorProps {
  errors: FieldErrors<FormProps>;
  clearErrors: UseFormClearErrors<FormProps>;
}

export function useError({ errors, clearErrors }: UseErrorProps) {
  const { onOpen } = useAlertModal();

  useEffect(() => {
    const {
      title,
      level,
      exampleTestCases,
      accuracyTestCases,
      languages: languageError,
    } = errors;

    if (accuracyTestCases) {
      onOpen(errors.accuracyTestCases?.message);
      return clearErrors('accuracyTestCases');
    }

    if (exampleTestCases) {
      onOpen(errors.exampleTestCases?.message);
      return clearErrors('exampleTestCases');
    }

    if (languageError?.message && languageError?.message?.length > 0) {
      onOpen(errors.languages?.message);
      return clearErrors('languages');
    }

    if (level) {
      onOpen('종합 난이도를 선택하지 않았습니다.');
      return clearErrors('level');
    }
    if (title) {
      onOpen('제목을 입력해주세요.');
      return clearErrors('title');
    }
  }, [errors]);
}
