import {
  Container,
  OptionGroup,
  Option,
  DisplayArea,
  SelectedValue,
} from './style';
import { DropdownItemType, useDropDown, useOutsideClick } from './hooks';
import { ClassAttributes, FC } from 'react';

export type { DropdownItemType };

export interface DropDownProps extends ClassAttributes<HTMLDivElement> {
  initialState?: string | number;
  title?: string;
  items?: DropdownItemType[];
  onClick?: (props: DropdownItemType) => void;
}

const DropDown: FC<DropDownProps> = ({
  initialState,
  title,
  items = [],
  onClick,
  ...rest
}) => {
  const { currentChoice, isOpen, setOpen, handleClick } = useDropDown({
    initialState,
    title,
    isOpen: false,
    onClick,
    items
  });

  const ref = useOutsideClick(() => {
    setOpen(false);
  });

  return (
    <Container {...rest} ref={ref}>
      <OptionGroup isOpen={isOpen}>
        {items.map(({ name, key }) => (
          <Option
            data-testid={`widget-dropdown-option-${key}`}
            key={`widget-dropdown-option-${key}`}
            onClick={() =>
              handleClick({
                name,
                key,
              })
            }
          >
            <a>{name}</a>
          </Option>
        ))}
      </OptionGroup>
      <DisplayArea onClick={() => setOpen(!isOpen)} isOpen={isOpen}>
        <SelectedValue>{currentChoice ?? title}</SelectedValue>
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M11.9998 14.8247C11.8029 14.8246 11.6082 14.7842 11.4276 14.706C11.247 14.6277 11.0844 14.5133 10.9498 14.3697L7.38477 10.6097L8.61477 9.43969L11.9998 12.9997L15.3848 9.42969L16.6148 10.5997L13.0498 14.3697C12.9151 14.5133 12.7525 14.6277 12.5719 14.706C12.3913 14.7842 12.1966 14.8246 11.9998 14.8247Z'
            fill='#868E96'
          />
        </svg>
      </DisplayArea>
    </Container>
  );
};

export default DropDown;
