import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { RangeValue } from 'rc-picker/es/interface';
import { Dayjs } from 'dayjs';
import { DATE_FORMAT } from '@phs/interfaces';
import { PMS_PAGES, PMS_URL } from '@phs/constants';
import { download, validate } from '@helpers/image.ts';
import { EditorEventType } from '@components/EditorHeader';
import { useAlertModal } from '@components/Modals/Alert/hook.ts';
import { PopUpCreationRqDTO } from '@interfaces/notice/popup.dto.ts';
import { useMutation } from '@tanstack/react-query';
import PopupRepository from '@repositories/popup.repository.ts';
import { usePopupImageUpload } from '@hooks/file/useImageUpload.ts';
import { fallback, FormProps } from './config';
import { schema } from './scheme.ts';
import { ChangeEvent } from 'react';

export function usePopUp(sn?: string) {
  const { mutate: create } = useMutation(PopupRepository.createPopUp);
  const { mutate: update } = useMutation(PopupRepository.updatePopUp);
  const { uploadImage } = usePopupImageUpload();
  const { onOpen } = useAlertModal();

  const navigation = useNavigate();
  const {
    watch,
    register,
    resetField,
    reset,
    clearErrors,
    getValues,
    setValue,
    formState: { errors },
    setError,
    handleSubmit,
  } = useForm<FormProps>({
    defaultValues: fallback,
    resolver: zodResolver(schema),
  });

  const onCancelClick = () => {
    setValue('image', null);
    resetField('imageLink');
    resetField('imageLinkOption');
    setValue('fileUid', undefined);
  };

  const onDownloadClick = async () => {
    if (!getValues('image') || !getValues('fileUid')) return;

    await download({
      url: PMS_URL.FILE_DOWNLOAD_COMMON(getValues('fileUid') as string),
      fileName: getValues('image')?.[0]?.name ?? '',
    });
  };

  const createPopUp = async (payload: PopUpCreationRqDTO) => {
    create(payload);
  };

  const updatePopUp = async (payload: PopUpCreationRqDTO) => {
    update({
      sn: sn as string,
      data: payload,
    });
  };

  const onChangeDates = (values: RangeValue<Dayjs>, _: [string, string]) => {
    if (!values) return;
    if (values.length < 2) return;
    setValue('startDateTime', values[0]);
    setValue('endDateTime', values[1]);
  };

  const onSubmit = async (value: FormProps) => {
    const payload: PopUpCreationRqDTO = {
      title: value.title,
      startDateTime: value.startDateTime!.format(
        DATE_FORMAT.YYYY_MM_DD_T_HH_mm_ss_SSS,
      ),
      endDateTime: value.endDateTime!.format(
        DATE_FORMAT.YYYY_MM_DD_T_HH_mm_ss_SSS,
      ),
      pauseYn: value.pause,
      fileUid: value.fileUid,
      viewTodayPermitYn: value.todayOption,
      imageLinkYn: value.imageLinkOption,
      imageLink: value.imageLink,
      size: {
        width: value.width,
        height: value.height,
      },
      position: {
        x: value.left,
        y: value.up,
      },
    };

    if (!value.fileUid) {
      setError('image', { message: '업로드 이미지가 필요합니다.' });
      return;
    }

    if (sn === undefined) {
      await createPopUp(payload);
      setTimeout(() => {
        return navigation(PMS_PAGES.NOTICE.HOME);
      }, 300);
    } else {
      await updatePopUp(payload);
      setTimeout(() => {
        return navigation(PMS_PAGES.NOTICE.HOME);
      }, 300);
    }
  };

  const onBack = (type: EditorEventType) => {
    if (type !== EditorEventType.CANCEL) return;

    reset();
    clearErrors();
    navigation(-1);
  };

  const onChangeInput = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;

    if (validate.greaterThen100MB(files)) {
      setError('image', { message: '이미지 용량이 10MB를 초과합니다.' });
      return;
    }
    if (!validate.acceptedFormats(files)) {
      setError('image', {
        message: 'png, jpg, jpeg 파일만 업로드 할 수 있습니다.',
      });
      return;
    }
    if (await validate.tooSmallSize(files)) {
      setError('image', { message: '이미지 크기가 200 x 200 보다 작습니다.' });
      return;
    }
    if (await validate.tooLargeSize(files)) {
      setError('image', { message: '이미지 크기가 1000 x 500 보다 큽니다.' });
      return;
    }
    setError('image', { message: '' });
    if (files === null || files.length <= 0) return;

    await uploadImage(
      files[0],
      async (fileUid: string) => {
        setValue('fileUid', fileUid);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setValue('image', [{ name: files[0].name }]);
      },
      () => onOpen('파일 업로드에 문제가 발생하였습니다.'),
    );
  };

  return {
    onCancelClick,
    onDownloadClick,
    onBack,
    onSubmit,
    onChangeInput,
    onChangeDates,
    errors,
    watch,
    handleSubmit,
    register,
    setValue,
  };
}
