import { FC } from 'react';
import { useTheme } from '@emotion/react';
import { ExamLanguageDTO } from '@phs/interfaces';
import { LanguageConfig } from '@widget/icons-language';
import TextButton from '@widget/button-text';
import { IconClose } from '@widget/icons';
import Switch from '@widget/switch';
import {
  Container,
  LanguageWrapper,
  IconWrapper,
  Content,
  LanguageName,
  LanguageSpec,
  TitleArea,
} from './style.ts';
import { useLanguageModal } from './hooks.ts';
import _ from 'lodash-es';

interface LanguageModalProps {
  currentLanguages?: ExamLanguageDTO[];
  onConfirm?: (data: ExamLanguageDTO[]) => void;
  onClose?: () => void;
}

const LanguageModal: FC<LanguageModalProps> = ({
  currentLanguages = [],
  onConfirm,
  onClose,
}) => {
  const { cache, onChangeCache, onSubmit, onCancel } = useLanguageModal({
    currentLanguages: _.cloneDeep(currentLanguages),
    onConfirm,
    onClose,
  });
  const { colors } = useTheme();

  return (
    <Container>
      <TitleArea>
        <h2>언어 선택</h2>
        <IconClose onClick={onCancel} />
      </TitleArea>
      {cache.map(({ language, compiler, usage }, idx) => (
        <LanguageWrapper key={`language-modal-${language}`}>
          <IconWrapper>{LanguageConfig[language].svg('svg', 48)}</IconWrapper>
          <Content>
            <LanguageName>{language}</LanguageName>
            <LanguageSpec>{compiler}</LanguageSpec>
          </Content>
          <Switch
            checked={usage}
            onChange={() => onChangeCache(idx, !usage)}
            color={colors.teal_400}
            style={{ marginLeft: 'auto' }}
          />
        </LanguageWrapper>
      ))}
      <TextButton
        theme={'primary'}
        style={{ marginTop: 40, width: '100%', height: '60px' }}
        type={'button'}
        onClick={onSubmit}
      >
        확인
      </TextButton>
    </Container>
  );
};

export default LanguageModal;
