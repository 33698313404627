import { Container, Title } from './style';

const TestCaseInformation = () => {
  return (
    <Container>
      <ul>
        <li>
          테스트 케이스의 크기가 큰 경우 파일 형태로 업로드할 수 있는
          기능입니다.
        </li>
        <li>
          함수형 문제의 input 파일의 경우 변수 유형에 맞춰 입력해야 하며 각
          Parameter 값을 콤마(,)로 구분하여 업로드해 주시기 바랍니다
        </li>
        <li className='none-style'>파일 내용 예시</li>
        <ul>
          <li>
            Parameter가 정수형(int)과 문자열(string)인 경우 : 115,“string”
          </li>
          <li>
            Parameter가 정수형(int)과 문자열 2차원 배열(string[][])인 경우 :
            115,[[“string_1”, “array_1”], [“string_2”, “array_2”]]
          </li>
        </ul>
        <li>
          STDIO 문제의 경우 별도의 구분 기호 없이 입력하며 공백과 줄바꿈을
          유의하여 입력해 주시기 바랍니다.
        </li>
      </ul>
      <Title>개별 업로드</Title>
      <ul>
        <li>
          테스트 케이스별로 input, output 박스를 클릭하여 txt 형식의 파일을
          개별로 업로드할 수 있습니다.
        </li>
      </ul>
      <div></div>
      <Title>파일 일괄 업로드</Title>
      <ul>
        <li>
          파일 일괄 업로드를 통해 여러 개의 테스트 케이스를 일괄로 업로드할 수
          있습니다.
        </li>
        <li>
          동일한 파일명을 하나의 테스트케이스로 인식하며 .in 확장자 파일은
          input, .out 확장자 파일은 output파일로 인식됩니다.
        </li>
        <li className='none-style'>파일 일괄 업로드 예시</li>
        <ul>
          <li>
            TC1.in, TC1.out 파일을 선택 : 1번 테스트 케이스 input은 TC1.in,
            output은 TC1.out이 txt 형식으로 변환되어 업로드
          </li>
        </ul>
      </ul>
    </Container>
  );
};

export default TestCaseInformation;
