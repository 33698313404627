import { FC } from 'react';
import { GuideFileType } from '@phs/interfaces';
import { Frame } from '@pages/Manage/Document/style.ts';
import Register from '@pages/Manage/Document/Register';

const DocumentManagement: FC = () => {
  return (
    <Frame>
      <Register title={'이용 가이드'} guideType={GuideFileType.HOW_TO} />
      <Register
        title={'리포트 해석 가이드'}
        guideType={GuideFileType.REPORT_ANAL}
      />
    </Frame>
  );
};

export default DocumentManagement;
