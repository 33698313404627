import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: max-content;
`;

export const ErrorText = styled.span`
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  color: #fa5252;
`;
