// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { RequireTitle, InputWrap } from './style.ts';
import TextInput from '@widget/input-text';
import {
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';
import { HTMLAttributes } from 'react';

interface TitleProps<T extends FieldValues>
  extends HTMLAttributes<HTMLDivElement> {
  placeholder?: string;
  register: UseFormRegister<T>;
  errors?: FieldErrors<T>;
  watch?: UseFormWatch<T>;
}

function Title<T extends FieldValues>({
  placeholder,
  register,
  errors,
  watch,
  ...rest
}: TitleProps<T>) {
  return (
    <div {...rest}>
      <RequireTitle>문제 이름</RequireTitle>
      <InputWrap>
        <TextInput
          data-testid={'exam-title-input'}
          placeholder={placeholder}
          error={errors?.title?.message}
          value={watch('title')}
          {...register('title')}
        />
        <span>{errors?.title?.message}</span>
      </InputWrap>
    </div>
  );
}

export default Title;
