export enum ModifyCommandType { // 문제 수정 커맨드 타입
  CREATE = 'CREATE', //Create
  READ = 'READ', //Read
  UPDATE = 'UPDATE', //Update
  DELETE = 'DELETE', //Delete
}

export enum ProblemBaseType {
  DEFAULT = 'DEFAULT', // 기본형
  PACKAGE = 'PACKAGE', // 추가 조건형
}

export enum ProblemType {
  PRIVATE = 'PRIVATE', // CMS
  COMMON = 'COMMON', // PMS
}

export enum ProblemCategory {
  STDIO = 'STDIO', // 일반형
  FUNCTION = 'FUNCTION', // 함수형
  PST2 = 'PST2', // 추가 조건형
}

export enum AccProgressType {
  NOT_STARTED = 'NOT_STARTED', // 미응시
  IN_PROGRESS = 'IN_PROGRESS', // 응시 중
  COMPLETED = 'COMPLETED', // 완료
  CONNECT_OVER = 'CONNECT_OVER', // 연결 끊김
}

export enum AccAnalysisType {
  FAILED = 'FAILED', // 분석 오류
  WAITING = 'WAITING', // 분석 대기
  PROGRESS = 'PROGRESS', // 분석 중
  COMPLETE = 'COMPLETE', // 분석 완료
}

export enum JobListType {
  GAME_CLIENT = 'GAME_CLIENT',
  GAME_SERVER = 'GAME_SERVER',
  APPLICATION_SW = 'APPLICATION_SW', //응용 SW 개발
  FRONT_END = 'FRONT_END', // 프론트 엔드 개발
  BACK_END = 'BACK_END', // 백엔드 개발
  ANDROID = 'ANDROID', // Android 개발
  IOS = 'IOS',
  BIG_DATA = 'BIG_DATA',
  AI_MACHINE_RUNNING = 'AI_MACHINE_RUNNING',
  DEV_OPS = 'DEV_OPS',
  CROSS_PLATFORM = 'CROSS_PLATFORM',
  VR_AR_ENGINEER = 'VR_AR_ENGINEER',
  GRAPHICS_ENGINEER = 'GRAPHICS_ENGINEER',
  SYSTEM_NETWORK = 'SYSTEM_NETWORK',
  BLOCKCHAIN = 'BLOCKCHAIN',
  SW_ENGINEER = 'SW_ENGINEER',
  EMBEDDED_HARDWARE = 'EMBEDDED_HARDWARE',
}

export const JobList2Text: Record<keyof typeof JobListType, string> = {
  GAME_CLIENT: '게임 클라이언트 개발',
  GAME_SERVER: '게임 서버 개발',
  APPLICATION_SW: '응용 SW 개발',
  FRONT_END: '프론트 엔드 개발',
  BACK_END: '백엔드 개발',
  ANDROID: 'Android 앱 개발',
  IOS: 'IOS 앱 개발',
  BIG_DATA: '빅데이터 엔지니어',
  AI_MACHINE_RUNNING: '인공지능/머신러닝',
  DEV_OPS: 'DevOps',
  CROSS_PLATFORM: '크로스플랫폼 앱 개발',
  VR_AR_ENGINEER: 'VR/AR 엔지니어',
  GRAPHICS_ENGINEER: '그래픽스 엔지니어',
  SYSTEM_NETWORK: '시스템 네트워크 엔지니어',
  BLOCKCHAIN: '블록체인 개발',
  SW_ENGINEER: 'SW 엔지니어',
  EMBEDDED_HARDWARE: '임베디드/하드웨어',
};

export enum StatusType {
  IN_PROGRESS = 'IN_PROGRESS',
  BEFORE_PROGRESS = 'BEFORE_PROGRESS',
  END = 'END',
}

export enum PaccScoreType {
  MT = 'MT',
  T = 'T',
}

export enum PublicType {
  COMPANY = 'COMPANY', //기업서비스
  JOBDA = 'JOBDA', //잡다 노출
}

export enum ExposureFrequencyType {
  VERY_HIGH = 'VERY_HIGH',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
  VERY_LOW = 'VERY_LOW',
}
