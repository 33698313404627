import { ChangeEvent, useState } from 'react';
import { AlogrithmData } from '@pages/Data/Algorithms/Table';
import DataRepository from '@repositories/data.repository.ts';
import { AlgorithmExcelDataDto } from '@interfaces/company/data.dto.ts';
import { Algorithm, Convert2Algorithm } from '@pages/Data/config.ts';
import { useAlertModal } from '@components/Modals/Alert/hook.ts';

export function useFunction(
  setData: (rsData: AlgorithmExcelDataDto, data: AlogrithmData[]) => void,
) {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { onOpen } = useAlertModal();

  const convertModalState = (state: boolean) => {
    setOpenModal(state);
  };

  const onSyncData = () => {
    convertModalState(true);
  };

  const onUploadData = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const files = e.currentTarget.files;
      if (files === null || files.length <= 0) return;
      const rs = await DataRepository.postAlgorithmData(files[0]);
      const keys = Object.keys(Algorithm);
      const tableData = keys.map((value) => {
        const category = Convert2Algorithm[value as Algorithm];
        const ratioList = rs.algorithmTagInfoMap[value as Algorithm];
        return {
          category: category,
          value: [
            [
              ratioList.level1_1,
              ratioList.level1_2,
              ratioList.level1_3,
              ratioList.level1_4,
              ratioList.level1_5,
            ],
            [
              ratioList.level2_1,
              ratioList.level2_2,
              ratioList.level2_3,
              ratioList.level2_4,
              ratioList.level2_5,
            ],
            [
              ratioList.level3_1,
              ratioList.level3_2,
              ratioList.level3_3,
              ratioList.level3_4,
              ratioList.level3_5,
            ],
            [
              ratioList.level4_1,
              ratioList.level4_2,
              ratioList.level4_3,
              ratioList.level4_4,
              ratioList.level4_5,
            ],
            [
              ratioList.level5_1,
              ratioList.level5_2,
              ratioList.level5_3,
              ratioList.level5_4,
              ratioList.level5_5,
            ],
          ],
        };
      });

      setData(rs, tableData);
      onOpen('엑셀 업로드 완료되었습니다.');
    } catch (error) {
      onOpen('엑셀 업로드 중 오류가 발생했습니다.');
    }
  };

  const onDownloadData = () => {
    const a = document.createElement('a');
    a.hidden = true;
    document.body.appendChild(a);
    const rs = DataRepository.downloadAlgorithmFile();
    rs.then((value) => {
      if (!value.data) return;
      const blob = new Blob([value.data as Blob], { type: 'octet/stream' });

      const contentDisposition = value.headers['content-disposition'] || '';
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      const originFileName =
        matches && matches[1] ? matches[1].replace(/['"]/g, '') : 'download';

      a.download = decodeURIComponent(originFileName);
      a.href = URL.createObjectURL(blob);
      a.click();
      a.remove();
    }).catch((reason) => alert(reason));
  };

  return {
    onSyncData,
    onUploadData,
    onDownloadData,
    openModal,
    convertModalState,
  };
}
