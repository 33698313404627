import { QueryCache, QueryClient } from '@tanstack/react-query';

export const createQueryClient = () =>
  new QueryClient({
    queryCache: new QueryCache({}),
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });
