import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { FC, PropsWithChildren } from 'react';
import { useSort } from './hooks';
import { SortType } from './enum';
import { IconElementWrapper, IconWrapper } from './style';

interface SortIconProps extends PropsWithChildren {
	onClick?: (sortType: SortType) => void;
}

const SortIcon: FC<SortIconProps> = ({ children, onClick }) => {
	const { sortType, handleClick } = useSort({ onClick });

	return (
		<IconWrapper>
			{children}
			<IconElementWrapper onClick={handleClick}>
				<AiFillCaretUp
					size={10}
					color={sortType === SortType.DESC ? '#099268' : undefined}
				/>
				<AiFillCaretDown
					size={10}
					color={sortType === SortType.ASC ? '#099268' : undefined}
				/>
			</IconElementWrapper>
		</IconWrapper>
	);
};

export default SortIcon;
