import { LevelExcelDataDto } from '@interfaces/company/data.dto.ts';
import { LevelData } from '@pages/Data/Level/Table';
import { ChangeEvent, useState } from 'react';
import DataRepository from '@repositories/data.repository.ts';
import { LEVEL_DATA } from '@pages/Data/config.ts';
import { useAlertModal } from '@components/Modals/Alert/hook.ts';

export function useFunction(
  setData: (rsData: LevelExcelDataDto, data: LevelData[]) => void,
) {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { onOpen } = useAlertModal();

  const convertModalState = (state: boolean) => {
    setOpenModal(state);
  };

  const onSyncData = () => {
    convertModalState(true);
  };

  const onUploadData = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const files = e.currentTarget.files;
      if (files === null || files.length <= 0) return;
      const response = await DataRepository.postLevelData(files[0]);
      const rs = response.problemWeightInfoMap;
      const tableData = [
        {
          level: '1단계',
          value: [
            [
              rs[LEVEL_DATA.LEVEL_1_1].firstStepValue,
              rs[LEVEL_DATA.LEVEL_1_2].firstStepValue,
              rs[LEVEL_DATA.LEVEL_1_3].firstStepValue,
              rs[LEVEL_DATA.LEVEL_1_4].firstStepValue,
              rs[LEVEL_DATA.LEVEL_1_5].firstStepValue,
            ],
            [
              rs[LEVEL_DATA.LEVEL_2_1].firstStepValue,
              rs[LEVEL_DATA.LEVEL_2_2].firstStepValue,
              rs[LEVEL_DATA.LEVEL_2_3].firstStepValue,
              rs[LEVEL_DATA.LEVEL_2_4].firstStepValue,
              rs[LEVEL_DATA.LEVEL_2_5].firstStepValue,
            ],
            [
              rs[LEVEL_DATA.LEVEL_3_1].firstStepValue,
              rs[LEVEL_DATA.LEVEL_3_2].firstStepValue,
              rs[LEVEL_DATA.LEVEL_3_3].firstStepValue,
              rs[LEVEL_DATA.LEVEL_3_4].firstStepValue,
              rs[LEVEL_DATA.LEVEL_3_5].firstStepValue,
            ],
            [
              rs[LEVEL_DATA.LEVEL_4_1].firstStepValue,
              rs[LEVEL_DATA.LEVEL_4_2].firstStepValue,
              rs[LEVEL_DATA.LEVEL_4_3].firstStepValue,
              rs[LEVEL_DATA.LEVEL_4_4].firstStepValue,
              rs[LEVEL_DATA.LEVEL_4_5].firstStepValue,
            ],
            [
              rs[LEVEL_DATA.LEVEL_5_1].firstStepValue,
              rs[LEVEL_DATA.LEVEL_5_2].firstStepValue,
              rs[LEVEL_DATA.LEVEL_5_3].firstStepValue,
              rs[LEVEL_DATA.LEVEL_5_4].firstStepValue,
              rs[LEVEL_DATA.LEVEL_5_5].firstStepValue,
            ],
          ],
        },
        {
          level: '2단계',
          value: [
            [
              rs[LEVEL_DATA.LEVEL_1_1].secondStepValue,
              rs[LEVEL_DATA.LEVEL_1_2].secondStepValue,
              rs[LEVEL_DATA.LEVEL_1_3].secondStepValue,
              rs[LEVEL_DATA.LEVEL_1_4].secondStepValue,
              rs[LEVEL_DATA.LEVEL_1_5].secondStepValue,
            ],
            [
              rs[LEVEL_DATA.LEVEL_2_1].secondStepValue,
              rs[LEVEL_DATA.LEVEL_2_2].secondStepValue,
              rs[LEVEL_DATA.LEVEL_2_3].secondStepValue,
              rs[LEVEL_DATA.LEVEL_2_4].secondStepValue,
              rs[LEVEL_DATA.LEVEL_2_5].secondStepValue,
            ],
            [
              rs[LEVEL_DATA.LEVEL_3_1].secondStepValue,
              rs[LEVEL_DATA.LEVEL_3_2].secondStepValue,
              rs[LEVEL_DATA.LEVEL_3_3].secondStepValue,
              rs[LEVEL_DATA.LEVEL_3_4].secondStepValue,
              rs[LEVEL_DATA.LEVEL_3_5].secondStepValue,
            ],
            [
              rs[LEVEL_DATA.LEVEL_4_1].secondStepValue,
              rs[LEVEL_DATA.LEVEL_4_2].secondStepValue,
              rs[LEVEL_DATA.LEVEL_4_3].secondStepValue,
              rs[LEVEL_DATA.LEVEL_4_4].secondStepValue,
              rs[LEVEL_DATA.LEVEL_4_5].secondStepValue,
            ],
            [
              rs[LEVEL_DATA.LEVEL_5_1].secondStepValue,
              rs[LEVEL_DATA.LEVEL_5_2].secondStepValue,
              rs[LEVEL_DATA.LEVEL_5_3].secondStepValue,
              rs[LEVEL_DATA.LEVEL_5_4].secondStepValue,
              rs[LEVEL_DATA.LEVEL_5_5].secondStepValue,
            ],
          ],
        },
        {
          level: '3단계',
          value: [
            [
              rs[LEVEL_DATA.LEVEL_1_1].thirdStepValue,
              rs[LEVEL_DATA.LEVEL_1_2].thirdStepValue,
              rs[LEVEL_DATA.LEVEL_1_3].thirdStepValue,
              rs[LEVEL_DATA.LEVEL_1_4].thirdStepValue,
              rs[LEVEL_DATA.LEVEL_1_5].thirdStepValue,
            ],
            [
              rs[LEVEL_DATA.LEVEL_2_1].thirdStepValue,
              rs[LEVEL_DATA.LEVEL_2_2].thirdStepValue,
              rs[LEVEL_DATA.LEVEL_2_3].thirdStepValue,
              rs[LEVEL_DATA.LEVEL_2_4].thirdStepValue,
              rs[LEVEL_DATA.LEVEL_2_5].thirdStepValue,
            ],
            [
              rs[LEVEL_DATA.LEVEL_3_1].thirdStepValue,
              rs[LEVEL_DATA.LEVEL_3_2].thirdStepValue,
              rs[LEVEL_DATA.LEVEL_3_3].thirdStepValue,
              rs[LEVEL_DATA.LEVEL_3_4].thirdStepValue,
              rs[LEVEL_DATA.LEVEL_3_5].thirdStepValue,
            ],
            [
              rs[LEVEL_DATA.LEVEL_4_1].thirdStepValue,
              rs[LEVEL_DATA.LEVEL_4_2].thirdStepValue,
              rs[LEVEL_DATA.LEVEL_4_3].thirdStepValue,
              rs[LEVEL_DATA.LEVEL_4_4].thirdStepValue,
              rs[LEVEL_DATA.LEVEL_4_5].thirdStepValue,
            ],
            [
              rs[LEVEL_DATA.LEVEL_5_1].thirdStepValue,
              rs[LEVEL_DATA.LEVEL_5_2].thirdStepValue,
              rs[LEVEL_DATA.LEVEL_5_3].thirdStepValue,
              rs[LEVEL_DATA.LEVEL_5_4].thirdStepValue,
              rs[LEVEL_DATA.LEVEL_5_5].thirdStepValue,
            ],
          ],
        },
        {
          level: '4단계',
          value: [
            [
              rs[LEVEL_DATA.LEVEL_1_1].fourStepValue,
              rs[LEVEL_DATA.LEVEL_1_2].fourStepValue,
              rs[LEVEL_DATA.LEVEL_1_3].fourStepValue,
              rs[LEVEL_DATA.LEVEL_1_4].fourStepValue,
              rs[LEVEL_DATA.LEVEL_1_5].fourStepValue,
            ],
            [
              rs[LEVEL_DATA.LEVEL_2_1].fourStepValue,
              rs[LEVEL_DATA.LEVEL_2_2].fourStepValue,
              rs[LEVEL_DATA.LEVEL_2_3].fourStepValue,
              rs[LEVEL_DATA.LEVEL_2_4].fourStepValue,
              rs[LEVEL_DATA.LEVEL_2_5].fourStepValue,
            ],
            [
              rs[LEVEL_DATA.LEVEL_3_1].fourStepValue,
              rs[LEVEL_DATA.LEVEL_3_2].fourStepValue,
              rs[LEVEL_DATA.LEVEL_3_3].fourStepValue,
              rs[LEVEL_DATA.LEVEL_3_4].fourStepValue,
              rs[LEVEL_DATA.LEVEL_3_5].fourStepValue,
            ],
            [
              rs[LEVEL_DATA.LEVEL_4_1].fourStepValue,
              rs[LEVEL_DATA.LEVEL_4_2].fourStepValue,
              rs[LEVEL_DATA.LEVEL_4_3].fourStepValue,
              rs[LEVEL_DATA.LEVEL_4_4].fourStepValue,
              rs[LEVEL_DATA.LEVEL_4_5].fourStepValue,
            ],
            [
              rs[LEVEL_DATA.LEVEL_5_1].fourStepValue,
              rs[LEVEL_DATA.LEVEL_5_2].fourStepValue,
              rs[LEVEL_DATA.LEVEL_5_3].fourStepValue,
              rs[LEVEL_DATA.LEVEL_5_4].fourStepValue,
              rs[LEVEL_DATA.LEVEL_5_5].fourStepValue,
            ],
          ],
        },
      ];
      setData(response, tableData);
      onOpen('엑셀 업로드 완료되었습니다.');
    } catch (error) {
      onOpen('엑셀 업로드 중 오류가 발생했습니다.');
    }
  };
  const onDownloadData = () => {
    const a = document.createElement('a');
    a.hidden = true;
    document.body.appendChild(a);
    const rs = DataRepository.downloadLevelFile();
    rs.then((value) => {
      if (!value.data) return;
      const blob = new Blob([value.data as Blob], { type: 'octet/stream' });

      const contentDisposition = value.headers['content-disposition'] || '';
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      const originFileName =
        matches && matches[1] ? matches[1].replace(/['"]/g, '') : 'download';

      a.download = decodeURIComponent(originFileName);
      a.href = URL.createObjectURL(blob);
      a.click();
      a.remove();
    }).catch((reason) => alert(reason));
  };

  return {
    onSyncData,
    onUploadData,
    onDownloadData,
    openModal,
    convertModalState,
  };
}
