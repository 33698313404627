import { FC, FocusEvent } from 'react';
import { CodeTransformer } from '@phs/code';
import Select from './Select';
import Input from './Input';
import { Container, Title, Table } from './style.ts';
import { FunctionParameterAndReturnTypeDTO } from '@phs/interfaces';
import { SelectItemProps } from '@widget/select';

interface ParametersAndReturnTypesProps {
  params: FunctionParameterAndReturnTypeDTO;
  onChangeSelect: (
    { key }: SelectItemProps,
    type: 'input' | 'output',
    idx: number,
  ) => void;
  onClick: (type: 'add' | 'remove', idx?: number) => void;
  onBlurInput: (event: FocusEvent<HTMLInputElement>, idx: number) => void;
  onFocusInput: (event: FocusEvent<HTMLInputElement>) => void;
  onClickSelectParam: (type: 'input' | 'output', idx: number) => void;
}

const ParametersAndReturnTypes: FC<ParametersAndReturnTypesProps> = ({
  params,
  onChangeSelect,
  onClick,
  onBlurInput,
  onFocusInput,
  onClickSelectParam,
}) => {
  return (
    <Container>
      <Title>매개변수&nbsp;&&nbsp;Return&nbsp;Type</Title>
      <Table>
        <thead>
          <tr>
            <th style={{ width: '25%' }}></th>
            <th style={{ width: '40%' }}>매개 변수</th>
            <th style={{ width: '35%' }}>Return</th>
          </tr>
        </thead>
        <tbody>
          {params?.paramInfo.map(({ paramType, paramName }, idx) => {
            return (
              <tr key={`${paramType}-${paramName}-${idx}`}>
                <td>
                  <Select
                    data-testid={`param-input-${idx}`}
                    defaultValue={{
                      key: `${paramType}-${idx.toString()}`,
                      name: CodeTransformer.dataType2Text[paramType],
                    }}
                    onChange={(props) => onChangeSelect(props, 'input', idx)}
                    onClick={() => {
                      onClickSelectParam('input', idx);
                    }}
                  />
                </td>
                <td>
                  <Input
                    data-testid={`param-input-text-${idx}`}
                    key={`${paramName}-${idx}-${paramType}`}
                    defaultValue={paramName}
                    eventMode={idx > 0 ? 'remove' : 'add'}
                    onAdd={() => onClick('add')}
                    onRemove={() => onClick('remove', idx)}
                    onBlur={(e) => onBlurInput(e, idx)}
                    onFocus={onFocusInput}
                  />
                </td>
                <td>
                  {idx === 0 ? (
                    <Select
                      data-testid={`param-output`}
                      defaultValue={{
                        name: CodeTransformer.dataType2Text[params.returnType],
                        key: CodeTransformer.dataType2Text[params.returnType],
                      }}
                      onChange={(props) => onChangeSelect(props, 'output', idx)}
                      onClick={() => {
                        onClickSelectParam('output', idx);
                      }}
                    />
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};

export default ParametersAndReturnTypes;
