import { DropdownItemType } from '@widget/dropdown';

export const DETAIL_LEVEL_ITEMS: DropdownItemType[] = [
	{
		name: '1',
		key: 1,
	},
	{
		name: '2',
		key: 2,
	},
	{
		name: '3',
		key: 3,
	},
	{
		name: '4',
		key: 4,
	},
	{
		name: '5',
		key: 5,
	},
];
