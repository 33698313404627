import { RecoilRoot } from 'recoil';
import AppLayout from '@layout/App';
import Routes from './routes';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

function App(): JSX.Element {
  return (
    <RecoilRoot>
      <AppLayout>
        <Routes />
      </AppLayout>
    </RecoilRoot>
  );
}

export default App;
