import { type AxiosError, isAxiosError } from 'axios';
import {
  AxiosExceptionHandler,
  RuntimeExceptionHandler,
} from '@phs/exceptions';
import { Nullable } from '@phs/interfaces';
import { _10MB, FILE_ACCEPTS } from '@pages/PopUp/ContentSetting/config.ts';
import FileRepository from '@repositories/file.repository.ts';
import { PMS_EXCEPTION_MESSAGES } from '@constants/exceptions.ts';

export const getImageParams = async (
  file: File,
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();

    reader.onload = async (e: any) => {
      const image = new Image();
      image.src = e.target.result;
      await image.decode();

      resolve({
        width: image.width,
        height: image.height,
      });
    };

    reader.readAsDataURL(file);
  });
};

export const validate = {
  isNull: (files: Nullable<FileList>) => {
    return !files || files.length <= 0;
  },
  greaterThen100MB: (files: Nullable<FileList>) => {
    if (!files || files.length <= 0) return false;
    return files[0].size > _10MB;
  },
  acceptedFormats: (files: Nullable<FileList>) => {
    if (!files || files.length <= 0) return false;
    return FILE_ACCEPTS.includes(files[0].type);
  },
  tooSmallSize: async (files: Nullable<FileList>) => {
    if (!files || files.length <= 0) return false;
    const { width, height } = await getImageParams(files[0]);

    return width < 200 || height < 200;
  },
  tooLargeSize: async (files: Nullable<FileList>) => {
    if (!files || files.length <= 0) return false;
    const { width, height } = await getImageParams(files[0]);
    return width > 1000 || height > 500;
  },
};

interface FileDownloadProps {
  url: string;
  fileName: string;
}

export const download = async ({ url, fileName }: FileDownloadProps) => {
  const a = document.createElement('a');
  a.download = fileName;
  a.hidden = true;
  document.body.appendChild(a);

  try {
    const data = await FileRepository.download(url);
    const blob = new Blob([data], { type: 'octet/stream' });
    a.href = URL.createObjectURL(blob);
    a.click();
    a.remove();

    return true;
  } catch (error: unknown) {
    if (isAxiosError(error)) {
      new AxiosExceptionHandler({
        error: error as AxiosError,
        message: PMS_EXCEPTION_MESSAGES.PMS_API_ERROR,
      });
    } else {
      new RuntimeExceptionHandler({
        error,
        message: PMS_EXCEPTION_MESSAGES.PMS_API_ERROR,
      });
    }

    return false;
  }
};
