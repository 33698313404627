import styled from '@emotion/styled';

export const Container = styled.div<{ width?: number }>`
  position: absolute;
  top: 10vh;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? width : 1200)}px;
  height: max-content;
  box-sizing: border-box;
  padding: 24px 32px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
`;

export const TopArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  min-height: 40px;
  padding-bottom: 40px;
`;

export const Title = styled.div`
  position: sticky;
  top: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  background: ${({ theme }) => theme.colors.white};

  &.border {
    border-bottom: solid 1px ${({ theme }) => theme.colors.gray_300};
  }
`;

export const CloseBtn = styled.a`
  cursor: pointer;

  svg {
    :active {
      opacity: 0.8;
    }
  }
`;

export const ContentArea = styled.div`
  min-height: 84px;
  max-height: fit-content;
`;

export const BottomArea = styled.div`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding-top: 16px;
`;
