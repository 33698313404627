import { Container } from './style.ts';
import { sequenceSortOptions, startDateSortOptions } from './config.ts';
import DropDown from '@widget/dropdown';
import { usePopUpSort } from './hooks.ts';

const PopUpSort = () => {
	const { onClick } = usePopUpSort();

	return (
		<Container>
			<DropDown title={'NO.'} items={sequenceSortOptions} onClick={onClick} />
			<DropDown
				title={'게시 상태'}
				items={startDateSortOptions}
				onClick={onClick}
			/>
		</Container>
	);
};

export default PopUpSort;
