import { SortType } from './Sort/enum';

export interface HandleTableClickProps<T = any> {
	key: string;
	type: 'item' | 'sort';
	value: T | null;
	sortType?: SortType;
}

interface UseTableProps<T = any> {
	onClick?: (props: HandleTableClickProps<T>) => void;
}

export function useTable<T = any>({ onClick }: UseTableProps) {
	const handleClick = (props: HandleTableClickProps<T>) => onClick?.(props);

	return {
		handleClick,
	};
}
