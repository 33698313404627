import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';

const spin = keyframes`
  from {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(360deg)
  }
`;

const SpinnerStyle = css`
	position: absolute;
	animation: ${spin} 1s linear infinite;
`;

export const Container = styled.svg<{ isLoading?: boolean }>`
	${({ isLoading }) => isLoading && SpinnerStyle};
`;
