export enum LanguageType {
  C = 'C',
  CSHARP = 'CSHARP',
  CPP = 'CPP',
  JAVA = 'JAVA',
  JAVASCRIPT = 'JAVASCRIPT',
  PYTHON = 'PYTHON',
  RUBY = 'RUBY',
  SCALA = 'SCALA',
  SWIFT = 'SWIFT',
  KOTLIN = 'KOTLIN',
  R = 'R',
  PYPY = 'PYPY',
  GO = 'GO',
  DEFAULT = 'DEFAULT',
}

export enum DataType {
  INT = 'INT',
  LONG = 'LONG',
  DOUBLE = 'DOUBLE',
  STRING = 'STRING',
  BOOL = 'BOOL',
  INT_ARRAY = 'INT_ARRAY',
  LONG_ARRAY = 'LONG_ARRAY',
  DOUBLE_ARRAY = 'DOUBLE_ARRAY',
  STRING_ARRAY = 'STRING_ARRAY',
  BOOL_ARRAY = 'BOOL_ARRAY',
  INT_2_ARRAY = 'INT_2_ARRAY',
  LONG_2_ARRAY = 'LONG_2_ARRAY',
  DOUBLE_2_ARRAY = 'DOUBLE_2_ARRAY',
  STRING_2_ARRAY = 'STRING_2_ARRAY',
  BOOL_2_ARRAY = 'BOOL_2_ARRAY',
}
