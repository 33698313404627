import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { useRecoilValue } from 'recoil';
import { ModifyCommandType, PhaseProblemDTO } from '@phs/interfaces';
import { CodeTestCase } from '@phs/code';
import { useAlertModal } from '@components/Modals/Alert/hook.ts';
import { packageCurrentLanguages } from '@stores/selectors/exam/package.ts';

export function usePhaseValidation(cache: PhaseProblemDTO[]) {
  const languages = useRecoilValue(packageCurrentLanguages);
  const observableCache = of(cache);
  const { onOpen } = useAlertModal();

  const validate = (next?: () => void, error?: (error: unknown) => void) =>
    observableCache
      .pipe(
        map((data) => {
          if (languages.length < 1) {
            onOpen('언어를 선택하지 않았습니다.');
            throw new Error();
          } else if (data.length === 0) {
            onOpen('단계 문제가 존재하지 않습니다.');
            throw new Error();
          } else {
            return data;
          }
        }),
        map((data) => {
          return data.map(
            (
              {
                level,
                scoreRate,
                passScore,
                content,
                summary,
                summaryYn,
                algorithmTypeList,
                detailLevel,
                ...rest
              },
              tabIdx,
            ) => {
              if (!level || level < 1) {
                onOpen(`${tabIdx + 1}단계 난이도를 선택해주세요.`);
                throw new Error();
              }

              if (!detailLevel || detailLevel < 1) {
                onOpen(`${tabIdx + 1}단계 세부 난이도를 선택해주세요.`);
                throw new Error();
              }

              if (scoreRate < 1 || scoreRate > 100) {
                onOpen(
                  `${
                    tabIdx + 1
                  }단계 배점 비율을 1이상 100 이하 값으로 입력해주세요.`,
                );
                throw new Error();
              }

              let decimal = String(scoreRate).split('.')[1];
              if (decimal && decimal.length > 1) {
                onOpen(
                  `${
                    tabIdx + 1
                  }단계 배점 비율을 소수점 첫째 자리까지 입력해주세요.`,
                );
                throw new Error();
              }

              if (passScore <= 0 || passScore > 100) {
                onOpen(
                  `${
                    tabIdx + 1
                  }단계 통과 점수를 0 초과 100 이하 값으로 입력해주세요.`,
                );
                throw new Error();
              }

              decimal = String(passScore).split('.')[1];
              if (decimal && decimal.length > 1) {
                onOpen(
                  `${
                    tabIdx + 1
                  }단계 통과 점수를 소수점 첫째 자리까지 입력해주세요.`,
                );
                throw new Error();
              }

              if (summaryYn) {
                if (!summary) {
                  onOpen(`${tabIdx + 1}단계 요약 내용을 입력해주세요.`);
                  throw new Error();
                }
                if (summary.length > 100) {
                  onOpen(
                    `${
                      tabIdx + 1
                    }단계 요약 내용은 공백 포함 100글자까지 입력가능합니다.`,
                  );
                  throw new Error();
                }
              }

              if (content.length < 1) {
                onOpen(`${tabIdx + 1}단계 문제 설명을 입력해주세요.`);
                throw new Error();
              }

              if (!algorithmTypeList || algorithmTypeList.length === 0) {
                onOpen(`${tabIdx + 1}단계 알고리즘 분류를 선택해주세요.`);
                throw new Error();
              }

              return { scoreRate, ...rest };
            },
          );
        }),
        map((data) => {
          const result = data.map(({ testCaseList }, tabIdx) => {
            const {
              exampleTestCases,
              accuracyTestCases,
              accuracyFileTestCases,
            } = CodeTestCase.splitTestCases(testCaseList);

            if (
              exampleTestCases.filter(
                ({ commandType }) => commandType !== ModifyCommandType.DELETE,
              ).length < 1
            ) {
              onOpen(`${tabIdx + 1}단계 예시 테스트 케이스를 입력해주세요.`);
              throw new Error();
            }

            if (
              accuracyTestCases.filter(
                ({ commandType }) => commandType !== ModifyCommandType.DELETE,
              ).length < 1 &&
              accuracyFileTestCases.filter(
                ({ commandType }) => commandType !== ModifyCommandType.DELETE,
              ).length < 1
            ) {
              onOpen(`${tabIdx + 1}단계 정확성 테스트 케이스를 입력해주세요.`);
              throw new Error();
            }
          });
          if (data.reduce((sum, cur) => sum + cur.scoreRate, 0) !== 100) {
            onOpen(`배점 비율 합이 100이 아닙니다.`);
            throw new Error();
          }
          return result;
        }),
      )
      .subscribe({
        next,
        error,
      });

  return {
    validate,
  };
}
