import styled from '@emotion/styled';

export const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    vertical-align: middle;
  }
`;

export const TableWrap = styled.div`
  max-height: 55vh;
  overflow: auto;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
`;
