import { FC } from 'react';
import LoginButton from '@widget/button-login';
import LoginInput from '@widget/input-login';
import { useLogin } from './hooks.tsx';
import { Container, FormWrapper, ItemWrap, Title } from './style';
import PasswordModal from '@components/Modals/Password';
import { IconLogo } from '@widget/icons';

const LoginPage: FC = () => {
  const { handleLogin, register, handleSubmit, getValues } = useLogin();

  return (
    <>
      <PasswordModal
        email={getValues('email')}
        currentPassword={getValues('password')}
      />
      <Container onSubmit={handleSubmit(handleLogin)}>
        <FormWrapper>
          <IconLogo />
          <Title>JOBDA DEV 관리자 페이지에 로그인해주세요.</Title>
          <ItemWrap>
            <LoginInput
              placeholder={'아이디'}
              type={'email'}
              register={register('email', {
                required: true,
                minLength: 4,
              })}
            />
          </ItemWrap>
          <ItemWrap>
            <LoginInput
              placeholder={'비밀번호'}
              type={'password'}
              register={register('password', {
                required: true,
              })}
            />
          </ItemWrap>
          <ItemWrap>
            <LoginButton type={'submit'}>로그인</LoginButton>
          </ItemWrap>
        </FormWrapper>
      </Container>
    </>
  );
};

export default LoginPage;
