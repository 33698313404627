import styled from '@emotion/styled';

export const Container = styled.form`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 480px;
  min-height: 208px;
  height: max-content;
  max-height: 80%;
  box-sizing: border-box;
  padding: 48px 24px 32px;
  overflow-y: hidden;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Hint = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgb(52, 58, 64);
`;

export const ButtonWrap = styled.div`
  display: flex;
  margin: 0 auto;
  gap: 16px;
  padding-top: 32px;
`;
