import { useMemo } from 'react';
import { IconUseLanguage } from '@widget/icons';
import MultipleDropDown from '@widget/dropdown-multiple';
import { useLanguageList } from '@hooks/exam/useLanguage.ts';
import { useFilters } from '../hooks';
import { getItems } from './config';

const LanguageFilter = () => {
  const data = useLanguageList();
  const { handleChangeLanguageFilter, language } = useFilters();

  const items = useMemo(() => {
    if (!data?.languageList) return [];

    return getItems(data.languageList.map(({ code }) => code));
  }, [data?.languageList]);

  return (
    <MultipleDropDown
      prefixIcon={<IconUseLanguage />}
      title={'사용 언어'}
      items={items}
      value={language}
      onChange={handleChangeLanguageFilter}
    />
  );
};

export default LanguageFilter;
