import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';
import { ReactNode } from 'react';
import { alertModalInfo } from '@stores/selectors/shared/modal.ts';
import {
  alertModalAction,
  alertModalStatus,
  alertModalText,
} from '@stores/atoms/shared/modal.ts';

export function useAlertModal() {
  const resetStatus = useResetRecoilState(alertModalStatus);
  const resetText = useResetRecoilState(alertModalText);
  const setStatus = useSetRecoilState(alertModalStatus);
  const setText = useSetRecoilState(alertModalText);
  const [action, setAction] = useRecoilState(alertModalAction);
  const info = useRecoilValue(alertModalInfo);

  const onOpenAndActionAfterClose = (children?: ReactNode, cb?: () => void) => {
    setText(children);
    setStatus(true);
    if (cb)
      setAction(() => {
        return cb;
      });
  };

  const onOpen = (children?: ReactNode) => {
    setText(children);
    setStatus(true);
  };

  const onClose = () => {
    resetStatus();
    resetText();

    if (action) {
      action();
      setAction(null);
    }
  };

  return {
    info,
    onOpen,
    onClose,
    onOpenAndActionAfterClose,
  };
}
