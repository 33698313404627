import styled from '@emotion/styled';

export const Container = styled.section`
  display: flex;
  gap: 8px;
  align-items: center;
  box-sizing: border-box;
  padding: 12px 0;
`;

export const Filter = styled.div`
  flex: 1;
`;
