import { FC } from 'react';
import { Data, Frame, Title } from './style.ts';

interface CardProps {
  title?: string;
  data: number;
  suffix: string;
}

const Card: FC<CardProps> = ({ title, data, suffix }) => {
  return (
    <Frame>
      {title && <Title>{title}</Title>}
      <Data>
        {data}
        <span>{suffix}</span>
      </Data>
    </Frame>
  );
};

export default Card;
