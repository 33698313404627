import { useMemo, useState, useRef, useEffect } from 'react';

export type SelectItemProps = {
  name: string | number;
  key: string;
};

type UseSelectProps = {
  item?: SelectItemProps;
  isOpen?: boolean;
  onClick?: (props: SelectItemProps) => void;
};

const fallback = {
  item: { name: 'None', key: 'None' },
  isOpen: false,
  onClick: undefined,
};

export function useSelect(initialState: UseSelectProps) {
  const [currentItem, setCurrentItem] = useState<string | number>(
    initialState?.item?.name ?? fallback.item.name,
  );
  const [isActive, setIsActive] = useState<boolean>(
    initialState.isOpen as boolean,
  );

  useEffect(() => {
    if (initialState.item) setCurrentItem(initialState.item.name);
  }, [initialState.item]);

  const activeItem = useMemo(() => currentItem, [currentItem]);
  const isOpen = useMemo(() => isActive, [isActive]);

  const handleClick = (props: SelectItemProps) => {
    setCurrentItem(props.name as string);
    initialState?.onClick?.(props);
    setIsActive(false);
  };

  return { activeItem, isOpen, setOpen: setIsActive, handleClick };
}

export function useOutsideClick(callback: () => void) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [callback]);

  return ref;
}
