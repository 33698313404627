import { injectable } from 'inversify';
import {
	ExamParameterDTO,
	ReturnTypeDTO,
	ReturnTypeValueDTO,
} from '../interfaces/language.dto';

@injectable()
export abstract class Language {
	protected setReturnType(
		type: string = '',
		paramType: string = '',
		value: string = '',
	): ReturnTypeDTO {
		return {
			type,
			paramType,
			value,
		};
	}

	public abstract get defaultTimeLimit(): number;

	public abstract get returnType(): ReturnTypeValueDTO;

	public abstract getStdioTemplate(): string;

	public abstract getFunctionTemplate(parameters: ExamParameterDTO): string;

	public abstract loadFunctionCode(parameters: ExamParameterDTO): string;
}
