import MultipleDropDown from '@widget/dropdown-multiple';
import { Container } from './style.ts';
import { Category } from './config.ts';
import { usePopUpFilter } from './hooks.ts';

const PopUpFilter = () => {
  const { onChange, popUpStatus } = usePopUpFilter();

  return (
    <Container>
      <MultipleDropDown
        title={'게시 상태'}
        items={Category}
        onChange={onChange}
        value={popUpStatus}
      />
    </Container>
  );
};

export default PopUpFilter;
