import { stateKeys } from '@constants/key';
import {
  algorithmType,
  language,
  level,
  packageLevel,
  publicType,
  step,
} from '@stores/atoms/dashboard/exam.filter';
import { currentPage, pageSize } from '@stores/atoms/shared/pagination.ts';
import { selector } from 'recoil';
import { searchKeyword } from '@stores/atoms/shared/keyword.ts';

// 단일 풀이형 문제 쿼리
export const pstPackagePaginationQuery = selector({
  key: stateKeys.filter.pstPackagePaginationQuery,
  get: ({ get }) => {
    const _level = get(level);
    const _step = get(step);
    const _packageLevel = get(packageLevel);
    const _language = get(language);
    const _algorithmType = get(algorithmType);
    const _publicType = get(publicType);
    const _searchKeyword = get(searchKeyword);
    const _currentPage = get(currentPage);
    const _pageSize = get(pageSize);

    let query = '';
    query += '?';
    query += `levelSet=${_level}`;
    query += `&packageLevelSet=${_packageLevel}`;
    query += `&languageSet=${_language}`;
    query += `&publicTypeSet=${_publicType}`;
    query += `&algorithmTypeSet=${_algorithmType}`;
    query += `&totalStepSet=${_step}`;
    query += `&keyword=${_searchKeyword}`;
    query += `&pageIndex=${_currentPage}`;
    query += `&pageSize=${_pageSize}`;

    return query;
  },
});
