import { forwardRef } from 'react';
import type {
  DetailedHTMLProps,
  ForwardedRef,
  InputHTMLAttributes,
  ReactNode,
} from 'react';
import {
  PrimaryContainer,
  SecondaryContainer,
  TertiaryContainer,
  FileInput,
  NoneContainer,
} from './style';

interface UploadButtonProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  children?: ReactNode;
  theme?: 'primary' | 'secondary' | 'tertiary' | 'none';
}

const UploadButton = (
  { id, style, children, theme = 'primary', ...rest }: UploadButtonProps,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  switch (theme) {
    case 'primary':
      return (
        <PrimaryContainer htmlFor={id} style={style}>
          {children}
          <FileInput
            {...rest}
            id={id}
            type='file'
            ref={ref}
            onClick={(e) => {
              rest.onClick?.(e);
              e.currentTarget.value = '';
            }}
          />
        </PrimaryContainer>
      );

    case 'secondary':
      return (
        <SecondaryContainer htmlFor={id} style={style}>
          {children}
          <FileInput
            {...rest}
            id={id}
            type='file'
            ref={ref}
            onClick={(e) => {
              rest.onClick?.(e);
              e.currentTarget.value = '';
            }}
          />
        </SecondaryContainer>
      );

    case 'tertiary':
      return (
        <TertiaryContainer htmlFor={id} style={style}>
          {children}
          <FileInput
            {...rest}
            id={id}
            type='file'
            ref={ref}
            onClick={(e) => {
              rest.onClick?.(e);
              e.currentTarget.value = '';
            }}
          />
        </TertiaryContainer>
      );

    default:
      return (
        <NoneContainer htmlFor={id} style={style}>
          {children}
          <FileInput
            {...rest}
            id={id}
            type='file'
            ref={ref}
            onClick={(e) => {
              rest.onClick?.(e);
              e.currentTarget.value = '';
            }}
          />
        </NoneContainer>
      );
  }
};

export default forwardRef<HTMLInputElement, UploadButtonProps>(UploadButton);
