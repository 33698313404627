const PMS_URL = {
  LOGIN: '/login',
  LOGOUT: '/logout',
  LOGIN_EXISTS: '/login/exists',
  LOGIN_PREVIEW: (sn: number) => `/mock-exam/${sn}/candidate`,
  FORCE_PASSWORD_CHANGE: '/supervisor/password/essential',
  ISSUE_ACCESS_TOKEN: '/login/access-token',
  LANGUAGE_LIST: '/language/list',
  PROBLEM_LIST: (type: string) => `/pst/problem/${type}/list`,
  COPY_PROBLEM: (type: string) => `/pst/problem/${type}/copy`,
  SYNC_PROBLEM: (sn: string, type: string) => `/pst/problem/${sn}/${type}/sync`,
  CONVERT_PUBLIC_PROBLEM: (sn: string) => `/pst/problem/${sn}/public`,
  BRING_PROBLEM_LIST: (type: string) => `/pst/problem/cms/${type}/copy`,
  CREATE_PROBLEM: `/pst/problem/default`,
  UPDATE_PROBLEM: (sn: string) => `/pst/problem/${sn}/default`,
  READ_PROBLEM: (sn: string) => `/pst/problem/${sn}/default`,
  CREATE_PACKAGE_PROBLEM: `/pst/problem/package`,
  UPDATE_PACKAGE_PROBLEM: (sn: string) => `/pst/problem/${sn}/package`,
  READ_PACKAGE_PROBLEM: (sn: string) => `/pst/problem/${sn}/package`,
  DELETE_PROBLEM: `/pst/problem`,
  READ_TESTCASES: (sn: string) => `/pst/problem/${sn}/default/test-case/list`,
  READ_PACKAGE_TESTCASES: (sn: string) =>
    `/pst/problem/${sn}/package/test-case/list`,
  COMPANY_LIST: '/company/list',
  COMMON_SCHEME_SYNC: '/sync/common/database',
  COMMON_SCHEDULER_SYNC: '/sync/scheduler/database',
  COMMON_DATABASE_SYNC: '/sync/database/all',
  DATABASE_SYNC: '/sync/tenant/database',
  GUIDE_FILE_INFO: '/file/guide',
  GUIDE_FILE_DOWNLOAD: (fileUid: string) => `/file/${fileUid}/download`,
  GUIDE_DELETE: '/file/guide',
  GUIDE_UPLOAD: '/file/guide',
  SCALE_OPTION: '/scale/option',
  CONVERT_SCALE_OPTION: '/scale/option',
  CURRENT_CANDIDATE_COUNT: '/scale/candidate/pst/count',
  POPUP_LIST: '/notice/popup/list',
  CREATE_POPUP: '/notice/popup',
  READ_POPUP: (sn: string) => `/notice/popup/${sn}`,
  UPDATE_POPUP: (sn: string) => `/notice/popup/${sn}`,
  DELETE_POPUP: `/notice/popup`,
  FILE_VIEW_BY_ID: (fileUid: string) => `/file/${fileUid}/view`,
  FILE_UPLOAD: '/file/problem/parameter',
  FILE_UPLOAD_PROBLEM_IMAGE: '/file/problem/image',
  FILE_DOWNLOAD_COMMON: (fileUid: string) => `/file/common/${fileUid}/download`,
  TESTCASE_FILE_DOWNLOAD: (fileUid: string) => `/file/${fileUid}/download`,
  TESTCASE_FILE_BULK_DOWNLOAD: `/file/zip/download`,
  POPUP_IMAGE_UPLOAD: '/file/notice/popup/image',
  CHECK_PROBLEM_HISTORY: (sn: string) => `/pst/problem/${sn}/exam-history`,
  LEVEL_EXCEL_DATA: `/pst/problem/level/weight`,
  LEVEL_EXCEL_DATA_DOWNLOAD: `/pst/problem/level/weight/template`,
  LEVEL_EXCEL_DATA_SAVE: `/pst/problem/level/weight/save`,
  ALGORITHM_EXCEL_DATA: `/pst/problem/algorithm/tag/excel`,
  ALGORITHM_EXCEL_DATA_DOWNLOAD: `/pst/problem/algorithm/tag/excel/template`,
  ALGORITHM_EXCEL_DATA_SAVE: `/pst/problem/algorithm/tag/excel/save`,
};

export { PMS_URL };
