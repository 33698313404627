import { useRecoilState } from 'recoil';
import { popUpStatus } from '@stores/atoms/notice/popup.filter.ts';
import { PopUpStatus } from '@interfaces/notice/popup.enum.ts';
import { useEffect } from 'react';

export function usePopUpFilter() {
  const [popUpStatusState, setPopUpStatus] = useRecoilState(popUpStatus);

  useEffect(() => {
    setPopUpStatus([]);
  }, []);

  const onChange = (value: string[]) => {
    setPopUpStatus(value as PopUpStatus[]);
  };

  return {
    onChange,
    popUpStatus: popUpStatusState,
  };
}
