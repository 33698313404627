import { FC } from 'react';
import {
  FunctionParameterAndReturnTypeDTO,
  PhaseProblemSourceDTO,
  TestCaseDTO,
  TestCaseType,
} from '@phs/interfaces';
import Table, { RenderTableBody } from '@components/Table';
import TextButton from '@widget/button-text';
import EfficiencyTestCaseModal from '../Modals/EfficiencyTestCase';
import HighCapacityTestCaseModal from '../Modals/HighCapacityTestCase';
import { ButtonWrapper, Container, Title, TitleWrap } from './style';
import { useEfficiencyCase } from './hooks';
import { HEADERS } from './config';

interface EfficiencyCaseProps {
  params: FunctionParameterAndReturnTypeDTO;
  disabled?: boolean;
  languages?: PhaseProblemSourceDTO[];
  testCases?: TestCaseDTO[];
  fileTestCases?: TestCaseDTO[];
  handleTestCase?: (
    type: TestCaseType,
    data: TestCaseDTO[],
    isFile?: boolean,
  ) => void;
}

const EfficiencyCase: FC<EfficiencyCaseProps> = ({
  params,
  disabled = false,
  languages = [],
  testCases = [],
  fileTestCases = [],
  handleTestCase,
}) => {
  const {
    isModalOpened,
    isFileModalOpened,
    handleOpen,
    renderData,
    renderFileData,
    onSubmit,
    downloadAllFiles,
    onFileSubmit,
    onClose,
  } = useEfficiencyCase({
    disabled,
    handleTestCase,
  });

  return (
    <Container>
      {isModalOpened && (
        <EfficiencyTestCaseModal
          params={params}
          testCaseType={TestCaseType.EFFICIENCY}
          languages={languages}
          testCases={testCases}
          onSubmit={onSubmit}
          onClose={onClose}
        />
      )}
      {isFileModalOpened && (
        <HighCapacityTestCaseModal
          params={params}
          testCaseType={TestCaseType.EFFICIENCY}
          languages={languages}
          testCases={fileTestCases}
          onSubmit={onFileSubmit}
          onClose={onClose}
        />
      )}
      <TitleWrap>
        <Title>효율성 테스트 케이스</Title>
        <ButtonWrapper>
          <TextButton
            onClick={() => downloadAllFiles(fileTestCases)}
            style={{
              minWidth: 120,
              height: 52,
            }}
          >
            테스트 케이스 파일 다운로드
          </TextButton>
          <TextButton
            onClick={() => handleOpen('file', true)}
            style={{
              minWidth: 120,
              height: 52,
            }}
            data-testid='efficiency-file-testcase'
          >
            대용량 테스트 케이스 추가
          </TextButton>
          <TextButton
            onClick={() => handleOpen('testcase', true)}
            style={{
              minWidth: 120,
              height: 52,
            }}
            data-testid='efficiency-testcase'
          >
            테스트 케이스 추가 및 수정
          </TextButton>
        </ButtonWrapper>
      </TitleWrap>
      <Table<TestCaseDTO>
        headers={HEADERS}
        data={testCases}
        renderTableBody={renderData as RenderTableBody<TestCaseDTO>}
        fixLayout
        data-testid='package-efficiency-testcase'
      />
      {fileTestCases.length > 0 && (
        <Table<TestCaseDTO>
          headers={HEADERS}
          data={fileTestCases}
          renderTableBody={renderFileData as RenderTableBody<TestCaseDTO>}
          fixLayout
          data-testid='package-efficiency-file-testcase'
        />
      )}
    </Container>
  );
};

export default EfficiencyCase;
