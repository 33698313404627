import styled from '@emotion/styled';

export const Container = styled.form`
  position: relative;
  width: 100vw;
  height: 100vh;
`;

export const EditorContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  box-sizing: border-box;
  max-width: 1398px;
  height: max-content;
  padding: 40px 40px 120px;
  margin: auto;
`;
