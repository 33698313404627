import styled from '@emotion/styled';

export const RequireTitle = styled.span`
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray_900};

  &::after {
    display: inline-block;
    margin-left: 4px;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.red_600};
    vertical-align: text-bottom;
    content: '*';
  }
`;
