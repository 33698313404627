import * as z from 'zod';
import { type Dayjs } from 'dayjs';

const customErrorMap: z.ZodErrorMap = (error, ctx) => {
  /*
  This is where you override the various error codes
  */
  switch (error.code) {
    case z.ZodIssueCode.too_small:
      return {
        message: `최소 픽셀은 ${error.minimum}입니다.`,
      };
    case z.ZodIssueCode.too_big:
      return {
        message: `최대 픽셀은 ${error.maximum}입니다.`,
      };
    default:
      // fall back to default message!
      return { message: ctx.defaultError };
  }
};

export const schema = z.object({
  title: z.string().min(1, '제목 (관리용)을 입력해주세요.'),
  startDateTime: z.any().refine((dayjs: Dayjs) => {
    return dayjs.isValid();
  }),
  endDateTime: z.any().refine((dayjs: Dayjs) => {
    return dayjs.isValid();
  }),
  todayOption: z.boolean().optional(),
  pause: z.boolean().optional(),
  imageLink: z.string().nullable().optional(),
  imageLinkOption: z.boolean(),
  fileUid: z.string().optional(),
  width: z.number({ errorMap: customErrorMap }).min(200).max(1000).positive(),
  height: z.number({ errorMap: customErrorMap }).min(200).max(500).positive(),
  left: z.number({ errorMap: customErrorMap }).min(0).max(5000),
  up: z.number({ errorMap: customErrorMap }).min(0).max(5000),
});
