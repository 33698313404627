import { BaseRepository } from '@phs/repositories';
import {
  PopUpCreationRqDTO,
  PopUpListRsDTO,
  PopUpRsDTO,
} from '@interfaces/notice/popup.dto.ts';
import { PMS_URL } from '@phs/constants';

class PopUpRepository extends BaseRepository {
  constructor(baseURL: string) {
    super(baseURL);
  }

  public async createPopUp(data: PopUpCreationRqDTO) {
    const { data: response } = await super.post(
      `${PMS_URL.CREATE_POPUP}`,
      data,
    );

    return response;
  }

  public async updatePopUp({
    sn,
    data,
  }: {
    sn: string;
    data: PopUpCreationRqDTO;
  }) {
    const { data: response } = await super.put(
      `${PMS_URL.UPDATE_POPUP(sn)}`,
      data,
    );

    return response;
  }

  public async readPopUp(sn: string) {
    const { data: response } = await super.get<PopUpRsDTO>(
      PMS_URL.READ_POPUP(sn),
    );

    return response;
  }

  public async readPopUpList(query: Record<string, any>) {
    const { data: response } = await super.get<PopUpListRsDTO>(
      `${PMS_URL.POPUP_LIST}`,
      {
        params: query,
      },
    );

    return response;
  }

  public async deletePopUpBulk(noticePopupSnList: number[]) {
    const { data: response } = await super.delete(PMS_URL.DELETE_POPUP, {
      params: { noticePopupSnList },
    });

    return response;
  }
}

const popUpRepository = new PopUpRepository(import.meta.env.VITE_API_URL);
export default popUpRepository;
