export const resizeOptions = {
  minHeight: 345,
  boundsByDirection: true,
  defaultSize: {
    width: '100%',
    height: '1000px',
  },
  enable: {
    top: false,
    right: false,
    bottom: true,
    left: false,
    topRight: false,
    bottomRight: false,
    bottomLeft: false,
    topLeft: false,
  },
};
