import { useEffect, useState } from 'react';
import { AlgorithmExcelDataDto } from '@interfaces/company/data.dto.ts';
import DataRepository from '@repositories/data.repository.ts';
import { AlogrithmData } from '@pages/Data/Algorithms/Table';
import { Algorithm, Convert2Algorithm } from '@pages/Data/config.ts';
import { useAlertModal } from '@components/Modals/Alert/hook.ts';

export function useAlgorithm() {
  const [rsData, setRsData] = useState<AlgorithmExcelDataDto | null>(null);
  const [tableData, setTableData] = useState<AlogrithmData[]>([]);
  const { onOpen } = useAlertModal();

  useEffect(() => {
    (async () => {
      try {
        const rs = await DataRepository.postAlgorithmData();
        setRsData(rs);

        const keys = Object.keys(Algorithm);
        setTableData(
          keys.map((value) => {
            const category = Convert2Algorithm[value as Algorithm];
            const ratioList = rs.algorithmTagInfoMap[value as Algorithm];
            return {
              category: category,
              value: [
                [
                  ratioList.level1_1,
                  ratioList.level1_2,
                  ratioList.level1_3,
                  ratioList.level1_4,
                  ratioList.level1_5,
                ],
                [
                  ratioList.level2_1,
                  ratioList.level2_2,
                  ratioList.level2_3,
                  ratioList.level2_4,
                  ratioList.level2_5,
                ],
                [
                  ratioList.level3_1,
                  ratioList.level3_2,
                  ratioList.level3_3,
                  ratioList.level3_4,
                  ratioList.level3_5,
                ],
                [
                  ratioList.level4_1,
                  ratioList.level4_2,
                  ratioList.level4_3,
                  ratioList.level4_4,
                  ratioList.level4_5,
                ],
                [
                  ratioList.level5_1,
                  ratioList.level5_2,
                  ratioList.level5_3,
                  ratioList.level5_4,
                  ratioList.level5_5,
                ],
              ],
            };
          }),
        );
      } catch (error) {
        onOpen('데이터 불러오는 중 오류가 발생했습니다.');
      }
    })();
  }, []);

  const setDataByExcel = (
    rsData: AlgorithmExcelDataDto,
    data: AlogrithmData[],
  ) => {
    setRsData(rsData);
    setTableData(data);
  };

  return {
    rsData,
    tableData,
    setDataByExcel,
  };
}
