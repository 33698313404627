import {
  algorithmType,
  language,
  level,
  packageLevel,
  publicType,
  step,
} from '@stores/atoms/dashboard/exam.filter';
import { useResetRecoilState, useRecoilState } from 'recoil';
import { usePagination } from '@components/Pagination/hooks';
import { searchKeyword } from '@stores/atoms/shared/keyword';

export function useFilters() {
  const { resetPage } = usePagination();

  const [levelState, setLevel] = useRecoilState(level);
  const resetLevel = useResetRecoilState(level);

  const [packageLevelState, setPackageLevel] = useRecoilState(packageLevel);
  const resetPackageLevel = useResetRecoilState(packageLevel);

  const [stepState, setStep] = useRecoilState(step);
  const resetStep = useResetRecoilState(step);

  const [languageState, setLanguage] = useRecoilState(language);
  const resetLanguage = useResetRecoilState(language);

  const [publicTypeState, setPublicType] = useRecoilState(publicType);
  const resetPublicType = useResetRecoilState(publicType);

  const [algorithmTypeState, setAlgorithmType] = useRecoilState(algorithmType);
  const resetAlgorithmType = useResetRecoilState(algorithmType);

  const resetSearchKeyword = useResetRecoilState(searchKeyword);

  const resetFilter = () => {
    resetLevel();
    resetLanguage();
    resetPublicType();
    resetSearchKeyword();
    resetPackageLevel();
    resetStep();
    resetAlgorithmType();
  };

  const handleLevelFilter = (value: string[]) => {
    setLevel(value);
    resetPage();
  };

  const handleLanguageFilter = (value: string[]) => {
    setLanguage(value);
    resetPage();
  };

  const handlePublicType = (value: string[]) => {
    setPublicType(value);
    resetPage();
  };

  const handlePackageLevelFilter = (value: string[]) => {
    setPackageLevel(value);
    resetPage();
  };

  const handleStepFilter = (value: string[]) => {
    setStep(value);
    resetPage();
  };

  const handleChangeAlgorithmType = (value: string[]) => {
    setAlgorithmType(value);
    resetPage();
  };

  return {
    resetFilter,
    handleLevelFilter,
    handlePackageLevelFilter,
    handleStepFilter,
    handleLanguageFilter,
    handlePublicType,
    handleChangeAlgorithmType,
    level: levelState,
    packageLevel: packageLevelState,
    step: stepState,
    language: languageState,
    publicType: publicTypeState,
    algorithmType: algorithmTypeState,
  };
}
