import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { PMS_EXCEPTION_MESSAGES } from '@constants/exceptions.ts';
import { RuntimeExceptionHandler } from '@phs/exceptions';
import {
  FunctionParameterAndReturnTypeDTO,
  ModifyCommandType,
  PhaseProblemSourceDTO,
  TestCaseDTO,
  TestCaseLanguageDTO,
  TestCaseType,
} from '@phs/interfaces';
import { CodeTestCase, CodeTransformer } from '@phs/code';
import { InputTableHeaderType, Removable, Input } from '@widget/table-input';
import { IconModify } from '@widget/icons';
import { LanguageConfig } from '@widget/icons-language';
import InputNumber from '@widget/input-number';
import { InputContainer, LanguageContainer, LanguageName } from './style.ts';
import { useValidation } from '@pages/Exam/function/shared/useValidation.ts';
import _ from 'lodash-es';

interface UseTestCaseProps {
  params: FunctionParameterAndReturnTypeDTO;
  languages?: PhaseProblemSourceDTO[];
  testCaseType?: TestCaseType;
  testCases?: TestCaseDTO[];
  onSubmit?: (data: TestCaseDTO[]) => void;
  onClose?: () => void;
}

export function useTestCase({
  params,
  testCaseType = TestCaseType.EXAMPLE,
  languages = [],
  testCases = [],
  onSubmit,
  onClose,
}: UseTestCaseProps) {
  const [cache, setCache] = useState<TestCaseDTO[]>(testCases);
  const [timeLimitCache, setTimeLimitCache] = useState<
    Pick<TestCaseLanguageDTO, 'language' | 'timeLimit'>[]
  >([]);
  const [isTimeLimitModalOpen, setIsTimeLimitModalOpen] =
    useState<boolean>(false);
  // -1은 전체 time limit 변경
  const [testCaseIndex, setTestCaseIndex] = useState<number>(-1);
  const { validate } = useValidation(cache);

  useEffect(() => {
    if (testCases.length === 0) {
      const defaultCache = [
        CodeTestCase.createTestCase({
          input: params.paramInfo.map(({ paramName, paramType }) => ({
            parameterType: paramType,
            parameterName: paramName,
            value: '',
          })),
          output: {
            parameterName: 'return',
            parameterType: params.returnType,
            value: '',
          },
          number: 1,
          testCaseType,
          testCaseLanguageSet: CodeTestCase.createLanguageSet(languages) ?? [],
        }),
      ];
      setCache(defaultCache);
      return;
    }
    setCache(_.cloneDeep(testCases));
  }, [testCases]);

  const selectedLanguages = useMemo(
    () => languages.filter(({ usage }) => usage),
    [languages],
  );

  const onSubmitTimeLimit = () => {
    const newCache = [...cache];

    if (testCaseIndex === -1) {
      newCache.forEach((testCase) => {
        testCase.testCaseLanguageSet.forEach((languageSet) => {
          languageSet.timeLimit =
            timeLimitCache.find(
              (timeLimit) => timeLimit.language === languageSet.language,
            )?.timeLimit ?? 0;
          if (languageSet.commandType === ModifyCommandType.READ) {
            languageSet.commandType = ModifyCommandType.UPDATE;
          }
        });
        if (testCase.commandType === ModifyCommandType.READ) {
          testCase.commandType = ModifyCommandType.UPDATE;
        }
      });
    } else {
      const targetTestCase = newCache[testCaseIndex];
      targetTestCase.testCaseLanguageSet.forEach((testCase) => {
        testCase.timeLimit =
          timeLimitCache.find(
            (timeLimit) => timeLimit.language === testCase.language,
          )?.timeLimit ?? 0;
        if (testCase.commandType === ModifyCommandType.READ) {
          testCase.commandType = ModifyCommandType.UPDATE;
        }
      });
      if (targetTestCase.commandType === ModifyCommandType.READ) {
        targetTestCase.commandType = ModifyCommandType.UPDATE;
      }
    }

    setCache(newCache);
    onCloseTimeLimitModal();
  };

  const renderTimeLimitData = (data: PhaseProblemSourceDTO[]) => {
    return data.map((language, idx) => {
      return [
        <LanguageContainer>
          {LanguageConfig[language.language].svg('svg', 48)}
          <LanguageName>{language.language}</LanguageName>
        </LanguageContainer>,
        <InputContainer>
          <InputNumber
            type='number'
            min='0'
            value={
              timeLimitCache.find(
                (value) => value.language === language.language,
              )?.timeLimit
            }
            onChange={({ target: { value } }) => {
              setTimeLimitCache((prev) => {
                const newCache = [...prev];
                const findValue = newCache.find(
                  (value) => value.language === language.language,
                );
                if (findValue) findValue.timeLimit = Number(value);
                return newCache;
              });
            }}
            data-testid={`time-limit-input-${idx}`}
          />
          ms
        </InputContainer>,
      ];
    });
  };

  const addTestCase = () => {
    setCache((prev) => {
      return [
        ...prev,
        CodeTestCase.createTestCase({
          input: params.paramInfo.map(({ paramName, paramType }) => ({
            parameterType: paramType,
            parameterName: paramName,
            value: '',
          })),
          output: {
            parameterName: 'return',
            parameterType: params.returnType,
            value: '',
          },
          number: cache.length,
          testCaseType,
          testCaseLanguageSet: CodeTestCase.createLanguageSet(languages) ?? [],
        }),
      ];
    });
  };

  const updateTestCase = (idx: number, value: Partial<TestCaseDTO>) => {
    const newCache = cache.slice();
    newCache[idx] = CodeTestCase.updateTestCase(cache[idx], value);
    return newCache;
  };

  const deleteTestCase = (idx: number) => {
    const targetTestCase = cache[idx];

    if (targetTestCase.sn) {
      setCache((prev) => {
        return prev.map((testCase, i) => ({
          ...testCase,
          number: i + 1,
          commandType:
            idx === i ? ModifyCommandType.DELETE : testCase.commandType,
        }));
      });
    } else {
      setCache((prev) => {
        return prev
          .filter((_, i) => idx !== i)
          .map((testCase, i) => ({
            ...testCase,
            number: i + 1,
          }));
      });
    }
  };

  const onChangeInput = (
    event: ChangeEvent<HTMLInputElement>,
    rowIdx: number,
    columnIdx: number,
  ) => {
    const { input: inputValue } = cache[rowIdx];

    setCache(
      updateTestCase(rowIdx, {
        input: [
          ...inputValue.slice(0, columnIdx),
          {
            ...inputValue[columnIdx],
            value: event.currentTarget.value,
          },
          ...inputValue.slice(columnIdx + 1),
        ],
      }),
    );
  };

  const onChangeOutput = (
    event: ChangeEvent<HTMLInputElement>,
    idx: number,
  ) => {
    const { output: outputValue } = cache[idx];

    setCache(
      updateTestCase(idx, {
        output: {
          ...outputValue,
          value: event.currentTarget.value,
        },
      }),
    );
  };

  const getDefaultTimeLimit = (languages: PhaseProblemSourceDTO[]) => {
    return languages
      .filter(({ usage }) => usage)
      .map(({ language }) => ({
        language,
        timeLimit: CodeTestCase.getTimeLimit(language),
      }));
  };

  const getTimeLimitCache = (data: TestCaseLanguageDTO[]) => {
    return data.map(({ language, timeLimit }) => ({
      language,
      timeLimit,
    }));
  };

  const getTimeLimit = (testCaseLanguageSet: TestCaseLanguageDTO[]) => {
    const usingLanguageSet = testCaseLanguageSet.filter(
      ({ commandType }) => commandType !== ModifyCommandType.DELETE,
    );
    const minTimeLimit = Math.min(
      ...usingLanguageSet.map(({ timeLimit }) => timeLimit),
    );
    const maxTimeLimit = Math.max(
      ...usingLanguageSet.map(({ timeLimit }) => timeLimit),
    );

    return {
      editable: minTimeLimit === maxTimeLimit,
      value:
        minTimeLimit === maxTimeLimit
          ? minTimeLimit
          : `${minTimeLimit}~${maxTimeLimit}`,
    };
  };

  const renderData = (data: TestCaseDTO[]) => {
    if (!data) return undefined;

    return data
      .map((item, originalRowIdx) => {
        return {
          item,
          originalRowIdx,
        };
      })
      .filter(({ item }) => item.commandType !== ModifyCommandType.DELETE)
      .map(
        (
          {
            item: {
              sn,
              testCaseLanguageSet,
              input: inputValue,
              output: outputValue,
            },
            originalRowIdx,
          },
          rowIdx,
        ) => {
          const textAreas = inputValue.map(({ value }, columnIdx) => (
            <Input
              data-testid={`testcase-textarea-input-${rowIdx}-${columnIdx}`}
              key={`testcase-textarea-function-input-${sn}-${rowIdx}-${columnIdx}`}
              placeholder={'Input'}
              value={value}
              onChange={(e) => onChangeInput(e, originalRowIdx, columnIdx)}
            />
          ));

          const timeLimit = getTimeLimit(testCaseLanguageSet);

          const nodes = [
            ...textAreas,
            <Input
              data-testid={`testcase-textarea-output-${rowIdx}`}
              placeholder={'Output'}
              value={outputValue.value}
              onChange={(e) => onChangeOutput(e, originalRowIdx)}
            />,
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '16px',
                justifyContent: 'center',
              }}
            >
              <InputNumber
                data-testid={`testcase-textarea-timelimit-${rowIdx}`}
                disabled={!timeLimit.editable}
                value={timeLimit.value}
                onChange={({ target: { value } }) => {
                  const newCache = [...cache];
                  const languageSet =
                    newCache[originalRowIdx].testCaseLanguageSet;
                  const data = Number(value);
                  if (data < 0 || isNaN(data)) return;

                  languageSet.forEach((language) => {
                    language.timeLimit = data;
                  });
                  setCache(newCache);
                }}
              />
              <span style={{ color: '#343a40' }}>ms</span>
              <IconModify
                data-testid={`add-time-testcase-${rowIdx}`}
                width='26'
                height='26'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  openTimeLimitModal(originalRowIdx);
                }}
              />
            </div>,
            <Removable
              key={`testcase-textarea-function-output-${sn}-${rowIdx}`}
              onRemove={() => deleteTestCase(originalRowIdx)}
            ></Removable>,
          ];

          return nodes;
        },
      );
  };

  const openTimeLimitModal = (testCaseIndex: number) => {
    setTestCaseIndex(testCaseIndex);
    if (testCaseIndex === -1) {
      setTimeLimitCache(getDefaultTimeLimit(languages));
    } else {
      setTimeLimitCache(
        getTimeLimitCache(cache[testCaseIndex].testCaseLanguageSet),
      );
    }
    setIsTimeLimitModalOpen(true);
  };

  const onCloseTimeLimitModal = () => {
    setIsTimeLimitModalOpen(false);
  };

  const renderHeaders = (): InputTableHeaderType[] => {
    return [
      'Parameters',
      `Return(${CodeTransformer.dataType2Text[params.returnType]})`,
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '10px',
        }}
      >
        Time Limit{' '}
        <IconModify
          width='26'
          height='26'
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openTimeLimitModal(-1);
          }}
        />
      </div>,
      '',
    ];
  };

  const handleSubmit = () =>
    validate(
      () => {
        onSubmit?.(cache);
      },
      (error) => {
        new RuntimeExceptionHandler({
          error,
          message: PMS_EXCEPTION_MESSAGES.PMS_VALIDATION_ERROR,
        });
      },
    );
  const handleClose = () => {
    setCache([]);
    onClose?.();
  };

  return {
    cache,
    addTestCase,
    renderData,
    handleSubmit,
    handleClose,
    isTimeLimitModalOpen,
    onCloseTimeLimitModal,
    selectedLanguages,
    openTimeLimitModal,
    renderHeaders,
    renderTimeLimitData,
    onSubmitTimeLimit,
  };
}
