import { PopUpStatus } from '@interfaces/notice/popup.enum.ts';

export const Category = [
	{
		name: '대기',
		key: PopUpStatus.WAITING,
	},
	{
		name: '게시 중',
		key: PopUpStatus.POSTING,
	},
	{
		name: '종료',
		key: PopUpStatus.ENDED,
	},
];
