import { atom } from 'recoil';
import { atomKeys } from '@constants/key.ts';
import { PopUpStatus } from '@interfaces/notice/popup.enum.ts';

const fallback = {
	popUpStatus: [],
};

export const popUpStatus = atom<PopUpStatus[]>({
	key: atomKeys.filter.popUpStatus,
	default: fallback.popUpStatus,
});
