import { AiOutlineInbox } from 'react-icons/ai';
import { Container } from './style';

const Empty = () => {
	return (
		<Container>
			<AiOutlineInbox
				size={40}
				color={'rgba(0,0,0,.25)'}
				style={{ margin: '0 auto' }}
			/>
			<h4>데이터 없음</h4>
		</Container>
	);
};

export default Empty;
