import { FC, useMemo } from 'react';
import TextButton from '@widget/button-text';
import {
  FunctionParameterAndReturnTypeDTO,
  ModifyCommandType,
  PhaseProblemSourceDTO,
  TestCaseDTO,
  TestCaseType,
} from '@phs/interfaces';
import Table, { RenderTableBody } from '@components/Table';
import TestCaseModal from '../Modals/TestCase';
import { Container, RequireTitle, TitleWrap } from './style';
import { useExampleCaseProps } from './hooks';
import { HEADERS } from './config';

interface ExampleCaseProps {
  params: FunctionParameterAndReturnTypeDTO;
  disabled?: boolean;
  languages?: PhaseProblemSourceDTO[];
  testCases?: TestCaseDTO[];
  handleTestCase?: (
    type: TestCaseType,
    data: TestCaseDTO[],
    isFile?: boolean,
  ) => void;
}

const ExampleCase: FC<ExampleCaseProps> = ({
  params,
  disabled = false,
  languages = [],
  testCases = [],
  handleTestCase,
}) => {
  const { isModalOpened, handleOpen, renderData, onSubmit } =
    useExampleCaseProps({
      disabled,
      handleTestCase,
    });

  const noTestCase = useMemo(() => {
    return testCases.every(
      ({ commandType }) => commandType === ModifyCommandType.DELETE,
    );
  }, [testCases]);

  return (
    <Container>
      {isModalOpened && (
        <TestCaseModal
          params={params}
          testCaseType={TestCaseType.EXAMPLE}
          languages={languages}
          testCases={testCases}
          onSubmit={onSubmit}
          onClose={() => handleOpen(false)}
        />
      )}
      <TitleWrap>
        <RequireTitle className={noTestCase ? 'invalid' : ''}>
          예시 테스트 케이스
        </RequireTitle>
        <TextButton
          data-testid={'example-testcase'}
          onClick={() => handleOpen(true)}
          style={{
            width: '20%',
            height: 52,
          }}
        >
          테스트 케이스 추가 및 수정
        </TextButton>
      </TitleWrap>
      <Table<TestCaseDTO>
        headers={HEADERS}
        data={testCases}
        renderTableBody={renderData as RenderTableBody<TestCaseDTO>}
        fixLayout
        data-testid='package-example-testcase'
      />
    </Container>
  );
};

export default ExampleCase;
