import { useAlertModal } from '@components/Modals/Alert/hook';
import { PMS_URL } from '@phs/constants';
import { BaseRepository } from '@phs/repositories';
import authRepository from '@repositories/auth.repository';
import { pmsAccessToken } from '@stores/atoms/shared/auth';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

const AxiosErrorHandler = () => {
  const { onOpenAndActionAfterClose, onOpen } = useAlertModal();
  const setAccessToken = useSetRecoilState(pmsAccessToken);
  const resetAccessToken = useResetRecoilState(pmsAccessToken);

  const interceptors = async (error: AxiosError) => {
    if (error.response?.status === 400 && error.config?.method === 'get') {
      onOpen(
        <>{(error.response?.data as any)?.message ?? '요청에 실패했습니다.'}</>,
      );
      return Promise.reject(error);
    }

    // 403
    // TOKEN INVALID
    if (
      error.response?.status === 403 ||
      (error.response?.status === 401 &&
        (error.response?.data as any).code === 'J001') ||
      (error.response?.status === 401 &&
        (error.response?.data as any).code === 'J003' &&
        error.response?.config?.url === PMS_URL.ISSUE_ACCESS_TOKEN)
    ) {
      onOpenAndActionAfterClose(
        <>
          로그인 세션이 만료되었습니다.
          <br />
          다시 로그인해 주시기 바랍니다.
        </>,
        () => {
          window.location.replace('/auth/login');
        },
      );
      return Promise.reject(error);
    }

    // 중복 로그인
    if (
      error.response?.status === 401 &&
      (error.response?.data as any).code === 'J004'
    ) {
      onOpenAndActionAfterClose(
        <>중복 로그인이 발생하여 로그인 화면으로 이동합니다.</>,
        () => {
          window.location.replace('/auth/login');
        },
      );
      return Promise.reject(error);
    }

    // 토큰 만료 재요청
    if (
      error.response?.status === 401 &&
      (error.response?.data as any).code === 'J003'
    ) {
      try {
        const data = await authRepository.issueAccessToken();
        if (!data || !data.accessToken) {
          resetAccessToken();
          return Promise.reject(error);
        }

        setAccessToken(data.accessToken);

        return await BaseRepository.axios.request({
          ...error.config,
          headers: {
            ...error?.config?.headers,
            Authorization: `Bearer ${data.accessToken}`,
          },
        });
      } catch {
        resetAccessToken();
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  };

  useEffect(() => {
    BaseRepository.setInterCeptors(interceptors);
  }, []);
  return <></>;
};

export default AxiosErrorHandler;
