import LoadingButton from '@widget/button-loading';
import { PMS_PAGES } from '@phs/constants';
import { Container } from './style.ts';
import { useMenu } from './hooks';

const PopUpMenu = () => {
  const { onAddClick, onDeleteClick } = useMenu();

  return (
    <Container>
      <LoadingButton key={'remove-popup'} onClick={onDeleteClick}>
        팝업&nbsp;삭제
      </LoadingButton>
      <LoadingButton
        data-testid={'add-popup'}
        key={PMS_PAGES.POPUP.HOME}
        onClick={onAddClick}
      >
        팝업&nbsp;추가&nbsp;+&nbsp;
      </LoadingButton>
    </Container>
  );
};

export default PopUpMenu;
