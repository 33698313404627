import { PopUpSortType } from '@interfaces/notice/popup.enum';
import { DropdownItemType } from '@widget/dropdown';

export enum ResetKey {
	RESET_SEQUENCE = 'resetSequence',
	REST_START_DATE = 'resetExpiredDate',
}

export const resetTypeMap: Record<ResetKey | PopUpSortType, PopUpSortType[]> = {
	[ResetKey.RESET_SEQUENCE]: [PopUpSortType.SN_ASC, PopUpSortType.SN_DESC],
	[ResetKey.REST_START_DATE]: [
		PopUpSortType.START_DATE_TIME_ASC,
		PopUpSortType.START_DATE_TIME_DESC,
	],
	[PopUpSortType.SN_ASC]: [PopUpSortType.SN_DESC],
	[PopUpSortType.SN_DESC]: [PopUpSortType.SN_ASC],
	[PopUpSortType.START_DATE_TIME_ASC]: [PopUpSortType.START_DATE_TIME_DESC],
	[PopUpSortType.START_DATE_TIME_DESC]: [PopUpSortType.START_DATE_TIME_ASC],
};

export const sequenceSortOptions: DropdownItemType[] = [
	{
		key: ResetKey.RESET_SEQUENCE,
		name: '기본정렬',
	},
	{
		key: PopUpSortType.SN_ASC,
		name: '오름차순',
	},
	{
		key: PopUpSortType.SN_DESC,
		name: '내림차순',
	},
];

export const startDateSortOptions: DropdownItemType[] = [
	{
		key: ResetKey.REST_START_DATE,
		name: '기본정렬',
	},
	{
		key: PopUpSortType.START_DATE_TIME_ASC,
		name: '오름차순',
	},
	{
		key: PopUpSortType.START_DATE_TIME_DESC,
		name: '내림차순',
	},
];
