import { Container } from './style';
import { ButtonHTMLAttributes, DetailedHTMLProps, FC, ReactNode } from 'react';

interface LoginButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children?: ReactNode;
}

const LoginButton: FC<LoginButtonProps> = ({ children, ...rest }) => {
  return <Container {...rest}>{children}</Container>;
};

export default LoginButton;
