import { ReactNode } from 'react';
import { useTable, type HandleTableClickProps } from './hook';
import { Container, PlainTable } from './style';
import Empty from './Empty';
import SortIcon from './Sort';

export { HandleTableClickProps };

export interface TableHeaderProps {
  sortable?: boolean;
  width?: string;
  name: string;
  key: string;
}

interface TableProps<T = any> {
  headers: TableHeaderProps[];
  data?: ReactNode[][];
  onClick?: (props: HandleTableClickProps<T>) => void;
}

function BasicTable<T = any>({
  headers,
  data,
  onClick,
  ...rest
}: TableProps<T>) {
  const { handleClick } = useTable<T>({ onClick });

  return (
    <Container>
      <PlainTable {...rest}>
        <thead>
          <tr>
            {headers?.map(({ name, key, width, sortable }, idx) => (
              <th key={`widget-plain-table-th-${key}-${idx}`} style={{ width }}>
                {sortable ? (
                  <SortIcon
                    onClick={(sortType) => {
                      handleClick({
                        key,
                        type: 'sort',
                        value: null,
                        sortType,
                      });
                    }}
                  >
                    {name}
                  </SortIcon>
                ) : (
                  name
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((items, idx) => {
            return (
              <tr key={`widget-plain-table-tbody-tr-${idx}`}>
                {items.map((element, idx) => (
                  <td key={`widget-plain-table-td-${idx}`}>{element}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </PlainTable>
      {data?.length === 0 && <Empty />}
    </Container>
  );
}

export default BasicTable;
