import { selectorFamily } from 'recoil';
import { toggles } from '@stores/atoms/shared/table.ts';
import { stateKeys } from '@constants/key.ts';
import { PackageProblemDTO } from '@interfaces/dashboard/problem.dto.ts';

interface PackageToggleKeysParam extends PackageProblemDTO {
  // eslint-disable @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export const packageToggleKeys = selectorFamily<
  PackageProblemDTO[],
  PackageToggleKeysParam[]
>({
  key: stateKeys.table.currentPopUpToggleKeys,
  get:
    (items: PackageProblemDTO[]) =>
    ({ get }) => {
      const _toggles = get(toggles);
      return items.filter((_, idx) => _toggles[idx]);
    },
});
