import { ReactNode } from 'react';
import CheckBox from '@widget/checkbox';
import { Container, ToggleTable } from './style';
import { useTable, HandleToggleTableClickProps } from './hook';
import Empty from './Empty';
import SortIcon from './Sort';

export type ToggleTableHeaderProps = {
  name: string;
  key: string;
  width?: string;
  sortable?: boolean;
};

export type { HandleToggleTableClickProps };

export interface ToggleTableProps<T = any> {
  headers: ToggleTableHeaderProps[];
  data?: ReactNode[][];
  activeStatus?: boolean[];
  onClick?: (props: HandleToggleTableClickProps<T>) => void;
}

function Table<T = any>({
  headers,
  data,
  activeStatus = [],
  onClick,
}: ToggleTableProps<T>) {
  const { toggle, onActiveChange, resetActive, handleClick } = useTable(
    activeStatus,
    onClick,
  );

  return (
    <Container>
      <ToggleTable>
        <thead>
          <tr>
            <th style={{ width: 20 }}>
              <CheckBox
                id={`toggle-table-header-checkbox-all-active`}
                isActive={toggle}
                onClick={({ isActive }) => resetActive(!isActive)}
              />
            </th>
            {headers?.map(({ name, key, width, sortable }) => (
              <th key={`widget-toggle-table-th-${key}`} style={{ width }}>
                {sortable ? (
                  <SortIcon
                    onClick={(sortType) => {
                      handleClick({
                        type: 'sort',
                        value: [],
                        sortType,
                      });
                    }}
                  >
                    {name}
                  </SortIcon>
                ) : (
                  name
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((items, idx) => {
            return (
              <tr key={`widget-toggle-table-tbody-tr-${idx}`}>
                <td>
                  <CheckBox
                    id={`toggle-table-body-checkbox-active-${idx}`}
                    isActive={activeStatus[idx]}
                    onClick={({ isActive }) => onActiveChange(idx, !isActive)}
                  />
                </td>
                {items.map((element, idx) => (
                  <td key={`widget-toggle-table-td-${idx}`}>{element}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </ToggleTable>
      {data?.length === 0 && <Empty />}
    </Container>
  );
}

export default Table;
