import { FC } from 'react';
import ModalWidget from '@widget/modal';
import { BottomWrapper, Container, ContentWrapper } from './style.ts';
import TextButton from '@widget/button-text';

interface DeleteConfirmModalProps {
  onOk: () => void;
  onClose: () => void;
}

const DeleteConfirmModal: FC<DeleteConfirmModalProps> = ({ onClose, onOk }) => {
  return (
    <ModalWidget>
      <Container>
        <ContentWrapper>파일을 삭제하시겠습니까?</ContentWrapper>
        <BottomWrapper>
          <TextButton onClick={onClose}>취소</TextButton>
          <TextButton theme={'primary'} onClick={onOk}>
            확인
          </TextButton>
        </BottomWrapper>
      </Container>
    </ModalWidget>
  );
};

export default DeleteConfirmModal;
