import { ToggleTableHeaderProps } from '@widget/table-toggle';

export const HEADERS: ToggleTableHeaderProps[] = [
  {
    name: 'NO.',
    key: 'sequence',
  },
  {
    name: '제목',
    key: 'title',
    width: '33%',
  },
  {
    name: '게시 상태',
    key: 'posting',
  },
  {
    name: '게시 기간',
    key: 'duration',
  },
  {
    name: '작성일',
    key: 'createdAt',
  },
  {
    name: '최근 수정일',
    key: 'updatedAt',
  },
];
