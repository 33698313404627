import { ReactNode } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { toggles } from '@stores/atoms/shared/table.ts';
import { HandleToggleTableClickProps } from '@widget/table-toggle';
import { useDebouncedCallback } from '@react-hookz/web';

interface UseTableProps<T> {
  renderTableBody?: (data: T[]) => ReactNode[][];
  onClick?: (props: HandleToggleTableClickProps<T>) => void;
}

export function useTable<T = never>({
  renderTableBody,
  onClick,
}: UseTableProps<T>) {
  const resetTableToggle = useResetRecoilState(toggles);
  const setTableToggle = useSetRecoilState(toggles);

  const handleClick = useDebouncedCallback(
    (props: HandleToggleTableClickProps<T>) => {
      if (props.type === 'toggle') {
        // console.log(props.value);
        setTableToggle(props.value as boolean[]);
      } else {
        onClick?.(props);
      }
    },
    [],
    100,
  );

  const render = (data?: T[]) => {
    if (!data || !renderTableBody) return undefined;

    return renderTableBody(data);
  };

  return {
    render,
    handleClick,
    resetTableToggle,
    setTableToggle,
  };
}
