import styled from '@emotion/styled';

export const Container = styled.div``;

export const Title = styled.h3`
	padding: 12px 0;
	margin: 0;
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
`;
