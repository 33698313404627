import styled from '@emotion/styled';

export const Container = styled.table`
  width: 100%;
  border: 1px solid rgb(222 226 230);

  thead {
    tr {
      th {
        height: 40px;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        vertical-align: middle;
        background: rgb(248 249 250);
        border-bottom: 1px solid rgb(222 226 230);

        &:not(:first-of-type) {
          border-left: 1px solid rgb(222 226 230);
        }
      }
      &:first-of-type {
        th:last-of-type {
          width: 40px;
        }
      }
    }
  }
`;

export const Tbody = styled.tbody`
  td {
    box-sizing: border-box;
    padding: 16px 24px;
    background: rgb(241 243 245);
    vertical-align: middle;

    &:not(:first-of-type) {
      border-left: 1px solid rgb(222 226 230);
    }
  }
`;

export const Textarea = styled.textarea`
  width: 100%;
  height: 92px;
  box-sizing: border-box;
  padding: 12px 11px;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-radius: 4px;
  color: #495057;
  background: #fff;
  border: none;
  outline: none;
  resize: none;

  ::placeholder {
    color: #adb5bd;
    background: #fff;
  }

  :focus,
  :focus-visible,
  :active {
    background: #fff;
    border: 1px solid #ced4da;
    outline: none;
    box-shadow: none;
  }

  svg {
    width: 16px;
    height: 16px;
    fill: #2f68f6;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 40px;
  color: #495057;
  border-radius: 4px;

  &:not(:disabled) {
    box-sizing: border-box;
    padding: 12px;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    background: #fff;
    border: 1px solid #ced4da;
    outline: none;

    &::placeholder {
      color: #adb5bd;
    }

    &:focus-visible {
      background: #fff;
      border: 1px solid #495057;
      outline: none;
      box-shadow: none;
    }

    &:active {
      background: #fff;
      border: 1px solid #495057;
      outline: none;
      box-shadow: none;
    }

    &:focus {
      background: #fff;
      border: 1px solid #495057;
      outline: none;
      box-shadow: none;
    }
  }
`;

export const EmptyArea = styled.tbody`
  height: max-content;
  padding: 0;
  background: rgb(241 243 245);
`;
