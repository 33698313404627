export const localStorage = {
	get: (key: string) => {
		try {
			return window.localStorage.getItem(key);
		} catch (e) {
			return null;
		}
	},
	set: (key: string, value: any) => {
		try {
			window.localStorage.setItem(key, JSON.stringify(value));
			return true;
		} catch (e) {
			return false;
		}
	},
	remove: (key: string) => {
		try {
			window.localStorage.removeItem(key);
			return true;
		} catch (e) {
			return false;
		}
	},
};
