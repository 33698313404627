import styled from '@emotion/styled';
import { css } from '@emotion/react';

const CommonStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 16px 0;
  font-family: inherit;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  border-radius: 4px;
  cursor: pointer;
`;

export const FileInput = styled.input`
  display: none;
`;

export const NoneContainer = styled.label`
  ${CommonStyle};
`;

export const PrimaryContainer = styled.label`
  ${CommonStyle};

  background: #12b886;
  border: 1px solid #12b886;
  color: #fff;

  &:hover {
    background: #0ca678;
  }

  &:active {
    opacity: 0.8;
  }

  &:disabled {
    color: #212529;
    cursor: not-allowed;
    background: #adb5bd;
    border: 1px solid #adb5bd;
  }
`;

export const SecondaryContainer = styled.label`
  ${CommonStyle};

  background: #dee2e6;
  border-radius: 4px;
  color: #495057;

  &:hover {
    background: #ced4da;
  }

  &:active {
    opacity: 0.8;
  }
`;

export const TertiaryContainer = styled.label`
  ${CommonStyle};

  background: #fff;
  border: 1px solid #12b886;
  border-radius: 4px;
  color: #12b886;

  &:hover {
    background: #d3f9d8;
  }

  &:active {
    opacity: 0.8;
  }
`;
