import styled from '@emotion/styled';

export const Container = styled.table`
  width: 100%;
  border: 1px solid rgb(222 226 230);

  thead {
    th {
      height: 40px;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      vertical-align: middle;
      background: rgb(248 249 250);
      border: 1px solid rgb(222 226 230);
    }
  }
`;

export const Tbody = styled.tbody`
  td {
    box-sizing: border-box;
    padding: 16px 24px;
    vertical-align: middle;
    border-bottom: 1px solid rgb(222 226 230);

    &:not(:first-of-type) {
      border-left: 1px solid rgb(222 226 230);
    }
  }
`;

export const EmptyArea = styled.tbody`
  height: max-content;
  padding: 0;
  background: rgb(241 243 245);
`;
