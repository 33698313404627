import { FC } from 'react';
import { ExamLanguageDTO, TestCaseDTO, TestCaseType } from '@phs/interfaces';
import ClosableModal from '@components/Modals/Closable';
import UploadTable from '@components/UploadTable';
import { Tooltip } from '@widget/tooltips';
import { IconInfo } from '@widget/icons';
import TextButton from '@widget/button-text';
import UploadButton from '@widget/button-upload';
import { ButtonWrapper, TooltipWrapper, TableWrapper } from './style';
import { useTestCase } from './hooks';
import TimeLimitModal from '../TimeLimit';
import TestCaseInformation from '@pages/Exam/shared/TestCaseInformation/file';

export interface HighCapacityTestCaseModalProps {
  languages?: ExamLanguageDTO[];
  testCaseType: TestCaseType;
  testCases?: TestCaseDTO[];
  onSubmit?: (data: TestCaseDTO[]) => void;
  onClose?: () => void;
}

const HighCapacityTestCaseModal: FC<HighCapacityTestCaseModalProps> = ({
  languages = [],
  testCaseType,
  testCases = [],
  onSubmit,
  onClose,
}) => {
  const {
    cache,
    onChangeBulkUpload,
    createTestCase,
    handleSubmit,
    selectedLanguages,
    handleClose,
    onCloseTimeLimitModal,
    isTimeLimitModalOpen,
    onSubmitTimeLimit,
    renderTimeLimitData,
    renderData,
    renderHeaders,
  } = useTestCase({
    languages,
    testCaseType,
    testCases,
    onSubmit,
    onClose,
  });

  return (
    <>
      <ClosableModal
        onClose={handleClose}
        onSubmit={handleSubmit}
        title={
          <TooltipWrapper>
            <h3>테스트 케이스 추가</h3>
            <Tooltip content={<TestCaseInformation />} position={'right'}>
              <IconInfo
                style={{
                  width: 32,
                  height: 32,
                }}
              />
            </Tooltip>
          </TooltipWrapper>
        }
      >
        <ButtonWrapper>
          <UploadButton
            onClick={(e) => {
              // 같은 파일도 다시 import 가능하도록
              e.currentTarget.value = '';
            }}
            onChange={onChangeBulkUpload}
            type={'file'}
            multiple={true}
            theme={'secondary'}
            style={{
              width: 160,
              height: 40,
              marginLeft: 'auto',
            }}
          >
            파일 일괄 업로드
          </UploadButton>
          <TextButton
            onClick={createTestCase}
            theme={'secondary'}
            style={{
              width: 120,
              height: 40,
            }}
          >
            추가
          </TextButton>
        </ButtonWrapper>
        <TableWrapper>
          <UploadTable
            headers={renderHeaders()}
            data={cache}
            renderData={renderData}
          />
        </TableWrapper>
      </ClosableModal>
      {isTimeLimitModalOpen && (
        <TimeLimitModal
          data={selectedLanguages}
          onClose={onCloseTimeLimitModal}
          renderTimeLimitData={renderTimeLimitData}
          onSubmit={onSubmitTimeLimit}
        />
      )}
    </>
  );
};

export default HighCapacityTestCaseModal;
