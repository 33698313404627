import { css } from '@emotion/react';
import styled from '@emotion/styled';

const PositionLeft = css`
  position: absolute;
  left: 50%;
  transform: translate(-89%, 8%);
`;

const PositionLeftBefore = css`
  position: absolute;
  left: 90%;
  transform: translate(-50%, 0);
  top: -8px;
`;

const PositionCenter = css`
  position: absolute;
  left: 50%;
  transform: translate(-57%, 8%);
`;

const PositionCenterBefore = css`
  position: absolute;
  left: 58%;
  transform: translate(-50%, 0);
  top: -8px;
`;

const PositionRight = css`
  position: absolute;
  left: 50%;
  transform: translate(-10%, 8%);
`;

const PositionRightBefore = css`
  position: absolute;
  right: 87%;
  transform: translate(-50%, 0);
  top: -8px;
`;

const PositionTopLeft = css`
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translate(-89%, -8%);
`;

const PositionTopLeftBefore = css`
  position: absolute;
  right: 10px;
  transform: scaleY(-1);
  bottom: -8px;
`;

const PositionTopCenter = css`
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translate(-50%, -12px);
`;

const PositionTopCenterBefore = css`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0) scaleY(-1);
  bottom: -8px;
`;

const PositionTopRight = css`
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translate(-10%, -8%);
`;

const PositionTopRightBefore = css`
  position: absolute;
  left: 10px;
  transform: scaleY(-1);
  bottom: -8px;
`;

export const TooltipText = styled.div<{
  position?: 'left' | 'right' | 'center' | 'topLeft' | 'topCenter' | 'topRight';
  color?: string;
  maxContent?: boolean;
  top?: number;
  left?: number;
  tightPadding?: boolean;
}>`
  ${({ position }) => {
    switch (position) {
      case 'right':
        return PositionRight;
      case 'center':
        return PositionCenter;
      case 'topLeft':
        return PositionTopLeft;
      case 'topCenter':
        return PositionTopCenter;
      case 'topRight':
        return PositionTopRight;
      case 'left':
      default:
        return PositionLeft;
    }
  }};

  ${({ top }) => {
    if (top) {
      return `top: ${top}px; bottom: auto;`;
    }
    return '';
  }}
  left: ${({ left }) => left && `${left}%`};
  z-index: 1;
  box-sizing: border-box;
  width: ${({ maxContent = true }) => (maxContent ? `max-content` : `350px`)};
  padding: ${({ tightPadding }) => (tightPadding ? '8px 12px' : '20px')};
  font-family: inherit;
  font-style: normal;
  color: #fff;
  text-align: left;
  pointer-events: none;
  visibility: hidden;
  background: ${({ color }) => (color ? color : '#212529')};
  border-radius: 4px;

  &::before {
    ${({ position }) => {
      switch (position) {
        case 'right':
          return PositionRightBefore;
        case 'center':
          return PositionCenterBefore;
        case 'topLeft':
          return PositionTopLeftBefore;
        case 'topCenter':
          return PositionTopCenterBefore;
        case 'topRight':
          return PositionTopRightBefore;
        case 'left':
        default:
          return PositionLeftBefore;
      }
    }};
    pointer-events: none;
    content: '';
    border-right: 4px solid transparent;
    border-bottom: 8px solid ${({ color }) => (color ? color : '#212529')};
    border-left: 4px solid transparent;
  }

  h5 {
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }
`;

export const TooltipTextContent = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left !important;
  word-break: keep-all;
  white-space: pre-wrap;
`;

export const Container = styled.div<{ height: number }>`
  position: relative;
  cursor: pointer;
  height: ${({ height }) => `${height}px`};

  &:hover {
    ${TooltipText} {
      visibility: visible;
    }
  }
`;
