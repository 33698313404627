import { selector } from 'recoil';
import { stateKeys } from '@constants/key.ts';
import { ExamLanguageDTO, PhaseProblemSourceDTO } from '@phs/interfaces';
import { packageExamLanguages } from '@stores/atoms/exam/exam.package.ts';

export const packageCurrentLanguages = selector<PhaseProblemSourceDTO[]>({
  key: stateKeys.exam.packageLanguages,
  get: ({ get }) => {
    const languages = get(packageExamLanguages);
    return languages.filter(({ usage }) => usage) as PhaseProblemSourceDTO[];
  },
});

export const packageExamList = selector<ExamLanguageDTO[]>({
  key: stateKeys.exam.packageLanguageStatus,
  get: ({ get }) => {
    return get(packageExamLanguages);
  },
});
