import styled from '@emotion/styled';

export const Container = styled.div`
	display: flex;
	gap: 16px;

	button:first-of-type {
		margin-left: auto;
	}
`;
