import { FC } from 'react';
import { UseFormRegister } from 'react-hook-form';
import CheckBox from '@widget/checkbox';
import { Textarea } from '@widget/textarea';
import { PackageExamFormProps } from '@pages/Exam/package/config.ts';
import { Container, Title, Hint, ErrorText } from './style.ts';

interface SummaryProps {
  cursor: number;
  register: UseFormRegister<PackageExamFormProps>;
  summaryYn: boolean;
  handleToggle?: (status: boolean) => void;
  value?: string;
  error?: string;
}

const Summary: FC<SummaryProps> = ({
  cursor,
  register,
  summaryYn,
  handleToggle,
  value,
  error,
}) => {
  return (
    <Container>
      <CheckBox
        isActive={summaryYn}
        onClick={({ isActive }) => handleToggle?.(!isActive)}
        {...register(`stepProblemList.${cursor}.summaryYn`)}
      >
        <Title>
          단계&nbsp;요약&nbsp;내용&nbsp;제공&nbsp;
          <Hint>
            &nbsp;응시자&nbsp;페이지&nbsp;및&nbsp;리포트에 반영됩니다.
          </Hint>
        </Title>
      </CheckBox>
      <Textarea
        disabled={!summaryYn}
        placeholder={'내용을 입력해주세요.'}
        style={{
          marginTop: 16,
          border: 'solid 1px #ced4da',
        }}
        value={value}
        {...register(`stepProblemList.${cursor}.summary`)}
      />
      {summaryYn && <ErrorText>{error}</ErrorText>}
    </Container>
  );
};

export default Summary;
