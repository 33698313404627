import { UseFormSetValue } from 'react-hook-form';
import { useState } from 'react';
import { ModifyCommandType, TestCaseDTO } from '@phs/interfaces';
import { useAlertModal } from '@components/Modals/Alert/hook.ts';
import { FormProps } from '@pages/Exam/shared/config.ts';
import { CodeTestCase } from '@phs/code';
import { TableCell } from './style';

interface UseExampleCasePropsProps {
  disabled?: boolean;
  setValue: UseFormSetValue<FormProps>;
}

export function useExampleCaseProps({
  disabled = false,
  setValue,
}: UseExampleCasePropsProps) {
  const { onOpen } = useAlertModal();
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  const handleOpen = (status: boolean) => {
    if (status && disabled) return onOpen('언어가 선택되지 않았습니다.');
    setIsModalOpened(status);
  };

  const onSubmit = (data: TestCaseDTO[]) => {
    setIsModalOpened(false);
    setValue('exampleTestCases', data);
  };

  const renderData = (data: TestCaseDTO[]) => {
    if (!data) return undefined;

    return data
      .filter(({ commandType }) => commandType !== ModifyCommandType.DELETE)
      .map((props, idx) => [
        <TableCell>{idx + 1}</TableCell>,
        <TableCell>
          {CodeTestCase.stringifyAll(props.input.map(({ value }) => value))}
        </TableCell>,
        <TableCell>{props.output.value}</TableCell>,
      ]);
  };

  return {
    isModalOpened,
    handleOpen,
    renderData,
    onSubmit,
  };
}
