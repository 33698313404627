import { atom } from 'recoil';
import { ReactNode } from 'react';
import { Nullable } from '@phs/interfaces';
import { atomKeys } from '@constants/key.ts';

interface ConfirmModalAction {
  onOk: () => void;
}

export const alertModalStatus = atom<boolean>({
  key: atomKeys.modal.alertStatus,
  default: false,
});

export const alertModalText = atom<ReactNode>({
  key: atomKeys.modal.alertText,
  default: null,
});

export const alertModalAction = atom<Nullable<() => void>>({
  key: atomKeys.modal.alertAction,
  default: null,
});

export const confirmModalStatus = atom<boolean>({
  key: atomKeys.modal.confirmStatus,
  default: false,
});

export const confirmModalText = atom<ReactNode>({
  key: atomKeys.modal.confirmText,
  default: null,
});

export const confirmModalAction = atom<ConfirmModalAction>({
  key: atomKeys.modal.confirmModalAction,
  default: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onOk: () => {},
  },
});

export const passwordModalStatus = atom<boolean>({
  key: atomKeys.modal.passwordStatus,
  default: false,
});

export const passwordModalPhrase = atom<string>({
  key: atomKeys.modal.passwordAlertPhrase,
  default: '',
});
