import { injectable } from 'inversify';
import 'reflect-metadata';
import { Language } from './base';
import { ExamParameterDTO } from '../interfaces/language.dto';

@injectable()
export class Kotlin extends Language {
  public get defaultTimeLimit(): number {
    return 5000;
  }

  public loadFunctionCode(parameters: ExamParameterDTO): string {
    return parameters.paramInfo
      .map(({ paramType, paramName }) => {
        const returnType = this.returnType[paramType];
        return `${paramName}: ${returnType.paramType}`.trim();
      })
      .join(', ');
  }

  public getFunctionTemplate(parameters: ExamParameterDTO): string {
    const { type, value } = this.returnType[parameters.returnType];

    return `class Solution {\n    fun solution(${this.loadFunctionCode(
      parameters,
    )}): ${type} {\n        var answer: ${type}${value}\n        return answer\n    }\n}`;
  }

  public getStdioTemplate() {
    return "fun main(args: Array<String>) {\n    val (a, b) = readLine()!!.split(' ').map(String::toInt)\n    println(a + b)\n}";
  }

  public get returnType() {
    return {
      // Primitive Types
      INT: this.setReturnType('Int', 'Int', ' = 0'),
      LONG: this.setReturnType('Long', 'Long', ' = 0'),
      DOUBLE: this.setReturnType('Double', 'Double', ' = 0.0'),
      STRING: this.setReturnType('String', 'String', ' = ""'),
      BOOL: this.setReturnType('Boolean', 'Boolean', ' = true'),
      // Reference Types
      INT_ARRAY: this.setReturnType('IntArray', 'IntArray', ' = intArrayOf()'),
      LONG_ARRAY: this.setReturnType(
        'LongArray',
        'LongArray',
        ' = longArrayOf()',
      ),
      DOUBLE_ARRAY: this.setReturnType(
        'DoubleArray',
        'DoubleArray',
        ' = doubleArrayOf()',
      ),
      STRING_ARRAY: this.setReturnType(
        'Array<String>',
        'Array<String>',
        ' = arrayOf<String>()',
      ),
      BOOL_ARRAY: this.setReturnType(
        'BooleanArray',
        'BooleanArray',
        ' = booleanArrayOf()',
      ),
      // 2 Dimensional Reference Types
      INT_2_ARRAY: this.setReturnType(
        'Array<IntArray>',
        'Array<IntArray>',
        ' = arrayOf(intArrayOf())',
      ),
      LONG_2_ARRAY: this.setReturnType(
        'Array<LongArray>',
        'Array<LongArray>',
        ' = arrayOf(longArrayOf())',
      ),
      DOUBLE_2_ARRAY: this.setReturnType(
        'Array<DoubleArray>',
        'Array<DoubleArray>',
        ' = arrayOf(doubleArrayOf())',
      ),
      STRING_2_ARRAY: this.setReturnType(
        'Array<Array<String>>',
        'Array<Array<String>>',
        ' = arrayOf(arrayOf())',
      ),
      BOOL_2_ARRAY: this.setReturnType(
        'Array<BooleanArray>',
        'Array<BooleanArray>',
        ' = arrayOf(booleanArrayOf())',
      ),
    };
  }
}
