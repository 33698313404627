// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
  RequireTitle,
  DropDownWrapper,
  ErrorText,
  Container,
} from './style.ts';
import { FieldErrors, FieldValues } from 'react-hook-form';
import DropDown, { DropdownItemType } from '@widget/dropdown';
import { DETAIL_LEVEL_ITEMS } from './config.ts';

interface DetailLevelProps<T extends FieldValues> {
  title?: string;
  initialState?: number | string;
  onChange?: (props: DropdownItemType) => void;
  errorMessage?: string;
}

function DetailLevel<T extends FieldValues>({
  title = '세부 난이도',
  initialState,
  onChange,
  errorMessage,
}: DetailLevelProps<T>) {
  return (
    <Container>
      <RequireTitle>{title}</RequireTitle>
      <DropDownWrapper>
        <DropDown
          data-testid={'exam-detail-level'}
          title={title}
          initialState={initialState}
          items={DETAIL_LEVEL_ITEMS}
          onClick={onChange}
        />
        <ErrorText>{errorMessage}</ErrorText>
      </DropDownWrapper>
    </Container>
  );
}

export default DetailLevel;
