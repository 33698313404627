import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Container = styled.div`
  position: relative;
`;

export const DisplayArea = styled.div<{ isOpen?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 160px;
  height: 40px;
  padding: 12px;
  box-sizing: border-box;
  border: 1px solid #adb5bd;
  border-radius: 4px;
  background: #dee2e6;
  transition: all 0.5s ease;
  cursor: pointer;

  svg {
    transition: all 0.1s ease;
    transform: ${({ isOpen }) =>
      isOpen ? 'rotate(0.5turn) translate(0, 10%)' : 'rotate(0)'};
  }
`;

export const DisplayIconArea = styled.div`
  width: fit-content;
  height: fit-content;
  cursor: pointer;
`;

export const SelectedValue = styled.span`
  margin-right: 6px;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #495057;
`;

export const OptionRight = css`
  right: 0;
`;

export const OptionGroup = styled.ul<{ isRight?: boolean; isOpen?: boolean }>`
  position: absolute;
  top: 2px;
  z-index: 100;
  width: 100%;
  min-width: 160px;
  min-height: fit-content;
  transform: ${({ isOpen }) =>
    isOpen ? 'translateY(42px) scaleY(1)' : 'translateY(0) scaleY(0)'};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  border: 1px solid #adb5bd;
  border-radius: 4px;
  background: white;
  transition: all 0.2s ease;
  list-style: none;
  ${({ isRight }) => isRight && OptionRight};
`;

export const Option = styled.li<{ isActive?: boolean }>`
  box-sizing: border-box;
  height: 40px;
  padding: 6px 12px;
  font-weight: 700;
  font-size: 12px;
  line-height: 28px;
  color: #495057;
  cursor: pointer;
  z-index: 10;
  overflow: auto;

  a {
    width: 100%;
    min-width: 40px;
    height: 40px;
  }

  &:first-of-type {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-of-type {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:hover {
    background: #e9ecef;
  }
`;
