import { FC, SVGProps } from 'react';

export const LEVELS = [
	{
		name: '1',
		key: '1',
	},
	{
		name: '2',
		key: '2',
	},
	{
		name: '3',
		key: '3',
	},
	{
		name: '4',
		key: '4',
	},
	{
		name: '5',
		key: '5',
	},
];

export const Category = [
	{
		name: 'Stdio',
		key: 'STDIO',
	},
	{
		name: 'Function',
		key: 'FUNCTION',
	},
];

export const Public = [
	{
		name: '기업 서비스',
		key: 'COMPANY',
	},
	{
		name: '잡다 CMS',
		key: 'JOBDA',
	},
	{
		name: '비공개',
		key: 'PRIVATE',
	},
];

export const IconLevel: FC<SVGProps<SVGSVGElement>> = (props) => (
	<svg
		width='24'
		height='25'
		viewBox='0 0 24 25'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='M17.2004 5.65088H6.80039C6.09797 5.6522 5.4247 5.93182 4.92802 6.42851C4.43133 6.92519 4.15171 7.59846 4.15039 8.30088V16.7009C4.15171 17.4033 4.43133 18.0766 4.92802 18.5733C5.4247 19.0699 6.09797 19.3496 6.80039 19.3509H17.2004C17.9028 19.3496 18.5761 19.0699 19.0728 18.5733C19.5694 18.0766 19.8491 17.4033 19.8504 16.7009V8.30088C19.8491 7.59846 19.5694 6.92519 19.0728 6.42851C18.5761 5.93182 17.9028 5.6522 17.2004 5.65088ZM18.1504 16.7009C18.1504 16.9528 18.0503 17.1945 17.8721 17.3726C17.694 17.5508 17.4523 17.6509 17.2004 17.6509H6.80039C6.54843 17.6509 6.3068 17.5508 6.12864 17.3726C5.95048 17.1945 5.85039 16.9528 5.85039 16.7009V8.30088C5.85039 8.04892 5.95048 7.80729 6.12864 7.62913C6.3068 7.45097 6.54843 7.35088 6.80039 7.35088H17.2004C17.4523 7.35088 17.694 7.45097 17.8721 7.62913C18.0503 7.80729 18.1504 8.04892 18.1504 8.30088V16.7009Z'
			fill='#495057'
		/>
		<path
			d='M7.37539 9.02588C7.25622 9.14464 7.17497 9.29609 7.14194 9.46105C7.1089 9.62601 7.12556 9.79707 7.18981 9.95256C7.25406 10.108 7.363 10.241 7.50285 10.3345C7.6427 10.428 7.80715 10.478 7.97539 10.478C8.14363 10.478 8.30809 10.428 8.44793 10.3345C8.58778 10.241 8.69672 10.108 8.76097 9.95256C8.82522 9.79707 8.84188 9.62601 8.80884 9.46105C8.77581 9.29609 8.69456 9.14464 8.57539 9.02588C8.49718 8.94605 8.40383 8.88264 8.3008 8.83935C8.19777 8.79606 8.08714 8.77376 7.97539 8.77376C7.86364 8.77376 7.75301 8.79606 7.64998 8.83935C7.54695 8.88264 7.4536 8.94605 7.37539 9.02588Z'
			fill='#495057'
		/>
		<path
			d='M11.2604 10.2259C11.3789 10.1068 11.4594 9.95538 11.4919 9.79061C11.5243 9.62584 11.5072 9.45514 11.4428 9.30007C11.3783 9.14499 11.2694 9.01248 11.1297 8.91927C10.99 8.82605 10.8258 8.7763 10.6579 8.7763C10.49 8.7763 10.3258 8.82605 10.1861 8.91927C10.0464 9.01248 9.93744 9.14499 9.87299 9.30007C9.80853 9.45514 9.79146 9.62584 9.82392 9.79061C9.85638 9.95538 9.93693 10.1068 10.0554 10.2259C10.1344 10.3052 10.2283 10.3682 10.3317 10.4112C10.4351 10.4542 10.5459 10.4763 10.6579 10.4763C10.7699 10.4763 10.8807 10.4542 10.9841 10.4112C11.0875 10.3682 11.1814 10.3052 11.2604 10.2259Z'
			fill='#495057'
		/>
		<path
			d='M13.9454 10.2259C14.0639 10.1068 14.1444 9.95538 14.1769 9.79061C14.2093 9.62584 14.1922 9.45514 14.1278 9.30007C14.0633 9.14499 13.9544 9.01248 13.8147 8.91927C13.675 8.82605 13.5108 8.7763 13.3429 8.7763C13.175 8.7763 13.0108 8.82605 12.8711 8.91927C12.7314 9.01248 12.6224 9.14499 12.558 9.30007C12.4935 9.45514 12.4765 9.62584 12.5089 9.79061C12.5414 9.95538 12.6219 10.1068 12.7404 10.2259C12.8194 10.3052 12.9133 10.3682 13.0167 10.4112C13.1201 10.4542 13.2309 10.4763 13.3429 10.4763C13.4549 10.4763 13.5657 10.4542 13.6691 10.4112C13.7725 10.3682 13.8664 10.3052 13.9454 10.2259Z'
			fill='#495057'
		/>
		<path
			d='M15.4254 9.02588C15.3062 9.14464 15.225 9.29609 15.1919 9.46105C15.1589 9.62601 15.1756 9.79707 15.2398 9.95256C15.3041 10.108 15.413 10.241 15.5529 10.3345C15.6927 10.428 15.8572 10.478 16.0254 10.478C16.1936 10.478 16.3581 10.428 16.4979 10.3345C16.6378 10.241 16.7467 10.108 16.811 9.95256C16.8752 9.79707 16.8919 9.62601 16.8588 9.46105C16.8258 9.29609 16.7446 9.14464 16.6254 9.02588C16.5472 8.94605 16.4538 8.88264 16.3508 8.83935C16.2478 8.79606 16.1371 8.77376 16.0254 8.77376C15.9136 8.77376 15.803 8.79606 15.7 8.83935C15.597 8.88264 15.5036 8.94605 15.4254 9.02588Z'
			fill='#495057'
		/>
		<path
			d='M7.37539 11.8959C7.25622 12.0146 7.17497 12.1661 7.14194 12.3311C7.1089 12.496 7.12556 12.6671 7.18981 12.8226C7.25406 12.978 7.363 13.111 7.50285 13.2045C7.6427 13.298 7.80715 13.348 7.97539 13.348C8.14363 13.348 8.30809 13.298 8.44793 13.2045C8.58778 13.111 8.69672 12.978 8.76097 12.8226C8.82522 12.6671 8.84188 12.496 8.80884 12.3311C8.77581 12.1661 8.69456 12.0146 8.57539 11.8959C8.41607 11.7371 8.20031 11.648 7.97539 11.648C7.75047 11.648 7.53471 11.7371 7.37539 11.8959Z'
			fill='#495057'
		/>
		<path
			d='M11.2604 13.1009C11.3789 12.9818 11.4594 12.8304 11.4919 12.6656C11.5243 12.5008 11.5072 12.3301 11.4428 12.1751C11.3783 12.02 11.2694 11.8875 11.1297 11.7943C10.99 11.701 10.8258 11.6513 10.6579 11.6513C10.49 11.6513 10.3258 11.701 10.1861 11.7943C10.0464 11.8875 9.93744 12.02 9.87299 12.1751C9.80853 12.3301 9.79146 12.5008 9.82392 12.6656C9.85638 12.8304 9.93693 12.9818 10.0554 13.1009C10.2156 13.26 10.4321 13.3492 10.6579 13.3492C10.8836 13.3492 11.1002 13.26 11.2604 13.1009Z'
			fill='#495057'
		/>
		<path
			d='M13.9454 13.1009C14.0639 12.9818 14.1444 12.8304 14.1769 12.6656C14.2093 12.5008 14.1922 12.3301 14.1278 12.1751C14.0633 12.02 13.9544 11.8875 13.8147 11.7943C13.675 11.701 13.5108 11.6513 13.3429 11.6513C13.175 11.6513 13.0108 11.701 12.8711 11.7943C12.7314 11.8875 12.6224 12.02 12.558 12.1751C12.4935 12.3301 12.4765 12.5008 12.5089 12.6656C12.5414 12.8304 12.6219 12.9818 12.7404 13.1009C12.9006 13.26 13.1171 13.3492 13.3429 13.3492C13.5686 13.3492 13.7852 13.26 13.9454 13.1009Z'
			fill='#495057'
		/>
		<path
			d='M15.4254 11.8959C15.3062 12.0146 15.225 12.1661 15.1919 12.3311C15.1589 12.496 15.1756 12.6671 15.2398 12.8226C15.3041 12.978 15.413 13.111 15.5529 13.2045C15.6927 13.298 15.8572 13.348 16.0254 13.348C16.1936 13.348 16.3581 13.298 16.4979 13.2045C16.6378 13.111 16.7467 12.978 16.811 12.8226C16.8752 12.6671 16.8919 12.496 16.8588 12.3311C16.8258 12.1661 16.7446 12.0146 16.6254 11.8959C16.4661 11.7371 16.2503 11.648 16.0254 11.648C15.8005 11.648 15.5847 11.7371 15.4254 11.8959Z'
			fill='#495057'
		/>
		<path
			d='M15.0004 14.5259H9.00039V16.2259H15.0004V14.5259Z'
			fill='#495057'
		/>
	</svg>
);

export const IconProblemCategory: FC<SVGProps<SVGSVGElement>> = (props) => (
	<svg
		width='24'
		height='25'
		viewBox='0 0 24 25'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='M16.7004 19.8514H7.30039C5.84039 19.8514 4.65039 18.6614 4.65039 17.2014V7.80137C4.65039 6.34137 5.84039 5.15137 7.30039 5.15137H16.7004C18.1604 5.15137 19.3504 6.34137 19.3504 7.80137V17.2014C19.3504 18.6614 18.1604 19.8514 16.7004 19.8514ZM7.30039 6.85137C6.77539 6.85137 6.35039 7.27637 6.35039 7.80137V17.2014C6.35039 17.7264 6.77539 18.1514 7.30039 18.1514H16.7004C17.2254 18.1514 17.6504 17.7264 17.6504 17.2014V7.80137C17.6504 7.27637 17.2254 6.85137 16.7004 6.85137H7.30039Z'
			fill='#495057'
		/>
		<path
			d='M8.55539 16.1464L7.45039 14.8564L10.2004 12.5014L7.45039 10.1464L8.55539 8.85637L11.5254 11.4014C11.8454 11.6764 12.0304 12.0764 12.0304 12.5014C12.0304 12.9264 11.8454 13.3264 11.5254 13.6014L8.55539 16.1464Z'
			fill='#495057'
		/>
		<path
			d='M16.5004 14.4464H12.5004V16.1464H16.5004V14.4464Z'
			fill='#495057'
		/>
	</svg>
);

export const IconUseLanguage: FC<SVGProps<SVGSVGElement>> = (props) => (
	<svg
		width='24'
		height='25'
		viewBox='0 0 24 25'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='M8.36039 17.116L4.60039 13.551C4.31039 13.276 4.15039 12.896 4.15039 12.501C4.15039 12.106 4.31539 11.721 4.60039 11.451L8.36039 7.88598L9.53039 9.12098L5.96039 12.506L9.53039 15.891L8.36039 17.126V17.116Z'
			fill='#495057'
		/>
		<path
			d='M15.6404 17.116L14.4704 15.881L18.0404 12.496L14.4704 9.11098L15.6404 7.87598L19.4004 11.441C19.6904 11.716 19.8504 12.096 19.8504 12.491C19.8504 12.886 19.6854 13.271 19.4004 13.541L15.6404 17.106V17.116Z'
			fill='#495057'
		/>
		<path
			d='M11.9437 7.92041L10.3824 16.784L12.0566 17.0789L13.6179 8.21532L11.9437 7.92041Z'
			fill='#495057'
		/>
	</svg>
);

export const IconExposureFrequency: FC<SVGProps<SVGSVGElement>> = (props) => (
	<svg
		width='24'
		height='25'
		viewBox='0 0 24 25'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='M16.7004 5.15137H7.30039C5.84039 5.15137 4.65039 6.34137 4.65039 7.80137V14.2014C4.65039 15.6614 5.84039 16.8514 7.30039 16.8514H11.1004V18.1514H9.00039V19.8514H15.0004V18.1514H12.9004V16.8514H16.7004C18.1604 16.8514 19.3504 15.6614 19.3504 14.2014V7.80137C19.3504 6.34137 18.1604 5.15137 16.7004 5.15137ZM17.6504 14.2014C17.6504 14.7264 17.2254 15.1514 16.7004 15.1514H7.30039C6.77539 15.1514 6.35039 14.7264 6.35039 14.2014V7.80137C6.35039 7.27637 6.77539 6.85137 7.30039 6.85137H16.7004C17.2254 6.85137 17.6504 7.27637 17.6504 7.80137V14.2014Z'
			fill='#495057'
		/>
		<path
			d='M15.0004 8.65137H9.00039V10.3514H15.0004V8.65137Z'
			fill='#495057'
		/>
		<path
			d='M15.0004 11.6514H9.00039V13.3514H15.0004V11.6514Z'
			fill='#495057'
		/>
	</svg>
);
