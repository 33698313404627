import { PropsWithChildren, ReactNode } from 'react';
import type { UseFormRegisterReturn } from 'react-hook-form';
import NumberInput from '@widget/input-number';
import { Container, ErrorText } from './style.ts';

interface ScoreProps extends PropsWithChildren {
  register: UseFormRegisterReturn<
    | `stepProblemList.${number}.scoreRate`
    | `stepProblemList.${number}.passScore`
  >;
  placeholder?: string;
  extraInfo?: ReactNode;
  min?: number;
  max?: number;
  error?: string;
  inputId?: string;
  value?: number;
}

function Score({
  register,
  children,
  placeholder,
  extraInfo,
  min,
  max,
  error,
  inputId,
  value,
}: ScoreProps) {
  return (
    <Container>
      {children}
      <NumberInput
        id={inputId}
        data-testid={inputId}
        type={'number'}
        placeholder={placeholder}
        style={{ width: 320 }}
        min={min}
        max={max}
        value={value}
        {...register}
      />
      {extraInfo}
      <ErrorText>{error}</ErrorText>
    </Container>
  );
}

export default Score;
