import { ChangeEventHandler, CSSProperties, FC, forwardRef } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { Container, Input } from './style';
import { InputTypes, usePasswordInput } from './hook';

interface PasswordInputProps {
  register?: UseFormRegisterReturn;
  placeholder?: string;
  onChange?: ChangeEventHandler;
  fontColor?: string;
  placeholderColor?: string;
  backgroundColor?: string;
  style?: CSSProperties;
  readOnly?: boolean;
  disabled?: boolean;
  value?: string;
}

const PasswordInput: FC<PasswordInputProps> = forwardRef(
  (
    {
      register,
      placeholder,
      onChange,
      placeholderColor,
      backgroundColor,
      style,
      disabled,
      readOnly,
      value,
    },
    ref,
  ) => {
    const { visible, toggleVisible } = usePasswordInput();

    return (
      <Container backgroundColor={backgroundColor} style={style}>
        <Input
          type={visible}
          placeholder={placeholder}
          onChange={onChange}
          placeholderColor={placeholderColor}
          style={style}
          disabled={disabled}
          readOnly={readOnly}
          value={value}
          fontColor={'#212529'}
          {...ref}
          {...register}
        />
        {visible === InputTypes[1] && (
          <AiOutlineEye
            size={24}
            onClick={toggleVisible}
            style={{ color: 'rgba(0,0,0,.45)' }}
          />
        )}
        {visible === InputTypes[0] && (
          <AiOutlineEyeInvisible
            size={24}
            onClick={toggleVisible}
            style={{ color: 'rgba(0,0,0,.45)' }}
          />
        )}
      </Container>
    );
  },
);

export default PasswordInput;
