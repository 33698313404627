import { FC } from 'react';
import { Container } from '@pages/Data/Algorithms/style.ts';
import Function from '@pages/Data/Level/Function';
import Info from '@pages/Data/Level/Info';
import Table from '@pages/Data/Level/Table';
import { useLevel } from '@pages/Data/Level/hook.ts';

const DataLevel: FC = () => {
  const { rsData, setDataByExcel, tableData } = useLevel();
  return (
    <Container>
      <Function rsData={rsData} setData={setDataByExcel} />
      <Info recentSyncDate={rsData?.syncDateTime ?? '-'} />
      <Table data={tableData} />
    </Container>
  );
};
export default DataLevel;
