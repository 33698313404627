import { useMemo } from 'react';

interface UseInputTableProps {
  parameterLength: number;
}

export function useInputTable({ parameterLength }: UseInputTableProps) {
  const colSpan = useMemo(() => parameterLength, [parameterLength]);

  return {
    colSpan,
  };
}
