import { FC, ReactNode } from 'react';
import BorderTable, { BorderTableHeaderType } from '@widget/table-border';
import { TestCaseDTO } from '@phs/interfaces';

interface TableProps {
  headers: BorderTableHeaderType[];
  data: TestCaseDTO[];
  renderData: (data: TestCaseDTO[]) => ReactNode[][];
}

const Table: FC<TableProps> = ({ headers, data, renderData, ...rest }) => {
  return <BorderTable headers={headers} data={renderData(data)} {...rest} />;
};

export default Table;
