import { FC } from 'react';
import { Container } from './style';
import { Outlet } from 'react-router-dom';
import Tab from '@widget/tab';
import { Menu } from './config.ts';
import { PMS_PAGES } from '@phs/constants';
import { useTab } from '@hooks/useTab.ts';

const ManagePage: FC = () => {
  const { onClick, currentTab } = useTab({
    items: Menu,
    navigateList: [
      PMS_PAGES.MANAGE.HOME,
      PMS_PAGES.MANAGE.DOCUMENT,
      PMS_PAGES.MANAGE.OPERATION,
    ],
  });

  return (
    <Container>
      <Tab item={Menu} activeKey={currentTab} onClick={onClick} />
      <Outlet />
    </Container>
  );
};

export default ManagePage;
