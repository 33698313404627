import {
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormGetValues,
  UseFormTrigger,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { FormEventHandler, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import _ from 'lodash-es';
import { isAxiosError } from 'axios';
import { packageCurrentLanguages } from '@stores/selectors/exam/package.ts';
import { CodeGenerator } from '@phs/code';
import {
  ModifyCommandType,
  PackageProblemMakeReqDTO,
  PhaseProblemDTO,
} from '@phs/interfaces';
import { useUpdatePackageExam } from '@hooks/exam/usePackageExam.ts';
import { PMS_PAGES } from '@phs/constants';
import { useAlertModal } from '@components/Modals/Alert/hook.ts';
import { usePhaseValidation } from '@pages/Exam/package/shared/usePhaseValidation.ts';
import { DefaultTabItem, PackageExamFormProps } from '../config.ts';
import pstRepository from '@repositories/pst.repository.ts';

interface UseEditExamProps {
  hasTitle?: boolean;
  hasLevel?: boolean;
  append?: UseFieldArrayAppend<PackageExamFormProps, 'stepProblemList'>;
  remove?: UseFieldArrayRemove;
  stepProblemList?: PhaseProblemDTO[];
  getValues: UseFormGetValues<PackageExamFormProps>;
  sn: string;
  trigger: UseFormTrigger<PackageExamFormProps>;
}

export function useEditExam({
  hasTitle = false,
  hasLevel = false,
  append,
  remove,
  getValues,
  stepProblemList = [],
  sn,
  trigger,
}: UseEditExamProps) {
  const { validate } = usePhaseValidation(getValues('stepProblemList'));
  const { onOpen } = useAlertModal();
  const navigate = useNavigate();
  const { update, isLoading: isUpdateLoading } = useUpdatePackageExam();
  const currentLanguages = useRecoilValue(packageCurrentLanguages);

  const [cursor, setCursor] = useState<number>(0);
  const deletedStepProblemList = useRef<PhaseProblemDTO[]>([]);

  const items = useMemo(() => {
    const stepProblemItems = stepProblemList.map((_, i) => ({
      name: `단계 ${i + 1}`,
      key: `package-tab-item-${i + 1}`,
    }));

    return [DefaultTabItem, ...stepProblemItems];
  }, [stepProblemList]);

  const getTotalAlgorithmList = () =>
    [
      ...new Set(
        stepProblemList
          .map((stepProblem) => stepProblem.algorithmTypeList)
          .flat()
          .filter((v) => v !== undefined),
      ),
    ] as string[];

  const getTotalExpectStepPassRate = () =>
    stepProblemList
      .map((stepProblem) => stepProblem.expectStepPassRate)
      .filter((v) => v !== undefined) as number[];

  const currentActiveKey = useMemo(() => items[cursor].key, [cursor, items]);

  const stepCursor = useMemo(() => (cursor > 1 ? cursor - 1 : 0), [cursor]);

  const onAdd = () => {
    if (!hasTitle) return onOpen('제목을 입력해주세요.');
    if (!hasLevel) return onOpen('종합 난이도를 선택하지 않았습니다.');
    if (currentLanguages.length < 1) {
      return onOpen('언어를 선택하지 않았습니다.');
    }

    append?.(
      CodeGenerator.getPackageTemplate({
        judgeLanguageList: currentLanguages,
        number: items.length,
      }),
    );

    setTimeout(() => onChangeCursor(items.length), 100);
  };

  const onRemove = (idx: number) => {
    const targetStepProblem = stepProblemList[idx - 1];
    targetStepProblem.commandType = ModifyCommandType.DELETE;
    if (targetStepProblem.sn) {
      deletedStepProblemList.current.push(targetStepProblem);
    }
    const itemLength = items.length;
    if (cursor === itemLength - 1) {
      onChangeCursor(cursor - 1);
    }
    remove?.(idx - 1);
  };

  const onChangeCursor = (idx: number) => {
    if (!hasTitle) return onOpen('제목을 입력해주세요.');
    if (!hasLevel) return onOpen('종합 난이도를 선택하지 않았습니다.');
    if (currentLanguages.length < 1) {
      return onOpen('언어를 선택하지 않았습니다.');
    }
    setCursor(idx);
  };

  const removePropertiesFromStep = (step: PhaseProblemDTO) => {
    const data = {
      ...step,
      judgeLanguageList: step.judgeLanguageList
        ?.filter((item) => item.commandType !== ModifyCommandType.READ)
        .map((item) => ({
          ...item,
          referenceSource: item.referenceSourceYn ? item.referenceSource : '',
        })),
      testCaseList: step.testCaseList
        .filter((testCase) => testCase.commandType !== ModifyCommandType.READ)
        .map((testCase) => {
          const newTestCaseLanguageSet = testCase.testCaseLanguageSet.filter(
            ({ commandType }) => commandType !== ModifyCommandType.READ,
          );
          return {
            ...testCase,
            testCaseLanguageSet: newTestCaseLanguageSet,
          };
        }),
    };

    delete data.parameters;
    if (!data.testCaseScoreRateYn) {
      delete data.accuracyScoreRate;
      delete data.efficiencyScoreRate;
    }
    if (!data.summaryYn) {
      data.summary = '';
    }
    return data;
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    trigger();

    const { title, level, detailLevel, stepProblemList, manualDifficultyYn } =
      getValues();

    if (title === '') {
      onOpen('제목을 입력해주세요.');
      return;
    }

    if (level < 1) {
      onOpen('종합 난이도를 선택하지 않았습니다.');
      return;
    }

    const stepChanged =
      stepProblemList.some(
        ({ commandType }) => commandType !== ModifyCommandType.READ,
      ) || deletedStepProblemList.current.length > 0;
    const hasHistory =
      stepChanged && (await pstRepository.checkProblemHistory(sn));

    if (
      hasHistory &&
      !confirm(
        '단계 추가/제거 이력이 있습니다. 통계정보는 유지되어 쌓이니 주의해주시기 바랍니다.',
      )
    ) {
      return;
    }

    const data: PackageProblemMakeReqDTO = {
      title,
      manualDifficultyYn,
      packageLevel: level.toString(),
      packageDetailLevel: detailLevel,
      stepProblemList: [
        ...stepProblemList
          .map((data, i) => ({
            ...data,
            number: i + 1,
            commandType:
              data.number !== i + 1 &&
              data.commandType === ModifyCommandType.READ
                ? ModifyCommandType.UPDATE
                : data.commandType,
          }))
          .filter(({ commandType }) => commandType !== ModifyCommandType.READ)
          .map((step) => removePropertiesFromStep(step)),
        ...deletedStepProblemList.current.map((step) =>
          removePropertiesFromStep(step),
        ),
      ],
      onlyProblemUpdateYn: false,
    };

    const payload = {
      sn,
      data,
    };

    validate(() => {
      update(payload, {
        onSuccess: () => {
          navigate(PMS_PAGES.DASHBOARD.PACKAGES);
        },
        onError: (error) => {
          if (!isAxiosError(error)) {
            return onOpen('문제 정보 수정 시 에러가 발생했습니다.');
          }

          onOpen(
            error.response!.data?.message ||
              '문제 정보 수정 시 에러가 발생했습니다.',
          );
        },
      });
    });
  };

  return {
    cursor,
    stepCursor,
    items,
    currentActiveKey,
    onAdd,
    onRemove,
    onChangeCursor,
    onSubmit,
    isUpdateLoading,
    getTotalAlgorithmList,
    getTotalExpectStepPassRate,
  };
}
