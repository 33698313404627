import { atomKeys } from '@constants/key';
import { FilterDTO } from '@interfaces/dashboard/filter.dto';
import { atom } from 'recoil';

const fallback: Omit<FilterDTO, 'searchKeyword'> = {
  level: [],
  judgeType: [],
  language: [],
  publicType: [],
  step: [],
  packageLevel: [],
  algorithmType: [],
};

export const level = atom<string[]>({
  key: atomKeys.filter.level,
  default: fallback.level,
});

export const judgeType = atom<string[]>({
  key: atomKeys.filter.judgeType,
  default: fallback.judgeType,
});

export const language = atom<string[]>({
  key: atomKeys.filter.language,
  default: fallback.language,
});

export const publicType = atom<string[]>({
  key: atomKeys.filter.publicType,
  default: fallback.publicType,
});

export const step = atom<string[]>({
  key: atomKeys.filter.step,
  default: fallback.step,
});

export const packageLevel = atom<string[]>({
  key: atomKeys.filter.packageLevel,
  default: fallback.packageLevel,
});

export const algorithmType = atom<string[]>({
  key: atomKeys.filter.algorithmType,
  default: fallback.algorithmType,
});
