import type { Nullable } from '@phs/interfaces';
import type { Dayjs } from 'dayjs';

export interface FormProps {
  title: string;
  startDateTime: Nullable<Dayjs>;
  endDateTime: Nullable<Dayjs>;
  todayOption: boolean;
  pause: boolean;
  image: Nullable<Partial<FileList>>;
  imageLink?: string;
  imageLinkOption: boolean;
  fileUid?: string;
  width: number;
  height: number;
  left: number;
  up: number;
}

export const fallback: FormProps = {
  title: '',
  startDateTime: null,
  endDateTime: null,
  todayOption: false,
  pause: false,
  image: null,
  imageLink: '',
  imageLinkOption: false,
  fileUid: undefined,
  width: 0,
  height: 0,
  left: 0,
  up: 0,
};
