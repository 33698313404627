import { InputTableParameterProps } from '@widget/table-input';
import { FunctionParameterAndReturnTypeDTO } from '@phs/interfaces';
import { CodeTransformer } from '@phs/code';

export const renderParameters = ({
  paramInfo,
}: FunctionParameterAndReturnTypeDTO): InputTableParameterProps[] => {
  return paramInfo.map(({ paramName, paramType }) => ({
    name: `${paramName} (${CodeTransformer.dataType2Text[paramType]})`,
    key: `${paramName}`,
  }));
};
