export const PageQueryOptions = [
  {
    name: '10',
    key: '10',
  },
  {
    name: '50',
    key: '50',
  },
  {
    name: '100',
    key: '100',
  },
];
