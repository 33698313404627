import styled from '@emotion/styled';

export const Container = styled.nav`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 64px;
  background: #212529;
`;

export const Logo = styled.div`
  box-sizing: border-box;
  padding: 22px 24px;
  list-style-type: none;
`;

export const Content = styled.ul`
  display: block;
  list-style-type: none;
  padding: 0;
`;

export const HeaderItem = styled.li<{ isSelected?: boolean }>`
  float: left;

  &:not(&:last-of-type) {
    margin-right: 12px;
  }

  a {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: fit-content;
    height: 52px;
    padding: 14px 32px 14px 28px;
    margin-top: 12px;
    color: ${({ isSelected }) => (isSelected ? '#343A40' : '#F1F3F5')};
    text-align: center;
    cursor: pointer;
    background: ${({ isSelected }) => (isSelected ? '#F8F9FA' : '#495057')};
    border-radius: 8px 8px 0 0;

    span {
      margin-left: 14px;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }

    &:active {
      color: #343a40;
      background: #495057;
    }

    &:hover {
      color: #f1f3f5;
      background: #343a40;
    }
  }
`;

export const RightMenuWrap = styled.li`
  float: right;
  line-height: 64px;
  cursor: pointer;
`;
