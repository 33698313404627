import { inject, injectable } from 'inversify';
import { BulkFileDTO, DataType } from '@phs/interfaces';
import { TYPES } from '../types';
import { CodeValidator } from './validator';
import { CheckDataTypesReturnDTO } from '../interfaces/validator.dto';

export interface FileHandlerProps {
  validateExt(target: string, pattern: string): boolean;

  validateSize(size: number): boolean;

  validateFile(
    dataTypes: DataType[],
    file: File,
  ): Promise<CheckDataTypesReturnDTO>;

  validateFileMap(fileList: FileList): {
    status: boolean;
    result: string | Map<string, BulkFileDTO>;
  };
}

@injectable()
export class FileHandler implements FileHandlerProps {
  private readonly KB = 1024;
  private readonly MB = this.KB * this.KB;
  private readonly maximumSize = 20 * this.MB;

  constructor(
    @inject(TYPES.VALIDATOR) private readonly validator: CodeValidator,
  ) {}

  public validateExt(target: string, pattern: string) {
    return new RegExp(pattern).test(target);
  }

  public validateSize(size: number) {
    return size < this.maximumSize;
  }

  public async validateFile(
    dataTypes: DataType[],
    file: File,
  ): Promise<CheckDataTypesReturnDTO> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        try {
          resolve(
            this.validator.checkDataTypes(dataTypes, reader.result as string),
          );
        } catch (error: unknown) {
          if ((error as any)?.message) {
            reject(
              `${file.name} : ${
                (error as any)?.message || '알 수 없는 문제가 발생하였습니다.'
              }`,
            );
          }
        }
      };

      reader.onerror = () =>
        reject(`${file.name}파일을 읽는데 문제가 발생하였습니다.`);

      reader.readAsText(file, 'UTF-8');
    });
  }

  public validateFileMap(fileList: FileList) {
    const fileMap = this.makeFileMap(fileList);

    for (const [fileName, file] of fileMap.entries()) {
      if (!file.input) {
        return {
          status: false,
          result: `${fileName}.in 파일이 없습니다.`,
        };
      }
      if (!file.output) {
        return {
          status: false,
          result: `${fileName}.out 파일이 없습니다.`,
        };
      }
    }

    return {
      status: true,
      result: fileMap,
    };
  }

  private makeFileMap(fileList: FileList) {
    const fileMap = new Map<string, BulkFileDTO>();

    for (const _file of fileList) {
      const fileInfo = _file.name.split('.');
      const fileName = fileInfo[0];
      const fileExt = fileInfo[1];
      const findFile = fileMap.get(fileName);
      const blob = _file.slice(0, _file.size, 'text/plain');
      const file = new File([blob], fileName + `.${fileExt}` + '.txt', {
        type: 'text/plain',
      });
      if (fileExt === 'in') {
        if (findFile) {
          findFile.input = file;
        } else {
          fileMap.set(fileName, { input: file });
        }
      } else {
        if (findFile) {
          findFile.output = file;
        } else {
          fileMap.set(fileName, { output: file });
        }
      }
    }

    return fileMap;
  }
}
