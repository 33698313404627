export const Menu: Array<{ name: string; key: string }> = [
	{
		name: '단일풀이형',
		key: '단일풀이형',
	},
	{
		name: '추가 조건 제시형',
		key: '추가 조건 제시형',
	},
];
