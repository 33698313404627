import { useEffect, useMemo, useRef, useState } from 'react';

export type DropdownItemType = {
  name: string | number;
  key: string | number;
};

type UseDropDownProps = {
  initialState?: string | number;
  title?: string;
  isOpen?: boolean;
  onClick?: (props: DropdownItemType) => void;
  items?: DropdownItemType[];
};

export function useDropDown(props: UseDropDownProps) {
  const [currentChoice, setCurrentChoice] = useState<string | number | null>(
    props.initialState ?? null,
  );
  const [isActive, setIsActive] = useState<boolean>(
    props.isOpen as boolean,
  );

  const isOpen = useMemo(() => isActive, [isActive]);

  const handleClick = (dropdownItem: DropdownItemType) => {
    props?.onClick?.(dropdownItem);
    setIsActive(false);
    setCurrentChoice(dropdownItem.name);
  };

  useEffect(() => {
    if (!props.initialState) return;
    const selectedItem = props?.items?.find(v => v.key === props.initialState);
    setCurrentChoice(selectedItem?.name ?? props.title ?? null);
  }, [props]);

  return {
    currentChoice,
    isOpen,
    setOpen: setIsActive,
    handleClick,
  };
}

export function useOutsideClick(callback: () => void) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [callback]);

  return ref;
}
