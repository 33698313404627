import { DropdownItemType } from '@widget/dropdown';

export const LEVEL_ITEMS: DropdownItemType[] = [
	{
		name: '1 (기초)',
		key: 1,
	},
	{
		name: '2 (초급)',
		key: 2,
	},
	{
		name: '3 (중급)',
		key: 3,
	},
	{
		name: '4 (고급)',
		key: 4,
	},
	{
		name: '5 (전문가)',
		key: 5,
	},
];
