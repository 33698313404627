import {
  IconC,
  IconJava,
  IconR,
  IconRuby,
  IconCPP,
  IconCSHARP,
  IconSwift,
  IconScala,
  IconPython,
  IconKotlin,
  IconJavascript,
  IconJavascript48,
  IconPypy,
  IconGo,
} from './Icons';
import { LanguageType } from '@phs/interfaces';
import { ReactNode } from 'react';

export interface LanguageConfigProps {
  editorLanguage: string;
  reportLanguage: string;
  svg: (id: string, size: number) => ReactNode;
  defaultTimeLimit: number;
}

export const LanguageConfig: Record<
  keyof typeof LanguageType,
  LanguageConfigProps
> = {
  C: {
    editorLanguage: 'c',
    reportLanguage: 'C',
    svg: (id: string, size: number) => (
      <IconC id={id} width={size} height={size} />
    ),
    defaultTimeLimit: 1000,
  },
  CSHARP: {
    editorLanguage: 'csharp',
    reportLanguage: 'C#',
    svg: (id: string, size: number) => (
      <IconCSHARP id={id} width={size} height={size} />
    ),
    defaultTimeLimit: 3000,
  },
  CPP: {
    editorLanguage: 'cpp',
    reportLanguage: 'C++',
    svg: (id: string, size: number) => (
      <IconCPP id={id} width={size} height={size} />
    ),
    defaultTimeLimit: 1000,
  },
  JAVA: {
    editorLanguage: 'java',
    reportLanguage: 'Java',
    svg: (id: string, size: number) => (
      <IconJava id={id} width={size} height={size} />
    ),
    defaultTimeLimit: 3000,
  },
  JAVASCRIPT: {
    editorLanguage: 'javascript',
    reportLanguage: 'Javascript',
    svg: (id: string, size: number) => {
      if (size === 48) {
        return <IconJavascript48 id={id} width={size} height={size} />;
      }
      return <IconJavascript id={id} width={size} height={size} />;
    },
    defaultTimeLimit: 5000,
  },
  PYTHON: {
    editorLanguage: 'python',
    reportLanguage: 'Python',
    svg: (id: string, size: number) => (
      <IconPython id={id} width={size} height={size} />
    ),
    defaultTimeLimit: 5000,
  },
  PYPY: {
    editorLanguage: 'python',
    reportLanguage: 'Python',
    svg: (id: string, size: number) => (
      <IconPypy id={id} width={size} height={size} />
    ),
    defaultTimeLimit: 5000,
  },
  RUBY: {
    editorLanguage: 'ruby',
    reportLanguage: 'Ruby',
    svg: (id: string, size: number) => (
      <IconRuby id={id} width={size} height={size} />
    ),
    defaultTimeLimit: 5000,
  },
  SCALA: {
    editorLanguage: 'scala',
    reportLanguage: 'Scala',
    svg: (id: string, size: number) => (
      <IconScala id={id} width={size} height={size} />
    ),
    defaultTimeLimit: 5000,
  },
  SWIFT: {
    editorLanguage: 'swift',
    reportLanguage: 'Swift',
    svg: (id: string, size: number) => (
      <IconSwift id={id} width={size} height={size} />
    ),
    defaultTimeLimit: 5000,
  },
  KOTLIN: {
    editorLanguage: 'kotlin',
    reportLanguage: 'Kotlin',
    svg: (id: string, size: number) => (
      <IconKotlin id={id} width={size} height={size} />
    ),
    defaultTimeLimit: 3000,
  },
  R: {
    editorLanguage: 'r',
    reportLanguage: 'R',
    svg: (id: string, size: number) => (
      <IconR id={id} width={size} height={size} />
    ),
    defaultTimeLimit: 5000,
  },
  GO: {
    editorLanguage: 'go',
    reportLanguage: 'Go',
    svg: (id, size: number, key?: string) => (
      <IconGo key={key} id={id} width={size} height={size} />
    ),
    defaultTimeLimit: 5000,
  },
  DEFAULT: {
    editorLanguage: '',
    reportLanguage: '',
    svg: (_: string, __: number) => <></>,
    defaultTimeLimit: 5000,
  },
};
