import { FC, SVGProps } from 'react';

type labelColor = 'white' | 'gray';

const IconClose: FC<SVGProps<SVGSVGElement>> = (props) => (
	<svg
		width='18'
		height='18'
		viewBox='0 0 18 18'
		xmlns='http://www.w3.org/2000/svg'
		fill={props.fill || '#ADB5BD'}
		{...props}
	>
		<path d='M13.9508 5.51329L13.0508 4.61328L9.00078 8.66328L4.95078 4.61328L4.05078 5.51329L8.09703 9.56329L4.05078 13.6133L4.95078 14.5133L9.00078 10.4633L13.0508 14.5133L13.9508 13.6133L9.90078 9.56329L13.9508 5.51329Z' />
	</svg>
);

export { IconClose, type labelColor };
