import { css } from '@emotion/react';

export const ScrollBarStyle = css`
  /* width */

  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */

  ::-webkit-scrollbar-track {
    background: #f8f9fa;
  }

  /* Handle */

  ::-webkit-scrollbar-thumb {
    background: #ced4da;
  }

  /* Handle on hover */

  ::-webkit-scrollbar-thumb:hover {
    background: #868e96;
  }
`;
