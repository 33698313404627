import {
  RequireTitle,
  UploadFileWrapper,
  ImageName,
  Hint,
  CheckboxTitle,
  InputWrap,
  Error,
} from './style';
import { ChangeEvent, FC } from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import UploadButton from '@widget/button-upload';
import TextButton from '@widget/button-text';
import Checkbox from '@widget/checkbox';
import TextInput from '@widget/input-text';
import { FormProps } from '../config';
import { FILE_ACCEPTS } from './config';

interface ContentSettingProps {
  register: UseFormRegister<FormProps>;
  errors?: FieldErrors<FormProps>;
  defaultName?: string;
  defaultImageLink?: string;
  imageLinkOption?: boolean;
  onChangeFileInput: (e: ChangeEvent<HTMLInputElement>) => void;
  onCancelClick?: () => void;
  onDownloadClick?: () => void;
}

const ContentSetting: FC<ContentSettingProps> = ({
  register,
  errors,
  defaultName = '',
  defaultImageLink = '',
  imageLinkOption = false,
  onChangeFileInput,
  onCancelClick,
  onDownloadClick,
}) => {
  return (
    <>
      <RequireTitle>이미지 업로드</RequireTitle>
      <UploadFileWrapper>
        <UploadButton
          onChange={onChangeFileInput}
          accept={FILE_ACCEPTS.join(', ')}
          style={{ width: 88 }}
        >
          파일 찾기
        </UploadButton>
        <ImageName disabled={true} defaultValue={defaultName} />
        <TextButton
          theme={'primary'}
          style={{ width: 120 }}
          onClick={onCancelClick}
        >
          파일 삭제
        </TextButton>
        <TextButton
          theme={'primary'}
          style={{ width: 148 }}
          onClick={onDownloadClick}
        >
          파일 다운로드
        </TextButton>
      </UploadFileWrapper>
      <Error>{errors?.image?.message}</Error>
      <Hint>
        <li>이미지 최소 크기: 200 x 200, 이미지 최대 크기 : 1000 x 500</li>
        <li>업로드 가능 최대 용량 : 10MB</li>
      </Hint>
      <Checkbox
        {...register('imageLinkOption')}
        id={'imageLinkOption'}
        isActive={imageLinkOption}
      >
        <CheckboxTitle>이미지 클릭시 링크 적용</CheckboxTitle>
      </Checkbox>
      <InputWrap>
        <TextInput
          {...register('imageLink')}
          placeholder={'링크를 입력해주세요.'}
          disabled={!imageLinkOption}
          defaultValue={defaultImageLink}
        />
      </InputWrap>
    </>
  );
};

export default ContentSetting;
