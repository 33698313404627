import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { PMS_PAGES } from '@phs/constants';
import { CodeGenerator, CodeTestCase } from '@phs/code';
import {
  LanguageDTO,
  ModifyCommandType,
  ProblemCategory,
  TestCaseDTO,
} from '@phs/interfaces';
import { useAlertModal } from '@components/Modals/Alert/hook.ts';
import { DropdownItemType } from '@widget/dropdown';
import { zodResolver } from '@hookform/resolvers/zod';
import { MakeProblemReqDTO } from '@interfaces/dashboard/problem.dto.ts';
import { useReadExam, useUpdateExam } from '@hooks/exam/useExam.ts';
import { useTestCase } from '@hooks/exam/useTestCase.ts';
import { FormProps, initWithFallback } from '../../shared/config.ts';
import { schema } from '../../shared/scheme.ts';

interface UseExamProps {
  sn?: string;
  languageList?: LanguageDTO[];
}

export function useExam({ sn, languageList = [] }: UseExamProps) {
  const {
    watch,
    register,
    formState: { errors },
    setValue,
    getValues,
    trigger,
    handleSubmit,
    clearErrors,
  } = useForm<FormProps>({
    defaultValues: initWithFallback(languageList),
    resolver: zodResolver(schema),
  });

  const navigation = useNavigate();
  const { onOpen } = useAlertModal();
  const { update, isLoading: isUpdateLoading } = useUpdateExam();
  const testCases = useTestCase(sn || '');
  const currentExam = useReadExam({ sn: sn || '' });

  const isAbleToAddTestCase = useMemo(() => {
    const languages = getValues('languages');
    return languages.filter(({ usage }) => usage).length == 0;
  }, [watch('languages')]);

  const isEnableCalculation = useMemo(() => {
    const accuracyTestCases = getValues('accuracyTestCases') ?? [];
    const accuracyFileTestCases = getValues('accuracyFileTestCases') ?? [];
    const efficiencyTestCases = getValues('efficiencyTestCases') ?? [];
    const efficiencyFileTestCases = getValues('efficiencyFileTestCases') ?? [];

    return (
      (accuracyTestCases.filter(
        ({ commandType }) => commandType !== ModifyCommandType.DELETE,
      ).length > 0 ||
        accuracyFileTestCases.filter(
          ({ commandType }) => commandType !== ModifyCommandType.DELETE,
        ).length > 0) &&
      (efficiencyTestCases.filter(
        ({ commandType }) => commandType !== ModifyCommandType.DELETE,
      ).length > 0 ||
        efficiencyFileTestCases.filter(
          ({ commandType }) => commandType !== ModifyCommandType.DELETE,
        ).length > 0)
    );
  }, [
    watch('accuracyTestCases'),
    watch('accuracyFileTestCases'),
    watch('efficiencyTestCases'),
    watch('efficiencyFileTestCases'),
  ]);

  useEffect(() => {
    if (isEnableCalculation) return;
    setValue('scoreRateYn', false);
  }, [isEnableCalculation]);

  const onChangeLevel = (props: DropdownItemType) => {
    setValue('level', Number(props.key));
  };

  const onChangeDetailLevel = (props: DropdownItemType) => {
    setValue('detailLevel', Number(props.key));
  };

  const onChangeAlgorithm = (props: string[]) => {
    setValue('algorithmTypeList', props);
  };

  const isEmptyTestCase = (testCases: TestCaseDTO[]) => {
    return (
      testCases.filter(
        ({ commandType }) => commandType !== ModifyCommandType.DELETE,
      ).length === 0
    );
  };

  const onSubmit = () => {
    const {
      title,
      content,
      level,
      detailLevel,
      algorithmTypeList,
      scoreRateYn,
      accuracyScoreRate,
      efficiencyScoreRate,
      languages,
      exampleTestCases = [],
      accuracyTestCases = [],
      efficiencyTestCases = [],
      accuracyFileTestCases = [],
      efficiencyFileTestCases = [],
    } = getValues();

    const usingLanguageList = languages.filter(({ usage }) => usage);
    if (usingLanguageList.length === 0) {
      onOpen('언어를 선택하지 않았습니다.');
      return;
    }

    if (isEmptyTestCase(exampleTestCases)) {
      onOpen('예시 테스트 케이스가 없습니다.');
      return;
    }

    if (
      isEmptyTestCase(accuracyTestCases) &&
      isEmptyTestCase(accuracyFileTestCases)
    ) {
      onOpen('정확도 테스트 케이스가 없습니다.');
      return;
    }

    const payload: MakeProblemReqDTO = {
      title,
      content,
      level: level.toString(),
      detailLevel,
      algorithmTypeList,
      judgeType: ProblemCategory.STDIO,
      judgeLanguageList: languages.filter(
        ({ commandType }) =>
          commandType && commandType !== CodeTestCase.commandType.READ,
      ),
      testCaseList: CodeTestCase.mergeTestCases(ProblemCategory.STDIO, [
        exampleTestCases,
        accuracyTestCases,
        accuracyFileTestCases,
        efficiencyTestCases,
        efficiencyFileTestCases,
      ]),
      testCaseScoreRateYn: scoreRateYn,
      accuracyScoreRate: scoreRateYn ? accuracyScoreRate : undefined,
      efficiencyScoreRate: scoreRateYn ? efficiencyScoreRate : undefined,
      onlyProblemUpdateYn: false,
    };

    update(
      {
        sn: sn!,
        data: payload,
      },
      {
        onSuccess: () => {
          navigation(PMS_PAGES.DASHBOARD.HOME);
        },
        onError: () => {
          onOpen('문제 수정에 실패하였습니다.');
        },
      },
    );
  };

  useEffect(() => {
    if (!sn) return navigation(PMS_PAGES.DASHBOARD.HOME, { replace: true });

    const {
      title,
      content,
      level,
      detailLevel,
      algorithmTypeList,
      overallPredictionAverageScore,
      overallPredictionLevel,
      testCaseScoreRateYn,
      accuracyScoreRate,
      efficiencyScoreRate,
    } = currentExam;

    setValue('title', title);
    setValue('content', content);
    setValue('level', parseInt(level ?? '0'));
    setValue('detailLevel', detailLevel);
    setValue('algorithmTypeList', algorithmTypeList);
    setValue('overallPredictionAverageScore', overallPredictionAverageScore);
    setValue('overallPredictionLevel', overallPredictionLevel);
    setValue('scoreRateYn', testCaseScoreRateYn);
    if (testCaseScoreRateYn) {
      setValue('accuracyScoreRate', accuracyScoreRate ?? 50);
      setValue('efficiencyScoreRate', efficiencyScoreRate ?? 50);
    }

    const {
      exampleTestCases,
      accuracyTestCases,
      accuracyFileTestCases,
      efficiencyTestCases,
      efficiencyFileTestCases,
    } = CodeTestCase.splitTestCases(
      CodeTestCase.removeEscape(
        testCases?.problemTestCaseRsList ?? [],
        CodeTestCase.problemType.STDIO,
      ),
    );

    setValue('exampleTestCases', CodeTestCase.readTestCase(exampleTestCases));
    setValue('accuracyTestCases', CodeTestCase.readTestCase(accuracyTestCases));
    setValue(
      'accuracyFileTestCases',
      CodeTestCase.readTestCase(accuracyFileTestCases),
    );
    setValue(
      'efficiencyTestCases',
      CodeTestCase.readTestCase(efficiencyTestCases),
    );
    setValue(
      'efficiencyFileTestCases',
      CodeTestCase.readTestCase(efficiencyFileTestCases),
    );
  }, [currentExam, navigation, setValue, sn, testCases?.problemTestCaseRsList]);

  useEffect(() => {
    const { judgeLanguageList } = currentExam;
    const initialLanguages = CodeGenerator.getLanguageTemplate(
      languageList,
    ).map((language) => {
      const judgeLanguage = judgeLanguageList.find(
        (judgeLanguage) => judgeLanguage.language === language.language,
      );

      return judgeLanguage
        ? {
            ...language,
            ...judgeLanguage,
            commandType: ModifyCommandType.READ,
            usage: true,
          }
        : language;
    });

    setValue('languages', initialLanguages);
  }, [languageList, currentExam]);

  const accuracyTestCasesCount = useMemo(() => {
    const count =
      (watch('accuracyFileTestCases') ?? []).filter(
        ({ commandType }) => commandType !== ModifyCommandType.DELETE,
      ).length +
      (watch('accuracyTestCases') ?? []).filter(
        ({ commandType }) => commandType !== ModifyCommandType.DELETE,
      ).length;
    return count === 0 ? 1 : count;
  }, [watch('accuracyFileTestCases'), watch('accuracyTestCases')]);

  const efficiencyTestCasesCount = useMemo(() => {
    const count =
      (watch('efficiencyFileTestCases') ?? []).filter(
        ({ commandType }) => commandType !== ModifyCommandType.DELETE,
      ).length +
      (watch('efficiencyTestCases') ?? []).filter(
        ({ commandType }) => commandType !== ModifyCommandType.DELETE,
      ).length;
    return count === 0 ? 1 : count;
  }, [watch('efficiencyFileTestCases'), watch('efficiencyTestCases')]);

  useEffect(() => {
    const accuracyScoreRate = getValues('accuracyScoreRate');
    if (accuracyScoreRate === undefined || accuracyScoreRate === null) return;
    let num = Math.max(1, accuracyScoreRate);
    if (isNaN(num)) num = 1;
    num = Math.min(99, num);

    setValue('accuracyScoreRate', num);
    setValue('efficiencyScoreRate', 100 - num);
  }, [watch('accuracyScoreRate')]);

  return {
    watch,
    register,
    errors,
    clearErrors,
    setValue,
    getValues,
    trigger,
    handleSubmit,
    onChangeLevel,
    onChangeDetailLevel,
    onChangeAlgorithm,
    onSubmit,
    isAbleToAddTestCase,
    isEnableCalculation,
    accuracyTestCasesCount,
    efficiencyTestCasesCount,
    isUpdateLoading,
  };
}
