import { FC } from 'react';
import Select, { SelectItemProps } from '@widget/select';
import { PageQueryOptions } from './config';
import { Container, Page, Anchor } from './style';
import { usePagination } from './hook';

export interface PaginationProps {
  defaultPage?: number;
  pages: number[];
  totalPage: number;
  pageSize: number;
  onFirst?: () => void;
  onBefore?: (prevPage: number) => void;
  onNext?: (nextPage: number) => void;
  onLast?: () => void;
  onChangePageLimit?: (props: SelectItemProps) => void;
  onChangePage?: (page: number) => void;
}

const Pagination: FC<PaginationProps> = ({
  pages,
  onChangePageLimit,
  pageSize,
  ...rest
}) => {
  const {
    currentPage,
    handleNext,
    handlePrev,
    handleFirst,
    handleLast,
    handelClickPage,
    isDisableNext,
    isDisableNextSkip,
    isDisablePrev,
    isDisablePrevSkip,
  } = usePagination({
    defaultPage: 0,
    pages,
    ...rest,
  });

  return (
    <Container>
      <Anchor isDisabled={isDisablePrevSkip()} onClick={handleFirst}>
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M14.7751 23.1004L9.20012 17.5254C8.63512 16.9604 8.63512 16.0404 9.20012 15.4754L14.7751 9.90039L15.9751 11.1004L10.5751 16.5004L15.9751 21.9004L14.7751 23.1004Z'
            fill='#868E96'
          />
          <path
            d='M21.7751 23.1004L16.2001 17.5254C15.6351 16.9604 15.6351 16.0404 16.2001 15.4754L21.7751 9.90039L22.9751 11.1004L17.5751 16.5004L22.9751 21.9004L21.7751 23.1004Z'
            fill='#868E96'
          />
        </svg>
      </Anchor>
      <Anchor isDisabled={isDisablePrev()} onClick={handlePrev}>
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M18.2749 23.1004L12.6999 17.5254C12.4249 17.2504 12.2749 16.8854 12.2749 16.5004C12.2749 16.1154 12.4249 15.7504 12.6999 15.4754L18.2749 9.90039L19.4749 11.1004L14.0749 16.5004L19.4749 21.9004L18.2749 23.1004Z'
            fill='#868E96'
          />
        </svg>
      </Anchor>
      {pages.map((pageNum) => (
        <Page
          key={`widget-pagination-page-${pageNum}`}
          isActive={currentPage === pageNum}
          onClick={() => handelClickPage(pageNum)}
        >
          {pageNum + 1}
        </Page>
      ))}
      <Anchor isDisabled={isDisableNext()} onClick={handleNext}>
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M13.7249 23.1004L12.5249 21.9004L17.9249 16.5004L12.5249 11.1004L13.7249 9.90039L19.2999 15.4754C19.5749 15.7504 19.7249 16.1154 19.7249 16.5004C19.7249 16.8854 19.5749 17.2504 19.2999 17.5254L13.7249 23.1004V23.1004Z'
            fill='#868E96'
          />
        </svg>
      </Anchor>
      <Anchor isDisabled={isDisableNextSkip()} onClick={handleLast}>
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M17.2249 23.1004L16.0249 21.9004L21.4249 16.5004L16.0249 11.1004L17.2249 9.90039L22.7999 15.4754C23.0749 15.7504 23.2249 16.1154 23.2249 16.5004C23.2249 16.8854 23.0749 17.2504 22.7999 17.5254L17.2249 23.1004Z'
            fill='#868E96'
          />
          <path
            d='M10.2249 23.1004L9.0249 21.9004L14.4249 16.5004L9.0249 11.1004L10.2249 9.90039L15.7999 15.4754C16.0749 15.7504 16.2249 16.1154 16.2249 16.5004C16.2249 16.8854 16.0749 17.2504 15.7999 17.5254L10.2249 23.1004Z'
            fill='#868E96'
          />
        </svg>
      </Anchor>
      <Select
        items={PageQueryOptions}
        onClick={onChangePageLimit}
        initialValue={{
          key: pageSize.toString(),
          name: pageSize.toString(),
        }}
      />
    </Container>
  );
};

export default Pagination;
