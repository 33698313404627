import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import KeyRepository from './key.repository';
import { createInstance } from './axios';

class BaseRepository {
  protected static _axios: AxiosInstance;
  private _apiUrl: string;

  constructor(url: string) {
    this._apiUrl = url;

    BaseRepository._axios = createInstance({
      baseURL: url,
      withCredentials: true,
    });
  }

  public static setInterCeptors(
    errorInterceptors: (error: AxiosError) => Promise<any>,
  ) {
    BaseRepository._axios.interceptors.response.use(
      (response: AxiosResponse) => response,
      errorInterceptors,
    );
  }

  public static get axios() {
    return BaseRepository._axios;
  }

  protected static get defaultHeaders() {
    const accessToken = KeyRepository.getInstance().accessToken;

    return {
      accept: 'application/json',
      'Content-Type': 'application/json',
      ...(accessToken !== '' && {
        Authorization: accessToken,
      }),
    };
  }

  protected get currentBaseUrl() {
    return this._apiUrl;
  }

  protected get axios(): AxiosInstance {
    return BaseRepository._axios;
  }

  protected customSerializer = (paramObj: any) => {
    const params = new URLSearchParams();
    for (const key in paramObj) {
      const data = paramObj[key];

      if (data === undefined) continue;

      if (Array.isArray(data)) {
        data.forEach((value: any) => {
          params.append(key, value);
        });
      } else {
        params.append(key, data);
      }
    }
    return params.toString();
  };

  protected async post<T extends any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse> {
    return BaseRepository._axios.post<any, AxiosResponse<T, any>>(
      url,
      JSON.stringify(data),
      {
        ...config,
        paramsSerializer: {
          serialize: this.customSerializer,
        },
        headers: {
          ...BaseRepository.defaultHeaders,
          ...config?.headers,
        },
      },
    );
  }

  protected async get<T extends any>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse> {
    return BaseRepository._axios.get<any, AxiosResponse<T, any>>(url, {
      ...config,
      paramsSerializer: {
        serialize: this.customSerializer,
      },
      headers: {
        ...BaseRepository.defaultHeaders,
        ...config?.headers,
      },
    });
  }

  protected async put<T extends any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse> {
    return BaseRepository._axios.put<any, AxiosResponse<T, any>>(
      url,
      JSON.stringify(data),
      {
        ...config,
        paramsSerializer: {
          serialize: this.customSerializer,
        },
        headers: {
          ...BaseRepository.defaultHeaders,
          ...config?.headers,
        },
      },
    );
  }

  protected async delete<T extends any>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse> {
    return BaseRepository._axios.delete<any, AxiosResponse<T, any>>(url, {
      ...config,
      paramsSerializer: {
        serialize: this.customSerializer,
      },
      headers: {
        ...BaseRepository.defaultHeaders,
        ...config?.headers,
      },
    });
  }

  protected async postForm<T extends any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse> {
    return BaseRepository._axios.postForm<any, AxiosResponse<T, any>>(
      url,
      data,
      {
        ...config,
        paramsSerializer: {
          serialize: this.customSerializer,
        },
        headers: {
          ...BaseRepository.defaultHeaders,
          ...config?.headers,
        },
      },
    );
  }
}

export default BaseRepository;
