export const atomKeys = {
  auth: {
    pmsAccessToken: 'pmsAccessToken',
    pmsRefreshToken: 'pmsRefreshToken',
  },
  modal: {
    alertStatus: 'alertStatus',
    alertText: 'alertText',
    alertAction: 'alertAction',
    confirmStatus: 'confirmStatus',
    confirmText: 'confirmText',
    confirmModalAction: 'confirmModalAction',
    passwordStatus: 'passwordStatus',
    passwordAlertPhrase: 'passwordAlertPhrase',
  },
  filter: {
    level: 'level',
    judgeType: 'judgeType',
    language: 'language',
    publicType: 'publicType',
    searchKeyword: 'searchKeyword',
    step: 'step',
    packageLevel: 'packageLevel',
    popUpStatus: 'popUpStatus',
    algorithmType: 'algorithmType',
  },
  sort: {
    companySortType: 'companySortType',
    popUpSortType: 'popUpSortType',
  },
  select: {
    popUpSelectList: 'popUpSelectList',
  },
  table: {
    toggles: 'toggles',
  },
  pagination: {
    currentPage: 'currentPage',
    totalPage: 'totalPage',
    pageSize: 'pageSize',
  },
  exam: {
    functionParameters: 'functionParameters',
    packageExamLanguages: 'packageExamLanguages',
  },
};

export const stateKeys = {
  filter: {
    paginationArgs: 'paginationArgs',
    companyPaginationQuery: 'companyPaginationQuery',
    pstDefaultPaginationQuery: 'pstDefaultPaginationQuery',
    pstPackagePaginationQuery: 'pstPackagePaginationQuery',
    popUpPaginationQuery: 'popUpPaginationQuery',
  },
  table: {
    currentToggles: 'currentToggles',
    currentPopUpToggleKeys: 'currentPopUpToggleKeys',
    currentSingularToggleKeys: 'currentSingularToggleKeys',
  },
  modal: {
    alertModalInfo: 'alertModalInfo',
    confirmModalInfo: 'confirmModalInfo',
  },
  exam: {
    functionTemplateParameters: 'functionTemplateParameters',
    functionParams: 'functionParams',
    functionReturns: 'functionReturns',
    functionInputAndOutput: 'functionInputAndOutput',
    packageLanguages: 'packageLanguages',
    packageLanguageStatus: 'packageLanguageStatus',
  },
};

export const queryKeys = {
  autoScaling: 'autoScaling',
  company: 'company',
  data: 'data',
  language: 'language',
  pst: 'pst',
  readPstProblem: 'readPstProblem',
  package: 'package',
  popup: 'popup',
  testCases: 'testCases',
};

export const mutationKeys = {
  pst: {
    copy: 'pst-copy',
    fileUpload: 'fileUpload',
  },
  popup: {
    create: 'popup-create',
    delete: 'popup-delete',
  },
};
