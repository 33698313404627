import { TableHeaderProps } from '@widget/table';

const useTimeLimitModal = () => {
  const headers: TableHeaderProps[] = [
    {
      name: '언어',
      key: 'languages',
      width: '50%',
    },
    {
      name: 'Time Limit',
      key: 'timeLimit',
      width: '50%',
    },
  ];

  return {
    headers,
  };
};

export default useTimeLimitModal;
