import { injectable } from 'inversify';
import 'reflect-metadata';
import { Language } from './base';
import { ExamParameterDTO } from '../interfaces/language.dto';

@injectable()
export class Scala extends Language {
	public get defaultTimeLimit(): number {
		return 5000;
	}

	public loadFunctionCode(parameters: ExamParameterDTO): string {
		return parameters.paramInfo
			.map(({ paramType, paramName }) => {
				const returnType = this.returnType[paramType];
				return `${returnType.paramType}: ${paramName}`.trim();
			})
			.join(', ');
	}

	public getFunctionTemplate(parameters: ExamParameterDTO): string {
		const { type, value } = this.returnType[parameters.returnType];

		return `object Solution {\n    def solution(${this.loadFunctionCode(
			parameters,
		)}): ${type} = {\n        return ${value}\n    }\n}`;
	}

	public getStdioTemplate() {
		return 'import scala.io.StdIn.readLine\n\nobject Solution {\n    def main(args: Array[String]) {\n        val n = readLine().split(" ")\n        val (a, b) = (n(0).toInt, n(1).toInt)\n        println(a + b)\n    }\n}';
	}

	public get returnType() {
		return {
			// Primitive Types
			INT: this.setReturnType('Int', 'Int', '0'),
			LONG: this.setReturnType('Long', 'Long', '0'),
			DOUBLE: this.setReturnType('Double', 'Double', '0'),
			STRING: this.setReturnType('String', 'String', '""'),
			BOOL: this.setReturnType('Boolean', 'Boolean', 'true'),
			// Reference Types
			INT_ARRAY: this.setReturnType(
				'Vector[Int]',
				'Vector[Int]',
				'Vector[Int]()',
			),
			LONG_ARRAY: this.setReturnType(
				'Vector[Long]',
				'Vector[Long]',
				'Vector[Long]()',
			),
			DOUBLE_ARRAY: this.setReturnType(
				'Vector[Double]',
				'Vector[Double]',
				'Vector[Double]()',
			),
			STRING_ARRAY: this.setReturnType(
				'Vector[String]',
				'Vector[String]',
				'Vector[String]()',
			),
			BOOL_ARRAY: this.setReturnType(
				'Vector[Boolean]',
				'Vector[Boolean]',
				'Vector[Boolean]()',
			),
			// 2 Dimensional Reference Types
			INT_2_ARRAY: this.setReturnType(
				'Vector[Vector[Int]]',
				'Vector[Vector[Int]]',
				'Vector[Vector[Int]]()',
			),
			LONG_2_ARRAY: this.setReturnType(
				'Vector[Vector[Long]]',
				'Vector[Vector[Long]]',
				'Vector[Vector[Long]]()',
			),
			DOUBLE_2_ARRAY: this.setReturnType(
				'Vector[Vector[Double]]',
				'Vector[Vector[Double]]',
				'Vector[Vector[Double]]()',
			),
			STRING_2_ARRAY: this.setReturnType(
				'Vector[Vector[String]]',
				'Vector[Vector[String]]',
				'Vector[Vector[String]]()',
			),
			BOOL_2_ARRAY: this.setReturnType(
				'Vector[Vector[Boolean]]',
				'Vector[Vector[Boolean]]',
				'Vector[Vector[Boolean]]()',
			),
		};
	}
}
