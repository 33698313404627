import styled from '@emotion/styled';

export const Guide = styled.a`
	min-width: 116px;
	height: 32px;

	svg {
		vertical-align: middle;
	}

	span {
		margin-left: 14px;
		font-size: 14px;
		font-weight: 700;
		line-height: 20px;
		color: #e9ecef;
	}
`;

export const Settings = styled.a`
	margin: 0 24px 0 28px;

	svg {
		vertical-align: middle;
	}
`;

export const UserWrap = styled.div`
	margin: 12px 24px;
`;
