import ClosableModal from '@components/Modals/Closable';
import { FC } from 'react';
import { Container } from './style';
import Table, { RenderTableBody } from '@components/Table';
import useTimeLimitModal from './hooks';
import { ExamLanguageDTO } from '@phs/interfaces';

interface TimeLimitProps {
  onClose: () => void;
  renderTimeLimitData: RenderTableBody<ExamLanguageDTO>;
  data: ExamLanguageDTO[];
  onSubmit: () => void;
}

const TimeLimit: FC<TimeLimitProps> = ({
  onClose,
  renderTimeLimitData,
  data,
  onSubmit,
}) => {
  const { headers } = useTimeLimitModal();

  return (
    <ClosableModal
      onClose={onClose}
      title='제한 시간 설정'
      style={{ width: '480px' }}
      onSubmit={onSubmit}
    >
      <Container>
        <Table<ExamLanguageDTO>
          headers={headers}
          renderTableBody={renderTimeLimitData}
          data={data}
        />
      </Container>
    </ClosableModal>
  );
};

export default TimeLimit;
