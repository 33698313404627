import { RouteObject } from 'react-router-dom';
import { PMS_PAGES } from '@phs/constants';
import AuthenticatedLayout from '@layout/Authenticated';
import DataPage from 'src/pages/Data';
import DashboardLayout from '@layout/Dashboard';
import DataLevel from '@pages/Data/Level';
import DataAlgorithms from '@pages/Data/Algorithms';

const DataRoute: RouteObject = {
  path: PMS_PAGES.DATA.HOME,
  element: <DashboardLayout />,
  children: [
    {
      path: PMS_PAGES.DATA.HOME,
      element: (
        <AuthenticatedLayout>
          <DataPage />
        </AuthenticatedLayout>
      ),
      children: [
        {
          index: true,
          element: <DataAlgorithms />,
        },
        {
          path: PMS_PAGES.DATA.LEVEL,
          element: <DataLevel />,
        },
      ],
    },
  ],
};

export default DataRoute;
