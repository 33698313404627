import { CheckboxTitle, Title, InputWrapper } from './style';
import { FC } from 'react';
import { UseFormRegister } from 'react-hook-form';
import Checkbox from '@widget/checkbox';
import TextInput from '@widget/input-text';
import { PackageExamFormProps } from '@pages/Exam/package/config.ts';
import { Nullable } from '@phs/interfaces';

interface CalculationProps {
  isActive?: boolean;
  disabled?: boolean;
  accuracyScoreRate?: Nullable<number>;
  efficiencyScoreRate?: Nullable<number>;
  cursor: number;
  register: UseFormRegister<PackageExamFormProps>;
  accuracyCount: number;
  efficiencyCount: number;
}

const Calculation: FC<CalculationProps> = ({
  isActive,
  disabled,
  accuracyScoreRate,
  efficiencyScoreRate,
  cursor,
  register,
  accuracyCount,
  efficiencyCount,
}) => {
  return (
    <>
      <Title>점수 계산 설정</Title>
      <Checkbox
        {...register(`stepProblemList.${cursor}.testCaseScoreRateYn`)}
        isActive={isActive}
        disabled={disabled}
        data-testid='score-rate-yn'
      >
        <CheckboxTitle disabled={disabled}>
          정확성, 효율성 테스트 케이스의 점수 비율 설정 (체크 해제 시 테스트
          케이스 수에 따라 균등 배분됩니다.)
        </CheckboxTitle>
      </Checkbox>
      {isActive && (
        <>
          <InputWrapper>
            <label>
              정확성
              <span className='count'>
                테스트 케이스 1 개당{' '}
                {((accuracyScoreRate ?? 50) / accuracyCount).toFixed(2)}%
              </span>
            </label>
            <div>
              <TextInput
                {...register(`stepProblemList.${cursor}.accuracyScoreRate`, {
                  valueAsNumber: true,
                  min: 0,
                })}
                disabled={disabled || !isActive}
                value={accuracyScoreRate?.toString()}
                role={'accuracy-score-rate-input'}
                type={'number'}
                placeholder={'0'}
                min={0}
                style={{
                  width: 160,
                  textAlign: 'right',
                }}
              />
              <span>&nbsp;%</span>
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>
              효율성
              <span className='count'>
                테스트 케이스 1 개당{' '}
                {((efficiencyScoreRate ?? 50) / efficiencyCount).toFixed(2)}%
              </span>
            </label>
            <div>
              <TextInput
                {...register(`stepProblemList.${cursor}.efficiencyScoreRate`, {
                  valueAsNumber: true,
                  min: 0,
                })}
                value={efficiencyScoreRate?.toString()}
                readOnly={true}
                disabled={true}
                type={'number'}
                placeholder={'0'}
                min={0}
                style={{
                  width: 160,
                  textAlign: 'right',
                }}
              />
              <span>&nbsp;%</span>
            </div>
          </InputWrapper>
        </>
      )}
    </>
  );
};

export default Calculation;
