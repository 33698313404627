import { queryKeys } from '@constants/key.ts';
import {
  CompanyDTO,
  CompanyListRsDTO,
} from '@interfaces/company/company.dto.ts';
import CompanyRepository from '@repositories/company.repository.ts';
import { companyPaginationQuery } from '@stores/selectors/company/query.ts';
import { withPaginationFallback } from '@helpers/fallback.ts';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

const defaultCompanyFallback: { data: CompanyListRsDTO; refetch?: () => void } =
  {
    data: {
      companyList: withPaginationFallback<CompanyDTO>({
        sn: 0,
        companyName: '',
        databaseSyncTime: '',
        databaseSyncYn: false,
      }),
    },
  };

export function useCompany(): { data: CompanyListRsDTO; refetch?: () => void } {
  const { query, queryString } = useRecoilValue(companyPaginationQuery);
  const { data, isError, isLoading, refetch } = useQuery<
    string,
    unknown,
    CompanyListRsDTO,
    readonly string[]
  >([queryKeys.company, queryString], () =>
    CompanyRepository.getCompanyList({
      searchKeyword: query.searchKeyword,
      page: query.currentPage,
      size: query.pageSize,
      sort: query.sort,
    }),
  );

  if (isError || isLoading) return defaultCompanyFallback;

  return {
    data,
    refetch,
  };
}
