import { ChangeEventHandler, FC, useEffect, useState } from 'react';
import { Container, Input } from './style';

/**
 * https://yoksel.github.io/url-encoder/
 */

interface SearchInputProps {
  placeholder?: string;
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

const SearchInput: FC<SearchInputProps> = ({
  placeholder = '검색',
  onChange,
  value = '',
}) => {
  const [isFocused, setFocus] = useState<boolean>(false);
  const [valueState, setValue] = useState<string>('');

  useEffect(() => {
    setValue(value);
  }, [value]);

  return (
    <Container isActive={isFocused}>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M19.15 17.9291L15.65 14.4291C16.4584 13.3906 16.9029 12.1151 16.915 10.7991C16.9204 9.41751 16.4585 8.07473 15.6043 6.98879C14.7502 5.90285 13.554 5.13759 12.2101 4.81728C10.8661 4.49697 9.45334 4.64043 8.20122 5.22439C6.9491 5.80834 5.93118 6.79847 5.31281 8.03396C4.69443 9.26944 4.51192 10.6776 4.79492 12.0299C5.07791 13.3822 5.8098 14.5992 6.87169 15.4831C7.93358 16.3669 9.26311 16.8657 10.6443 16.8986C12.0255 16.9315 13.3773 16.4964 14.48 15.6641L17.98 19.1641L19.15 17.9291ZM10.8 15.1841C9.92977 15.1841 9.07907 14.926 8.35549 14.4425C7.63192 13.959 7.06796 13.2719 6.73493 12.4679C6.40191 11.6639 6.31477 10.7792 6.48454 9.92568C6.65432 9.07216 7.07338 8.28822 7.68873 7.67287C8.30408 7.05752 9.08809 6.63842 9.94161 6.46865C10.7951 6.29887 11.6798 6.38599 12.4838 6.71901C13.2878 7.05204 13.975 7.61604 14.4585 8.33962C14.9419 9.0632 15.2 9.91384 15.2 10.7841C15.1987 11.9506 14.7347 13.0691 13.9098 13.8939C13.0849 14.7188 11.9666 15.1828 10.8 15.1841Z'
          fill='#495057'
        />
      </svg>
      <Input
        type={'search'}
        placeholder={placeholder}
        onChange={(e) => {
          setValue(e.target.value);
          onChange && onChange(e);
        }}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        value={valueState}
      />
    </Container>
  );
};

export default SearchInput;
