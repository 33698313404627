import { Container } from './style';
import { AiFillMinusSquare } from 'react-icons/ai';
import { FC, PropsWithChildren } from 'react';

interface RemovableProps extends PropsWithChildren {
  onRemove?: () => void;
}

const Removable: FC<RemovableProps> = ({ onRemove }) => {
  return (
    <Container>
      <AiFillMinusSquare
        size={40}
        style={{ cursor: 'pointer' }}
        onClick={onRemove}
      />
    </Container>
  );
};

export default Removable;
