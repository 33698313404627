import { Container, Icon, Content, Input } from './style';
import {
  CSSProperties,
  DetailedHTMLProps,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
} from 'react';

export type CheckBoxOnClickProps = (props: {
  id: string;
  isActive: boolean;
}) => void;

interface CheckBoxProps
  extends Omit<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'onClick'
  > {
  isActive?: boolean;
  onClick?: CheckBoxOnClickProps;
  children?: ReactNode;
  style?: CSSProperties;
}

const CheckBox = (
  { id, isActive = false, onClick, children, style, ...rest }: CheckBoxProps,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  return (
    <Container
      data-testid={id}
      data-checked={isActive}
      htmlFor={id}
      onClick={() =>
        onClick?.({
          id: id || '',
          isActive,
        })
      }
      style={style}
    >
      <Input
        {...rest}
        id={id}
        ref={ref}
        type={'checkbox'}
        // readOnly={true}
      />
      <Content isChecked={isActive}>
        <Icon viewBox='0 0 24 24'>
          <polyline points='19 7 10 17 5 12' />
        </Icon>
      </Content>
      {children}
    </Container>
  );
};

export default forwardRef<HTMLInputElement, CheckBoxProps>(CheckBox);
