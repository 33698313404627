import type { AxiosError } from 'axios';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import * as Sentry from '@sentry/react';
import { SENTRY_KEYS } from '@phs/constants';

interface AxiosExceptionHandlerConfig {
	error: AxiosError;
	message: string;
	requestHeaders?: Record<string, any>;
}

class AxiosExceptionHandler {
	constructor(props: AxiosExceptionHandlerConfig) {
		this.init(props);
	}

	private async init({
		error,
		message,
		requestHeaders,
	}: AxiosExceptionHandlerConfig) {
		if (requestHeaders) await this.writeRequestHeaders(requestHeaders);
		if (error.config) await this.writeAxiosConfig(error.config);
		error.response
			? await this.writeAxiosResponse(error.response)
			: await this.writeUnknownError(error);
		await this.sendLogs(message);
	}

	private async writeRequestHeaders(requestHeaders: Record<string, any>) {
		Sentry.setContext(SENTRY_KEYS.CONTEXTS.API_REQUEST_HEADERS, {
			...requestHeaders,
			raw: JSON.stringify(requestHeaders),
		});
	}

	private async writeAxiosConfig(config: AxiosRequestConfig) {
		const { method, url, params, data, headers } = config;

		Sentry.setContext(SENTRY_KEYS.CONTEXTS.AXIOS_CONFIG_DETAIL, {
			method,
			url,
			params,
			data,
			headers,
		});

		if ((config as any)?.curlCommand) {
			// Curl 디버깅
			Sentry.setContext(SENTRY_KEYS.CONTEXTS.AXIOS_REQUEST_CURL, {
				curl: (config as any)?.curlCommand ?? '',
			});
		}
	}

	private async writeAxiosResponse(res: AxiosResponse) {
		const { data, status, statusText, config, request } = res;

		Sentry.setContext(SENTRY_KEYS.CONTEXTS.API_RESPONSE_DETAIL, {
			request,
			config,
			statusText,
			status,
			data,
		});
	}

	private async writeUnknownError(error: unknown) {
		const { message, name } = (error as Error) ?? null;
		Sentry.setContext(SENTRY_KEYS.CONTEXTS.API_UNKNOWN_ERROR_DETAIL, {
			message,
			name,
		});
	}

	private async sendLogs(message: string) {
		Sentry.withScope((scope) => {
			scope.setTag(SENTRY_KEYS.TYPES.API, SENTRY_KEYS.MESSAGES.API_ERROR);
			scope.setLevel('error');
			Sentry.captureMessage(message);
		});
	}
}

export default AxiosExceptionHandler;
