import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModifyCommandType, TestCaseDTO } from '@phs/interfaces';
import { CodeTransformer, CodeValidator } from '@phs/code';
import { useAlertModal } from '@components/Modals/Alert/hook.ts';

export function useValidation(cache: TestCaseDTO[]) {
  const observableCache = of(cache);
  const { onOpen } = useAlertModal();

  const validate = (next?: () => void, error?: (error: unknown) => void) =>
    observableCache
      .pipe(
        map((data) => {
          return data
            .filter(
              ({ commandType }) => commandType !== ModifyCommandType.DELETE,
            )
            .map(({ input: inputValue, output: outputValue }, rowIdx) => {
              inputValue.forEach((inputObj, idx) => {
                if (CodeValidator.isEmpty(inputObj.value)) {
                  onOpen(
                    `${rowIdx + 1}번째 테스트 케이스의 ${
                      idx + 1
                    }번째 매개변수가 비었습니다.`,
                  );

                  throw new Error();
                }
              });

              if (CodeValidator.isEmpty(outputValue.value)) {
                onOpen(
                  `${rowIdx + 1}번째 테스트 케이스의 결과값이 비었습니다.`,
                );

                throw new Error();
              }

              inputValue.forEach((inputObj, idx) => {
                if (
                  !CodeValidator.checkDataType[inputObj.parameterType](
                    inputObj.value,
                  )
                ) {
                  onOpen(
                    `${rowIdx + 1}번째 테스트 케이스의 ${
                      idx + 1
                    }번째 매개변수 타입이 설정값 ${
                      CodeTransformer.dataType2Text[inputObj.parameterType]
                    }와(과) 다릅니다.`,
                  );

                  throw new Error();
                }

                if (
                  !CodeValidator.validateCodePrecision[inputObj.parameterType](
                    inputObj.value.toString(),
                  )
                ) {
                  onOpen(
                    `${rowIdx + 1}번째 테스트 케이스의 ${idx + 1}번째 ${
                      CodeTransformer.dataType2Text[inputObj.parameterType]
                    }형 매개변수의\n 자릿수는 15자리 이하로 입력해야 합니다.`,
                  );

                  throw new Error();
                }
              });

              if (
                !CodeValidator.checkDataType[outputValue.parameterType](
                  outputValue.value,
                )
              ) {
                onOpen(
                  `${rowIdx + 1}번째 테스트 케이스의 결과값 타입이 설정값 ${
                    CodeTransformer.dataType2Text[outputValue.parameterType]
                  }와(과) 다릅니다.`,
                );

                throw new Error();
              }

              if (
                !CodeValidator.validateCodePrecision[outputValue.parameterType](
                  outputValue.value.toString(),
                )
              ) {
                onOpen(
                  `${rowIdx + 1}번째 테스트 케이스의 ${
                    CodeTransformer.dataType2Text[outputValue.parameterType]
                  }형 결과값의\n 자릿수는 15자리 이하로 입력해야 합니다.`,
                );

                throw new Error();
              }

              return inputValue;
            });
        }),
      )
      .subscribe({
        next,
        error,
      });

  return { validate };
}
