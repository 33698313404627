import { useMemo } from 'react';
import { DataType } from '@phs/interfaces';
import { CodeTransformer } from '@phs/code';

export function useSelect() {
  const items = useMemo(() => {
    return Object.values(DataType).map((key) => ({
      key,
      name: CodeTransformer.dataType2Text[key],
    }));
  }, []);

  return { items };
}
