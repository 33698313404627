import { atom } from 'recoil';
import { atomKeys } from '@constants/key.ts';

const fallback: Record<string, string> = {
	searchKeyword: '',
};

export const searchKeyword = atom<string>({
	key: atomKeys.filter.searchKeyword,
	default: fallback.searchKeyword,
});
