import { FC } from 'react';
import { ExamLanguageDTO } from '@phs/interfaces';
import Modal from '@widget/modal';
import { LanguageConfig } from '@widget/icons-language';
import TextButton from '@widget/button-text';
import { IconClose } from '@widget/icons';
import {
  Container,
  LanguageWrapper,
  IconWrapper,
  Content,
  LanguageName,
  LanguageSpec,
  TitleArea,
} from './style.ts';
import { useLanguageModal } from './hooks.ts';

interface LanguageModalProps {
  currentLanguages?: ExamLanguageDTO[];
  onClose?: () => void;
}

const LanguageModal: FC<LanguageModalProps> = ({
  currentLanguages = [],
  onClose,
}) => {
  const { onCancel } = useLanguageModal({
    onClose,
  });

  return (
    <Modal>
      <Container>
        <TitleArea>
          <h2>언어 정보</h2>
          <IconClose onClick={onCancel} />
        </TitleArea>
        {currentLanguages.map(({ language, compiler }) => (
          <LanguageWrapper key={`language-modal-${language}`}>
            <IconWrapper>{LanguageConfig[language].svg('svg', 48)}</IconWrapper>
            <Content>
              <LanguageName>{language}</LanguageName>
              <LanguageSpec>{compiler}</LanguageSpec>
            </Content>
          </LanguageWrapper>
        ))}
        <TextButton
          theme={'primary'}
          style={{ marginTop: 40 }}
          type={'button'}
          onClick={onCancel}
        >
          확인
        </TextButton>
      </Container>
    </Modal>
  );
};

export default LanguageModal;
