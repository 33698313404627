import { BaseRepository } from '@phs/repositories';
import { PMS_URL } from '@phs/constants';
import { ScaleOptionRsDTO } from '@interfaces/company/company.dto.ts';

class OperationRepository extends BaseRepository {
  constructor(baseUrl: string) {
    super(baseUrl);
  }

  public async getScaleOption() {
    const { data: response } = await super.get<ScaleOptionRsDTO>(
      `${PMS_URL.SCALE_OPTION}`,
    );

    return response;
  }

  public async putScaleOption(
    commonCandidateCount: number,
    companyScaleFactor: number,
    cheatingScaleFactor: number,
  ) {
    await super.put(`${PMS_URL.CONVERT_SCALE_OPTION}`, {
      commonCandidateCount,
      companyScaleFactor,
      cheatingScaleFactor,
    });
  }

  public async getCandidateCount(dateTime: string) {
    const { data: response } = await super.get<{ candidateCount: number }>(
      `${PMS_URL.CURRENT_CANDIDATE_COUNT}`,
      {
        params: {
          dateTime,
        },
      },
    );
    return response;
  }
}

const operationRepository = new OperationRepository(
  import.meta.env.VITE_API_URL,
);

export default operationRepository;
