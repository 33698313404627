import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { popUpSortType } from '@stores/atoms/notice/popup.sort';
import { PopUpSortType } from '@interfaces/notice/popup.enum';
import { getRegex } from '@phs/helpers';
import { ResetKey, resetTypeMap } from './config';
import { DropdownItemType } from '@widget/dropdown';

export function usePopUpSort() {
  const setPopUpSortType = useSetRecoilState(popUpSortType);
  const resetPopUpSortType = useResetRecoilState(popUpSortType);

  const filterSortType = (state: PopUpSortType[], target: PopUpSortType[]) => {
    const regex = getRegex(target);

    return state.filter((sortType) => !regex.test(sortType));
  };

  const resetCurrentSortType = (key: ResetKey) => {
    setPopUpSortType((currVal) => {
      return filterSortType(currVal, resetTypeMap[key]);
    });
  };

  const addCurrentSortType = (sortType: PopUpSortType) => {
    setPopUpSortType((currVal) => {
      return filterSortType([...currVal, sortType], resetTypeMap[sortType]);
    });
  };

  const onClick = (props: DropdownItemType) => {
    switch (props.key) {
      case ResetKey.RESET_SEQUENCE:
      case ResetKey.REST_START_DATE:
        resetCurrentSortType(props.key);
        break;

      case PopUpSortType.SN_ASC:
      case PopUpSortType.SN_DESC:
      case PopUpSortType.START_DATE_TIME_ASC:
      case PopUpSortType.START_DATE_TIME_DESC:
        addCurrentSortType(props.key as PopUpSortType);
        break;

      default:
        break;
    }
  };

  return {
    resetPopUpSortType,
    onClick,
  };
}
