import { useState } from 'react';

export const InputTypes = ['password', 'text'];

export function usePasswordInput() {
  const [visible, setVisible] = useState(InputTypes[0]);

  const toggleVisible = () => {
    setVisible((currentValue) => {
      return currentValue === InputTypes[0] ? InputTypes[1] : InputTypes[0];
    });
  };

  return {
    visible,
    toggleVisible,
  };
}
