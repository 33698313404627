import styled from '@emotion/styled';

export const Container = styled.div`
  max-width: 1398px;
  margin: auto;
`;

export const Header = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 24px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray_200};
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SyncWrap = styled.div<{ syncYn: boolean }>`
  svg {
    cursor: ${({ syncYn }) => (syncYn ? `not-allowed` : `pointer`)};
    opacity: ${({ syncYn }) => (syncYn ? '0.3' : '1')};
    stroke: ${({ syncYn }) => (syncYn ? `gray` : `black`)};

    &.rotate {
      transform-origin: 50% 50%;
      animation: rotate-image 1s linear infinite;
    }

    @keyframes rotate-image {
      100% {
        transform: rotate(1turn);
      }
    }
  }
`;
