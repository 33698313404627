import styled from '@emotion/styled';

export const RequireTitle = styled.div`
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray_900};

  &::after {
    display: inline-block;
    margin-left: 4px;
    color: ${({ theme }) => theme.colors.red_600};
    vertical-align: middle;
    content: '*';
  }
`;

export const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 40px;

  span {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    color: #fa5252;
  }
`;

export const Title = styled.div`
  margin: 24px 0 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray_900};
`;

export const CheckboxTitle = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
`;

export const DateRangeWrapper = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 24px;
`;
