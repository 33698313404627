import styled from '@emotion/styled';

export const IconWrapper = styled.div`
	display: flex;
`;

export const IconElementWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: auto;
	cursor: pointer;
`;
