import DropDown, { DropdownItemType } from '@widget/dropdown';
import { RequireTitle, DropDownWrapper, ErrorText } from './style.ts';
import { LEVEL_ITEMS } from './config.ts';
import { FC } from 'react';

interface StepLevelProps {
  title?: string;
  initialState?: number | string;
  onChange?: (props: DropdownItemType) => void;
  error?: string;
}

const StepLevel: FC<StepLevelProps> = ({
  title = '난이도',
  initialState,
  onChange,
  error,
}) => {
  return (
    <>
      <RequireTitle>{title}</RequireTitle>
      <DropDownWrapper>
        <DropDown
          data-testid={'step-level'}
          title={title}
          initialState={initialState}
          items={LEVEL_ITEMS}
          onClick={onChange}
        />
        <ErrorText>{error}</ErrorText>
      </DropDownWrapper>
    </>
  );
};

export default StepLevel;
