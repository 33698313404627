import { FC } from 'react';
import ModalWidget from '@widget/modal';
import TextButton from '@widget/button-text';
import PasswordInput from '@widget/input-password';
import { usePasswordModal } from './hook.ts';
import { Container, Hint, Content, InputWrapper, ButtonWrap } from './style.ts';

interface PasswordModalProps {
  email: string;
  currentPassword: string;
}

const PasswordModal: FC<PasswordModalProps> = ({ email, currentPassword }) => {
  const { isDisabledSubmit, isOpen, register, handleSubmit, onSubmit, phrase } =
    usePasswordModal({
      email,
      currentPassword,
    });

  if (!isOpen) return null;

  return (
    <ModalWidget>
      <Container method={'POST'} onSubmit={handleSubmit(onSubmit)}>
        <Content>
          <Hint>
            {phrase}
            <br />
            새로운 비밀번호를 설정해 주세요.
          </Hint>
          <InputWrapper>
            <Hint>현재 비밀번호</Hint>
            <PasswordInput register={register('currentPassword')} />
          </InputWrapper>
          <InputWrapper>
            <Hint>신규 비밀번호</Hint>
            <PasswordInput register={register('newPassword')} />
          </InputWrapper>
          <InputWrapper>
            <Hint>신규 비밀번호 확인</Hint>
            <PasswordInput register={register('checkPassword')} />
          </InputWrapper>
        </Content>
        <ButtonWrap>
          <TextButton
            disabled={isDisabledSubmit}
            type={'submit'}
            theme={'primary'}
            style={{
              width: 128,
              height: 52,
            }}
          >
            확인
          </TextButton>
        </ButtonWrap>
      </Container>
    </ModalWidget>
  );
};

export default PasswordModal;
