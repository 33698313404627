import styled from '@emotion/styled';

export const RequireTitle = styled.div`
	margin-bottom: 16px;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: ${({ theme }) => theme.colors.gray_900};

	&::after {
		display: inline-block;
		margin-left: 4px;
		color: ${({ theme }) => theme.colors.red_600};
		vertical-align: middle;
		content: '*';
	}
`;

export const UploadFileWrapper = styled.div`
	display: flex;
	height: 40px;
	gap: 12px;
`;

export const ImageName = styled.input`
	width: 458px;
	height: 40px;
	padding: 12px;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	border: 1px solid ${({ theme }) => theme.colors.gray_600};
	border-radius: 4px;
	background: white;
`;

export const Hint = styled.ul`
	padding: 16px 0 48px 16px;
	list-style-type: disc;

	li {
		font-size: 14px;
		line-height: 20px;
		color: ${({ theme }) => theme.colors.gray_800};

		&:not(:last-of-type) {
			margin-bottom: 12px;
		}
	}
`;

export const CheckboxTitle = styled.span`
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
`;

export const InputWrap = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
	margin: 16px 0;

	p {
		font-size: 12px;
		line-height: 18px;
		color: #fa5252;
	}
`;

export const Error = styled.p`
	font-size: 12px;
	line-height: 18px;
	color: #fa5252;
`;
