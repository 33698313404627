import styled from '@emotion/styled';

export const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  margin: 64px 0 32px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
`;

export const CardGroup = styled.div`
  padding: 12px 40px;
  display: flex;
  gap: 40px;
  align-items: center;
`;
