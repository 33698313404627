import { Container, Wrapper, Item } from './style';
import { FC, LiHTMLAttributes, MouseEventHandler } from 'react';

type ItemProps = {
	name: string;
	key: string;
};

interface TabProps {
	activeKey?: string;
	item: ItemProps[];
	onClick?: (value: number) => void;
}

const Tab: FC<TabProps> = ({ item, activeKey, onClick }) => {
	const handleClick: MouseEventHandler<HTMLUListElement> = (event) => {
		// @ts-ignore
		const { tagName, value } = event.target as LiHTMLAttributes<HTMLLIElement>;

		if (tagName !== 'LI' || typeof value !== 'number') return;

		onClick?.(value);
	};

	return (
		<Container>
			<Wrapper onClick={handleClick}>
				{item.map(({ name, key }, idx) => (
					<Item key={`${name}_${idx}`} value={idx} isActive={key === activeKey}>
						{name}
					</Item>
				))}
			</Wrapper>
		</Container>
	);
};

export default Tab;
