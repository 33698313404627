import { Container, HeaderItem, Logo, Content, RightMenuWrap } from './style';
import { FC, ReactNode } from 'react';

export { HeaderItem };

export interface HeaderProps {
  cursor?: number;
  navItems?: ReactNode[];
  rightMenu?: ReactNode[];
}

const Header: FC<HeaderProps> = ({ cursor, navItems, rightMenu }) => {
  return (
    <Container>
      <Logo>
        <svg
          width='149'
          height='19'
          viewBox='0 0 149 19'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M29.719 16.3427C27.9714 18.0818 25.8452 18.9514 23.3404 18.9514C20.8357 18.9514 18.7119 18.0818 16.974 16.3427C15.2348 14.6035 14.3652 12.4725 14.3652 9.9508C14.3652 7.42913 15.2348 5.29811 16.974 3.55894C18.7131 1.81978 20.8357 0.950195 23.3404 0.950195C25.8452 0.950195 27.9726 1.81978 29.719 3.55894C31.4666 5.29811 32.3398 7.42913 32.3398 9.9508C32.3398 12.4725 31.4654 14.6035 29.719 16.3427ZM19.359 14.0181C20.4305 15.0812 21.7573 15.6134 23.3392 15.6134C24.9212 15.6134 26.2479 15.0824 27.3195 14.0181C28.391 12.955 28.9268 11.5993 28.9268 9.9508C28.9268 8.30234 28.391 6.94294 27.3195 5.87138C26.2479 4.79982 24.9212 4.26404 23.3392 4.26404C21.7573 4.26404 20.4293 4.79982 19.359 5.87138C18.2874 6.94294 17.7516 8.30355 17.7516 9.9508C17.7516 11.598 18.2874 12.955 19.359 14.0181Z'
            fill='white'
          />
          <path
            d='M56.6432 1.29785C59.0173 1.29785 60.9984 2.12994 62.59 3.79533C64.1804 5.46072 64.9762 7.51313 64.9762 9.95256C64.9762 12.392 64.1816 14.4444 62.59 16.1098C60.9984 17.7752 59.0173 18.6073 56.6432 18.6073H50.8863C50.7012 18.6073 50.5513 18.4573 50.5513 18.2723V1.63407C50.5513 1.44903 50.7012 1.29906 50.8863 1.29906H56.6432V1.29785ZM56.6432 15.3418C58.1429 15.3418 59.3632 14.8435 60.3029 13.8457C61.2427 12.8491 61.7119 11.5502 61.7119 9.95135C61.7119 8.35248 61.2415 7.05476 60.3029 6.05697C59.3632 5.0604 58.1429 4.5609 56.6432 4.5609H54.2981C54.113 4.5609 53.9631 4.71087 53.9631 4.89592V15.0068C53.9631 15.1918 54.113 15.3418 54.2981 15.3418H56.6432Z'
            fill='white'
          />
          <path
            d='M3.51798 14.065C4.06585 15.0857 4.97293 15.5937 6.23074 15.5937C7.13781 15.5937 7.81388 15.382 8.26017 14.9527C8.70524 14.5233 8.92898 13.9404 8.92898 13.1978V1.61384C8.92898 1.43001 9.07774 1.28125 9.26158 1.28125H11.9792C12.1654 1.28125 12.3154 1.43122 12.3154 1.61747V13.1978C12.3154 15.0119 11.7482 16.4197 10.6113 17.4248C9.47323 18.4334 8.01465 18.9341 6.23195 18.9341C3.51435 18.9341 1.6107 17.8396 0.51979 15.6457C0.0626243 14.8402 0.344424 13.8086 1.1487 13.3429C1.95781 12.8773 2.99429 13.1555 3.46355 13.9646L3.51798 14.0662V14.065Z'
            fill='white'
          />
          <path
            d='M46.2278 9.72881C47.3187 8.85318 47.8618 7.60505 47.8618 6.16945C47.8618 4.73385 47.3538 3.58489 46.3403 2.67056C45.3244 1.75623 44.0762 1.29785 42.5947 1.29785H34.7249C34.5374 1.29785 34.3887 1.45024 34.3887 1.63407V18.2698C34.3887 18.4573 34.5411 18.6061 34.7249 18.6061H43.1111C44.6277 18.6061 45.9061 18.1368 46.9462 17.1983C47.9827 16.2561 48.5028 15.0564 48.5028 13.5857C48.5028 11.8223 47.7444 10.5996 46.2278 9.7276V9.72881ZM42.9914 15.5534H37.8017V4.62621H42.4629C43.4558 4.62621 44.3423 5.35308 44.4354 6.3424C44.5419 7.47685 43.6529 8.43109 42.5403 8.43109H40.8047C40.0766 8.43109 39.4139 8.92454 39.2687 9.6381C39.0704 10.6177 39.8166 11.4764 40.7612 11.4764H43.076C44.2685 11.4764 45.224 12.502 45.1079 13.7175C45.0063 14.777 44.0557 15.5534 42.9914 15.5534Z'
            fill='white'
          />
          <path
            d='M81.0488 18.1571L75.1806 1.52137C75.1298 1.38833 75.0052 1.29883 74.8637 1.29883H71.181C71.0407 1.29883 70.9149 1.38833 70.8641 1.52137L64.996 18.1571C64.9173 18.376 65.0818 18.607 65.3128 18.607H68.0619C68.2022 18.607 68.3316 18.5175 68.3788 18.3809L69.3995 15.4915H76.6476L77.6684 18.3809C77.7156 18.5175 77.8438 18.607 77.9853 18.607H80.7343C80.9653 18.607 81.1286 18.376 81.0512 18.1571H81.0488ZM70.5243 12.2998L72.9117 5.54031C72.948 5.4363 73.0955 5.4363 73.133 5.54031L75.5205 12.2998H70.5243Z'
            fill='white'
          />
          <path
            d='M147.917 1.76953L141.343 16.6359C140.813 17.8332 139.628 18.6048 138.319 18.6048C137.01 18.6048 135.824 17.8332 135.295 16.6359L128.721 1.76953C128.623 1.5482 128.785 1.29906 129.027 1.29906H131.955C132.088 1.29906 132.208 1.37767 132.261 1.49862L137.986 14.4347C138.104 14.6996 138.48 14.7008 138.598 14.4347L144.376 1.4962C144.43 1.37525 144.55 1.29785 144.682 1.29785H147.611C147.853 1.29785 148.015 1.54699 147.917 1.76832V1.76953Z'
            fill='white'
          />
          <path
            d='M100.081 1.29883H88.4273C88.2423 1.29883 88.0923 1.4488 88.0923 1.63384V18.2696C88.0923 18.4546 88.2423 18.6046 88.4273 18.6046H100.081C102.928 18.6046 105.236 16.297 105.236 13.45V6.45464C105.236 3.60764 102.928 1.30004 100.081 1.30004V1.29883ZM101.974 12.8937C101.974 14.2458 100.878 15.3416 99.5263 15.3416H91.6903C91.5053 15.3416 91.3553 15.1916 91.3553 15.0066V4.89569C91.3553 4.71064 91.5053 4.56067 91.6903 4.56067H99.5263C100.878 4.56067 101.974 5.65642 101.974 7.00857V12.8925V12.8937Z'
            fill='white'
          />
          <path
            d='M125.388 1.29883H109.537C109.352 1.29883 109.202 1.44882 109.202 1.63384V4.47117C109.202 4.6562 109.352 4.80619 109.537 4.80619H125.388C125.573 4.80619 125.723 4.6562 125.723 4.47117V1.63384C125.723 1.44882 125.573 1.29883 125.388 1.29883Z'
            fill='white'
          />
          <path
            d='M122.126 8.19824H109.537C109.352 8.19824 109.202 8.34823 109.202 8.53325V11.3706C109.202 11.5556 109.352 11.7056 109.537 11.7056H122.126C122.311 11.7056 122.461 11.5556 122.461 11.3706V8.53325C122.461 8.34823 122.311 8.19824 122.126 8.19824Z'
            fill='white'
          />
          <path
            d='M125.388 15.0977H109.537C109.352 15.0977 109.202 15.2476 109.202 15.4327V18.27C109.202 18.455 109.352 18.605 109.537 18.605H125.388C125.573 18.605 125.723 18.455 125.723 18.27V15.4327C125.723 15.2476 125.573 15.0977 125.388 15.0977Z'
            fill='white'
          />
        </svg>
      </Logo>
      <Content>
        {navItems?.map((item, idx) => (
          <HeaderItem
            key={`widget-header-nav-item-${idx}`}
            isSelected={cursor === idx}
          >
            {item}
          </HeaderItem>
        ))}
      </Content>
      <Content>
        {rightMenu?.map((menu, idx) => (
          <RightMenuWrap key={`widget-header-right-menu-${idx}`}>
            {menu}
          </RightMenuWrap>
        ))}
      </Content>
    </Container>
  );
};

export default Header;
