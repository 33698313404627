export const KB = 1024;
export const MB = KB * KB;
export const _10MB = 10 * MB;
export const FILE_ACCEPTS = [
  '.png',
  '.jpg',
  '.jpeg',
  'image/jpeg',
  'image/jpg',
  'image/png',
];
