import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  FunctionParameterAndReturnTypeDTO,
  LanguageDTO,
  ModifyCommandType,
  ProblemCategory,
  TestCaseDTO,
} from '@phs/interfaces';
import { CodeGenerator, CodeTestCase } from '@phs/code';
import { PMS_PAGES } from '@phs/constants';
import { useAlertModal } from '@components/Modals/Alert/hook.ts';
import { DropdownItemType } from '@widget/dropdown';
import { MakeProblemReqDTO } from '@interfaces/dashboard/problem.dto.ts';
import { useReadExam, useUpdateExam } from '@hooks/exam/useExam.ts';
import { useTestCase } from '@hooks/exam/useTestCase.ts';
import { FormProps, initWithFallback } from '../../shared/config.ts';
import { schema } from '../../shared/scheme.ts';
import { useParametersAndReturnTypes } from '../shared/Parameters/hooks.ts';
import { functionParametersAndReturnTypes } from '@stores/atoms/exam/exam.function.ts';
import { useRecoilValue } from 'recoil';

interface UseExamProps {
  sn?: string;
  languageList?: LanguageDTO[];
}

export function useFuncExam({ sn, languageList = [] }: UseExamProps) {
  const {
    trigger,
    watch,
    register,
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
  } = useForm<FormProps>({
    defaultValues: initWithFallback(languageList),
    resolver: zodResolver(schema),
  });
  const defaultParams = useRecoilValue(functionParametersAndReturnTypes);

  const clearTestCases = () => {
    setValue(
      'exampleTestCases',
      CodeTestCase.deleteAllTestCases(getValues('exampleTestCases')),
    );
    setValue(
      'accuracyTestCases',
      CodeTestCase.deleteAllTestCases(getValues('accuracyTestCases') ?? []),
    );
    setValue(
      'efficiencyTestCases',
      CodeTestCase.deleteAllTestCases(getValues('efficiencyTestCases') ?? []),
    );
    setValue(
      'accuracyFileTestCases',
      CodeTestCase.deleteAllTestCases(getValues('accuracyFileTestCases') ?? []),
    );
    setValue(
      'efficiencyFileTestCases',
      CodeTestCase.deleteAllTestCases(
        getValues('efficiencyFileTestCases') ?? [],
      ),
    );
  };

  const updateLanguageSet = (params: FunctionParameterAndReturnTypeDTO) => {
    const languages = getValues('languages');
    const data = languages.map(
      ({
        defaultSource,
        expectedSource,
        usage,
        language,
        commandType,
        ...rest
      }) => {
        if (usage && commandType === CodeTestCase.commandType.READ) {
          commandType = CodeTestCase.commandType.UPDATE;
        }

        return {
          ...rest,
          usage,
          language,
          commandType,
          defaultSource: CodeGenerator.getFunctionTemplate(language, params),
          expectedSource: '',
        };
      },
    );

    setValue('languages', data);
  };

  const updateTestCases = (params: FunctionParameterAndReturnTypeDTO) => {
    const exampleTestCases = getValues('exampleTestCases') ?? [];
    const accuracyTestCases = getValues('accuracyTestCases') ?? [];
    const accuracyFileTestCases = getValues('accuracyFileTestCases') ?? [];
    const efficiencyTestCases = getValues('efficiencyTestCases') ?? [];
    const efficiencyFileTestCases = getValues('efficiencyFileTestCases') ?? [];

    setValue(
      'exampleTestCases',
      exampleTestCases.map((tc) => {
        const newInput = tc.input.map((input, idx) => ({
          ...input,
          parameterName: params?.paramInfo?.[idx]?.paramName,
        }));

        return {
          ...tc,
          input: newInput,
          commandType:
            tc.commandType === ModifyCommandType.READ
              ? ModifyCommandType.UPDATE
              : tc.commandType,
        };
      }),
    );
    setValue(
      'accuracyTestCases',
      accuracyTestCases.map((tc) => {
        const newInput = tc.input.map((input, idx) => ({
          ...input,
          parameterName: params?.paramInfo?.[idx]?.paramName,
        }));

        return {
          ...tc,
          input: newInput,
          commandType:
            tc.commandType === ModifyCommandType.READ
              ? ModifyCommandType.UPDATE
              : tc.commandType,
        };
      }),
    );
    setValue(
      'efficiencyTestCases',
      efficiencyTestCases.map((tc) => {
        const newInput = tc.input.map((input, idx) => ({
          ...input,
          parameterName: params?.paramInfo?.[idx]?.paramName,
        }));

        return {
          ...tc,
          input: newInput,
          commandType:
            tc.commandType === ModifyCommandType.READ
              ? ModifyCommandType.UPDATE
              : tc.commandType,
        };
      }),
    );
    setValue(
      'accuracyFileTestCases',
      accuracyFileTestCases.map((tc) => {
        const newInput = tc.input.map((input, idx) => ({
          ...input,
          parameterName: params?.paramInfo?.[idx]?.paramName,
        }));

        return {
          ...tc,
          input: newInput,
          commandType:
            tc.commandType === ModifyCommandType.READ
              ? ModifyCommandType.UPDATE
              : tc.commandType,
        };
      }),
    );
    setValue(
      'efficiencyFileTestCases',
      efficiencyFileTestCases.map((tc) => {
        const newInput = tc.input.map((input, idx) => ({
          ...input,
          parameterName: params?.paramInfo?.[idx]?.paramName,
        }));

        return {
          ...tc,
          input: newInput,
          commandType:
            tc.commandType === ModifyCommandType.READ
              ? ModifyCommandType.UPDATE
              : tc.commandType,
        };
      }),
    );
  };

  const isAbleToAddTestCase = useMemo(() => {
    const languages = getValues('languages');
    return languages.some(({ usage }) => usage);
  }, [watch('languages')]);

  const navigation = useNavigate();
  const { onOpen } = useAlertModal();
  const { update, isLoading: isUpdateLoading } = useUpdateExam();

  const hasTestCase = useMemo(() => {
    return (
      (watch('exampleTestCases') ?? []).filter(
        ({ commandType }) => commandType !== ModifyCommandType.DELETE,
      ).length +
        (watch('accuracyTestCases') ?? []).filter(
          ({ commandType }) => commandType !== ModifyCommandType.DELETE,
        ).length +
        (watch('efficiencyTestCases') ?? []).filter(
          ({ commandType }) => commandType !== ModifyCommandType.DELETE,
        ).length +
        (watch('accuracyFileTestCases') ?? []).filter(
          ({ commandType }) => commandType !== ModifyCommandType.DELETE,
        ).length +
        (watch('efficiencyFileTestCases') ?? []).filter(
          ({ commandType }) => commandType !== ModifyCommandType.DELETE,
        ).length >
      0
    );
  }, [
    watch('exampleTestCases'),
    watch('accuracyTestCases'),
    watch('efficiencyTestCases'),
    watch('accuracyFileTestCases'),
    watch('efficiencyFileTestCases'),
  ]);

  const shouldAlert = useMemo(() => {
    if (hasTestCase) return true;
    const languages = getValues('languages').filter(({ usage }) => usage);
    return languages.some(
      (item) =>
        item.defaultSource !==
        CodeGenerator.getFunctionTemplate(
          item.language,
          defaultParams as FunctionParameterAndReturnTypeDTO,
        ),
    );
  }, [getValues('languages'), defaultParams, hasTestCase]);

  const {
    params,
    onClick: onClickParam,
    onBlurInput: onBlurInputParam,
    onChangeSelect: onChangeSelectParam,
    onFocusInput: onFocusInputParam,
    onClickSelect: onClickSelectParam,
  } = useParametersAndReturnTypes({
    changeParamsCallback: (
      params: FunctionParameterAndReturnTypeDTO,
      testCaseClear?: boolean,
    ) => {
      if (testCaseClear) clearTestCases();
      else updateTestCases(params);
      updateLanguageSet(params);
    },
    shouldAlert,
  });
  const testCases = useTestCase(sn || '');
  const currentExam = useReadExam({ sn: sn || '' });

  const isEnableCalculation = useMemo(() => {
    const accuracyTestCases = getValues('accuracyTestCases') ?? [];
    const accuracyFileTestCases = getValues('accuracyFileTestCases') ?? [];
    const efficiencyTestCases = getValues('efficiencyTestCases') ?? [];
    const efficiencyFileTestCases = getValues('efficiencyFileTestCases') ?? [];

    return (
      (accuracyTestCases.filter(
        ({ commandType }) => commandType !== ModifyCommandType.DELETE,
      ).length > 0 ||
        accuracyFileTestCases.filter(
          ({ commandType }) => commandType !== ModifyCommandType.DELETE,
        ).length > 0) &&
      (efficiencyTestCases.filter(
        ({ commandType }) => commandType !== ModifyCommandType.DELETE,
      ).length > 0 ||
        efficiencyFileTestCases.filter(
          ({ commandType }) => commandType !== ModifyCommandType.DELETE,
        ).length > 0)
    );
  }, [
    watch('accuracyTestCases'),
    watch('accuracyFileTestCases'),
    watch('efficiencyTestCases'),
    watch('efficiencyFileTestCases'),
  ]);

  useEffect(() => {
    if (isEnableCalculation) return;
    setValue('scoreRateYn', false);
  }, [isEnableCalculation]);

  const onChangeLevel = (props: DropdownItemType) => {
    setValue('level', Number(props.key));
  };

  const onChangeDetailLevel = (props: DropdownItemType) => {
    setValue('detailLevel', Number(props.key));
  };

  const onChangeAlgorithm = (props: string[]) => {
    setValue('algorithmTypeList', props);
  };

  const isEmptyTestCase = (testCases: TestCaseDTO[]) => {
    return !testCases.some(
      ({ commandType }) => commandType !== ModifyCommandType.DELETE,
    );
  };

  const onSubmit = () => {
    const {
      title,
      content,
      level,
      detailLevel,
      algorithmTypeList,
      scoreRateYn,
      accuracyScoreRate,
      efficiencyScoreRate,
      languages,
      exampleTestCases = [],
      accuracyTestCases = [],
      efficiencyTestCases = [],
      accuracyFileTestCases = [],
      efficiencyFileTestCases = [],
    } = getValues();

    const usingLanguageList = languages.filter(({ usage }) => usage);
    if (usingLanguageList.length === 0) {
      onOpen('언어를 선택하지 않았습니다.');
      return;
    }

    if (isEmptyTestCase(exampleTestCases)) {
      onOpen('예시 테스트 케이스가 없습니다.');
      return;
    }

    if (
      isEmptyTestCase(accuracyTestCases) &&
      isEmptyTestCase(accuracyFileTestCases)
    ) {
      onOpen('정확성 테스트 케이스가 없습니다.');
      return;
    }

    const payload: MakeProblemReqDTO = {
      title,
      content,
      level: level.toString(),
      detailLevel,
      algorithmTypeList,
      judgeType: ProblemCategory.FUNCTION,
      judgeLanguageList: languages.filter(
        ({ commandType }) =>
          commandType && commandType !== CodeTestCase.commandType.READ,
      ),
      testCaseList: CodeTestCase.mergeTestCases(ProblemCategory.FUNCTION, [
        exampleTestCases,
        accuracyTestCases,
        accuracyFileTestCases,
        efficiencyTestCases,
        efficiencyFileTestCases,
      ]),
      testCaseScoreRateYn: scoreRateYn,
      accuracyScoreRate: scoreRateYn ? accuracyScoreRate : undefined,
      efficiencyScoreRate: scoreRateYn ? efficiencyScoreRate : undefined,
      onlyProblemUpdateYn: false,
    };

    update(
      {
        sn: sn!,
        data: payload,
      },
      {
        onSuccess: () => {
          navigation(PMS_PAGES.DASHBOARD.HOME);
        },
        onError: () => {
          onOpen('문제 수정에 실패하였습니다.');
        },
      },
    );
  };

  useEffect(() => {
    if (!sn) return navigation(PMS_PAGES.DASHBOARD.HOME, { replace: true });

    const {
      title,
      content,
      level,
      detailLevel,
      algorithmTypeList,
      overallPredictionAverageScore,
      overallPredictionLevel,
      testCaseScoreRateYn,
      accuracyScoreRate,
      efficiencyScoreRate,
    } = currentExam;

    setValue('title', title);
    setValue('content', content);
    setValue('level', parseInt(level ?? '0'));
    setValue('detailLevel', detailLevel);
    setValue('algorithmTypeList', algorithmTypeList);
    setValue('scoreRateYn', testCaseScoreRateYn);
    setValue('overallPredictionAverageScore', overallPredictionAverageScore);
    setValue('overallPredictionLevel', overallPredictionLevel);

    if (testCaseScoreRateYn) {
      setValue('accuracyScoreRate', accuracyScoreRate ?? 50);
      setValue('efficiencyScoreRate', efficiencyScoreRate ?? 50);
    }

    const {
      exampleTestCases,
      accuracyTestCases,
      accuracyFileTestCases,
      efficiencyTestCases,
      efficiencyFileTestCases,
    } = CodeTestCase.splitTestCases(
      CodeTestCase.removeEscape(
        testCases?.problemTestCaseRsList ?? [],
        CodeTestCase.problemType.FUNCTION,
      ),
    );

    setValue('exampleTestCases', CodeTestCase.readTestCase(exampleTestCases));
    setValue('accuracyTestCases', CodeTestCase.readTestCase(accuracyTestCases));
    setValue(
      'accuracyFileTestCases',
      CodeTestCase.readTestCase(accuracyFileTestCases),
    );
    setValue(
      'efficiencyTestCases',
      CodeTestCase.readTestCase(efficiencyTestCases),
    );
    setValue(
      'efficiencyFileTestCases',
      CodeTestCase.readTestCase(efficiencyFileTestCases),
    );
  }, [currentExam, navigation, setValue, sn, testCases?.problemTestCaseRsList]);

  useEffect(() => {
    const { judgeLanguageList } = currentExam;
    const initialLanguages = CodeGenerator.getLanguageTemplate(
      languageList,
    ).map((language) => {
      const judgeLanguage = judgeLanguageList.find(
        (judgeLanguage) => judgeLanguage.language === language.language,
      );

      return judgeLanguage
        ? {
            ...language,
            ...judgeLanguage,
            commandType: ModifyCommandType.READ,
            usage: true,
          }
        : language;
    });

    setValue('languages', initialLanguages);
  }, [languageList, currentExam]);

  const accuracyTestCasesCount = useMemo(() => {
    const count =
      (watch('accuracyFileTestCases') ?? []).filter(
        ({ commandType }) => commandType !== ModifyCommandType.DELETE,
      ).length +
      (watch('accuracyTestCases') ?? []).filter(
        ({ commandType }) => commandType !== ModifyCommandType.DELETE,
      ).length;
    return count === 0 ? 1 : count;
  }, [watch('accuracyFileTestCases'), watch('accuracyTestCases')]);

  const efficiencyTestCasesCount = useMemo(() => {
    const count =
      (watch('efficiencyFileTestCases') ?? []).filter(
        ({ commandType }) => commandType !== ModifyCommandType.DELETE,
      ).length +
      (watch('efficiencyTestCases') ?? []).filter(
        ({ commandType }) => commandType !== ModifyCommandType.DELETE,
      ).length;
    return count === 0 ? 1 : count;
  }, [watch('efficiencyFileTestCases'), watch('efficiencyTestCases')]);

  useEffect(() => {
    const accuracyScoreRate = getValues('accuracyScoreRate');
    if (accuracyScoreRate === undefined || accuracyScoreRate === null) return;
    let num = Math.max(1, accuracyScoreRate);
    if (isNaN(num)) num = 1;
    num = Math.min(99, num);

    setValue('accuracyScoreRate', num);
    setValue('efficiencyScoreRate', 100 - num);
  }, [watch('accuracyScoreRate')]);

  return {
    trigger,
    watch,
    register,
    errors,
    setValue,
    getValues,
    handleSubmit,
    onChangeLevel,
    onChangeDetailLevel,
    onChangeAlgorithm,
    onSubmit,
    isAbleToAddTestCase,
    isEnableCalculation,
    params,
    onClickParam,
    onBlurInputParam,
    onFocusInputParam,
    onChangeSelectParam,
    onClickSelectParam,
    accuracyTestCasesCount,
    efficiencyTestCasesCount,
    isUpdateLoading,
  };
}
