import styled from '@emotion/styled';

export const Container = styled.section`
  padding: 32px;
  background: #fff;
`;

export const FileIcon = styled.div`
  text-align: center;
  height: 64px;

  svg {
    width: 64px;
    height: 64px;
  }
`;

export const TextFrame = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #868e96;
`;
