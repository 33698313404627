import { UseFormSetValue } from 'react-hook-form';
import { useState } from 'react';
import { ModifyCommandType, TestCaseDTO } from '@phs/interfaces';
import { useAlertModal } from '@components/Modals/Alert/hook.ts';
import { FormProps } from '../../../shared/config.ts';
import fileRepository from '@repositories/file.repository.ts';
import { FileName, TableCell } from './style.ts';

interface UseAccuracyCaseProps {
  disabled?: boolean;
  setValue: UseFormSetValue<FormProps>;
}

export function useAccuracyCase({
  disabled = false,
  setValue,
}: UseAccuracyCaseProps) {
  const { onOpen } = useAlertModal();
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [isFileModalOpened, setIsFileModalOpened] = useState<boolean>(false);

  const downloadAllFiles = async (data: TestCaseDTO[]) => {
    if (
      data.filter(({ commandType }) => commandType !== ModifyCommandType.DELETE)
        .length <= 0
    ) {
      onOpen('정확성 파일 테스트 케이스가 없습니다.');
      return;
    }

    const fileList = data
      .filter(({ commandType }) => commandType !== ModifyCommandType.DELETE)
      .flatMap(({ inputFile, outputFile }) => [
        { fileName: inputFile?.fileName, fileUid: inputFile?.fileUid },
        { fileName: outputFile?.fileName, fileUid: outputFile?.fileUid },
      ]);

    fileRepository.testCaseFileBulkDownload('정확성 테스트 케이스.zip', {
      fileList,
    });
  };
  const handleOpen = (type: string, status: boolean) => {
    if (disabled) return onOpen('언어가 선택되지 않았습니다.');
    if (!status) return onClose();

    switch (type) {
      case 'testcase':
      default:
        setIsModalOpened(true);
        break;
      case 'file':
        setIsFileModalOpened(true);
        break;
    }
  };

  const onClose = () => {
    setIsModalOpened(false);
    setIsFileModalOpened(false);
  };

  const onSubmit = (data: TestCaseDTO[]) => {
    setIsModalOpened(false);
    setValue('accuracyTestCases', data);
  };

  const onFileSubmit = (data: TestCaseDTO[]) => {
    setIsFileModalOpened(false);
    setValue('accuracyFileTestCases', data);
  };

  const downloadFileHandler = async (fileName: string, fileUid: string) => {
    try {
      await fileRepository.testCaseFileDownload(fileName, fileUid);
    } catch (e: any) {
      console.error(e);
    }
  };

  const renderData = (data: TestCaseDTO[]) => {
    if (!data) return undefined;

    return data
      .filter(({ commandType }) => commandType !== ModifyCommandType.DELETE)
      .map((props, idx) => [
        <TableCell>{idx + 1}</TableCell>,
        <TableCell>{props.input?.[0].value}</TableCell>,
        <TableCell>{props.output.value}</TableCell>,
      ]);
  };

  const renderFileData = (data: TestCaseDTO[]) => {
    return data
      .filter(({ commandType }) => commandType !== ModifyCommandType.DELETE)
      .map((props, idx) => [
        <span>{idx + 1}</span>,
        <FileName
          onClick={() => {
            if (!props.inputFile) return;
            downloadFileHandler(
              props.inputFile.fileName,
              props.inputFile.fileUid,
            );
          }}
        >
          {props.inputFile?.fileName}
        </FileName>,
        <FileName
          onClick={() => {
            if (!props.outputFile) return;
            downloadFileHandler(
              props.outputFile.fileName,
              props.outputFile.fileUid,
            );
          }}
        >
          {props.outputFile?.fileName}
        </FileName>,
      ]);
  };

  return {
    isModalOpened,
    isFileModalOpened,
    handleOpen,
    renderData,
    renderFileData,
    onSubmit,
    onFileSubmit,
    downloadAllFiles,
    onClose,
  };
}
