export const Menu: Array<{ name: string; key: string }> = [
	{
		name: '기업 관리',
		key: '기업 관리',
	},
	{
		name: '자료 관리',
		key: '자료 관리',
	},
	{
		name: '운영 관리',
		key: '운영 관리',
	},
];
