import styled from '@emotion/styled';

export const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    color: #fa5252;
  }
`;

export const Input = styled.input<{ error?: string }>`
  width: 100%;
  min-width: 120px;
  height: 40px;
  background: white;
  border: 1px solid ${({ error }) => (error ? '#fa5252' : '#ced4da')};
  border-radius: 4px;
  padding: 12px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;

  &:focus-visible,
  &:target,
  &:hover,
  &:focus,
  &:focus-within,
  &:active,
  &:visited {
    border: 1px solid ${({ error }) => (error ? '#fa5252' : '#495057')};
    outline: none;
    box-shadow: none;
  }
`;
