import {
  ExamLanguageDTO,
  FunctionExamDTO,
  FunctionParameterAndReturnTypeDTO,
  ModifyCommandType,
  PhaseProblemSourceDTO,
  ProblemCategory,
  TestCaseDTO,
  TestCaseType,
} from '@phs/interfaces';
import { CodeGenerator, CodeTestCase } from '@phs/code';
import { useParametersAndReturnTypes } from '../Parameters/hooks';
import { useMemo } from 'react';

interface UsePackagePhaseProps {
  testCases?: TestCaseDTO[];
  handleTestCase?: (data: TestCaseDTO[]) => void;
  defaultParams?: FunctionParameterAndReturnTypeDTO;
  handleParams: (params: FunctionParameterAndReturnTypeDTO) => void;
  judgeLanguageList: PhaseProblemSourceDTO[];
  handleJudgeLanguageList: (languageList: ExamLanguageDTO[]) => void;
}

export function usePackagePhase({
  testCases = [],
  handleTestCase,
  handleParams,
  defaultParams,
  judgeLanguageList,
  handleJudgeLanguageList,
}: UsePackagePhaseProps) {
  const clearTestCases = () => {
    handleTestCase?.(CodeTestCase.deleteAllTestCases(testCases));
  };

  const updateTestCases = (params: FunctionParameterAndReturnTypeDTO) => {
    handleTestCase?.(
      testCases?.map((tc) => {
        if (tc.commandType === ModifyCommandType.DELETE) {
          return tc;
        }
        const newInput = tc.input.map((input, idx) => ({
          ...input,
          parameterName: params?.paramInfo?.[idx]?.paramName,
        }));

        return {
          ...tc,
          input: newInput,
          commandType:
            tc.commandType === ModifyCommandType.READ
              ? ModifyCommandType.UPDATE
              : tc.commandType,
        };
      }),
    );
  };

  const hasTestCase = useMemo(() => {
    return (
      testCases.filter(
        ({ commandType }) => commandType !== ModifyCommandType.DELETE,
      ).length > 0
    );
  }, [testCases]);

  const shouldAlert = useMemo(() => {
    if (hasTestCase) return true;
    return judgeLanguageList.some(
      (item) =>
        item.defaultSource !==
        CodeGenerator.getFunctionTemplate(
          item.language,
          defaultParams as FunctionParameterAndReturnTypeDTO,
        ),
    );
  }, [judgeLanguageList, defaultParams, hasTestCase]);

  const changeJudgeLanguageList = (
    params: FunctionParameterAndReturnTypeDTO,
  ) => {
    const languages = [...judgeLanguageList];
    const newLanguageList = languages.map(
      ({ language, usage, commandType, ...rest }) => {
        return {
          ...rest,
          language,
          usage,
          commandType:
            usage && commandType === CodeTestCase.commandType.READ
              ? CodeTestCase.commandType.UPDATE
              : commandType,
          defaultSource: CodeGenerator.getFunctionTemplate(language, params),
        };
      },
    ) as FunctionExamDTO[];
    handleJudgeLanguageList(newLanguageList);
  };

  const {
    params,
    onClick: onClickParam,
    onBlurInput: onBlurInputParam,
    onChangeSelect: onChangeSelectParam,
    onFocusInput: onFocusInputParam,
    onClickSelect: onClickSelectParam,
  } = useParametersAndReturnTypes({
    changeParamsCallback: (
      params: FunctionParameterAndReturnTypeDTO,
      testCaseClear?: boolean,
    ) => {
      if (testCaseClear) clearTestCases();
      else updateTestCases(params);
      handleParams(params);
      changeJudgeLanguageList(params);
    },
    shouldAlert,
    defaultParams,
  });

  const {
    exampleTestCases,
    accuracyTestCases,
    accuracyFileTestCases,
    efficiencyTestCases,
    efficiencyFileTestCases,
  } = useMemo(() => CodeTestCase.splitTestCases(testCases), [testCases]);

  const handleSubmit = (
    type: TestCaseType,
    data: TestCaseDTO[],
    isFile = false,
  ) => {
    switch (type) {
      case TestCaseType.EXAMPLE:
      default:
        handleTestCase?.(
          CodeTestCase.mergeTestCases(ProblemCategory.PST2, [
            data,
            accuracyTestCases,
            accuracyFileTestCases,
            efficiencyTestCases,
            efficiencyFileTestCases,
          ]),
        );
        break;

      case TestCaseType.ACCURACY: {
        const defaultTestCases = isFile
          ? accuracyTestCases
          : accuracyFileTestCases;

        handleTestCase?.(
          CodeTestCase.mergeTestCases(ProblemCategory.PST2, [
            exampleTestCases,
            data,
            defaultTestCases,
            efficiencyTestCases,
            efficiencyFileTestCases,
          ]),
        );
        break;
      }

      case TestCaseType.EFFICIENCY: {
        const defaultTestCases = isFile
          ? efficiencyTestCases
          : efficiencyFileTestCases;

        handleTestCase?.(
          CodeTestCase.mergeTestCases(ProblemCategory.PST2, [
            exampleTestCases,
            accuracyTestCases,
            accuracyFileTestCases,
            data,
            defaultTestCases,
          ]),
        );
        break;
      }
    }
  };

  return {
    exampleTestCases,
    accuracyTestCases,
    accuracyFileTestCases,
    efficiencyTestCases,
    efficiencyFileTestCases,
    handleSubmit,
    params,
    onClickParam,
    onBlurInputParam,
    onChangeSelectParam,
    onFocusInputParam,
    onClickSelectParam,
  };
}
