import { Container } from './style';

const TestCaseInformation = () => {
  return (
    <Container>
      표준 입출력 형식(STDIO) 문제에서는 테스트케이스 입력 시 별도의 구분 기호
      없이 문제에서 제시하는
      <br />
      형식에 맞춰 입력하시면 됩니다. 입력값은 내부적으로는 string으로
      인식합니다.
    </Container>
  );
};

export default TestCaseInformation;
