import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';

interface UseTabProps {
  items: Array<{
    name: string;
    key: string;
  }>;
  navigateList?: string[];
}

export function useTab({ items, navigateList }: UseTabProps) {
  const location = useLocation();
  const navigation = useNavigate();
  const [currentMenu, setCurrentMenu] = useState<string>(items[0].key);

  const currentTab = useMemo(() => currentMenu, [currentMenu]);

  const onClick = useCallback(
    (keyIdx: number) => {
      setCurrentMenu(items[keyIdx].key);
      if (!navigateList) return;
      navigation(navigateList[keyIdx]);
    },
    [items, navigateList, navigation],
  );

  useEffect(() => {
    navigateList?.findIndex((value, idx) => {
      if (idx === -1) return;
      if (currentMenu === items[idx].key) return;
      if (value === location.pathname) onClick(idx);
    });
  }, [currentMenu, items, location.pathname, navigateList, onClick]);

  return {
    currentTab,
    onClick,
  };
}
