import styled from '@emotion/styled';

export const Container = styled.div``;

export const Title = styled.span`
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
`;

export const Table = styled.table`
  width: 100%;
  margin-top: 12px;
  border: 1px solid rgb(222 226 230);

  thead {
    th {
      width: 33.33%;
      height: 32px;
      font-size: 12px;
      font-weight: 700;
      line-height: 14px;
      color: #656a71;
      text-align: center;
      vertical-align: middle;
      background: #f4f4f5;
    }
  }

  tbody {
    td {
      padding: 12px;
    }
  }
`;
