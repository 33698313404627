export class Validator {
  public static isEmpty(data: string) {
    return data === ''.replaceAll(' ', '');
  }

  public static isOverTheLength(text: string, length: number) {
    return text.length > length;
  }

  public static hasKorean(text: string): boolean {
    const korean = /[ㄱ-ㅎ|ㅏ-ㅣ가-힣]/;
    return korean.test(text);
  }

  public static hasNumeric(text: string): boolean {
    const reg = /^[0-9]/;
    return reg.test(text);
  }

  public static hasSpecialCharacters(text: string) {
    const special = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\" ]/g;
    return special.test(text);
  }
}
