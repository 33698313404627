import styled from '@emotion/styled';
import TextButton from '@widget/button-text';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray_300};
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  .toastui-editor,
  .toastui-editor-md-preview,
  .toastui-editor-ww-container > .toastui-editor {
    min-height: 0 !important;
  }

  .ProseMirror {
    height: 100%;
  }
  .highlight {
    background-color: #6cdd83;
    line-height: inherit;
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
  }
`;

export const RequireTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray_900};

  &::after {
    display: inline-block;
    margin-left: 4px;
    color: ${({ theme }) => theme.colors.red_600};
    vertical-align: middle;
    content: '*';
  }
`;

export const Hint = styled.span`
  margin-top: 8px;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.red_600};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;

  & > button {
    width: auto;
    padding: 12px 16px;
  }
`;

export const Button = styled(TextButton)``;
