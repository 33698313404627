import {
  algorithmType,
  judgeType,
  language,
  level,
  publicType,
} from '@stores/atoms/dashboard/exam.filter';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { usePagination } from '@components/Pagination/hooks';
import { searchKeyword } from '@stores/atoms/shared/keyword';

export function useFilters() {
  const { resetPage } = usePagination();

  const [levelState, setLevel] = useRecoilState(level);
  const resetLevel = useResetRecoilState(level);

  const [judgeTypeState, setJudgeType] = useRecoilState(judgeType);
  const resetJudgeType = useResetRecoilState(judgeType);

  const [languageState, setLanguage] = useRecoilState(language);
  const resetLanguage = useResetRecoilState(language);

  const [publicTypeState, setPublicType] = useRecoilState(publicType);
  const resetPublicType = useResetRecoilState(publicType);

  const [algorithmTypeState, setAlgorithmType] = useRecoilState(algorithmType);
  const resetAlgorithmType = useResetRecoilState(algorithmType);

  const resetSearchKeyword = useResetRecoilState(searchKeyword);

  const resetFilter = () => {
    resetLevel();
    resetJudgeType();
    resetLanguage();
    resetPublicType();
    resetSearchKeyword();
    resetAlgorithmType();
  };

  const handleChangeLevelFilter = (value: string[]) => {
    setLevel(value);
    resetPage();
  };

  const handleChangeJudgeTypeFilter = (value: string[]) => {
    setJudgeType(value);
    resetPage();
  };

  const handleChangeLanguageFilter = (value: string[]) => {
    setLanguage(value);
    resetPage();
  };

  const handleChangePublicType = (value: string[]) => {
    setPublicType(value);
    resetPage();
  };

  const handleChangeAlgorithmType = (value: string[]) => {
    setAlgorithmType(value);
    resetPage();
  };

  return {
    resetFilter,
    handleChangeLevelFilter,
    handleChangeJudgeTypeFilter,
    handleChangeLanguageFilter,
    handleChangePublicType,
    handleChangeAlgorithmType,
    level: levelState,
    judgeType: judgeTypeState,
    language: languageState,
    publicType: publicTypeState,
    algorithmType: algorithmTypeState,
  };
}
