import { atomKeys } from '@constants/key.ts';
import { PaginationDTO } from '@interfaces/dashboard/filter.dto.ts';
import { atom } from 'recoil';

const fallbackPagination: PaginationDTO = {
	pageSize: 10,
	currentPage: 0,
	totalPage: 0,
};

export const currentPage = atom<number>({
	key: atomKeys.pagination.currentPage,
	default: fallbackPagination.currentPage,
});

export const totalPage = atom<number>({
	key: atomKeys.pagination.totalPage,
	default: fallbackPagination.totalPage,
});

export const pageSize = atom<number>({
	key: atomKeys.pagination.pageSize,
	default: fallbackPagination.pageSize,
});
