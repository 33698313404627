import { Container, SubTitle, Title } from './style';

const TestCaseInformation = () => {
  return (
    <Container>
      <Title>테스트 케이스 입력 형식</Title>
      <SubTitle>
        함수형 문제에서 테스트 케이스 입력 시 다음의 형식에 맞춰 입력해야
        합니다.
      </SubTitle>
      <ul>
        <li>문자열 : “”사이에 값을 입력해 줍니다.</li>
        <li>배열 : 대괄호 []로 감싸주며 배열 내 값은 , 로 구분해 줍니다.</li>
      </ul>
      <SubTitle>입력 예시</SubTitle>
      <ul>
        <li>정수형 ( int ) : 115</li>
        <li>문자열 ( string ) : “string”</li>
        <li>정수형 1차원 배열 ( int[] ) : [10,250]</li>
        <li>문자열 1차원 배열 ( string[] ) : [“string”, “array”]</li>
        <li>정수형 2차원 배열 ( int[][] ) : [[1, 2], [3, 4]]</li>
        <li>
          문자열 2차원 배열 ( string[][] ) : [[“string_1”, “array_1”],
          [“string_2”, “array_2”]]
        </li>
      </ul>
    </Container>
  );
};

export default TestCaseInformation;
