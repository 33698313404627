import { LanguageType } from '@phs/interfaces';

export const getItems = (languageList: LanguageType[]) => {
	return languageList.map((data) => {
		return {
			name: data,
			key: data,
		};
	});
};
