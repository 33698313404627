import { useCallback, useEffect, useMemo, useState } from 'react';
import { SortType } from './Sort/enum';

const validateCurrentStatus = (status: boolean[]) => {
	const currentToggleStatus = new Set(status);
	return currentToggleStatus.size === 1 && currentToggleStatus.has(true);
};

export interface HandleToggleTableClickProps<T = any> {
	type: 'item' | 'toggle' | 'sort' | 'reset';
	value: T | T[] | boolean[];
	sortType?: SortType;
}

export function useTable<T = any>(
	initialStatus: boolean[],
	onClick?: (props: HandleToggleTableClickProps<T>) => void,
) {
	const [allToggle, setAllToggle] = useState<boolean>(
		validateCurrentStatus(initialStatus),
	);

	const toggle = useMemo(() => allToggle, [allToggle]);

	const handleClick = (props: HandleToggleTableClickProps) => onClick?.(props);

	const resetActive = (defaultStatus: boolean) => {
		const newStatus = Array.from(
			{ length: initialStatus.length },
			() => defaultStatus,
		);

		handleClick({
			type: 'toggle',
			value: newStatus,
		});
	};

	const onActiveChange = useCallback(
		(idx: number, status: boolean) => {
			const newStatus = initialStatus.slice();
			newStatus[idx] = status;
			handleClick({
				type: 'toggle',
				value: newStatus,
			});
		},
		[initialStatus, handleClick],
	);

	useEffect(() => {
		setAllToggle(validateCurrentStatus(initialStatus));
	}, [initialStatus]);

	return {
		toggle,
		onActiveChange,
		handleClick,
		resetActive,
	};
}
