import { InputTextWrap, InputWrap, RequireTitle, Hint } from './style';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import Input from '@widget/input';
import { FormProps } from '../config';
import { FC } from 'react';

const validation = {
  required: true,
  valueAsNumber: true,
  min: 0,
};

interface SizeSettingProps {
  register: UseFormRegister<FormProps>;
  errors?: FieldErrors<FormProps>;
}

const SizeSetting: FC<SizeSettingProps> = ({ register, errors }) => {
  return (
    <>
      <RequireTitle>크기 설정 (픽셀)</RequireTitle>
      <InputTextWrap>
        <span>너비 :</span>
        <InputWrap>
          <Input
            {...register('width', validation)}
            type={'number'}
            role={'popup-width'}
          />
          <span>{errors?.width?.message}</span>
        </InputWrap>
      </InputTextWrap>
      <InputTextWrap>
        <span>높이 :</span>
        <InputWrap>
          <Input
            {...register('height', validation)}
            type={'number'}
            role={'popup-height'}
          />
          <span>{errors?.height?.message}</span>
        </InputWrap>
      </InputTextWrap>
      <RequireTitle style={{ paddingTop: 16 }}>위치 설정 (픽셀)</RequireTitle>
      <InputTextWrap>
        <span>왼쪽으로 부터 :</span>
        <InputWrap>
          <Input
            {...register('left', validation)}
            type={'number'}
            role={'popup-left'}
          />
          <span>{errors?.left?.message}</span>
        </InputWrap>
      </InputTextWrap>
      <InputTextWrap>
        <span>위로 부터 :</span>
        <InputWrap>
          <Input
            {...register('up', validation)}
            type={'number'}
            role={'popup-right'}
          />
          <span>{errors?.up?.message}</span>
        </InputWrap>
      </InputTextWrap>
      <Hint>
        <li>
          동시에 여러 개의 팝업을 게시하는 경우 팝업이 안 겹치게 노출 위치를
          선정해주셔야합니다.
        </li>
        <li>사용자의 화면 해상도에 따라 다르게 보일 수 있습니다.</li>
      </Hint>
    </>
  );
};

export default SizeSetting;
