// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
  RequireTitle,
  DropDownWrapper,
  ErrorText,
  Container,
} from './style.ts';
import { FieldErrors, FieldValues } from 'react-hook-form';
import DropDown, { DropdownItemType } from '@widget/dropdown';
import { LEVEL_ITEMS } from './config.ts';

interface LevelProps<T extends FieldValues> {
  title?: string;
  initialState?: number | string;
  onChange?: (props: DropdownItemType) => void;
  errors?: FieldErrors<T>;
}

function Level<T extends FieldValues>({
  title = '난이도',
  initialState,
  onChange,
  errors,
}: LevelProps<T>) {
  return (
    <Container>
      <RequireTitle>{title}</RequireTitle>
      <DropDownWrapper>
        <DropDown
          data-testid={'exam-level'}
          title={title}
          initialState={initialState}
          items={LEVEL_ITEMS}
          onClick={onChange}
        />
        <ErrorText>{errors?.level?.message}</ErrorText>
      </DropDownWrapper>
    </Container>
  );
}

export default Level;
