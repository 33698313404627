import { FocusEvent, useCallback, useEffect, useRef, useState } from 'react';
import {
  DataType,
  FunctionParameterAndReturnTypeDTO,
  FunctionParameterDTO,
} from '@phs/interfaces';
import { useAlertModal } from '@components/Modals/Alert/hook.ts';
import { SelectItemProps } from '@widget/select';
import { NumericRegex } from '@phs/constants';
import { CodeTestCase } from '@phs/code';
import { DefaultCache } from './config.ts';
import { Validator } from '@helpers/validator.ts';

interface UseParametersAndReturnTypesProps {
  changeParamsCallback?: (
    params: FunctionParameterAndReturnTypeDTO,
    testCaseClear?: boolean,
  ) => void;
  shouldAlert?: boolean;
  defaultParams?: FunctionParameterAndReturnTypeDTO;
}

export function useParametersAndReturnTypes({
  changeParamsCallback,
  shouldAlert,
  defaultParams,
}: UseParametersAndReturnTypesProps) {
  const { onOpen } = useAlertModal();
  const [params, setParams] = useState<FunctionParameterAndReturnTypeDTO>({
    paramInfo: [
      {
        paramType: DataType.STRING,
        paramName: 'Parameter',
      },
    ],
    returnType: DataType.STRING,
  });
  const tempInputValue = useRef<string>('');
  const tempSelectValue = useRef<string>('');

  useEffect(() => {
    setParams(
      defaultParams ?? {
        paramInfo: [
          {
            paramType: DataType.STRING,
            paramName: 'Parameter',
          },
        ],
        returnType: DataType.STRING,
      },
    );
  }, [defaultParams]);

  const callbackSetParams = (
    params: FunctionParameterAndReturnTypeDTO,
    testCaseClear?: boolean,
  ) => {
    setParams(params);
    changeParamsCallback?.(params, testCaseClear);
  };

  const addParam = () => {
    if (shouldAlert) {
      if (
        !confirm(
          '현재 작성한 모든 언어의 코드가 초기화 됩니다. 그래도 진행하시겠습니까?',
        )
      ) {
        return;
      }
    }

    const paramInfo = [
      ...params.paramInfo,
      {
        ...DefaultCache.paramInfo[0],
        paramName: `Parameter${params.paramInfo.length}`,
      },
    ];

    callbackSetParams(
      {
        ...params,
        paramInfo,
      },
      true,
    );
  };

  const removeParam = (idx?: number) => {
    if (idx === undefined) return;
    if (shouldAlert) {
      if (
        !confirm(
          '현재 작성한 모든 언어의 코드가 초기화 됩니다. 그래도 진행하시겠습니까?',
        )
      ) {
        return;
      }
    }

    const paramInfo = [
      ...params.paramInfo.slice(0, idx),
      ...params.paramInfo.slice(idx + 1),
    ];

    callbackSetParams(
      {
        ...params,
        paramInfo,
      },
      true,
    );
  };

  const onClick = (type: 'add' | 'remove', idx?: number) => {
    switch (type) {
      case 'add':
      default:
        addParam();
        break;

      case 'remove':
        removeParam(idx);
        break;
    }
  };

  const callbackUpdateParam = (
    value: Partial<FunctionParameterDTO>,
    idx: number,
    testCaseClear?: boolean,
  ) => {
    const paramInfo = [
      ...params.paramInfo.slice(0, idx),
      {
        ...params.paramInfo[idx],
        ...value,
      },
      ...params.paramInfo.slice(idx + 1),
    ];

    callbackSetParams(
      {
        ...params,
        paramInfo,
      },
      testCaseClear,
    );
  };

  const updateParam = (value: Partial<FunctionParameterDTO>, idx: number) => {
    const paramInfo = [
      ...params.paramInfo.slice(0, idx),
      {
        ...params.paramInfo[idx],
        ...value,
      },
      ...params.paramInfo.slice(idx + 1),
    ];

    setParams({
      ...params,
      paramInfo,
    });
  };

  const updateReturnType = (dataType: DataType) => {
    setParams({
      ...params,
      returnType: dataType,
    });
  };

  const callbackUpdateReturnType = (dataType: DataType) => {
    callbackSetParams(
      {
        ...params,
        returnType: dataType,
      },
      true,
    );
  };

  const onBlurInput = useCallback(
    (event: FocusEvent<HTMLInputElement>, idx: number) => {
      const {
        target: { value },
      } = event;

      if (!value) {
        onOpen('빈 값은 입력이 제한됩니다.');
        updateParam({ paramName: tempInputValue.current }, idx);
        return;
      }

      if (Validator.isOverTheLength(value, 30)) {
        onOpen('30자 이하로 입력 가능합니다.');
        updateParam({ paramName: tempInputValue.current }, idx);
        return;
      }

      if (Validator.hasKorean(value)) {
        onOpen('한글 입력은 제한됩니다.');
        updateParam({ paramName: tempInputValue.current }, idx);
        return;
      }

      if (Validator.hasNumeric(value)) {
        onOpen('숫자는 맨 앞에 올 수 없습니다.');
        updateParam({ paramName: tempInputValue.current }, idx);
        return;
      }

      if (Validator.hasSpecialCharacters(value)) {
        onOpen('특수문자 및 공백은 입력은 제한됩니다.');
        updateParam({ paramName: tempInputValue.current }, idx);
        return;
      }
      if (CodeTestCase.hasSameParameters(params, value, idx)) {
        onOpen('같은 파라미터 이름을 사용할 수 없습니다.');
        updateParam({ paramName: tempInputValue.current }, idx);
        return;
      }

      if (value !== tempInputValue.current && shouldAlert) {
        if (
          !confirm(
            '현재 작성한 모든 언어의 코드가 초기화 됩니다. 그래도 진행하시겠습니까?',
          )
        ) {
          updateParam({ paramName: tempInputValue.current }, idx);
          return;
        }
      }
      callbackUpdateParam({ paramName: value }, idx);
      tempInputValue.current = '';
    },
    [params, shouldAlert],
  );

  const onFocusInput = (event: FocusEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    tempInputValue.current = value;
  };

  const onClickSelect = (type: 'input' | 'output', idx: number) => {
    const value =
      type === 'input' ? params.paramInfo[idx].paramType : params.returnType;
    tempSelectValue.current = value;
  };

  const onChangeSelect = (
    { key }: SelectItemProps,
    type: 'input' | 'output',
    idx: number,
  ) => {
    const paramType = key.replace('-', '').replace(NumericRegex, '');
    if (paramType !== tempInputValue.current && shouldAlert) {
      if (
        !confirm(
          '현재 작성한 모든 언어의 코드가 초기화 됩니다. 그래도 진행하시겠습니까?',
        )
      ) {
        switch (type) {
          case 'input':
            updateParam(
              { paramType: tempSelectValue.current as DataType },
              idx,
            );
            break;
          case 'output':
          default:
            updateReturnType(tempSelectValue.current as DataType);
            break;
        }
        return;
      }
    }

    switch (type) {
      case 'input':
        callbackUpdateParam({ paramType: paramType as DataType }, idx, true);
        break;
      case 'output':
      default:
        callbackUpdateReturnType(paramType as DataType);
        break;
    }
  };

  return {
    params,
    onClick,
    onBlurInput,
    onChangeSelect,
    onFocusInput,
    onClickSelect,
  };
}
