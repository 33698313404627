import { ReactNode } from 'react';

interface UseTableProps<T> {
	renderTableBody?: (data: T[]) => ReactNode[][];
}

export function useTable<T = never>({ renderTableBody }: UseTableProps<T>) {
	const render = (data?: T[]) => {
		if (!data || !renderTableBody) return undefined;

		return renderTableBody(data);
	};

	return { render };
}
