import styled from '@emotion/styled';

export const RangePickerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  box-shadow: none;

  input {
    box-sizing: border-box;
    width: 100%;
    min-width: 240px;
    height: 40px;
    padding: 12px;
    background: #f1f3f5;
    border: 1px solid #ced4da;
    border-radius: 4px;
    outline: none;

    ::placeholder {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #adb5bd;
    }

    :hover,
    :active {
      border-color: #12b886;
    }

    &:focus-visible {
      background: #fff;
      border: 1px solid #ced4da;
      outline: none;
      box-shadow: none;
    }
  }
`;

export const Container = styled.div`
  position: relative;

  .rc-picker-cell-inner {
    div {
      width: 120px !important;
    }
  }

  input {
    box-sizing: border-box;
    width: 100%;
    min-width: 240px;
    height: 40px;
    padding: 12px;
    background: #f1f3f5;
    border: 1px solid #ced4da;
    border-radius: 4px;
    outline: none;

    ::placeholder {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #adb5bd;
    }

    :hover,
    :active {
      border-color: #12b886;
    }

    &:focus-visible {
      background: #fff;
      border: 1px solid #ced4da;
      outline: none;
      box-shadow: none;
    }
  }
`;

export const Content = styled.div`
  position: absolute;
  top: 44px;
  background: white;
  outline: none;

  .rc-picker-range-wrapper .rc-picker-date-range-wrapper {
  }
`;

export const Day = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  color: #0d514c;
`;
