import SelectWidget, { SelectItemProps } from '@widget/select';
import { useSelect } from './hooks.ts';
import { FC } from 'react';

interface SelectProps {
  defaultValue?: SelectItemProps;
  onChange?: (props: SelectItemProps) => void;
  onClick?: () => void;
}

const Select: FC<SelectProps> = ({
  defaultValue,
  onChange,
  onClick,
  ...rest
}) => {
  const { items } = useSelect();

  return (
    <div
      onClick={() => {
        onClick?.();
      }}
    >
      <SelectWidget
        direction={'down'}
        initialValue={defaultValue}
        items={items}
        onClick={onChange}
        {...rest}
      />
    </div>
  );
};

export default Select;
