import { DateTime, Duration, Settings } from 'luxon';
import { DATE_FORMAT } from '@phs/interfaces';

class DateUtil {
  static {
    Settings.defaultLocale = 'ko';
    Settings.defaultZone = 'Asia/Seoul';
  }
  static str2DateTime = (
    dateTimeString: string,
    format: DATE_FORMAT = DATE_FORMAT.yyyy_MM_DD_HH_mm_ss,
  ): DateTime => {
    const dateTime = DateTime.fromFormat(dateTimeString, format);
    return dateTime.isValid ? dateTime : DateTime.fromISO(dateTimeString);
  };

  static milliseconds2Format = (
    milliseconds: number,
    format: DATE_FORMAT = DATE_FORMAT.yyyy_MM_DD_HH_mm_ss,
  ): string => Duration.fromMillis(milliseconds).toFormat(format);

  static minutes2Format = (
    minute: number,
    format: DATE_FORMAT = DATE_FORMAT.yyyy_MM_DD_HH_mm_ss,
  ): string => Duration.fromMillis(minute * 60 * 1000).toFormat(format);

  static fromSeconds = (seconds: number) => DateTime.fromMillis(seconds * 1000);

  static getRemainString = (exp: number) => {
    const expDate = DateTime.fromMillis(exp * 1000);

    const diff = expDate.diffNow(['hours', 'minutes', 'seconds']);

    return diff.isValid ? diff.toFormat('hh:mm:ss') : '00:00:00';
  };

  static now = () => DateTime.now();

  static diffNow = (time: DateTime) => {
    return time.diff(DateUtil.now()).milliseconds >= 0;
  };

  static currentYear = () => {
    const date = DateUtil.now();
    return date.get('year');
  };

  static calcHour = (time: number) => {
    return Math.floor(time / 60).toString();
  };

  static calcMinute = (time: number) => {
    return (time % 60).toString();
  };

  static dateTimeToFormat = (
    dateTime: DateTime,
    format: DATE_FORMAT = DATE_FORMAT.yyyy_MM_DD_HH_mm_ss,
  ): string => {
    const time = dateTime.toFormat(format);
    return time === 'Invalid DateTime' ? '-' : time;
  };

  static diffMin(startTime: string, endTime: string) {
    const end = DateTime.fromISO(endTime);
    const start = DateTime.fromISO(startTime);
    return end.diff(start, ['minutes']).minutes;
  }
}

export default DateUtil;
