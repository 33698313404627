import { DataType, FunctionParameterAndReturnTypeDTO } from '@phs/interfaces';

export const DefaultParameters: FunctionParameterAndReturnTypeDTO = {
  paramInfo: [
    {
      paramType: DataType.STRING,
      paramName: 'Parameter',
    },
  ],
  returnType: DataType.STRING,
};
