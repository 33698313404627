const light = {
  white: '#FFFFFF',
  black: '#000000',
  editor_black: '#1B1D25',
  editor_code: '#121319',
  guide: '#00C8FF',
  gray_50: '#F8F9FA',
  gray_80: '#F5F6F7',
  gray_100: '#F1F3F5',
  gray_200: '#E9ECEF',
  gray_300: '#DEE2E6',
  gray_400: '#CED4DA',
  gray_500: '#ADB5BD',
  gray_600: '#868E96',
  gray_700: '#495057',
  gray_800: '#343A40',
  gray_900: '#212529',
  red_50: '#FFF5F5',
  red_100: '#FFE3E3',
  red_200: '#FCC2D7',
  red_300: '#FFA8A8',
  red_400: '#FF8787',
  red_500: '#FF6B6B',
  red_600: '#FA5252',
  red_700: '#F03E3E',
  red_800: '#E03131',
  red_900: '#C92A2A',
  pink_50: '#FFF0F6',
  pink_100: '#FFDEEB',
  pink_200: '#FFDEEB',
  pink_300: '#FAA2C1',
  pink_400: '#F783AC',
  pink_500: '#F06595',
  pink_600: '#E64980',
  pink_700: '#D6336C',
  pink_800: '#C2255C',
  pink_900: '#A61E4D',
  grape_50: '#F8F0FC',
  grape_100: '#F3D9FA',
  grape_200: '#EEBEFA',
  grape_300: '#E599F7',
  grape_400: '#DA77F2',
  grape_500: '#CC5DE8',
  grape_600: '#BE4BDB',
  grape_700: '#AE3EC9',
  grape_800: '#9C36B5',
  grape_900: '#862E9C',
  violet_50: '#F3F0FF',
  violet_100: '#E5DBFF',
  violet_200: '#D0BFFF',
  violet_300: '#B197FC',
  violet_400: '#9775FA',
  violet_500: '#845EF7',
  violet_600: '#7950F2',
  violet_700: '#7048E8',
  violet_800: '#6741D9',
  violet_900: '#5F3DC4',
  indigo_50: '#EDF2FF',
  indigo_100: '#DBE4FF',
  indigo_200: '#BAC8FF',
  indigo_300: '#91A7FF',
  indigo_400: '#748FFC',
  indigo_500: '#5C7CFA',
  indigo_600: '#4C6EF5',
  indigo_700: '#4263EB',
  indigo_800: '#3B5BDB',
  indigo_900: '#364FC7',
  blue_50: '#E7F5FF',
  blue_100: '#D0EBFF',
  blue_200: '#A5D8FF',
  blue_300: '#74C0FC',
  blue_400: '#4DABF7',
  blue_500: '#339AF0',
  blue_600: '#228BE6',
  blue_700: '#1C7ED6',
  blue_800: '#1971C2',
  blue_900: '#1864AB',
  cyan_50: '#E3FAFC',
  cyan_100: '#C5F6FA',
  cyan_200: '#99E9F2',
  cyan_300: '#66D9E8',
  cyan_400: '#3BC9DB',
  cyan_500: '#22B8CF',
  cyan_600: '#15AABF',
  cyan_700: '#1098AD',
  cyan_800: '#0C8599',
  cyan_900: '#0B7285',
  teal_50: '#E6FCF5',
  teal_100: '#C3FAE8',
  teal_200: '#96F2D7',
  teal_300: '#63E6BE',
  teal_400: '#38D9A9',
  teal_500: '#20C997',
  teal_600: '#12B886',
  teal_700: '#0CA678',
  teal_800: '#099268',
  teal_900: '#087F5B',
  green_50: '#EBFBEE',
  green_100: '#D3F9D8',
  green_200: '#B2F2BB',
  green_300: '#8CE99A',
  green_400: '#69DB7C',
  green_500: '#51CF66',
  green_600: '#40C057',
  green_700: '#37B24D',
  green_800: '#2F9E44',
  green_900: '#2B8A3E',
  lime_50: '#F4FCE3',
  lime_100: '#E9FAC8',
  lime_200: '#D8F5A2',
  lime_300: '#C0EB75',
  lime_400: '#A9E34B',
  lime_500: '#94D82D',
  lime_600: '#82C91E',
  lime_700: '#74B816',
  lime_800: '#66A80F',
  lime_900: '#5C940D',
  yellow_50: '#FFF9DB',
  yellow_100: '#FFF3BF',
  yellow_300: '#FFE066',
  yellow_400: '#FFD43B',
  yellow_500: '#FCC419',
  yellow_600: '#FAB005',
  yellow_700: '#F59F00',
  yellow_900: '#E67700',
  orange_50: '#FFF4E6',
  orange_100: '#FFE8CC',
  orange_200: '#FFD8A8',
  orange_300: '#FFC078',
  orange_400: '#FFA94D',
  orange_500: '#FF922B',
  orange_600: '#FD7E14',
  orange_700: '#F76707',
  orange_800: '#E8590C',
  orange_900: '#D9480F',
};

const dark = {
  ...light,
};

export type Color = typeof light | typeof dark;
export { light, dark };
