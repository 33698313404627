import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDebouncedCallback } from '@react-hookz/web';
import { DropdownItemType } from '@widget/dropdown';
import {
  ExamLanguageDTO,
  FunctionParameterAndReturnTypeDTO,
  ModifyCommandType,
  PackageProblemMakeResDTO,
  PhaseProblemDTO,
  TestCaseDTO,
  TestCaseType,
} from '@phs/interfaces';
import { PackageProblemTestCasesRsDTO } from '@interfaces/dashboard/problem.dto.ts';
import { fallback, PackageExamFormProps } from '../config.ts';
import { schema } from '../scheme.ts';
import { DefaultParameters } from './config.ts';
import { CodeGenerator } from '@phs/code';
import { useLanguageList } from '@hooks/exam/useLanguage.ts';
import _ from 'lodash-es';
import { useSetRecoilState } from 'recoil';
import { packageExamLanguages } from '@stores/atoms/exam/exam.package.ts';

interface UsePackageDefaultProps {
  data: PackageProblemMakeResDTO;
  testCases: PackageProblemTestCasesRsDTO;
}

export function usePackageDefault({ data, testCases }: UsePackageDefaultProps) {
  const {
    trigger,
    control,
    watch,
    register,
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
  } = useForm<PackageExamFormProps>({
    defaultValues: fallback,
    resolver: zodResolver(schema),
    mode: 'onChange',
  });
  const { languageList } = useLanguageList();
  const setLanguages = useSetRecoilState(packageExamLanguages);

  const updateStep = (cursor: number) => {
    const stepProblem = getValues(`stepProblemList.${cursor}`);
    setValue(`stepProblemList.${cursor}`, {
      ...stepProblem,
      commandType:
        stepProblem.commandType === ModifyCommandType.READ
          ? ModifyCommandType.UPDATE
          : stepProblem.commandType,
    });
  };

  const onChangeLevel = useDebouncedCallback(
    (props: DropdownItemType) => {
      setValue('level', Number(props.key));
      trigger('level');
    },
    [],
    100,
  );

  const onChangeManualDifficultyYn = useDebouncedCallback(
    () => {
      setValue('manualDifficultyYn', !getValues('manualDifficultyYn'));
    },
    [],
    100,
  );

  const onChangeDetailLevel = useDebouncedCallback(
    (props: DropdownItemType) => {
      setValue('detailLevel', Number(props.key));
      trigger('detailLevel');
    },
    [],
    100,
  );

  const onChangeToggle = useDebouncedCallback(
    (status: boolean, cursor: number) => {
      setValue(`stepProblemList.${cursor}.summaryYn`, status);
      updateStep(cursor);
    },
    [],
    100,
  );

  const onChangeContent = useDebouncedCallback(
    (content: string, cursor: number) => {
      setValue(`stepProblemList.${cursor}.content`, content);
      updateStep(cursor);
    },
    [],
    100,
  );

  const onChangeTestCase = useDebouncedCallback(
    (testCases: TestCaseDTO[], cursor: number) => {
      setValue(`stepProblemList.${cursor}.testCaseList`, testCases);
      updateStep(cursor);
    },
    [],
    100,
  );

  const onChangePhaseLevel = useDebouncedCallback(
    (props: DropdownItemType, cursor: number) => {
      setValue(`stepProblemList.${cursor}.level`, Number(props.key));
      trigger(`stepProblemList.${cursor}.level`);
      updateStep(cursor);
    },
    [],
    100,
  );

  const onChangePhaseDetailLevel = useDebouncedCallback(
    (props: DropdownItemType, cursor: number) => {
      setValue(`stepProblemList.${cursor}.detailLevel`, Number(props.key));
      trigger(`stepProblemList.${cursor}.detailLevel`);
      updateStep(cursor);
    },
    [],
    100,
  );

  const onChangePhaseAlgorithm = useDebouncedCallback(
    (props: string[], cursor: number) => {
      setValue(`stepProblemList.${cursor}.algorithmTypeList`, props);
      trigger(`stepProblemList.${cursor}.algorithmTypeList`);
      updateStep(cursor);
    },
    [],
    100,
  );

  const onChangeParams = (
    params: FunctionParameterAndReturnTypeDTO,
    cursor: number,
  ) => {
    setValue(`stepProblemList.${cursor}.parameters`, params);
    updateStep(cursor);
  };

  const onChangeJudgeLanguageList = (
    languageList: ExamLanguageDTO[],
    cursor: number,
  ) => {
    setValue(`stepProblemList.${cursor}.judgeLanguageList`, languageList);
    updateStep(cursor);
  };

  const setPackageLanguage = (stepProblemList: PhaseProblemDTO[]) => {
    if (stepProblemList?.length < 1) return;
    const languages: ExamLanguageDTO[] =
      stepProblemList[0].judgeLanguageList!.map(({ priority, language }) => ({
        usage: true,
        priority,
        language,
        defaultSource: '',
        commandType: ModifyCommandType.READ,
      }));

    const newLanguages = _.unionBy(
      [...languages, ...CodeGenerator.getLanguageTemplate(languageList)],
      (language) => language.language,
    );
    newLanguages.sort((l1, l2) => l1.priority - l2.priority);
    setLanguages(newLanguages);
  };

  useEffect(() => {
    setValue('sn', data.sn);
    setValue('title', data.title);
    setValue('level', parseInt(data.packageLevel ?? '0'));
    setValue('detailLevel', data.packageDetailLevel);
    setValue(
      'overallPredictionAverageScore',
      data.overallPredictionAverageScore,
    );
    setValue('overallPredictionLevel', data.overallPredictionLevel);
    setValue('manualDifficultyYn', data.manualDifficultyYn);

    if (testCases.packageProblemTestCaseRsList.length === 0) return;

    const newStepProblemList = data.stepProblemList
      .map((props, idx) => {
        const stepTestCases =
          testCases?.packageProblemTestCaseRsList?.[idx]?.problemTestCaseRsList;

        if (!stepTestCases) return;

        let parameters: FunctionParameterAndReturnTypeDTO = DefaultParameters;
        const { input, output } = stepTestCases[0];

        parameters = {
          paramInfo: input.map(({ parameterType, parameterName }) => ({
            paramType: parameterType,
            paramName: parameterName,
          })),
          returnType: output.parameterType,
        };

        const newTestCases = stepTestCases?.map((testCase) => {
          const newTestCaseLanguageSet =
            testCase.testCaseType === TestCaseType.EFFICIENCY
              ? testCase.testCaseLanguageSet.map((lSet) => {
                  return {
                    ...lSet,
                    commandType: ModifyCommandType.READ,
                  };
                })
              : [];
          return {
            ...testCase,
            testCaseLanguageSet: newTestCaseLanguageSet,
            commandType: ModifyCommandType.READ,
          };
        });

        return {
          ...props,
          content: props.content ?? '',
          testCaseList: newTestCases,
          commandType: ModifyCommandType.READ, // 처음 초기화할때 READ 처리, 변경없으면 READ 제외
          parameters,
          judgeLanguageList: props.judgeLanguageList?.map((judgeLanguage) => ({
            ...judgeLanguage,
            usage: true,
            commandType: ModifyCommandType.READ,
            referenceSourceYn: !!judgeLanguage.referenceSource,
          })),
          accuracyScoreRate: props.accuracyScoreRate ?? 50,
          efficiencyScoreRate: props.efficiencyScoreRate ?? 50,
        };
      })
      .filter((v) => !!v) as PhaseProblemDTO[];

    setValue('stepProblemList', newStepProblemList);
    setPackageLanguage(newStepProblemList);
  }, [data, testCases]);

  return {
    trigger,
    control,
    watch,
    errors,
    register,
    setValue,
    getValues,
    handleSubmit,
    onChangeLevel,
    onChangeDetailLevel,
    onChangeToggle,
    onChangeContent,
    onChangeTestCase,
    onChangePhaseLevel,
    onChangeParams,
    onChangeJudgeLanguageList,
    updateStep,
    onChangePhaseDetailLevel,
    onChangePhaseAlgorithm,
    onChangeManualDifficultyYn,
  };
}
