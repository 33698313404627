import { FC, useState } from 'react';
import { LevelExcelDataDto } from '@interfaces/company/data.dto.ts';
import ModalWidget from '@widget/modal';
import { useForm } from 'react-hook-form';
import {
  Container,
  CloseBtn,
  Title,
  TopWrapper,
  ContentWrapper,
  BottomWrapper,
} from '@pages/Data/Algorithms/Modal/style.ts';
import { IconClose } from '@widget/icons';
import TextButton from '@widget/button-text';
import Input from '@widget/input';
import { isAxiosError } from 'axios';
import {
  AxiosExceptionHandler,
  RuntimeExceptionHandler,
} from '@phs/exceptions';
import { PMS_EXCEPTION_MESSAGES } from '@constants/exceptions.ts';
import DataRepository from '@repositories/data.repository.ts';

interface SyncDataModalProps {
  rsData: LevelExcelDataDto | null;
  onClose?: () => void;
  title: string;
}

interface FormProps {
  confirmMessage: string;
}

const SyncDataModal: FC<SyncDataModalProps> = ({ rsData, onClose, title }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormProps>({});
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (data: FormProps) => {
    try {
      if (loading) return;
      setLoading(true);
      if (rsData === null) {
        alert('데이터 문제가 있음 새로고침해주세요.');
        onClose?.();
        return;
      }

      if (data.confirmMessage.trim() !== '수정하겠습니다') {
        alert('수정하겠습니다 이렇게 쓰세요.');
        return;
      }

      await DataRepository.postLevelDataSave(
        rsData,
        data.confirmMessage.trim(),
      );
      onClose?.();
    } catch (error) {
      if (isAxiosError(error)) {
        new AxiosExceptionHandler({
          error,
          message: PMS_EXCEPTION_MESSAGES.PMS_API_ERROR,
        });
      } else {
        new RuntimeExceptionHandler({
          error,
          message: PMS_EXCEPTION_MESSAGES.PMS_API_ERROR,
        });
      }
    }
  };

  return (
    <ModalWidget>
      <Container onSubmit={handleSubmit(onSubmit)}>
        <TopWrapper>
          <Title>{title}</Title>
          <CloseBtn onClick={onClose}>
            <IconClose />
          </CloseBtn>
        </TopWrapper>
        <ContentWrapper>
          <Input
            type={'string'}
            defaultValue={''}
            error={errors.confirmMessage?.message}
            {...register('confirmMessage', {
              required: '값을 입력하세요',
            })}
          />
        </ContentWrapper>
        <BottomWrapper>
          <TextButton theme={'primary'} type={'submit'}>
            {loading ? '진행 중 움직이지마세요.' : '확인'}
          </TextButton>
        </BottomWrapper>
      </Container>
    </ModalWidget>
  );
};

export default SyncDataModal;
