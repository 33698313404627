import { selectorFamily } from 'recoil';
import { PopUpDTO } from '@interfaces/notice/popup.dto.ts';
import { stateKeys } from '@constants/key.ts';
import { toggles } from '@stores/atoms/shared/table.ts';

interface TableToggleKeysParams extends PopUpDTO {
	// eslint-disable @typescript-eslint/no-explicit-any
	[key: string]: any;
}

export const tableToggleKeys = selectorFamily<
	PopUpDTO[],
	TableToggleKeysParams[]
>({
	key: stateKeys.table.currentPopUpToggleKeys,
	get:
		(items: PopUpDTO[]) =>
		({ get }) => {
			const _toggles = get(toggles);
			return items.filter((_, idx) => _toggles[idx]);
		},
});
