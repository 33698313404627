import { stateKeys } from '@constants/key.ts';
import {
	currentPage,
	pageSize,
	totalPage,
} from '@stores/atoms/shared/pagination.ts';
import { selector } from 'recoil';

interface PaginationArgs {
	currentPage: number;
	totalPage: number;
	pageSize: number;
	pageIdxArray: number[];
}

// Pagination
export const paginationArgs = selector({
	key: stateKeys.filter.paginationArgs,
	get: ({ get }) => {
		const _currentPage = get(currentPage);
		const _totalPage = get(totalPage);
		const _pageSize = get(pageSize);

		const getPageIdxArray = () => {
			const quotient = Math.floor(_currentPage / 10);

			return (
				Array.from(
					{ length: Math.min(_totalPage - quotient * 10, 10) },
					(_, i) => i + 10 * quotient,
				) ?? []
			);
		};

		const payload: PaginationArgs = {
			currentPage: _currentPage,
			totalPage: _totalPage,
			pageSize: _pageSize,
			pageIdxArray: getPageIdxArray(),
		};

		return payload;
	},
});
