import { CheckBoxOnClickProps } from '@widget/checkbox';

interface UseCodeProps {
  handleChange?: (type: string, code?: string) => void;
  handleToggle?: CheckBoxOnClickProps;
}

export function useCode({ handleChange, handleToggle }: UseCodeProps) {
  const onChange = (type: string, code?: string) => {
    handleChange?.(type, code);
  };

  const onToggle: CheckBoxOnClickProps = ({ id, isActive }) => {
    handleToggle?.({
      id,
      isActive,
    });
  };

  return {
    onChange,
    onToggle,
  };
}
