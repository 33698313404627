export const SENTRY_KEYS = {
	SERVICES: {
		REPORT: 'REPORT',
		CMS: 'CMS',
		PMS: 'PMS',
	},
	TYPES: {
		API: 'API', // 내/외부 API 에러 타입
		BROWSER: 'BROWSER', // 클라이언트 브라우저 에러 타입
		SERVICE: 'SERVICE', //  Report / CMS / PMS 서비스 타입 에러
	},
	CONTEXTS: {
		TROUBLE_SHOOTING: '디버깅', // 디버깅
		API_REQUEST_DETAIL: 'API 요청 파라미터', // API 요청값
		API_RESPONSE_DETAIL: 'API 서버 응답 전문', // API 응답값
		API_REQUEST_HEADERS: 'API 요청 헤더 정보', // API 요청 헤더 정보
		API_UNKNOWN_ERROR_DETAIL: 'API 예외처리 상세', // 알 수 없는 API 에러
		RUNTIME_ERROR_DETAIL: '브라우저 에러 상세', // 내부 프론트 API 에러
		BROWSER_ERROR_DETAIL: '브라우저 에러 상세 (SPA)', // 브라우저 에러
		INTERNAL_SERVER_ERROR_DETAIL: '프론트 서버 에러', // 내부 프론트 서버 에러
		AXIOS_CONFIG_DETAIL: 'Axios 설정 정보', // Axios 호출 설정값
		AXIOS_REQUEST_CURL: 'Axios CURL 데이터', // 디버그 curl
	},
	MESSAGES: {
		API_ERROR: 'API 서버 에러', // 외부 API 에러
		INTERNAL_SERVER_ERROR: '프론트 서버 에러', // 내부 API 에러
		RUNTIME_ERROR: '브라우저 런타임 에러', // 브라우저 런타임 에러
		BROWSER_ERROR: '브라우저 런타임 에러 (SPA)', // 브라우저 런타임 에러 (SPA)
		INVALID_PAYLOAD: '잘못된 API 페이로드', // 잘못된 API 페이로드
		TROUBLE_SHOOTING: '디버깅', // 디버깅
	},
};
