import { injectable } from 'inversify';
import 'reflect-metadata';
import { ProblemCategory } from '@phs/interfaces';
import { DataType2TextReturnDTO } from '../interfaces/transformer.dto';

export interface CodeTransformerProps {
  string2Digit(data: string): number;

  string2Array(data: string): string[];

  string2Array2d(data: string): string[];

  addDoubleQuotes(input: string, type: ProblemCategory): string;

  removeDoubleQuotes(input: string, type: ProblemCategory): string;

  stringify(input: string[]): string;

  sanitize(input: string, type: ProblemCategory): string;

  readonly dataType2Text: DataType2TextReturnDTO;
}

@injectable()
export class CodeTransformer implements CodeTransformerProps {
  public stringify(input: string[]) {
    return `[${input.join(',')}]`;
  }

  public sanitize(input: string, type: ProblemCategory) {
    return this.removeDoubleQuotes(this.addDoubleQuotes(input, type), type);
  }

  public string2Digit(data: string) {
    const result = data.replace('.', '').replace('-', '');
    return result.length;
  }

  public string2Array(data: string) {
    return data.replace('[', '').replace(']', '').split(',');
  }

  public string2Array2d(data: string) {
    return data.replace('[', '').replace(/]$/, '').split(',');
  }

  public addDoubleQuotes(input: string, type: ProblemCategory) {
    switch (type) {
      case ProblemCategory.STDIO:
        return JSON.stringify(input);

      case ProblemCategory.FUNCTION:
        input = input
          .replaceAll('\\n', `\\\\n`)
          .replaceAll('\\r', `\\\\r`)
          .replaceAll('\\t', `\\\\t`)
          .replaceAll('\\b', `\\\\b`)
          .replaceAll('\\f', `\\\\f`)
          .replace(/[\u0000-\u0019]+/g, '');
        return input;

      default:
        return input;
    }
  }

  public removeDoubleQuotes(input: string, type: ProblemCategory) {
    switch (type) {
      case ProblemCategory.STDIO:
        return JSON.parse(input);

      case ProblemCategory.FUNCTION:
        input = JSON.stringify(JSON.parse(input));
        return input
          .replaceAll('\\\\n', `\\n`)
          .replaceAll('\\\\r', `\\r`)
          .replaceAll('\\\\t', `\\t`)
          .replaceAll('\\\\b', `\\b`)
          .replaceAll('\\\\f', `\\f`);

      default:
        return input;
    }
  }

  public get dataType2Text() {
    return {
      INT: 'int',
      LONG: 'long',
      DOUBLE: 'double',
      STRING: 'string',
      BOOL: 'bool',
      INT_ARRAY: 'int[]',
      LONG_ARRAY: 'long[]',
      DOUBLE_ARRAY: 'double[]',
      STRING_ARRAY: 'string[]',
      BOOL_ARRAY: 'bool[]',
      INT_2_ARRAY: 'int[][]',
      LONG_2_ARRAY: 'long[][]',
      DOUBLE_2_ARRAY: 'double[][]',
      STRING_2_ARRAY: 'string[][]',
      BOOL_2_ARRAY: 'bool[][]',
    };
  }
}
