export enum COMPANY_MENU {
  COMMON = 'COMMON',
  SCHEDULER = 'SCHEDULER',
  DB = 'DB',
}

export const MENUS: Array<{
  key: COMPANY_MENU;
  name: string;
}> = [
  {
    key: COMPANY_MENU.COMMON,
    name: 'Common Scheme 동기화',
  },
  {
    key: COMPANY_MENU.SCHEDULER,
    name: 'Scheduler Scheme 동기화',
  },
  {
    key: COMPANY_MENU.DB,
    name: 'DB 동기화',
  },
];
