import * as z from 'zod';
import { DataType, LanguageType } from '@phs/interfaces';

export const schema = z.object({
  title: z
    .string()
    .min(1, '제목을 입력해 주세요.')
    .max(50, '50글자 이하로 입력해 주세요.'),
  level: z.number().min(1, '종합 난이도를 선택하지 않았습니다.').max(5),
  detailLevel: z.number().min(1, '세부 난이도를 선택하지 않았습니다.').max(5),
  stepProblemList: z
    .object({
      level: z.number({ required_error: '난이도를 선택해 주세요.' }),
      detailLevel: z.number().min(1, '세부 난이도를 선택해 주세요.').max(5),
      scoreRate: z
        .number({ invalid_type_error: '1이상 100 이하 값을 입력해주세요.' })
        .min(1, '1이상 100 이하 값을 입력해주세요.')
        .max(100, '1이상 100 이하 값을 입력해주세요.')
        .multipleOf(0.1, '소수점 이하 첫째 자리까지 입력 가능합니다.'),
      passScore: z
        .number({ invalid_type_error: '0초과 100 이하 값을 입력해주세요.' })
        .min(0.1, '0초과 100 이하 값을 입력해주세요.')
        .max(100, '0초과 100 이하 값을 입력해주세요.')
        .multipleOf(0.1, '소수점 이하 첫째 자리까지 입력 가능합니다.'),
      summaryYn: z.boolean(),
      summary: z.string(),
      parameters: z
        .object({
          paramInfo: z
            .object({
              paramType: z.nativeEnum(DataType),
              paramName: z.string(),
            })
            .array(),
          returnType: z.nativeEnum(DataType),
        })
        .optional(),
      judgeLanguageList: z
        .object({
          sn: z.number().optional(),
          language: z.nativeEnum(LanguageType),
          defaultSource: z.string().optional(),
          referenceSourceYn: z.boolean().optional(),
          referenceSource: z.string().optional(),
          expectedSource: z.string().optional().nullable(),
          priority: z.number(),
        })
        .array()
        .optional(),
      testCaseList: z
        .object({
          input: z
            .object({
              parameterType: z.nativeEnum(DataType),
              parameterName: z.string(),
              value: z.string(),
            })
            .array(),
        })
        .array()
        .optional(),
    })
    .refine((data) => !data.summaryYn || data.summary.length < 100, {
      message: '공백 포함 100글자 까지만 입력 가능합니다.',
      path: ['summary'],
    })
    .array(),
});
