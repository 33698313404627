import styled from '@emotion/styled';

export const Title = styled.span`
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray_900};
`;

export const CheckboxTitle = styled.span<{ disabled?: boolean }>`
  margin-left: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray_700};

  ${({ disabled, theme }) =>
    disabled &&
    `
      background: ${theme.colors.white};
      opacity: 0.4;
    `};
`;

export const InputWrapper = styled.div`
  display: flex;
  line-height: 40px;
  flex-direction: column;

  label {
    font-size: 16px;
    font-weight: 700;

    .count {
      font-size: 14px;
      margin-left: 8px;
      font-weight: 400;
      color: #868e96;
    }
  }

  span {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
  }
`;
