import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { PMS_EXCEPTION_MESSAGES } from '@constants/exceptions';
import { RuntimeExceptionHandler } from '@phs/exceptions';
import { PMS_PAGES } from '@phs/constants';
import AuthRepository from '@repositories/auth.repository';
import { pmsAccessToken, pmsRefreshToken } from '@stores/atoms/shared/auth.ts';
import { LoginReqDTO } from '@interfaces/shared/auth.dto.ts';
import { useLoginError } from '@hooks/exceptions/useLoginError.ts';

export function useAuth() {
  const navigate = useNavigate();
  const { mutate } = useMutation(AuthRepository.login);
  const { mutate: checkLogin } = useMutation(AuthRepository.loginExists);
  const setAccessToken = useSetRecoilState(pmsAccessToken);
  const setRefreshToken = useSetRecoilState(pmsRefreshToken);
  const { handleError } = useLoginError();

  const login = async (data: LoginReqDTO) => {
    mutate(data, {
      onError: (error: unknown) => {
        setRefreshToken(null);
        setAccessToken(null);

        new RuntimeExceptionHandler({
          error,
          message: PMS_EXCEPTION_MESSAGES.PMS_LOGIN_ERROR,
        });
      },
      onSuccess: (res) => {
        if (res) {
          setRefreshToken(res.refreshToken ?? null);
          setAccessToken(res.accessToken ?? null);
          navigate(PMS_PAGES.DASHBOARD.HOME);
        } else {
          // TODO: 예외처리
        }
      },
    });
  };

  const isLoginExists = async (
    data: LoginReqDTO,
    onSuccess?: (res: boolean) => void,
  ) => {
    checkLogin(data, {
      onError: handleError,
      onSuccess,
    });
  };

  return {
    login,
    isLoginExists,
  };
}

export function useAccessToken() {
  const setAccessToken = useSetRecoilState(pmsAccessToken);
  const setRefreshToken = useSetRecoilState(pmsRefreshToken);

  const refresh = async ({
    onSuccess,
    onError,
  }: {
    onSuccess?: (accessToken: string) => void;
    onError?: () => void;
  }) => {
    try {
      const { accessToken } = await AuthRepository.issueAccessToken();
      setAccessToken(accessToken);
      onSuccess?.(accessToken);
    } catch (error) {
      new RuntimeExceptionHandler({
        error,
        message: PMS_EXCEPTION_MESSAGES.PMS_REFRESH_TOKEN_ERROR,
      });

      setAccessToken(null);
      onError?.();
    }
  };

  const reset = () => {
    setAccessToken(null);
    setRefreshToken(null);
  };

  return {
    reset,
    refresh,
  };
}
