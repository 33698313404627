import { ChangeEvent, useEffect, useState } from 'react';
import { PMS_EXCEPTION_MESSAGES } from '@constants/exceptions.ts';
import { RuntimeExceptionHandler } from '@phs/exceptions';
import {
  FunctionParameterAndReturnTypeDTO,
  ModifyCommandType,
  PhaseProblemSourceDTO,
  TestCaseDTO,
  TestCaseType,
} from '@phs/interfaces';
import { CodeTestCase, CodeTransformer } from '@phs/code';
import { Input, InputTableHeaderType, Removable } from '@widget/table-input';
import { useValidation } from '@pages/Exam/function/shared/useValidation';

interface UseTestCaseProps {
  params: FunctionParameterAndReturnTypeDTO;
  languages?: PhaseProblemSourceDTO[];
  testCaseType?: TestCaseType;
  testCases?: TestCaseDTO[];
  onSubmit?: (data: TestCaseDTO[]) => void;
  onClose?: () => void;
}

export function useTestCase({
  params,
  testCaseType = TestCaseType.EXAMPLE,
  testCases = [],
  onSubmit,
  onClose,
}: UseTestCaseProps) {
  const [cache, setCache] = useState<TestCaseDTO[]>(testCases);
  const { validate } = useValidation(cache);

  useEffect(() => {
    if (testCases.length === 0) {
      const defaultCache = [
        CodeTestCase.createTestCase({
          input: params.paramInfo.map(({ paramName, paramType }) => ({
            parameterType: paramType,
            parameterName: paramName,
            value: '',
          })),
          output: {
            parameterName: 'return',
            parameterType: params.returnType,
            value: '',
          },
          number: 1,
          testCaseType,
        }),
      ];
      setCache(defaultCache);
      return;
    }
    setCache(testCases);
  }, [testCases]);

  const addTestCase = () => {
    setCache((prev) => {
      return [
        ...prev,
        CodeTestCase.createTestCase({
          input: params.paramInfo.map(({ paramName, paramType }) => ({
            parameterType: paramType,
            parameterName: paramName,
            value: '',
          })),
          output: {
            parameterName: 'return',
            parameterType: params.returnType,
            value: '',
          },
          number: cache.length,
          testCaseType,
        }),
      ];
    });
  };

  const deleteTestCase = (idx: number) => {
    const targetTestCase = cache[idx];

    if (targetTestCase.sn) {
      setCache((prev) => {
        return prev.map((testCase, i) => {
          if (idx === i)
            return CodeTestCase.deleteTestCase(testCase, {
              number: i + 1,
            });

          return { ...testCase, number: i + 1 };
        });
      });
    } else {
      setCache((prev) => {
        return prev
          .filter((_, i) => idx !== i)
          .map((testCase, i) => ({
            ...testCase,
            number: i + 1,
          }));
      });
    }
  };

  const updateTestCase = (idx: number, value: Partial<TestCaseDTO>) => {
    const newCache = cache.slice();
    newCache[idx] = CodeTestCase.updateTestCase(cache[idx], value);
    return newCache;
  };

  const onChangeInput = (
    event: ChangeEvent<HTMLInputElement>,
    rowIdx: number,
    columnIdx: number,
  ) => {
    const { input: inputValue } = cache[rowIdx];

    setCache(
      updateTestCase(rowIdx, {
        input: [
          ...inputValue.slice(0, columnIdx),
          {
            ...inputValue[columnIdx],
            value: event.currentTarget.value,
          },
          ...inputValue.slice(columnIdx + 1),
        ],
      }),
    );
  };

  const onChangeOutput = (
    event: ChangeEvent<HTMLInputElement>,
    idx: number,
  ) => {
    const { output: outputValue } = cache[idx];

    setCache(
      updateTestCase(idx, {
        output: {
          ...outputValue,
          value: event.currentTarget.value,
        },
      }),
    );
  };

  const renderData = (data: TestCaseDTO[]) => {
    if (!data) return undefined;

    return data
      .map((item, originalRowIdx) => {
        return {
          item,
          originalRowIdx,
        };
      })
      .filter(({ item }) => item.commandType !== ModifyCommandType.DELETE)
      .map(
        (
          {
            item: { sn, input: inputValue, output: outputValue },
            originalRowIdx,
          },
          rowIdx,
        ) => {
          return inputValue
            .map(({ value }, columnIdx) => (
              <Input
                data-testid={`testcase-textarea-input-${rowIdx}-${columnIdx}`}
                key={`testcase-textarea-function-input-${sn}-${rowIdx}-${columnIdx}`}
                placeholder={'Input'}
                defaultValue={value}
                onChange={(e) => onChangeInput(e, originalRowIdx, columnIdx)}
              />
            ))
            .concat([
              <Input
                data-testid={`testcase-textarea-output-${rowIdx}`}
                placeholder={'Output'}
                value={outputValue.value}
                onChange={(e) => onChangeOutput(e, originalRowIdx)}
              />,
              <Removable
                key={`testcase-textarea-function-output-${sn}-${rowIdx}`}
                onRemove={() => deleteTestCase(originalRowIdx)}
              ></Removable>,
            ]);
        },
      );
  };

  const renderHeaders = (): InputTableHeaderType[] => {
    return [
      'Parameters',
      `Return(${CodeTransformer.dataType2Text[params.returnType]})`,
      '',
    ];
  };

  const handleSubmit = () =>
    validate(
      () => {
        // 테스트 케이스 추가

        onSubmit?.(cache);
      },
      (error) => {
        new RuntimeExceptionHandler({
          error,
          message: PMS_EXCEPTION_MESSAGES.PMS_VALIDATION_ERROR,
        });
      },
    );

  const handleClose = () => {
    setCache([]);
    onClose?.();
  };

  return {
    cache,
    addTestCase,
    renderData,
    handleSubmit,
    handleClose,
    renderHeaders,
  };
}
