import styled from '@emotion/styled';
import { ScrollBarStyle } from '@styles/mixin.ts';

export const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    vertical-align: middle;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 12px;
  box-sizing: border-box;
  padding: 12px 0;
`;

export const TableWrapper = styled.div`
  height: max-content;
  max-height: 55vh;
  overflow-x: hidden;
  overflow-y: scroll;

  ${ScrollBarStyle};
`;

export const LanguageContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const LanguageName = styled.div`
  display: block;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.gray_800};
  text-align: left;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${({ theme }) => theme.colors.gray_500};
`;
