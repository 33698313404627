import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  FC,
  PropsWithChildren,
} from 'react';
import { Container, Wrapper } from './style';
import LoadingIcon from './Icon';

interface LoadingButtonProps
  extends PropsWithChildren,
    DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    > {
  isLoading?: boolean;
}

const LoadingButton: FC<LoadingButtonProps> = ({
  isLoading,
  children,
  ...props
}) => {
  return (
    <Container {...props} disabled={props.disabled || isLoading}>
      {isLoading && <LoadingIcon isLoading={isLoading} />}
      <Wrapper isHidden={isLoading}>{children}</Wrapper>
    </Container>
  );
};

export default LoadingButton;
