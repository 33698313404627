import styled from '@emotion/styled';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	cursor: pointer;
	height: 240px;
	background: ${({ theme }) => theme.colors.gray_100};
	border-radius: 4px;
	justify-content: center;
`;

export const IconWrapper = styled.div`
	height: 80px;
	text-align: center;

	svg {
		width: 80px;
		height: 80px;
	}
`;

export const Text = styled.div`
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	text-align: center;
`;
