import { inject, injectable } from 'inversify';
import 'reflect-metadata';
import {
  DataType,
  ExamLanguageDTO,
  FunctionParameterAndReturnTypeDTO,
  LanguageDTO,
  LanguageType,
  ModifyCommandType,
  PhaseProblemDTO,
} from '@phs/interfaces';
import {
  C,
  Cpp,
  CSharp,
  Go,
  Java,
  JavaScript,
  Kotlin,
  Pypy,
  Python,
  R,
  Ruby,
  Scala,
  Swift,
} from '../langauges';
import { TYPES } from '../types';

const DEFAULT_PARAMS = {
  paramInfo: [
    {
      paramType: DataType.STRING,
      paramName: 'Parameter',
    },
  ],
  returnType: DataType.STRING,
};

export interface CodeGeneratorProps {
  readonly languageType: typeof LanguageType;
  readonly commandType: typeof ModifyCommandType;

  getPackageTemplate(value?: Partial<PhaseProblemDTO>): PhaseProblemDTO;

  getLanguageTemplate(data: LanguageDTO[]): ExamLanguageDTO[];

  getFunctionTemplate(
    language: Omit<LanguageType, 'DEFAULT'>,
    parameters: FunctionParameterAndReturnTypeDTO,
  ): string;

  getStdioTemplate(language: Omit<LanguageType, 'DEFAULT'>): string;
}

@injectable()
export class CodeGenerator implements CodeGeneratorProps {
  private readonly _LANGUAGE_TYPE = LanguageType;
  private readonly _COMMAND_TYPE = ModifyCommandType;

  constructor(
    @inject(TYPES.C) private readonly c: C,
    @inject(TYPES.CPP) private readonly cpp: Cpp,
    @inject(TYPES.CSHARP) private readonly csharp: CSharp,
    @inject(TYPES.JAVA) private readonly java: Java,
    @inject(TYPES.JAVASCRIPT) private readonly javascript: JavaScript,
    @inject(TYPES.KOTLIN) private readonly kotlin: Kotlin,
    @inject(TYPES.PYTHON) private readonly python: Python,
    @inject(TYPES.R) private readonly r: R,
    @inject(TYPES.RUBY) private readonly ruby: Ruby,
    @inject(TYPES.SCALA) private readonly scala: Scala,
    @inject(TYPES.SWIFT) private readonly swift: Swift,
    @inject(TYPES.PYPY) private readonly pypy: Pypy,
    @inject(TYPES.GO) private readonly go: Go,
  ) {}

  public get languageType() {
    return this._LANGUAGE_TYPE;
  }

  public get commandType() {
    return this._COMMAND_TYPE;
  }

  public getPackageTemplate(value?: Partial<PhaseProblemDTO>) {
    return {
      level: undefined,
      content: '',
      number: value?.number ?? 0,
      scoreRate: 0,
      passScore: 0,
      summaryYn: true,
      summary: undefined,
      testCaseScoreRateYn: false,
      accuracyScoreRate: 50,
      efficiencyScoreRate: 50,
      referenceSourceYn: false,
      commandType: this.commandType.CREATE,
      testCaseList: [],
      judgeLanguageList:
        value?.judgeLanguageList?.map((props) => ({
          ...props,
          commandType: this.commandType.CREATE,
          defaultSource: this.getFunctionTemplate(
            props.language,
            DEFAULT_PARAMS,
          ),
        })) ?? [], // 새로 생성한 추가조건형의 경우 commandType CREATE 설정
      parameters: DEFAULT_PARAMS,
    } as PhaseProblemDTO;
  }

  public getLanguageTemplate(data: LanguageDTO[]) {
    return data.map(({ code, compiler, priority }) => {
      return {
        language: code,
        defaultSource: '',
        usage: false,
        compiler,
        priority,
      } as ExamLanguageDTO;
    });
  }

  // 추가조건형 코드 생성
  public getFunctionTemplate(
    language: Omit<LanguageType, 'DEFAULT'>,
    parameters: FunctionParameterAndReturnTypeDTO,
  ) {
    switch (language) {
      case this.languageType.C:
      default:
        return this.c.getFunctionTemplate(parameters);

      case this.languageType.CPP:
        return this.cpp.getFunctionTemplate(parameters);

      case this.languageType.CSHARP:
        return this.csharp.getFunctionTemplate(parameters);

      case this.languageType.JAVA:
        return this.java.getFunctionTemplate(parameters);

      case this.languageType.JAVASCRIPT:
        return this.javascript.getFunctionTemplate(parameters);

      case this.languageType.KOTLIN:
        return this.kotlin.getFunctionTemplate(parameters);

      case this.languageType.PYTHON:
        return this.python.getFunctionTemplate(parameters);

      case this.languageType.PYPY:
        return this.pypy.getFunctionTemplate(parameters);

      case this.languageType.R:
        return this.r.getFunctionTemplate(parameters);

      case this.languageType.RUBY:
        return this.ruby.getFunctionTemplate(parameters);

      case this.languageType.SCALA:
        return this.scala.getFunctionTemplate(parameters);

      case this.languageType.SWIFT:
        return this.swift.getFunctionTemplate(parameters);

      case this.languageType.GO:
        return this.go.getFunctionTemplate(parameters);
    }
  }

  // 기본형 코드 생성
  public getStdioTemplate(language: Omit<LanguageType, 'DEFAULT'>) {
    switch (language) {
      case this.languageType.C:
      default:
        return this.c.getStdioTemplate();

      case this.languageType.CPP:
        return this.cpp.getStdioTemplate();

      case this.languageType.CSHARP:
        return this.csharp.getStdioTemplate();

      case this.languageType.JAVA:
        return this.java.getStdioTemplate();

      case this.languageType.JAVASCRIPT:
        return this.javascript.getStdioTemplate();

      case this.languageType.KOTLIN:
        return this.kotlin.getStdioTemplate();

      case this.languageType.PYTHON:
        return this.python.getStdioTemplate();

      case this.languageType.PYPY:
        return this.pypy.getStdioTemplate();

      case this.languageType.R:
        return this.r.getStdioTemplate();

      case this.languageType.RUBY:
        return this.ruby.getStdioTemplate();

      case this.languageType.SCALA:
        return this.scala.getStdioTemplate();

      case this.languageType.SWIFT:
        return this.swift.getStdioTemplate();

      case this.languageType.GO:
        return this.go.getStdioTemplate();
    }
  }
}
