import { useMemo } from 'react';
import { Container, Item, RequireTitle } from './style.ts';

interface ExpectationLevelProps {
  passRates?: number[];
  averageScore: number | null;
  level: number | null;
}

function ExpectationLevel({
  passRates,
  averageScore,
  level,
}: ExpectationLevelProps) {
  const levelToString = useMemo(() => {
    switch (level) {
      case 1:
        return '기초';
      case 2:
        return '초급';
      case 3:
        return '중급';
      case 4:
        return '고급';
      case 5:
        return '전문가';
    }
    return '0';
  }, [level]);

  const showElement = useMemo(
    () => passRates || averageScore !== null || level !== null,
    [passRates, averageScore, level],
  );

  if (!showElement) return null;

  return (
    <Container>
      <RequireTitle>기대 수준</RequireTitle>
      {passRates && (
        <Item>
          <div className='key'>단계별 통과율 (1-{passRates.length})</div>
          <div className='value'>
            {passRates.map((rate) => `${Math.round(rate)}%`).join(' | ')}
          </div>
        </Item>
      )}
      {averageScore !== null && (
        <Item>
          <div className='key'>평균 점수 예측</div>
          <div className='value'>{averageScore}점</div>
        </Item>
      )}
      {level !== null && (
        <Item>
          <div className='key'>예측 종합 난이도</div>
          <div className='value'>{levelToString}</div>
        </Item>
      )}
    </Container>
  );
}

export default ExpectationLevel;
