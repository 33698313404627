import styled from '@emotion/styled';

export const Container = styled.button`
  box-sizing: border-box;
  font-family: inherit;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  min-height: 40px;
  min-width: 88px;
  padding: 0 16px;
  display: inline-block;
  background: #12b886;
  color: #fff;
  position: relative;

  svg {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
  }

  &:active {
    color: #fff;
    background: #099268;
    border: 0 !important;
    outline: 0 !important;
  }

  &:hover {
    color: #fff;
    background: #099268;
    border: 0 !important;
    outline: 0 !important;
  }

  &:disabled,
  &:disabled:hover {
    color: #fff;
    background: #12b886;
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

export const Wrapper = styled.div<{ isHidden?: boolean }>`
  opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
`;
