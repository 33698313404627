import { useMutation, useQuery } from '@tanstack/react-query';
import PSTRepository from '@repositories/pst.repository.ts';
import {
  PackageProblemMakeReqDTO,
  PackageProblemMakeResDTO,
} from '@phs/interfaces';
import { queryKeys } from '@constants/key.ts';

export function useCreatePackageExam() {
  const { mutate, isLoading } = useMutation({
    mutationFn: PSTRepository.createPackageProblem,
  });

  const create = (
    data: PackageProblemMakeReqDTO,
    {
      onSuccess,
      onError,
    }: {
      onSuccess?: () => void;
      onError?: (error: unknown) => void;
    },
  ) =>
    mutate(data, {
      onSuccess,
      onError,
    });

  return { create, isLoading };
}

export function useUpdatePackageExam() {
  const { mutate, isLoading } = useMutation({
    mutationFn: PSTRepository.updatePackageProblem,
  });

  const update = (
    data: { sn: string; data: PackageProblemMakeReqDTO },
    {
      onSuccess,
      onError,
    }: {
      onSuccess?: () => void;
      onError?: (error: unknown) => void;
    },
  ) =>
    mutate(data, {
      onSuccess,
      onError,
    });

  return { update, isLoading };
}

const readPackageFallback: PackageProblemMakeResDTO = {
  title: '',
  packageLevel: '0',
  packageDetailLevel: 0,
  manualDifficultyYn: false,
  overallPredictionAverageScore: null,
  overallPredictionLevel: null,
  content: null,
  stepProblemList: [],
};

export function useReadPackageExam(sn: string) {
  const { data, isError, isLoading } = useQuery<
    Promise<PackageProblemMakeResDTO>,
    unknown,
    PackageProblemMakeResDTO,
    readonly string[]
  >([queryKeys.package, sn], () => PSTRepository.readPackageProblem(sn));

  if (isError || isLoading) return readPackageFallback;

  return data;
}
