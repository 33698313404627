export const Headers = [
	{
		name: 'No',
		key: 'No',
	},
	{
		name: '제목',
		key: 'title',
	},
	{
		name: '총 단계수',
		key: 'totalStep',
	},
	{
		name: '종합 난이도',
		key: 'packageLevel',
	},
	{
		name: '난이도',
		key: 'levelSet',
	},
	{
		name: '사용언어',
		key: 'language',
	},
	{
		name: '생성일',
		key: 'createdAt',
	},
	{
		name: '노출 횟수(기업)',
		key: 'inquiredCountCompany',
	},
	{
		name: '노출 횟수(잡다)',
		key: 'inquiredCountJobda',
	},
	{
		name: '기업 서비스',
		key: 'company',
	},
	{
		name: '잡다 CMS',
		key: 'jobda',
	},
	{
		name: '정보 동기화',
		key: 'sync',
	},
];
