import { stateKeys } from '@constants/key';
import { selector } from 'recoil';
import { currentPage, pageSize } from '@stores/atoms/shared/pagination.ts';
import { companySortType } from '@stores/atoms/manage/company.sort';
import { searchKeyword } from '@stores/atoms/shared/keyword.ts';

export const companyPaginationQuery = selector({
	key: stateKeys.filter.companyPaginationQuery,
	get: ({ get }) => {
		const _searchKeyword = get(searchKeyword);
		const _currentPage = get(currentPage);
		const _pageSize = get(pageSize);
		const _sortType = get(companySortType);

		let queryString = `?&keyword=${_searchKeyword}&pageIndex=${_currentPage}&pageSize=${_pageSize}&sort=`;

		const payload: Record<string, any> = {
			searchKeyword: _searchKeyword,
			currentPage: _currentPage,
			pageSize: _pageSize,
		};

		if (_sortType) {
			payload['sort'] = _sortType;
			queryString += `&sort=${_sortType}`;
		}

		return {
			query: payload,
			queryString,
		};
	},
});
