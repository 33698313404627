import { FC, ReactNode } from 'react';
import TextButton from '@widget/button-text';
import { Container, ButtonWrapper, Title, TitleWrapper } from './style';
import LoadingButton from '@widget/button-loading';

export enum EditorEventType {
  CANCEL = 'cancel',
  PREVIEW = 'preview',
  SUBMIT = 'submit',
}

interface EditorHeaderProps {
  title?: ReactNode;
  cancel?: ReactNode;
  preview?: ReactNode;
  submit?: ReactNode;
  onClick?: (type: EditorEventType) => void;
  isSubmitLoading?: boolean;
}

const EditorHeader: FC<EditorHeaderProps> = ({
  title,
  cancel,
  preview,
  submit,
  onClick,
  isSubmitLoading,
}) => {
  const handleClick = (type: EditorEventType) => {
    onClick?.(type);
  };

  return (
    <Container>
      <TitleWrapper>
        <Title>{title}</Title>
        <ButtonWrapper>
          <TextButton
            style={{
              maxWidth: 120,
              height: 36,
            }}
            theme={'secondary'}
            type={'reset'}
            onClick={() => {
              handleClick(EditorEventType.CANCEL);
            }}
          >
            {cancel}
          </TextButton>
          {preview && (
            <TextButton
              style={{
                maxWidth: 120,
                height: 36,
              }}
              theme={'primary'}
              type={'button'}
              onClick={() => {
                handleClick(EditorEventType.PREVIEW);
              }}
            >
              {preview}
            </TextButton>
          )}
          <LoadingButton
            isLoading={isSubmitLoading}
            style={{
              maxWidth: 160,
              height: 36,
            }}
            type={'submit'}
            role={'submit'}
            onClick={() => handleClick(EditorEventType.SUBMIT)}
          >
            {submit}
          </LoadingButton>
        </ButtonWrapper>
      </TitleWrapper>
    </Container>
  );
};

export default EditorHeader;
