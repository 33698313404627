import ModalWidget from '@widget/modal';
import { FC } from 'react';
import {
  CloseBtn,
  ContentWrapper,
  Title,
  TopWrapper,
  BottomWrapper,
  InputWrap,
  Container,
} from './style.ts';
import { IconClose } from '@widget/icons';
import TextButton from '@widget/button-text';
import Input from '@widget/input';
import { useForm } from 'react-hook-form';
import OperationRepository from '@repositories/operation.repository.ts';
import { isAxiosError } from 'axios';
import {
  AxiosExceptionHandler,
  RuntimeExceptionHandler,
} from '@phs/exceptions';
import { PMS_EXCEPTION_MESSAGES } from '@constants/exceptions.ts';

interface OptionSettingModalProps {
  title: string;
  companyScaleFactor: number;
  commonCandidateCount: number;
  cheatingScaleFactor: number;
  changeOption: (
    count: number,
    factor: number,
    cheatScaleFactor: number,
  ) => void;
  onClose?: () => void;
}

interface FormProps {
  companyScaleFactor: number;
  commonCandidateCount: number;
  cheatingScaleFactor: number;
}

const OptionSettingModal: FC<OptionSettingModalProps> = ({
  title,
  commonCandidateCount,
  companyScaleFactor,
  cheatingScaleFactor,
  onClose,
  changeOption,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormProps>({});

  const onSubmit = async (data: FormProps) => {
    try {
      await OperationRepository.putScaleOption(
        data.commonCandidateCount,
        data.companyScaleFactor,
        data.cheatingScaleFactor,
      );
      changeOption(
        data.commonCandidateCount,
        data.companyScaleFactor,
        data.cheatingScaleFactor,
      );
      onClose && onClose();
    } catch (error) {
      if (isAxiosError(error)) {
        new AxiosExceptionHandler({
          error,
          message: PMS_EXCEPTION_MESSAGES.PMS_API_ERROR,
        });
      } else {
        new RuntimeExceptionHandler({
          error,
          message: PMS_EXCEPTION_MESSAGES.PMS_API_ERROR,
        });
      }
    }
  };

  return (
    <ModalWidget>
      <Container onSubmit={handleSubmit(onSubmit)}>
        <TopWrapper>
          <Title>{title}</Title>
          <CloseBtn onClick={onClose}>
            <IconClose />
          </CloseBtn>
        </TopWrapper>
        <ContentWrapper>
          <InputWrap>
            <h4>잡다 가상 사용자 수</h4>
            <Input
              type={'number'}
              defaultValue={commonCandidateCount}
              error={errors.commonCandidateCount?.message}
              {...register('commonCandidateCount', {
                valueAsNumber: true,
                required: '값을 입력하세요',
              })}
            />
          </InputWrap>
          <InputWrap>
            <h4>서버 한 대의 최대 가용자 수 (부정행위X)</h4>
            <Input
              type={'number'}
              defaultValue={companyScaleFactor}
              error={errors.companyScaleFactor?.message}
              {...register('companyScaleFactor', {
                valueAsNumber: true,
                required: '값을 입력하세요',
              })}
            />
          </InputWrap>
          <InputWrap>
            <h4>서버 한 대의 최대 가용자 수 (부정행위0)</h4>
            <Input
              type={'number'}
              defaultValue={cheatingScaleFactor}
              error={errors.cheatingScaleFactor?.message}
              {...register('cheatingScaleFactor', {
                valueAsNumber: true,
                required: '값을 입력하세요',
              })}
            />
          </InputWrap>
        </ContentWrapper>
        <BottomWrapper>
          <TextButton onClick={onClose}>취소</TextButton>
          <TextButton theme={'primary'} type={'submit'}>
            확인
          </TextButton>
        </BottomWrapper>
      </Container>
    </ModalWidget>
  );
};

export default OptionSettingModal;
