import { PopUpDTO } from '@interfaces/notice/popup.dto';
import { useDate } from '@hooks/shared/useDate';
import { HyperText } from './style';
import { PopUpStatus } from '@interfaces/notice/popup.enum.ts';
import { useNavigate } from 'react-router-dom';
import { PMS_PAGES } from '@phs/constants';
import { HandleToggleTableClickProps } from '@widget/table-toggle';

const PopUpStateStringMap: Record<PopUpStatus, string> = {
  [PopUpStatus.WAITING]: '대기중',
  [PopUpStatus.POSTING]: '게시중',
  [PopUpStatus.ENDED]: '종료',
};

export function useNotice() {
  const navigation = useNavigate();
  const { safeStr2DateTime } = useDate();

  const onClick = ({ value, type }: HandleToggleTableClickProps<PopUpDTO>) => {
    if (type === 'item') {
      // 팝업 수정화면으로 라우팅
      navigation(PMS_PAGES.POPUP.EDIT((value as PopUpDTO).sn.toString()));
    }
  };

  const renderData = (data: PopUpDTO[]) => {
    if (!data) return undefined;

    return data.map((popup) => [
      <span>{popup.sn}</span>,
      <HyperText
        onClick={() =>
          onClick({
            type: 'item',
            value: popup,
          })
        }
      >
        {popup.title}
      </HyperText>,
      <span>
        {popup.pauseYn
          ? `${PopUpStateStringMap[popup.status]} (일시중지)`
          : PopUpStateStringMap[popup.status]}
      </span>,
      <span>
        {safeStr2DateTime(popup.startDateTime)}&nbsp;~&nbsp;
        {safeStr2DateTime(popup.endDateTime)}
      </span>,
      <span>{safeStr2DateTime(popup.createdDateTime)}</span>,
      <span>{safeStr2DateTime(popup.lastModifiedDateTime)}</span>,
    ]);
  };

  return {
    renderData,
    onClick,
  };
}
