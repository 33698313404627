import type { RouteObject } from 'react-router-dom';
import AuthenticatedLayout from '@layout/Authenticated';
import DashboardLayout from '@layout/Dashboard';
import ManagePage from '@pages/Manage';
import CompanyManagement from '@pages/Manage/Company';
import { PMS_PAGES } from '@phs/constants';
import DocumentManagement from '@pages/Manage/Document';
import OperationManagement from '@pages/Manage/Operation';

const ManageRoute: RouteObject[] = [
  {
    path: PMS_PAGES.MANAGE.HOME,
    element: <DashboardLayout />,
    children: [
      {
        path: PMS_PAGES.MANAGE.HOME,
        element: (
          <AuthenticatedLayout>
            <ManagePage />
          </AuthenticatedLayout>
        ),
        children: [
          {
            index: true,
            element: <CompanyManagement />,
          },
          {
            path: PMS_PAGES.MANAGE.DOCUMENT,
            element: <DocumentManagement />,
          },
          {
            path: PMS_PAGES.MANAGE.OPERATION,
            element: <OperationManagement />,
          },
        ],
      },
    ],
  },
];

export default ManageRoute;
