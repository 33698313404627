import styled from '@emotion/styled';

export const Container = styled.div``;

export const Title = styled.span`
  margin-left: 4px;
  font-family: inherit;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;

export const Hint = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray_700};
`;

export const ErrorText = styled.span`
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  color: #fa5252;
`;
