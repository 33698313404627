export const isSame = (target: string, comparator: string) =>
  target === comparator;

export const isValidToken = (target: number) =>
  target > Math.floor(Date.now() / 1000);

export const isSubUrlOfTarget = (target: string, comparator: string) =>
  target.startsWith(comparator);

export const isSameString = (target: string, comparator: string) =>
  target.localeCompare(comparator) === 0;
