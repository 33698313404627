import { injectable } from 'inversify';
import 'reflect-metadata';
import { Language } from './base';
import { ExamParameterDTO } from '../interfaces/language.dto';

@injectable()
export class JavaScript extends Language {
  public get defaultTimeLimit(): number {
    return 3000;
  }

  public loadFunctionCode(parameters: ExamParameterDTO): string {
    return parameters.paramInfo
      .map(({ paramType, paramName }) => {
        const returnType = this.returnType[paramType];
        return `${returnType.paramType} ${paramName}`.trim();
      })
      .join(', ');
  }

  public getFunctionTemplate(parameters: ExamParameterDTO): string {
    const { value } = this.returnType[parameters.returnType];

    return `function solution(${this.loadFunctionCode(
      parameters,
    )}) {\n    var answer${value};\n    return answer;\n}`;
  }

  public getStdioTemplate() {
    return "const readline = require('readline');\nconst rl = readline.createInterface({\n    input: process.stdin,\n    output: process.stdout\n});\n\nlet input = [];\n\nrl.on('line', function (line) {\n    input = line.split(' ');\n}).on('close', function () {\n    console.log(Number(input[0]) + Number(input[1]));\n});";
  }

  public get returnType() {
    return {
      // Primitive Types
      INT: this.setReturnType('', '', ' = 0'),
      LONG: this.setReturnType('', '', ' = 0'),
      DOUBLE: this.setReturnType('', '', ' = 0'),
      STRING: this.setReturnType('', '', " = ''"),
      BOOL: this.setReturnType('', '', ' = true'),
      // Reference Types
      INT_ARRAY: this.setReturnType('', '', ' = []'),
      LONG_ARRAY: this.setReturnType('', '', ' = []'),
      DOUBLE_ARRAY: this.setReturnType('', '', ' = []'),
      STRING_ARRAY: this.setReturnType('', '', ' = []'),
      BOOL_ARRAY: this.setReturnType('', '', ' = []'),
      // 2 Dimensional Reference Types
      INT_2_ARRAY: this.setReturnType('', '', ' = [[]]'),
      LONG_2_ARRAY: this.setReturnType('', '', ' = [[]]'),
      DOUBLE_2_ARRAY: this.setReturnType('', '', ' = [[]]'),
      STRING_2_ARRAY: this.setReturnType('', '', ' = [[]]'),
      BOOL_2_ARRAY: this.setReturnType('', '', ' = [[]]'),
    };
  }
}
