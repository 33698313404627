import { Container } from './style';
import type {
  DetailedHTMLProps,
  ForwardedRef,
  InputHTMLAttributes,
} from 'react';
import { forwardRef } from 'react';

interface FileInputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  elementId: string;
  defaultFileName?: string;
  placeholder?: string;
}

const FileInput = (
  { elementId, defaultFileName, placeholder, ...rest }: FileInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  return (
    <Container>
      <label htmlFor={elementId}>
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M25.1129 11.7799C25.0929 11.6799 25.0595 11.5932 25.0129 11.5065C24.9995 11.4865 24.9929 11.4599 24.9795 11.4399C24.9329 11.3532 24.8729 11.2732 24.7995 11.1999L19.4662 5.86654C19.3929 5.7932 19.3129 5.73987 19.2262 5.68654C19.2062 5.6732 19.1862 5.66654 19.1662 5.6532C19.0129 5.57987 18.8462 5.5332 18.6662 5.5332H10.3995C8.45288 5.5332 6.86621 7.11987 6.86621 9.06654V22.9332C6.86621 24.8799 8.45288 26.4665 10.3995 26.4665H21.5995C23.5462 26.4665 25.1329 24.8799 25.1329 22.9332V11.9999C25.1329 11.9265 25.1262 11.8532 25.1129 11.7799ZM21.2662 10.8665H19.7995V9.39987L21.2662 10.8665ZM21.5995 24.1999H10.3995C9.69954 24.1999 9.13288 23.6332 9.13288 22.9332V9.06654C9.13288 8.36654 9.69954 7.79987 10.3995 7.79987H17.5329V11.1999C17.5329 12.2665 18.3995 13.1332 19.4662 13.1332H22.8662V22.9332C22.8662 23.6332 22.2995 24.1999 21.5995 24.1999Z'
            fill='#495057'
          />
          <path
            d='M19.9995 14.8665H11.9995V17.1332H19.9995V14.8665Z'
            fill='#495057'
          />
          <path
            d='M19.9995 19.5332H11.9995V21.7999H19.9995V19.5332Z'
            fill='#495057'
          />
        </svg>
        <span>{defaultFileName || placeholder}</span>
      </label>
      <input
        {...rest}
        type={'file'}
        id={elementId}
        name={elementId}
        ref={ref}
        readOnly={true}
      />
    </Container>
  );
};

export default forwardRef<HTMLInputElement, FileInputProps>(FileInput);
