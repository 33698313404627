import { FC, useEffect } from 'react';
import { Resizable } from 're-resizable';
import { Editor as TuiEditor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import {
  Container,
  RequireTitle,
  Hint,
  Header,
  ButtonWrapper,
  Button,
} from './style.ts';
import { resizeOptions } from './config.ts';
import { useEditor } from './useEditor.ts';
import { useResize } from './useResize.ts';

interface EditorProps {
  initialValue?: string;
  handleChange?: (content: string) => void;
  error?: string;
}

const Editor: FC<EditorProps> = ({
  initialValue = '',
  handleChange,
  error,
}) => {
  const {
    editorRef,
    addImageBlobHook,
    onChange,
    execHighlight,
    highLightSyntaxPlugin,
    removeHighlight,
  } = useEditor(handleChange);
  const { onResizeStart, onResize } = useResize({ minHeight: 1000 });

  useEffect(() => {
    if (!editorRef.current) return;
    editorRef.current.getInstance().setMarkdown(initialValue);
  }, [editorRef]);

  return (
    <Container>
      <Header>
        <RequireTitle>문제 설명</RequireTitle>
        <ButtonWrapper>
          <Button onClick={execHighlight}>선택 영역 하이라이트</Button>
          <Button onClick={removeHighlight}>하이라이트 전체 제거</Button>
        </ButtonWrapper>
      </Header>
      <Resizable
        {...resizeOptions}
        onResizeStart={onResizeStart}
        onResize={onResize}
      >
        <TuiEditor
          usageStatistics={false}
          previewStyle={'vertical'}
          initialEditType='markdown' // markdown, wysiwyg
          ref={editorRef}
          hooks={{
            addImageBlobHook,
          }}
          onChange={onChange}
          height='100%'
          plugins={[highLightSyntaxPlugin]}
        />
      </Resizable>
      {!error && <Hint>{error}</Hint>}
    </Container>
  );
};

export default Editor;
