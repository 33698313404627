import { FC, useMemo } from 'react';
import { CustomTable, NumberList, NumberText } from './style.ts';

export interface AlogrithmData {
  category: string;
  value: number[][];
}

interface TableProps {
  data: AlogrithmData[];
}

const Table: FC<TableProps> = ({ data }) => {
  const tableBody = useMemo(() => {
    return data.map((tableData, index) => (
      <tr key={`algorithm-${index}`}>
        <td>{tableData.category}</td>
        {tableData.value.map((numberList, innerIdx) => (
          <td key={`algorithm-${index}-${innerIdx}`}>
            <NumberList>
              {numberList.map((numberData, numberIdx) => (
                <NumberText key={`algorithm-${index}-${innerIdx}-${numberIdx}`}>
                  {numberData}
                </NumberText>
              ))}
            </NumberList>
          </td>
        ))}
      </tr>
    ));
  }, [data]);

  return (
    <CustomTable>
      <thead>
        <tr>
          <th rowSpan={2}>알고리즘</th>
          <th colSpan={5}>난이도</th>
        </tr>
        <tr>
          <th>1레벨</th>
          <th>2레벨</th>
          <th>3레벨</th>
          <th>4레벨</th>
          <th>5레벨</th>
        </tr>
      </thead>
      <tbody>{tableBody}</tbody>
    </CustomTable>
  );
};

export default Table;
