import { useState } from 'react';
import { CodeTestCase } from '@phs/code';
import { ModifyCommandType, TestCaseDTO, TestCaseType } from '@phs/interfaces';
import { useAlertModal } from '@components/Modals/Alert/hook.ts';
import { TableCell } from './style';

interface UseExampleCasePropsProps {
  disabled?: boolean;
  handleTestCase?: (
    type: TestCaseType,
    data: TestCaseDTO[],
    isFile?: boolean,
  ) => void;
}

export function useExampleCaseProps({
  disabled = false,
  handleTestCase,
}: UseExampleCasePropsProps) {
  const { onOpen } = useAlertModal();
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  const handleOpen = (status: boolean) => {
    if (status && disabled) return onOpen('언어가 선택되지 않았습니다.');
    setIsModalOpened(status);
  };

  const onSubmit = (data: TestCaseDTO[]) => {
    setIsModalOpened(false);

    handleTestCase?.(TestCaseType.EXAMPLE, data);
  };

  const renderData = (data: TestCaseDTO[]) => {
    if (!data) return undefined;

    return data
      .filter(({ commandType }) => commandType !== ModifyCommandType.DELETE)
      .map((props, idx) => [
        <TableCell>{idx + 1}</TableCell>,
        <TableCell>
          {CodeTestCase.stringifyAll(props.input.map(({ value }) => value))}
        </TableCell>,
        <TableCell>{props.output.value}</TableCell>,
      ]);
  };

  return {
    isModalOpened,
    handleOpen,
    renderData,
    onSubmit,
  };
}
