import styled from '@emotion/styled';

export const Container = styled.span`
	cursor: pointer;
	height: 24px;
	float: left;
	overflow: hidden;
	position: relative;
	width: 42px;
	border-radius: 20px;

	input {
		position: absolute;
		z-index: 3;
		width: 100%;
		height: 100%;
		cursor: pointer;
		opacity: 0;
	}
`;

export const SwitchWrap = styled.span<{ background: string; check: boolean }>`
	color: #fff;
	display: inline-block;
	height: 100%;
	left: -100%;
	position: relative;
	width: 200%;
	background: ${({ background, check }) => (check ? background : '#CED4DA')};
	border-color: ${({ background }) => background};
	transition: all 0.7s ease;
`;

export const SwitchHandler = styled.span<{ check: boolean }>`
	background: #fff;
	border-radius: 50%;
	display: inline-block;
	height: 18px;
	left: 50%;
	position: relative;
	transition: 150ms linear;
	top: 3px;
	width: 18px;
	transform: ${({ check }) =>
		check ? `translateX(50%) translateX(12px)` : `translateX(2px)`};
`;
