import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  max-width: 70%;
  height: 40px;
  margin-left: auto;
  justify-content: flex-end;
`;
