import { FC } from 'react';
import { Container } from '@pages/Data/Algorithms/style.ts';
import AlgorithmFunction from '@pages/Data/Algorithms/Function';
import Info from '@pages/Data/Algorithms/Info';
import Table from '@pages/Data/Algorithms/Table';
import { useAlgorithm } from '@pages/Data/Algorithms/hook.ts';

const DataAlgorithms: FC = () => {
  const { tableData, rsData, setDataByExcel } = useAlgorithm();

  return (
    <Container>
      <AlgorithmFunction rsData={rsData} setData={setDataByExcel} />
      <Info recentSyncDate={rsData?.syncDateTime ?? '-'} />
      <Table data={tableData} />
    </Container>
  );
};
export default DataAlgorithms;
