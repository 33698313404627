import ToggleTable, {
	type ToggleTableHeaderProps,
	type HandleToggleTableClickProps,
} from '@widget/table-toggle';
import { RenderTableBody } from '@components/Table';
import { useTable } from './hooks';
import { useRecoilValue } from 'recoil';
import { tableToggles } from '@stores/selectors/shared/table.ts';
import { useEffect } from 'react';

interface TableProps<T = never> {
	headers: ToggleTableHeaderProps[];
	data?: T[];
	renderTableBody?: RenderTableBody<T>;
	onClick?: (props: HandleToggleTableClickProps<T>) => void;
}

function Table<T = never>({
	headers,
	data,
	renderTableBody,
	onClick,
}: TableProps<T>) {
	const { render, handleClick, setTableToggle } = useTable({
		renderTableBody,
		onClick,
	});
	const { currentToggles } = useRecoilValue(tableToggles);

	useEffect(() => {
		setTableToggle(
			Array.from({ length: data?.length ? data.length : 1 }, () => false),
		);
	}, [data, setTableToggle]);

	return (
		<ToggleTable<T>
			headers={headers}
			data={render(data)}
			activeStatus={currentToggles ?? []}
			onClick={handleClick}
		/>
	);
}

export default Table;
