import styled from '@emotion/styled';

export const Textarea = styled.textarea`
  width: 100%;
  height: 92px;
  box-sizing: border-box;
  padding: 12px 11px;
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-radius: 4px;
  color: #495057;
  background: #fff;
  border: none;
  outline: none;
  resize: none;

  ::placeholder {
    color: #adb5bd;
    background: #fff;
  }

  :focus,
  :focus-visible,
  :active {
    background: #fff;
    border: 1px solid #ced4da;
    outline: none;
    box-shadow: none;
  }

  :disabled {
    opacity: 0.6;
  }

  svg {
    width: 16px;
    height: 16px;
    fill: #2f68f6;
  }
`;
