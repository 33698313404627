import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const RequireTitle = styled.div<{ showDescription: boolean }>`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray_900};

  &::after {
    display: ${(props) => (props.showDescription ? 'inline-block' : 'none')};
    margin-left: 8px;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.gray_600};
    vertical-align: middle;
    content: '출제의도와 적합한 알고리즘 태그를 모두 선택해주세요.';
  }
`;

export const DropDownWrapper = styled.div`
  width: 25%;
`;

export const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const TagContainer = styled.div`
  display: inline-flex;
  gap: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray_400};
  padding: 6px 12px;
  color: ${({ theme }) => theme.colors.gray_700};
  border-radius: 4px;
  align-items: center;

  .delete {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
`;
