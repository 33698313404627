import { DataType } from '@phs/interfaces';
import { CodeValidator } from '@phs/code';

export function useFileValidation() {
  const checkValidation = (paramTypeList: DataType[], file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        try {
          const result = CodeValidator.checkDataTypes(
            paramTypeList,
            reader.result as string,
          );
          if (!result.status) {
            throw {
              message: result.message,
            };
          }
          resolve(true);
        } catch (e: any) {
          if (e.message) {
            reject({
              message: `${file.name} : ${
                e.message || '알 수 없는 문제가 발생하였습니다.'
              }`,
            });
          }
        }
      };
      reader.onerror = () => {
        reject({
          message: `${file.name} 파일을 읽는데 문제가 발생하였습니다.`,
        });
      };
      reader.readAsText(file, 'UTF-8');
    });
  };
  return { checkValidation };
}
