import styled from '@emotion/styled';

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 480px;
  min-height: 208px;
  height: max-content;
  max-height: 80%;
  box-sizing: border-box;
  padding: 32px 24px;
  overflow: auto;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  white-space: pre-wrap;
`;
