import { Outlet } from 'react-router-dom';
import GNB from '@components/GNB';
import { Container } from './style';

const DashboardLayout = (): JSX.Element => {
	return (
		<Container>
			<GNB />
			<Outlet />
		</Container>
	);
};

export default DashboardLayout;
