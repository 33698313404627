import { CSSProperties, FC } from 'react';
import { Container, SwitchHandler, SwitchWrap } from './style';

export interface SwitchProps {
	checked: boolean;
	disabled?: boolean;
	onChange: (check: boolean) => void;
	color: string;
	style?: CSSProperties;
}

const Switch: FC<SwitchProps> = (props) => {
	const { checked, disabled, onChange, color, style } = props;

	return (
		<Container style={style}>
			<input
				type='checkbox'
				checked={checked}
				onChange={() => {
					onChange(!checked);
				}}
				disabled={disabled}
			/>
			<SwitchWrap background={color} check={checked}>
				<SwitchHandler check={checked} />
			</SwitchWrap>
		</Container>
	);
};

export default Switch;
