import { useState } from 'react';

export function useAutoScalingState() {
  const [openOptionModal, setOpenOptionModal] = useState<boolean>(false);
  const [commonCandidateCount, setCommonCandidateCount] = useState<number>(0);
  const [companyScaleFactor, setCompanyScaleFactor] = useState<number>(0);
  const [cheatingScaleFactor, setCheatingScaleFactor] = useState<number>(0);

  const convertModalState = (state: boolean) => {
    setOpenOptionModal(state);
  };

  const setOptionValue = (
    count: number,
    factor: number,
    cheatScaleFactor: number,
  ) => {
    setCommonCandidateCount(count);
    setCompanyScaleFactor(factor);
    setCheatingScaleFactor(cheatScaleFactor);
  };

  return {
    openOptionModal,
    commonCandidateCount,
    companyScaleFactor,
    cheatingScaleFactor,
    convertModalState,
    setOptionValue,
  };
}
