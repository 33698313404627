import styled from '@emotion/styled';

export const Container = styled.div`
  ul {
    margin-bottom: 24px;
    list-style: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;

    .none-style {
      list-style: none;
    }
  }
  li {
    margin-bottom: 8px;
  }
`;

export const Title = styled.h3`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 12px;
`;

export const SubTitle = styled.div`
  margin-bottom: 12px;
`;
