import { PMS_PAGES } from '@phs/constants';
import LoginPage from '@pages/Login';
import type { RouteObject } from 'react-router-dom';

const AuthRoute: RouteObject = {
  path: PMS_PAGES.AUTH.HOME,
  children: [
    {
      path: PMS_PAGES.AUTH.LOGIN,
      element: <LoginPage />,
    },
  ],
};

export default AuthRoute;
