import { RequireTitle, DropDownWrapper, TagList, Container } from './style.ts';
import DropDownMultiple from '@widget/dropdown-multiple';
import Tag from './Tag.tsx';
import { algorithmList } from '@pages/Data/config.ts';
import { useAlertModal } from '@components/Modals/Alert/hook.ts';

interface AlgorithmProps {
  selectedValues: string[];
  onChange?: (value: string[]) => void;
  readOnly?: boolean;
}

function Algorithm({
  selectedValues,
  onChange = () => {},
  readOnly,
}: AlgorithmProps) {
  const { onOpen } = useAlertModal();

  const handleChange = (value: string[]) => {
    if (value.length <= 5) onChange(value);
    else onOpen('5개 까지만 입력하세요');
  };

  return (
    <Container>
      <RequireTitle showDescription={!readOnly}>알고리즘 분류</RequireTitle>
      {!readOnly && (
        <DropDownWrapper>
          <DropDownMultiple
            items={algorithmList}
            onChange={handleChange}
            title='알고리즘 분류'
            value={selectedValues}
            key={'algorithm'}
          />
        </DropDownWrapper>
      )}
      <TagList>
        {selectedValues.map((key) => (
          <Tag
            name={String(
              algorithmList.find((algorithm) => algorithm.key === key)?.name ??
                '',
            )}
            onDelete={() => {
              onChange(
                selectedValues.filter((algorithmKey) => algorithmKey !== key),
              );
            }}
            readOnly={readOnly}
            key={key}
          />
        ))}
      </TagList>
    </Container>
  );
}

export default Algorithm;
