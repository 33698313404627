import styled from '@emotion/styled';

export const Container = styled.div`
	max-width: 1398px;
	margin: auto;
`;

export const Header = styled.div`
	width: 100%;
	box-sizing: border-box;
	padding: 24px 0;
	border-bottom: 1px solid ${({ theme }) => theme.colors.gray_200};
`;

export const FilterWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
	padding: 24px 0;
`;

export const HyperText = styled.a`
	font-weight: 700;
	cursor: pointer;
`;
