import { FC, Fragment, useEffect, useMemo } from 'react';
import {
  FieldArrayWithId,
  FieldErrors,
  UseFormRegister,
  UseFormWatch,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';
import {
  ExamLanguageDTO,
  FunctionParameterAndReturnTypeDTO,
  ModifyCommandType,
  TestCaseDTO,
} from '@phs/interfaces';
import { DropdownItemType } from '@widget/dropdown';
import { PackageExamFormProps } from '@pages/Exam/package/config.ts';
import StepLevel from '../../shared/StepLevel';
import Score from '../../shared/Score';
import Summary from '../../shared/Summary';
import Editor from '../../shared/Editor';
import Language from '../../shared/Language';
import ExampleCase from '../../shared/ExampleCase';
import AccuracyCase from '../../shared/AccuracyCase';
import EfficiencyCase from '../../shared/EfficiencyCase';
import Calculation from '../../shared/Calculation';
import ParametersAndReturnTypes from '../../shared/Parameters';
import { usePackagePhase } from './hooks.ts';
import { RequireTitle, Divider, Title, TotalScoreRateText } from './style.ts';
import DetailLevel from '@pages/Exam/shared/DetailLevel/index.tsx';
import Algorithm from '@pages/Exam/shared/Algorithm/index.tsx';

interface PackagePhaseProps {
  cursor: number;
  watch: UseFormWatch<PackageExamFormProps>;
  getValues: UseFormGetValues<PackageExamFormProps>;
  setValue: UseFormSetValue<PackageExamFormProps>;
  register: UseFormRegister<PackageExamFormProps>;
  fields: FieldArrayWithId<PackageExamFormProps>[];
  errors?: FieldErrors<PackageExamFormProps>;
  handleChange?: (content: string) => void;
  handleTestCase?: (data: TestCaseDTO[]) => void;
  handleToggle?: (status: boolean) => void;
  handleLevel?: (props: DropdownItemType) => void;
  handleDetailLevel?: (props: DropdownItemType) => void;
  handleAlgorithm?: (algorithmTypeList: string[]) => void;
  handleParams: (params: FunctionParameterAndReturnTypeDTO) => void;
  handleJudgeLanguageList: (languageList: ExamLanguageDTO[]) => void;
  testCaseScoreRateYn?: boolean;
  summaryYn?: boolean;
}

const PackagePhase: FC<PackagePhaseProps> = ({
  cursor,
  watch,
  register,
  fields,
  errors,
  getValues,
  setValue,
  handleChange,
  handleTestCase,
  handleToggle,
  handleLevel,
  handleParams,
  handleJudgeLanguageList,
  handleAlgorithm,
  handleDetailLevel,
}) => {
  const {
    exampleTestCases,
    accuracyTestCases,
    accuracyFileTestCases,
    efficiencyTestCases,
    efficiencyFileTestCases,
    handleSubmit,
    onBlurInputParam,
    onChangeSelectParam,
    onClickParam,
    onClickSelectParam,
    onFocusInputParam,
    params,
  } = usePackagePhase({
    testCases: watch(`stepProblemList.${cursor}.testCaseList`),
    handleTestCase,
    defaultParams: watch(`stepProblemList.${cursor}.parameters`),
    handleParams,
    judgeLanguageList:
      watch(`stepProblemList.${cursor}.judgeLanguageList`) ?? [],
    handleJudgeLanguageList,
  });

  const calculateEnabled = useMemo(() => {
    return (
      (accuracyTestCases.filter(
        ({ commandType }) => commandType !== ModifyCommandType.DELETE,
      ).length > 0 ||
        accuracyFileTestCases.filter(
          ({ commandType }) => commandType !== ModifyCommandType.DELETE,
        ).length > 0) &&
      (efficiencyTestCases.filter(
        ({ commandType }) => commandType !== ModifyCommandType.DELETE,
      ).length > 0 ||
        efficiencyFileTestCases.filter(
          ({ commandType }) => commandType !== ModifyCommandType.DELETE,
        ).length > 0)
    );
  }, [
    accuracyTestCases,
    accuracyFileTestCases,
    efficiencyTestCases,
    efficiencyFileTestCases,
  ]);

  const accuracyTestCasesCount = useMemo(() => {
    const count =
      accuracyFileTestCases.filter(
        ({ commandType }) => commandType !== ModifyCommandType.DELETE,
      ).length +
      accuracyTestCases.filter(
        ({ commandType }) => commandType !== ModifyCommandType.DELETE,
      ).length;
    return count === 0 ? 1 : count;
  }, [accuracyFileTestCases, accuracyTestCases]);

  const efficiencyTestCasesCount = useMemo(() => {
    const count =
      efficiencyFileTestCases.filter(
        ({ commandType }) => commandType !== ModifyCommandType.DELETE,
      ).length +
      efficiencyTestCases.filter(
        ({ commandType }) => commandType !== ModifyCommandType.DELETE,
      ).length;
    return count === 0 ? 1 : count;
  }, [efficiencyFileTestCases, efficiencyTestCases]);

  useEffect(() => {
    if (calculateEnabled) return;
    setValue(`stepProblemList.${cursor}.testCaseScoreRateYn`, false);
  }, [calculateEnabled]);

  useEffect(() => {
    const accuracyScoreRate = getValues(
      `stepProblemList.${cursor}.accuracyScoreRate`,
    );
    if (accuracyScoreRate === null || accuracyScoreRate === undefined) return;

    let num = Math.max(1, accuracyScoreRate);
    num = Math.min(99, num);
    if (isNaN(accuracyScoreRate)) num = 1;

    setValue(`stepProblemList.${cursor}.accuracyScoreRate`, num);
    setValue(`stepProblemList.${cursor}.efficiencyScoreRate`, 100 - num);
  }, [watch(`stepProblemList.${cursor}.accuracyScoreRate`)]);

  const totalScoreRateText = useMemo(() => {
    let totalRate = 0;
    const scoreRates = watch('stepProblemList').map((stepProblem) => {
      const rate = isNaN(stepProblem.scoreRate) ? 0 : stepProblem.scoreRate;
      totalRate += rate;
      return rate;
    });

    return `현재 입력된 총 배점 비율 : ${totalRate}% (${scoreRates
      .map((rate, i) => `단계 ${i + 1} : ${rate}%`)
      .join(', ')})`;
  }, [watch(`stepProblemList.${cursor}.scoreRate`), watch(`stepProblemList`)]);

  return (
    <Fragment key={fields[cursor]?.id}>
      <Title>단계 기본 설정</Title>
      <StepLevel
        initialState={watch(`stepProblemList.${cursor}.level`)}
        onChange={handleLevel}
        error={errors?.stepProblemList?.[cursor]?.level?.message}
      />
      <DetailLevel
        initialState={watch(`stepProblemList.${cursor}.detailLevel`)}
        onChange={handleDetailLevel}
        errorMessage={errors?.stepProblemList?.[cursor]?.detailLevel?.message}
      />
      <div>
        <Score
          inputId={`score-rate-input`}
          register={register(`stepProblemList.${cursor}.scoreRate`, {
            valueAsNumber: true,
          })}
          placeholder={'숫자만 입력해주세요.'}
          error={errors?.stepProblemList?.[cursor]?.scoreRate?.message}
          value={watch(`stepProblemList.${cursor}.scoreRate`)}
        >
          <RequireTitle
            hint={'* 총점 중 해당 단계의 배점 비율을 입력해주세요.'}
          >
            해당 단계 배점 비율(%)
          </RequireTitle>
        </Score>
        <TotalScoreRateText>{totalScoreRateText}</TotalScoreRateText>
      </div>
      <Score
        inputId={`pass-score-input`}
        register={register(`stepProblemList.${cursor}.passScore`, {
          valueAsNumber: true,
        })}
        placeholder={'숫자만 입력해주세요.'}
        error={errors?.stepProblemList?.[cursor]?.passScore?.message}
        value={watch(`stepProblemList.${cursor}.passScore`)}
      >
        <RequireTitle
          hint={'* 해당 단계 총점 100점 기준의 통과점수를 입력해 주세요.'}
        >
          해당 단계 통과 점수
        </RequireTitle>
      </Score>
      <Summary
        cursor={cursor}
        register={register}
        summaryYn={watch(`stepProblemList.${cursor}.summaryYn`)}
        handleToggle={handleToggle}
        value={watch(`stepProblemList.${cursor}.summary`)}
        error={errors?.stepProblemList?.[cursor]?.summary?.message}
      />
      <Algorithm
        onChange={handleAlgorithm}
        selectedValues={
          watch(`stepProblemList.${cursor}.algorithmTypeList`) ?? []
        }
      />
      <Divider />
      <Title>문제 정보</Title>
      <Editor
        initialValue={watch(`stepProblemList.${cursor}.content`)}
        handleChange={handleChange}
        error={`${errors?.stepProblemList?.[cursor]?.content}`}
      />
      <ParametersAndReturnTypes
        params={params}
        onBlurInput={onBlurInputParam}
        onChangeSelect={onChangeSelectParam}
        onClick={onClickParam}
        onFocusInput={onFocusInputParam}
        onClickSelectParam={onClickSelectParam}
      />
      <Language
        params={
          watch(
            `stepProblemList.${cursor}.parameters`,
          ) as FunctionParameterAndReturnTypeDTO
        }
        tabIdx={cursor}
        languages={watch(`stepProblemList.${cursor}.judgeLanguageList`)}
        setValue={setValue}
      />
      <ExampleCase
        params={watch(`stepProblemList.${cursor}.parameters`)!}
        languages={watch(`stepProblemList.${cursor}.judgeLanguageList`)}
        testCases={exampleTestCases}
        handleTestCase={handleSubmit}
      />
      <AccuracyCase
        params={watch(`stepProblemList.${cursor}.parameters`)!}
        languages={watch(`stepProblemList.${cursor}.judgeLanguageList`)}
        testCases={accuracyTestCases}
        fileTestCases={accuracyFileTestCases}
        handleTestCase={handleSubmit}
      />
      <EfficiencyCase
        params={watch(`stepProblemList.${cursor}.parameters`)!}
        languages={watch(`stepProblemList.${cursor}.judgeLanguageList`)}
        testCases={efficiencyTestCases}
        fileTestCases={efficiencyFileTestCases}
        handleTestCase={handleSubmit}
      />
      <Calculation
        isActive={watch(`stepProblemList.${cursor}.testCaseScoreRateYn`)}
        cursor={cursor}
        register={register}
        accuracyScoreRate={watch(`stepProblemList.${cursor}.accuracyScoreRate`)}
        efficiencyScoreRate={watch(
          `stepProblemList.${cursor}.efficiencyScoreRate`,
        )}
        disabled={!calculateEnabled}
        accuracyCount={accuracyTestCasesCount}
        efficiencyCount={efficiencyTestCasesCount}
      />
    </Fragment>
  );
};

export default PackagePhase;
