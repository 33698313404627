import { injectable } from 'inversify';
import 'reflect-metadata';
import { Language } from './base';
import { ExamParameterDTO } from '../interfaces/language.dto';

@injectable()
export class Go extends Language {
  public get defaultTimeLimit(): number {
    return 1000;
  }

  public loadFunctionCode(parameters: ExamParameterDTO): string {
    return parameters.paramInfo
      .map(({ paramType, paramName }) => {
        const returnType = this.returnType[paramType];
        return `${paramName} ${returnType.paramType}`.trim();
      })
      .join(', ');
  }

  public getFunctionTemplate(parameters: ExamParameterDTO): string {
    const { value, type } = this.returnType[parameters.returnType];

    return `func solution(${this.loadFunctionCode(
      parameters,
    )}) ${type} {\n    return ${value}\n}`;
  }

  public getStdioTemplate() {
    return 'package main\n\nimport "fmt"\n\nfunc main() {\n    var a ,b int\n    fmt.Scan(&a, &b)\n    fmt.Println(a + b)\n}';
  }

  public get returnType() {
    return {
      // Primitive Types
      INT: this.setReturnType('int', 'int', '0'),
      LONG: this.setReturnType('int64', 'int64', '0'),
      DOUBLE: this.setReturnType('float64', 'float64', '0'),
      STRING: this.setReturnType('string', 'string', '""'),
      BOOL: this.setReturnType('bool', 'bool', 'true'),
      // Reference Types
      INT_ARRAY: this.setReturnType('[]int', '[]int', '[]int{}'),
      LONG_ARRAY: this.setReturnType('[]int64', '[]int64', '[]int64{}'),
      DOUBLE_ARRAY: this.setReturnType('[]float64', '[]float64', '[]float64{}'),
      STRING_ARRAY: this.setReturnType('[]string', '[]string', '[]string{}'),
      BOOL_ARRAY: this.setReturnType('[]bool', '[]bool', '[]bool{}'),
      // 2 Dimensional Reference Types
      INT_2_ARRAY: this.setReturnType('[][]int', '[][]int', '[][]int{{}}'),
      LONG_2_ARRAY: this.setReturnType(
        '[][]int64',
        '[][]int64',
        '[][]int64{{}}',
      ),
      DOUBLE_2_ARRAY: this.setReturnType(
        '[][]float64',
        '[][]float64',
        '[][]float64{{}}',
      ),
      STRING_2_ARRAY: this.setReturnType(
        '[][]string',
        '[][]string',
        '[][]string{{}}',
      ),
      BOOL_2_ARRAY: this.setReturnType('[][]bool', '[][]bool', '[][]bool{{}}'),
    };
  }
}
