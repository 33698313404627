import { GuideFileInfoRsDto } from '@interfaces/company/data.dto.ts';
import { useQuery } from '@tanstack/react-query';
import DataRepository from '@repositories/data.repository.ts';
import { GuideFileType } from '@phs/interfaces';
import { queryKeys } from '@constants/key.ts';

const defaultDataFallback: GuideFileInfoRsDto = {
  attachFileSn: 0,
  fileExtension: '',
  fileName: undefined,
  filePath: '',
  fileSize: 0,
  fileUid: undefined,
};

export function useData(guideType: GuideFileType): GuideFileInfoRsDto {
  const { data, isError, isLoading } = useQuery<
    string,
    unknown,
    GuideFileInfoRsDto,
    readonly string[]
  >([queryKeys.data, guideType], () => DataRepository.getGuideFile(guideType));

  if (isError || isLoading) return defaultDataFallback;

  return data;
}
