import { Container } from './style';
import { FC, PropsWithChildren } from 'react';

interface SectionProps extends PropsWithChildren {
	title?: string;
}

const Section: FC<SectionProps> = ({ title, children }) => {
	return (
		<Container>
			<legend>{title}</legend>
			{children}
		</Container>
	);
};

export default Section;
