import { usePagination } from '@components/Pagination/hooks.ts';
import { useResetRecoilState, useRecoilState } from 'recoil';
import { useDebouncedCallback } from '@react-hookz/web';
import { searchKeyword } from '@stores/atoms/shared/keyword.ts';

export function useSearch() {
  const { resetPage } = usePagination();

  const [searchKeywordState, setSearchKeyword] = useRecoilState(searchKeyword);
  const resetSearchKeyword = useResetRecoilState(searchKeyword);

  const handleSearchKeyword = useDebouncedCallback(
    (value: string) => {
      resetPage();
      setSearchKeyword(value);
    },
    [],
    400,
  );

  return {
    handleSearchKeyword,
    resetSearchKeyword,
    searchKeyword: searchKeywordState,
    setSearchKeyword,
  };
}
