import { DATE_FORMAT } from '@phs/interfaces';

export const generateRangePickerPanelConfig = {
  showTime: {
    format: DATE_FORMAT.HH_mm,
  },
  showNow: false,
  showSecond: false,
  minuteStep: 5,
  allowClear: false,
  format: DATE_FORMAT.YYYY_MM_DD_HH_mm_KR,
  separator: '~',
};
