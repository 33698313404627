import { Container, Wrapper, Item } from './style';
import { FC, LiHTMLAttributes, MouseEventHandler } from 'react';
import { AiOutlinePlus, AiOutlineClose } from 'react-icons/ai';

export type TabExpandableItemProps = {
  name: string;
  key: string;
  immutable?: boolean;
};

interface TabExpandableProps {
  activeKey?: string;
  item: TabExpandableItemProps[];
  onClick?: (value: number) => void;
  onRemove?: (value: number) => void;
  onAdd?: () => void;
  mode?: 'write' | 'read';
  maxItemCount?: number;
}

const TabExpandable: FC<TabExpandableProps> = ({
  item,
  activeKey,
  onClick,
  onRemove,
  onAdd,
  mode = 'write',
  maxItemCount = Number.MAX_SAFE_INTEGER,
}) => {
  const handleClick: MouseEventHandler<HTMLUListElement> = (event) => {
    // @ts-ignore
    const { tagName, value, dataset } =
      event.target as LiHTMLAttributes<HTMLLIElement>;

    switch (tagName) {
      case 'LI':
      default:
        if (typeof value === 'number') {
          if (value === -1) return;
          onClick?.(value);
        }
        break;

      case 'A':
        onClick?.(parseInt(dataset.value as string));
        break;
    }
  };

  return (
    <Container onClick={handleClick}>
      <Wrapper>
        {item.map(({ name, key, immutable = false }, idx) => (
          <Item key={`${name}_${idx}`} value={idx} isActive={key === activeKey}>
            <a data-value={idx}>{name}</a>
            {!immutable && (
              <AiOutlineClose
                onClick={() => onRemove?.(idx)}
                data-testid={`expandable-tab-remove-${idx}`}
                data-value={idx}
                style={{
                  width: 16,
                  height: 16,
                  cursor: 'pointer',
                  verticalAlign: 'text-top',
                }}
              />
            )}
          </Item>
        ))}
        {mode === 'write' && item.length < maxItemCount && (
          <Item value={-1} onClick={onAdd}>
            <AiOutlinePlus
              data-testid={'tab-add-btn'}
              style={{
                width: 16,
                height: 16,
                cursor: 'pointer',
              }}
            />
          </Item>
        )}
      </Wrapper>
    </Container>
  );
};

export default TabExpandable;
