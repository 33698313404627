import { Link } from 'react-router-dom';
import { ReactNode } from 'react';
import { PMS_PAGES } from '@phs/constants';
import { Guide, Settings } from './style';

export const Nav: ReactNode[] = [
  <Link to={PMS_PAGES.DASHBOARD.HOME}>
    <svg
      width='25'
      height='26'
      viewBox='0 0 25 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.833 0.75H2.16634C1.42996 0.75 0.833008 1.34695 0.833008 2.08333V23.4167C0.833008 24.153 1.42996 24.75 2.16634 24.75H20.833C21.5694 24.75 22.1663 24.153 22.1663 23.4167V2.08333C22.1663 1.34695 21.5694 0.75 20.833 0.75Z'
        fill='#94C6F7'
      />
      <path
        d='M16.7493 11.7498C17.2556 11.7498 17.666 11.3394 17.666 10.8332C17.666 10.3269 17.2556 9.9165 16.7493 9.9165H6.24935C5.74309 9.9165 5.33269 10.3269 5.33269 10.8332C5.33269 11.3394 5.74309 11.7498 6.24935 11.7498H16.7493Z'
        fill='#D1E6FF'
      />
      <path
        d='M16.7493 15.9168C17.2556 15.9168 17.666 15.5064 17.666 15.0002C17.666 14.4939 17.2556 14.0835 16.7493 14.0835H6.24935C5.74309 14.0835 5.33269 14.4939 5.33269 15.0002C5.33269 15.5064 5.74309 15.9168 6.24935 15.9168H16.7493Z'
        fill='#D1E6FF'
      />
      <path
        d='M8.74935 20.0833C9.25561 20.0833 9.66602 19.6729 9.66602 19.1667C9.66602 18.6604 9.25561 18.25 8.74935 18.25H6.24935C5.74309 18.25 5.33268 18.6604 5.33268 19.1667C5.33268 19.6729 5.74309 20.0833 6.24935 20.0833H8.74935Z'
        fill='#D1E6FF'
      />
      <path
        d='M13.4167 5.4165H9.58333C8.98502 5.4165 8.5 5.90153 8.5 6.49984C8.5 7.09814 8.98502 7.58317 9.58333 7.58317H13.4167C14.015 7.58317 14.5 7.09814 14.5 6.49984C14.5 5.90153 14.015 5.4165 13.4167 5.4165Z'
        fill='#73A9EA'
      />
      <path
        d='M20.8808 9.41418L23.6896 11.7711C23.9605 11.9984 24.13 12.324 24.1608 12.6762C24.1916 13.0285 24.0812 13.3786 23.8539 13.6495L15.0692 24.1188L10.2176 20.0478L19.0023 9.57852C19.2296 9.30763 19.5552 9.13813 19.9075 9.10731C20.2598 9.07649 20.6099 9.18687 20.8808 9.41418Z'
        fill='#3987F8'
      />
      <path
        d='M15.0691 24.1205L10.2174 20.0488L9.74409 24.6822C9.73318 24.7903 9.74889 24.8995 9.78985 25.0003C9.83082 25.101 9.89579 25.1901 9.97911 25.26C10.0624 25.3298 10.1616 25.3782 10.2679 25.401C10.3742 25.4237 10.4845 25.4201 10.5891 25.3905L15.0691 24.1205Z'
        fill='#2C6CCC'
      />
    </svg>
    <span>개발 구현 능력 평가</span>
  </Link>,
  <Link to={PMS_PAGES.MANAGE.HOME}>
    <svg
      width='33'
      height='33'
      viewBox='0 0 33 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M25.833 4.75H7.16634C6.42996 4.75 5.83301 5.34695 5.83301 6.08333V27.4167C5.83301 28.153 6.42996 28.75 7.16634 28.75H25.833C26.5694 28.75 27.1663 28.153 27.1663 27.4167V6.08333C27.1663 5.34695 26.5694 4.75 25.833 4.75Z'
        fill='#FFC82A'
      />
      <path
        d='M21.7508 20.9168C22.2571 20.9168 22.6675 20.5064 22.6675 20.0002C22.6675 19.4939 22.2571 19.0835 21.7508 19.0835H11.2508C10.7446 19.0835 10.3342 19.4939 10.3342 20.0002C10.3342 20.5064 10.7446 20.9168 11.2508 20.9168H21.7508Z'
        fill='#FFDA67'
      />
      <path
        d='M21.7508 24.7498C22.2571 24.7498 22.6675 24.3394 22.6675 23.8332C22.6675 23.3269 22.2571 22.9165 21.7508 22.9165H11.2508C10.7446 22.9165 10.3342 23.3269 10.3342 23.8332C10.3342 24.3394 10.7446 24.7498 11.2508 24.7498H21.7508Z'
        fill='#FFDA67'
      />
      <path
        d='M16.5015 12.13C17.4349 12.13 18.1915 11.3734 18.1915 10.44C18.1915 9.50664 17.4349 8.75 16.5015 8.75C15.5682 8.75 14.8115 9.50664 14.8115 10.44C14.8115 11.3734 15.5682 12.13 16.5015 12.13Z'
        fill='white'
      />
      <path
        d='M18.9167 15.7497H14.0834C13.9703 15.7506 13.8585 15.7253 13.7569 15.6758C13.6552 15.6262 13.5664 15.5538 13.4975 15.4642C13.4285 15.3745 13.3813 15.2701 13.3595 15.1592C13.3376 15.0482 13.3418 14.9337 13.3717 14.8247C13.7667 13.4713 15.0167 12.563 16.5 12.563C17.9834 12.563 19.2334 13.4713 19.6284 14.8247C19.6583 14.9337 19.6625 15.0482 19.6406 15.1592C19.6188 15.2701 19.5716 15.3745 19.5026 15.4642C19.4337 15.5538 19.3449 15.6262 19.2432 15.6758C19.1416 15.7253 19.0298 15.7506 18.9167 15.7497Z'
        fill='white'
      />
    </svg>
    <span>기업 관리</span>
  </Link>,
  <Link to={PMS_PAGES.NOTICE.HOME}>
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M25.8334 5.3335H7.16671C6.43033 5.3335 5.83337 5.93045 5.83337 6.66683V27.3335C5.83337 28.0699 6.43033 28.6668 7.16671 28.6668H25.8334C26.5698 28.6668 27.1667 28.0699 27.1667 27.3335V6.66683C27.1667 5.93045 26.5698 5.3335 25.8334 5.3335Z'
        fill='#15C480'
      />
      <path
        d='M20.5 3.3335H12.5C12.1319 3.3335 11.8334 3.63197 11.8334 4.00016V6.66683C11.8334 7.03502 12.1319 7.3335 12.5 7.3335H20.5C20.8682 7.3335 21.1667 7.03502 21.1667 6.66683V4.00016C21.1667 3.63197 20.8682 3.3335 20.5 3.3335Z'
        fill='#239A6D'
      />
      <path
        d='M22.9167 12.8335H18.75C18.2438 12.8335 17.8334 13.2439 17.8334 13.7502C17.8334 14.2564 18.2438 14.6668 18.75 14.6668H22.9167C23.423 14.6668 23.8334 14.2564 23.8334 13.7502C23.8334 13.2439 23.423 12.8335 22.9167 12.8335Z'
        fill='#9EEED3'
      />
      <path
        d='M12.779 15.6847L15.7313 12.2885C16.0634 11.9064 16.0229 11.3274 15.6408 10.9953C15.2587 10.6632 14.6798 10.7036 14.3476 11.0857L11.3954 14.4819C11.0632 14.864 11.1037 15.443 11.4858 15.7751C11.8679 16.1073 12.4469 16.0668 12.779 15.6847Z'
        fill='white'
      />
      <path
        d='M12.734 14.4349L10.7306 12.4314C10.3726 12.0734 9.7922 12.0734 9.43422 12.4314C9.07624 12.7894 9.07624 13.3698 9.43422 13.7277L11.4377 15.7312C11.7957 16.0892 12.3761 16.0892 12.734 15.7312C13.092 15.3732 13.092 14.7928 12.734 14.4349Z'
        fill='white'
      />
      <path
        d='M22.9167 21.5H18.75C18.2438 21.5 17.8334 21.9104 17.8334 22.4167C17.8334 22.9229 18.2438 23.3333 18.75 23.3333H22.9167C23.423 23.3333 23.8334 22.9229 23.8334 22.4167C23.8334 21.9104 23.423 21.5 22.9167 21.5Z'
        fill='#9EEED3'
      />
      <path
        d='M12.7798 24.3522L15.7321 20.956C16.0642 20.5739 16.0238 19.9949 15.6417 19.6628C15.2596 19.3306 14.6806 19.3711 14.3485 19.7532L11.3962 23.1494C11.0641 23.5315 11.1046 24.1105 11.4866 24.4426C11.8687 24.7747 12.4477 24.7343 12.7798 24.3522Z'
        fill='white'
      />
      <path
        d='M12.7342 23.1018L10.7307 21.0984C10.3727 20.7404 9.79232 20.7404 9.43434 21.0984C9.07636 21.4564 9.07636 22.0368 9.43434 22.3947L11.4378 24.3982C11.7958 24.7562 12.3762 24.7562 12.7342 24.3982C13.0922 24.0402 13.0922 23.4598 12.7342 23.1018Z'
        fill='white'
      />
    </svg>
    <span>공지 사항 관리</span>
  </Link>,
  <Link to={PMS_PAGES.DATA.HOME}>
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M25.3333 5.33203H6.66659C5.93021 5.33203 5.33325 5.92898 5.33325 6.66536V27.332C5.33325 28.0684 5.93021 28.6654 6.66659 28.6654H25.3333C26.0696 28.6654 26.6666 28.0684 26.6666 27.332V6.66536C26.6666 5.92898 26.0696 5.33203 25.3333 5.33203Z'
        fill='#3987F8'
      />
      <path
        d='M19.9999 3.33203H11.9999C11.6317 3.33203 11.3333 3.63051 11.3333 3.9987V6.66536C11.3333 7.03355 11.6317 7.33203 11.9999 7.33203H19.9999C20.3681 7.33203 20.6666 7.03355 20.6666 6.66536V3.9987C20.6666 3.63051 20.3681 3.33203 19.9999 3.33203Z'
        fill='#2C6CCC'
      />
      <path
        d='M12.9166 19.9284C14.6195 19.9284 15.9999 18.5479 15.9999 16.8451C15.9999 15.1422 14.6195 13.7617 12.9166 13.7617C11.2137 13.7617 9.83325 15.1422 9.83325 16.8451C9.83325 18.5479 11.2137 19.9284 12.9166 19.9284Z'
        fill='#D1E6FF'
      />
      <path
        d='M18.9033 17.332H14.07C13.7018 17.332 13.4033 17.6305 13.4033 17.9987V22.832C13.4033 23.2002 13.7018 23.4987 14.07 23.4987H18.9033C19.2715 23.4987 19.57 23.2002 19.57 22.832V17.9987C19.57 17.6305 19.2715 17.332 18.9033 17.332Z'
        fill='#94C6F7'
      />
      <path
        d='M22.0198 14.4711L17.1865 11.2211C16.9648 11.0727 16.6665 11.2311 16.6665 11.4977V17.9961C16.6665 18.2627 16.9648 18.4211 17.1865 18.2727L22.0198 15.0227C22.2165 14.8911 22.2165 14.6011 22.0198 14.4694V14.4711Z'
        fill='white'
      />
      <path
        d='M25.1667 31.3333C28.5724 31.3333 31.3333 28.5724 31.3333 25.1667C31.3333 21.7609 28.5724 19 25.1667 19C21.7609 19 19 21.7609 19 25.1667C19 28.5724 21.7609 31.3333 25.1667 31.3333Z'
        fill='#FFC82A'
      />
      <path
        d='M27.2499 23.4187V22.9987C27.2499 22.6304 26.9516 22.332 26.5833 22.332H23.7499C23.3816 22.332 23.0833 22.6304 23.0833 22.9987V23.4187C23.0833 23.8187 23.2633 24.197 23.5716 24.4504L24.4449 25.1654L23.5716 25.8804C23.2616 26.1337 23.0833 26.512 23.0833 26.912V27.332C23.0833 27.7004 23.3816 27.9987 23.7499 27.9987H26.5833C26.9516 27.9987 27.2499 27.7004 27.2499 27.332V26.912C27.2499 26.512 27.0699 26.1337 26.7616 25.8804L25.8883 25.1654L26.7616 24.4504C27.0716 24.197 27.2499 23.8187 27.2499 23.4187Z'
        fill='white'
      />
    </svg>
    <span>데이터 관리</span>
  </Link>,
];

export const Menu: ReactNode[] = [
  <Settings>
    <svg
      width='32'
      height='33'
      viewBox='0 0 32 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect y='0.0488281' width='32' height='32' rx='16' fill='#495057' />
      <path
        d='M15.9996 15.7463C18.2633 15.7463 20.0984 13.9113 20.0984 11.6476C20.0984 9.3839 18.2633 7.54883 15.9996 7.54883C13.736 7.54883 11.9009 9.3839 11.9009 11.6476C11.9009 13.9113 13.736 15.7463 15.9996 15.7463Z'
        fill='#ADB5BD'
      />
      <path
        d='M21.3686 24.5486C18.4674 24.5486 13.5324 24.5486 10.6311 24.5486C9.27486 24.5486 8.29361 23.2211 8.72736 21.9373C9.76861 18.8523 12.6311 16.8086 15.9999 16.8086C19.3686 16.8086 22.2311 18.8523 23.2724 21.9373C23.7061 23.2223 22.7249 24.5486 21.3686 24.5486Z'
        fill='#ADB5BD'
      />
    </svg>
  </Settings>,
  <Guide>
    <svg
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.8354 9.5849C18.8204 9.5099 18.7954 9.4449 18.7604 9.3799C18.7504 9.3649 18.7454 9.3449 18.7354 9.3299C18.7004 9.2649 18.6554 9.2049 18.6004 9.1499L14.6004 5.1499C14.5454 5.0949 14.4854 5.0549 14.4204 5.0149C14.4054 5.0049 14.3904 4.9999 14.3754 4.9899C14.2604 4.9349 14.1354 4.8999 14.0004 4.8999H7.80039C6.34039 4.8999 5.15039 6.0899 5.15039 7.5499V17.9499C5.15039 19.4099 6.34039 20.5999 7.80039 20.5999H16.2004C17.6604 20.5999 18.8504 19.4099 18.8504 17.9499V9.7499C18.8504 9.6949 18.8454 9.6399 18.8354 9.5849ZM15.9504 8.8999H14.8504V7.7999L15.9504 8.8999ZM16.2004 18.8999H7.80039C7.27539 18.8999 6.85039 18.4749 6.85039 17.9499V7.5499C6.85039 7.0249 7.27539 6.5999 7.80039 6.5999H13.1504V9.1499C13.1504 9.9499 13.8004 10.5999 14.6004 10.5999H17.1504V17.9499C17.1504 18.4749 16.7254 18.8999 16.2004 18.8999Z'
        fill='#E9ECEF'
      />
      <path
        d='M15.0004 11.8999H9.00039V13.5999H15.0004V11.8999Z'
        fill='#E9ECEF'
      />
      <path
        d='M15.0004 15.3999H9.00039V17.0999H15.0004V15.3999Z'
        fill='#E9ECEF'
      />
    </svg>
    <span>이용 가이드</span>
  </Guide>,
];
