import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormProps } from '@pages/Exam/shared/config.ts';
import { usePreview } from '@pages/Exam/stdio/shared/usePreview.ts';
import EditorHeader, { EditorEventType } from '@components/EditorHeader';
import { useLanguageList } from '@hooks/exam/useLanguage.ts';
import EventUtil from '@helpers/eventutil.ts';
import Title from '../../shared/Title';
import Level from '../../shared/Level';
import Editor from '../../shared/Editor';
import Language from '../shared/Language';
import ExampleCase from '../shared/ExampleCase';
import AccuracyCase from '../shared/AccuracyCase';
import EfficiencyCase from '../shared/EfficiencyCase';
import Calculation from '../../shared/Calculation';
import { useError } from '../shared/useError.ts';
import { Container, EditorContent } from './style.ts';
import { useExam } from './hooks.ts';
import { useUpdateLanguageSet } from '@pages/Exam/shared/useUpdateLanguageSet.ts';
import { CodePreview } from '@phs/code';
import DetailLevel from '@pages/Exam/shared/DetailLevel/index.tsx';
import Algorithm from '@pages/Exam/shared/Algorithm/index.tsx';
import ExpectationLevel from '@pages/Exam/shared/ExpectationLevel/index.tsx';

const EditExamPage: FC = () => {
  const navigate = useNavigate();
  const { sn } = useParams<{ sn?: string }>();
  const { languageList } = useLanguageList();
  const {
    register,
    errors,
    clearErrors,
    watch,
    setValue,
    getValues,
    trigger,
    handleSubmit,
    onChangeLevel,
    onChangeDetailLevel,
    onChangeAlgorithm,
    onSubmit,
    isAbleToAddTestCase,
    isEnableCalculation,
    accuracyTestCasesCount,
    efficiencyTestCasesCount,
    isUpdateLoading,
  } = useExam({
    sn,
    languageList,
  });
  const { onPreview } = usePreview({
    trigger,
    getValues,
  });

  const { updateLanguageCallback } = useUpdateLanguageSet({
    testCases: watch('efficiencyTestCases') ?? [],
    fileTestCases: watch('efficiencyFileTestCases') ?? [],
    setValue,
  });

  const onClick = async (type: EditorEventType) => {
    switch (type) {
      case EditorEventType.CANCEL:
        navigate(-1);
        break;

      case EditorEventType.PREVIEW:
        onPreview();
        break;

      default:
        break;
    }
  };

  useError({
    errors,
    clearErrors,
  });

  useEffect(() => {
    EventUtil.initBeforeunloadEvent();

    return () => {
      EventUtil.destroyBeforeunloadEvent();
      CodePreview.cancelPreview();
    };
  }, []);

  return (
    <Container
      method={'POST'}
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e) => {
        //@ts-ignore
        if (e.key === 'Enter' && e?.target?.type !== 'textarea')
          e.preventDefault();
      }}
    >
      <EditorHeader
        title={<>문제&nbsp;수정</>}
        cancel={'취소'}
        onClick={onClick}
        preview={'미리 보기'}
        submit={'문제 수정'}
        isSubmitLoading={isUpdateLoading}
      />
      <EditorContent>
        <Title<FormProps>
          placeholder={'제목을 입력해주세요.'}
          register={register}
          errors={errors}
          watch={watch}
        />
        <Level<FormProps>
          {...(watch('level') > 0 && {
            initialState: watch('level'),
          })}
          onChange={onChangeLevel}
          errors={errors}
        />
        <DetailLevel<FormProps>
          {...(watch('detailLevel') > 0 && {
            initialState: watch('detailLevel'),
          })}
          onChange={onChangeDetailLevel}
          errorMessage={errors?.detailLevel?.message}
        />
        <Algorithm
          onChange={onChangeAlgorithm}
          selectedValues={watch('algorithmTypeList')}
        />
        <ExpectationLevel
          averageScore={watch('overallPredictionAverageScore')}
          level={watch('overallPredictionLevel')}
        />
        {watch('content') !== undefined && (
          <Editor
            initialValue={watch('content')}
            setValue={setValue}
            errors={errors}
          />
        )}
        <Language
          languages={watch('languages')}
          setValue={setValue}
          updateLanguageCallback={updateLanguageCallback}
        />
        <ExampleCase
          disabled={isAbleToAddTestCase}
          languages={watch('languages')}
          testCases={watch('exampleTestCases')}
          setValue={setValue}
        />
        <AccuracyCase
          disabled={isAbleToAddTestCase}
          languages={watch('languages')}
          testCases={watch('accuracyTestCases')}
          fileTestCases={watch('accuracyFileTestCases')}
          setValue={setValue}
        />
        <EfficiencyCase
          disabled={isAbleToAddTestCase}
          languages={watch('languages')}
          testCases={watch('efficiencyTestCases')}
          fileTestCases={watch('efficiencyFileTestCases')}
          setValue={setValue}
        />
        <Calculation
          isActive={watch('scoreRateYn')}
          disabled={!isEnableCalculation}
          accuracyScoreRate={watch('accuracyScoreRate')}
          efficiencyScoreRate={watch('efficiencyScoreRate')}
          register={register}
          accuracyCount={accuracyTestCasesCount}
          efficiencyCount={efficiencyTestCasesCount}
        />
      </EditorContent>
    </Container>
  );
};

export default EditExamPage;
