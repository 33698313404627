import { PMS_PAGES } from '@phs/constants';
import type { RouteObject } from 'react-router-dom';

const ErrorRoute: RouteObject = {
  path: PMS_PAGES.ERROR.HOME,
  children: [
    // {
    // 	path: PAGES.ERROR.SESSION,
    // 	element: <SessionErrorPage />,
    // },
  ],
};

export default ErrorRoute;
