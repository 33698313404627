import { FC } from 'react';
import type { Nullable } from '@phs/interfaces';
import { generateConfig } from '../shared';
import { generateRangePickerPanelConfig } from './config';
import { RangePickerWrapper } from './style';
import type { Dayjs } from 'dayjs';
import { RangePicker } from 'rc-picker';
import { RangeValue } from 'rc-picker/es/interface';
import locale from 'rc-picker/lib/locale/en_US';
import 'rc-picker/assets/index.css';
import './style.css';

interface RangeCalendarProps {
  value?: [Nullable<Dayjs>, Nullable<Dayjs>];
  placeholder?: [string, string];
  onChange?: (
    values: RangeValue<Dayjs>,
    formatString: [string, string],
  ) => void;
}

const RangeCalendar: FC<RangeCalendarProps> = ({
  value,
  placeholder,
  onChange,
}) => {
  return (
    <RangePickerWrapper>
      <RangePicker
        {...generateRangePickerPanelConfig}
        generateConfig={generateConfig}
        locale={locale}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
    </RangePickerWrapper>
  );
};

export default RangeCalendar;
