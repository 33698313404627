import { injectable } from 'inversify';
import 'reflect-metadata';
import { Language } from './base';
import { ExamParameterDTO } from '../interfaces/language.dto';
import { DataType } from '@phs/interfaces';

@injectable()
export class C extends Language {
  public get defaultTimeLimit() {
    return 1000;
  }

  public loadFunctionCode(parameters: ExamParameterDTO): string {
    return parameters.paramInfo
      .map(({ paramType, paramName }) => {
        const returnType = this.returnType[paramType];
        switch (paramType) {
          case DataType.INT_2_ARRAY:
          case DataType.LONG_2_ARRAY:
          case DataType.DOUBLE_2_ARRAY:
          case DataType.STRING_2_ARRAY:
          case DataType.BOOL_2_ARRAY:
            return `${returnType.paramType} ${paramName}, size_t ${paramName}_rows, size_t ${paramName}_cols`.trim();
          case DataType.INT_ARRAY:
          case DataType.LONG_ARRAY:
          case DataType.DOUBLE_ARRAY:
          case DataType.STRING_ARRAY:
          case DataType.BOOL_ARRAY:
            return `${returnType.paramType} ${paramName}[], size_t ${paramName}_length`.trim();
          case DataType.INT:
          case DataType.LONG:
          case DataType.DOUBLE:
          case DataType.STRING:
          case DataType.BOOL:
          default:
            return `${returnType.paramType} ${paramName}`.trim();
        }
      })
      .join(', ');
  }

  public makeStructAnnotation = (returnType: DataType): string => {
    switch (returnType) {
      case DataType.INT_ARRAY:
      case DataType.DOUBLE_ARRAY:
      case DataType.LONG_ARRAY:
      case DataType.BOOL_ARRAY:
      case DataType.STRING_ARRAY:
        return '//아래의 result_array는 C 언어 채점을 위해 정의된 구조체이며 solution 함수의 반환형입니다.\n//해당 구조체는 채점 서버에 내장되어있으며, 주석을 해제하시면 컴파일 오류가 발생할 수 있습니다.\n//struct result_array {\n//    void* result;\n//    int result_len;\n//};\n\n';

      case DataType.INT_2_ARRAY:
      case DataType.DOUBLE_2_ARRAY:
      case DataType.LONG_2_ARRAY:
      case DataType.BOOL_2_ARRAY:
      case DataType.STRING_2_ARRAY:
        return '//아래의 result_2d_array는 C 언어 채점을 위해 정의된 구조체이며 함수의 반환 형태입니다.\n//해당 구조체는 채점 서버에 내장되어있으며, 주석을 해제하시면 컴파일 오류가 발생할 수 있습니다.\n//struct result_2d_array {\n//    void* result;\n//    int result_rows;\n//    int result_cols;\n//};\n\n';

      case DataType.INT:
      case DataType.DOUBLE:
      case DataType.LONG:
      case DataType.BOOL:
      case DataType.STRING:
      default:
        return ``;
    }
  };

  public loadFunctionAnnotation(parameters: ExamParameterDTO): string {
    const structAnnotation = this.makeStructAnnotation(parameters.returnType);

    return (
      structAnnotation +
      parameters.paramInfo
        .map((value) => {
          switch (value.paramType) {
            case DataType.INT_2_ARRAY:
            case DataType.LONG_2_ARRAY:
            case DataType.DOUBLE_2_ARRAY:
            case DataType.STRING_2_ARRAY:
            case DataType.BOOL_2_ARRAY:
              return `//${value.paramName}_rows는 2차원 배열 ${value.paramName}의 행 길이, ${value.paramName}_cols는 2차원 배열 ${value.paramName}의 열 길이입니다.\n`;
            case DataType.INT_ARRAY:
            case DataType.LONG_ARRAY:
            case DataType.DOUBLE_ARRAY:
            case DataType.STRING_ARRAY:
            case DataType.BOOL_ARRAY:
              return `//${value.paramName}_length는 배열 ${value.paramName}의 길이입니다.\n`;
            case DataType.INT:
            case DataType.LONG:
            case DataType.DOUBLE:
            case DataType.STRING:
            case DataType.BOOL:
            default:
              return '';
          }
        })
        .join('')
    );
  }

  public returnTypeByC = (type: DataType, returnType: string) => {
    switch (type) {
      case DataType.INT_ARRAY:
      case DataType.DOUBLE_ARRAY:
      case DataType.LONG_ARRAY:
      case DataType.BOOL_ARRAY:
      case DataType.STRING_ARRAY:
        return 'struct result_array';

      case DataType.INT_2_ARRAY:
      case DataType.DOUBLE_2_ARRAY:
      case DataType.LONG_2_ARRAY:
      case DataType.BOOL_2_ARRAY:
      case DataType.STRING_2_ARRAY:
        return 'struct result_2d_array';

      case DataType.INT:
      case DataType.DOUBLE:
      case DataType.LONG:
      case DataType.BOOL:
      case DataType.STRING:
      default:
        return returnType;
    }
  };

  public returnStructByC(
    type: DataType,
    returnType: string,
    returnValue: string,
  ): string {
    switch (type) {
      case DataType.INT_ARRAY:
      case DataType.DOUBLE_ARRAY:
      case DataType.LONG_ARRAY:
      case DataType.BOOL_ARRAY:
      case DataType.STRING_ARRAY:
        return `    struct result_array result;\n\n    //정답이 저장되는 배열의 크기는 상황에 맞게 조절해주세요.\n    ${returnType} answer${returnValue};\n\n    result.result = answer;\n    result.result_len = 1; // 정답 배열의 길이를 정확하게 명시해주세요.\n    return result;\n`;

      case DataType.INT_2_ARRAY:
      case DataType.DOUBLE_2_ARRAY:
      case DataType.LONG_2_ARRAY:
      case DataType.BOOL_2_ARRAY:
      case DataType.STRING_2_ARRAY:
        return `    struct result_2d_array result;\n\n    //정답이 저장되는 배열의 크기는 상황에 맞게 조절해주세요.\n    ${returnType} answer${returnValue};\n\n    result.result = answer;\n    result.result_rows = 1; // 정답 배열의 행의 개수를 정확하게 명시해주세요.\n    result.result_cols = 1; // 정답 배열의 열의 개수를 정확하게 명시해주세요.\n    return result;\n`;

      case DataType.INT:
      case DataType.DOUBLE:
      case DataType.LONG:
      case DataType.BOOL:
      case DataType.STRING:
      default:
        return `    ${returnType} answer${returnValue};\n    return answer;\n`;
    }
  }

  public getFunctionTemplate(parameters: ExamParameterDTO): string {
    const { type, value } = this.returnType[parameters.returnType];
    return `#include <stdio.h>\n#include <stdbool.h>\n#include <stdlib.h>\n\n${this.loadFunctionAnnotation(
      parameters,
    )}${this.returnTypeByC(
      parameters.returnType,
      type,
    )} solution(${this.loadFunctionCode(parameters)}) {\n${this.returnStructByC(
      parameters.returnType,
      type,
      value,
    )}}`;
  }

  public getStdioTemplate() {
    return '#include <stdio.h>\n#include <stdbool.h>\n#include <stdlib.h>\n\nint main(void) {\n    int a;\n    int b;\n    scanf("%d %d", &a, &b);\n    printf("%d", a + b);\n    return 0;\n }';
  }

  public get returnType() {
    return {
      // Primitive Types
      INT: this.setReturnType('int', 'int', ' = 0'),
      LONG: this.setReturnType('long long', 'long long', ' = 0'),
      DOUBLE: this.setReturnType('double', 'double', ' = 0'),
      STRING: this.setReturnType('char*', 'const char*', ' = (char*)malloc(1)'),
      BOOL: this.setReturnType('bool', 'bool', ' = true'),
      // Reference Types
      INT_ARRAY: this.setReturnType('int*', 'int', ' = (int*)malloc(1)'),
      LONG_ARRAY: this.setReturnType(
        'long long*',
        'long long',
        ' = (long long*)malloc(1)',
      ),
      DOUBLE_ARRAY: this.setReturnType(
        'double*',
        'double',
        ' = (double*)malloc(1)',
      ),
      STRING_ARRAY: this.setReturnType(
        'char**',
        'const char*',
        ' = (char**)malloc(1)',
      ),
      BOOL_ARRAY: this.setReturnType('bool*', 'bool', ' = (bool*)malloc(1)'),
      // 2 Dimensional Reference Types
      INT_2_ARRAY: this.setReturnType('int**', 'int**', ' = (int**)malloc(1)'),
      LONG_2_ARRAY: this.setReturnType(
        'long long**',
        'long long**',
        ' = (long long**)malloc(1)',
      ),
      DOUBLE_2_ARRAY: this.setReturnType(
        'double**',
        'double**',
        ' = (double**)malloc(1)',
      ),
      STRING_2_ARRAY: this.setReturnType(
        'char***',
        'const char***',
        ' = (char***)malloc(1)',
      ),
      BOOL_2_ARRAY: this.setReturnType(
        'bool**',
        'bool**',
        ' = (bool**)malloc(1)',
      ),
    };
  }
}
