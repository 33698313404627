import { injectable } from 'inversify';
import dayjs from 'dayjs';
import {
  DATE_FORMAT,
  LanguageType,
  ModifyCommandType,
  Nullable,
  PackageProblemMakeReqDTO,
  ProblemBaseType,
  ProblemCategory,
} from '@phs/interfaces';
import {
  MockExamDTO,
  MockExamEnterType,
  ProblemDefaultDTO,
} from '../interfaces/mock.dto';

export interface CodePreviewProps {
  readonly languageFallback: Array<LanguageType>;

  setTargetOrigin(targetOrigin: string): void;

  openWindow(exam: ProblemDefaultDTO, token: string): void;

  openPackageWindow(exam: PackageProblemMakeReqDTO, token: string): void;

  cancelPreview(): void;
}

@injectable()
export class CodePreview implements CodePreviewProps {
  private _targetOrigin: Nullable<string> = null;
  private _openTarget: Nullable<Window> = null;
  private _previewData: Nullable<MockExamDTO> = null;

  public setTargetOrigin(targetOrigin: string) {
    this._targetOrigin = targetOrigin;
  }

  public get targetOrigin() {
    return this._targetOrigin;
  }

  public get languageFallback() {
    return [
      LanguageType.CPP,
      LanguageType.JAVA,
      LanguageType.PYTHON,
      LanguageType.JAVASCRIPT,
      LanguageType.CSHARP,
      LanguageType.KOTLIN,
      LanguageType.SWIFT,
      LanguageType.PYPY,
      LanguageType.GO,
      LanguageType.C,
    ];
  }

  public openWindow(exam: ProblemDefaultDTO, token: string) {
    this.setPreviewData(exam, token);
    setTimeout(() => this.activateWindow(), 400);
  }

  private setPreviewData(exam: ProblemDefaultDTO, token: string) {
    this._previewData = {
      token,
      examEnterType: MockExamEnterType.PREVIEW,
      mockProblemList: [
        {
          problemDefault: exam,
          problemPackage: null,
          problemBaseType: ProblemBaseType.DEFAULT,
        },
      ],
      mockExamOption: this.makeExamOption(
        exam.judgeLanguageList.map(({ language }) => language) ??
          this.languageFallback,
        1,
      ),
    };
  }

  public openPackageWindow(exam: PackageProblemMakeReqDTO, token: string) {
    this.setPackagePreviewData(exam, token);
    setTimeout(() => this.activateWindow(), 400);
  }

  private makeExamOption(languageList: LanguageType[], problemCount: number) {
    const currentTime = dayjs();

    return {
      problemCount,
      languageList,
      title: '미리보기',
      startDateTime: currentTime.format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss),
      endDateTime: currentTime
        .add(1, 'hour')
        .format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss),
      examOption: {
        timeLimit: 60,
        copyPastePermitYn: true,
        languageChangePermitYn: true,
        testCaseResultOpenYn: true,
        scoreOpenYn: true,
        titleOpenYn: true,
        faceRegisterYn: false,
        resolveCommentYn: false,
      },
    };
  }

  private setPackagePreviewData(
    { title, stepProblemList }: PackageProblemMakeReqDTO,
    token: string,
  ) {
    this._previewData = {
      token,
      examEnterType: MockExamEnterType.PREVIEW,
      mockProblemList: [
        {
          problemDefault: null,
          problemPackage: {
            title,
            stepList: stepProblemList.map(
              ({
                judgeLanguageList,
                testCaseList,
                content,
                summary,
                summaryYn,
                ...step
              }) => ({
                ...step,
                title,
                judgeLanguageList: judgeLanguageList || [],
                content,
                summary: summaryYn ? summary : '',
                testCaseList: testCaseList
                  .filter(
                    ({ commandType }) =>
                      commandType !== ModifyCommandType.DELETE,
                  )
                  .map((testCase, idx) => {
                    const newLanguageSet = testCase.testCaseLanguageSet
                      .filter(
                        ({ commandType }) =>
                          commandType !== ModifyCommandType.DELETE,
                      )
                      .map((lSet) => ({
                        ...lSet,
                        commandType: ModifyCommandType.CREATE,
                      }));
                    return {
                      ...testCase,
                      testCaseLanguageSet: newLanguageSet,
                      number: idx + 1,
                    };
                  }),
                judgeType: ProblemCategory.FUNCTION,
              }),
            ),
          },
          problemBaseType: ProblemBaseType.PACKAGE,
        },
      ],
      mockExamOption: this.makeExamOption(
        stepProblemList[0].judgeLanguageList?.map(({ language }) => language) ??
          this.languageFallback,
        1,
      ),
    };
  }

  private activateWindow() {
    if (this._openTarget) this._openTarget.close();

    window.addEventListener('message', this.messageHandler.bind(this));

    this._openTarget = window.open(
      `${this._targetOrigin}/exam/preview`,
      '_blank',
    );

    setTimeout(() => {
      if (!this._openTarget) {
        throw new Error('미리보기를 여는데 문제가 생겼습니다.');
      }
    }, 200);
  }

  private messageHandler(e: MessageEvent) {
    if (e.origin === this.targetOrigin && e.data === 'connect') {
      if (!this._openTarget || !this._previewData) {
        throw new Error('미리보기 연결에 실패하였습니다.');
      }

      this._openTarget!.postMessage(
        this._previewData,
        this._targetOrigin ?? '',
      );
    }
  }

  public cancelPreview() {
    window.removeEventListener('message', this.messageHandler);
    this._previewData = null;
  }
}
