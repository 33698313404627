import { FC } from 'react';
import {
  FunctionParameterAndReturnTypeDTO,
  PhaseProblemSourceDTO,
  TestCaseDTO,
  TestCaseType,
} from '@phs/interfaces';
import TextButton from '@widget/button-text';
import { Tooltip } from '@widget/tooltips';
import { IconInfo } from '@widget/icons';
import ClosableModal from '@components/Modals/Closable';
import { RenderTableBody } from '@components/Table';
import InputTable from '@components/InputTable';
import TimeLimitModal from '../TimeLimit';
import { Header, TableWrap, TooltipWrapper } from './style';
import { useTestCase } from './hooks';
import { renderParameters } from './config';
import TestCaseInformation from '@pages/Exam/shared/TestCaseInformation/function';

interface TestCaseModalProps {
  params: FunctionParameterAndReturnTypeDTO;
  languages?: PhaseProblemSourceDTO[];
  testCaseType?: TestCaseType;
  testCases?: TestCaseDTO[];
  onSubmit?: (data: TestCaseDTO[]) => void;
  onClose?: () => void;
}

const EfficiencyTestCaseModal: FC<TestCaseModalProps> = ({
  params,
  languages = [],
  testCaseType,
  testCases = [],
  onSubmit,
  onClose,
}) => {
  const {
    cache,
    addTestCase,
    renderData,
    handleSubmit,
    handleClose,
    isTimeLimitModalOpen,
    onCloseTimeLimitModal,
    selectedLanguages,
    renderTimeLimitData,
    renderHeaders,
    onSubmitTimeLimit,
  } = useTestCase({
    params,
    languages,
    testCaseType,
    testCases,
    onSubmit,
    onClose,
  });

  return (
    <>
      <ClosableModal
        onClose={handleClose}
        onSubmit={handleSubmit}
        title={
          <TooltipWrapper>
            <h3>테스트 케이스 추가</h3>
            <Tooltip content={<TestCaseInformation />} position={'right'}>
              <IconInfo
                style={{
                  width: 32,
                  height: 32,
                }}
              />
            </Tooltip>
          </TooltipWrapper>
        }
      >
        <Header>
          <TextButton
            onClick={addTestCase}
            theme={'secondary'}
            role='add-testcase'
          >
            추가&nbsp;+&nbsp;
          </TextButton>
        </Header>
        <TableWrap>
          <InputTable<TestCaseDTO>
            headers={renderHeaders()}
            parameters={renderParameters(params)}
            data={cache}
            renderTableBody={renderData as RenderTableBody<TestCaseDTO>}
          />
        </TableWrap>
      </ClosableModal>
      {isTimeLimitModalOpen && (
        <TimeLimitModal
          data={selectedLanguages}
          onClose={onCloseTimeLimitModal}
          renderTimeLimitData={renderTimeLimitData}
          onSubmit={onSubmitTimeLimit}
        />
      )}
    </>
  );
};

export default EfficiencyTestCaseModal;
