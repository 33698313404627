import { Container, EmptyArea, Input, Tbody } from './style';
import { useInputTable } from './hooks';
import { FC, ReactNode, memo } from 'react';
import Empty from './Empty';
import Removable from './Removable';

export { Input, Removable };

export interface InputTableParameterProps {
  width?: string;
  name: string;
  key: string;
}

export type InputTableHeaderType = string | ReactNode;

interface InputTableProps {
  parameters: InputTableParameterProps[];
  headers: InputTableHeaderType[];
  data?: ReactNode[][];
}

const InputTable: FC<InputTableProps> = ({
  parameters,
  headers,
  data = [],
}) => {
  const { colSpan } = useInputTable({
    parameterLength: parameters.length,
  });

  return (
    <Container>
      <thead>
        <tr>
          {headers.map((header, idx) => (
            <th
              colSpan={idx === 0 ? colSpan : 1}
              rowSpan={idx === 0 ? 1 : 2}
              key={idx}
            >
              {header}
            </th>
          ))}
        </tr>
        <tr>
          {parameters.map(({ name, key, width }, idx) => {
            return (
              <th key={`widget-table-input-th-${key}-${idx}`} style={{ width }}>
                {name}
              </th>
            );
          })}
        </tr>
      </thead>
      <Tbody>
        {data?.map((items, idx) => {
          return (
            <tr key={`widget-table-input-tbody-tr-${idx}`}>
              {items.map((element, idx) => (
                <td key={`widget-table-input-td-${idx}`}>{element}</td>
              ))}
            </tr>
          );
        })}
      </Tbody>
      {data?.length === 0 && (
        <EmptyArea>
          <tr>
            <td colSpan={colSpan + headers.length - 1}>
              <Empty />
            </td>
          </tr>
        </EmptyArea>
      )}
    </Container>
  );
};

export default memo(InputTable);
