import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import 'reflect-metadata';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import App from './App.tsx';

if (
  import.meta.env.VITE_RUNTIME_ENV === 'production' ||
  import.meta.env.VITE_RUNTIME_ENV === 'staging2'
) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    environment: import.meta.env.VITE_RUNTIME_ENV,
    tracesSampleRate: 0.01,
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 0.01,
  });
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <App />,
);
